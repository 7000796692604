import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';

const TaskingSheetTableCard = (props) => {
    const {setShowTaskingSheetTable} = props; 
 
    return (
    <Card className="user-panel-card" onClick={() => setShowTaskingSheetTable(true)}>
      <Card.Img className="user-panel-image" variant="top" src="/icons/test_1.svg"/>
      <Card.Body>
        <Card.Title className="user-panel-title">Tasking Sheet Table</Card.Title>
        <Card.Text className="user-panel-description">
          Property Tasking Sheet Info
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TaskingSheetTableCard;
