import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const EvaluationSeverityModal = ({ show, onHide, onSubmit, row }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const severityOptions = [
    { color: 'green', label: 'Green', value: 1 },
    { color: 'lightgreen', label: 'Light Green', value: 2 },
    { color: 'yellow', label: 'Yellow', value: 3 },
    { color: 'orange', label: 'Orange', value: 4 },
    { color: 'red', label: 'Red', value: 5 },
  ];

  const handleOptionClick = (value) => {
    setSelectedValue(value);
  };

  const handleSubmit = (e) => {
    if (selectedValue !== null) {
      onSubmit(e, row, selectedValue);
    }
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Set Evaluation Severity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {severityOptions.map(option => (
          <div
            key={option.value}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick(option.value);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              cursor: 'pointer',
              border: selectedValue === option.value ? '2px solid #000' : '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
              backgroundColor: '#fff',
            }}
          >
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: option.color,
                marginRight: '10px',
                borderRadius: '3px',
              }}
            ></div>
            <span>{option.label}</span>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EvaluationSeverityModal;