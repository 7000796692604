import React from 'react';
import Model from '../models/Model';

export function QueryProvider({ children }) {
    return ( 
        <QueryContext.Provider 
            value = {{
                runQueryNetworkOnly: Model.runQueryNetworkOnly,
                runQueryCacheAndNetwork: Model.runQueryCacheAndNetwork,
                runQuery: Model.runQuery,
                runMutation: Model.runMutation
            }}>
            {children}
        </QueryContext.Provider>
    );
}

const QueryContext = React.createContext({});
export default QueryContext;