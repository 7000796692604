import React, { useEffect, useState } from 'react';
import Equipment from '../../models/Equipment';
import _ from 'lodash';
import User from '../../models/User';
import { OverlayTrigger, Tooltip, Modal, Dropdown, Button, Form } from 'react-bootstrap';

const UserCard = (props) => {
  const { 
    onUserSelect,
    user
  } = props;

  return (
    <div className="grid-card card btn rounded-lg mr-1 mb-1" style={{width: '15em'}} key={user.uuid} onClick={(event) => {
      event.preventDefault();
      console.log("clicked user", user)
      onUserSelect(user)
    }}>
      <div className="card-body pt-0 pl-0 pr-0 pb-0 mt-1">
        <h5 className="card-title" onClick={(event) => {}}>
          {user.full_name}
        </h5>

        <div className="d-flex flex-column" >
          <span className="d-flex flex-row justify-content-between">
            <span>Company</span>
            <span>{`${user.company ? user.company.title : ""}`}</span>
          </span>
          <span className="d-flex flex-row justify-content-between flex-wrap">
            <span>Email</span>
            <span>
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Groups</span>
            <span>{User.allCompanyGroups(user).map(cg => cg?.title).join(', ')}</span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Roles</span>
            <span>{user.roles ? user.roles.map(r => r.name).join(', ') : ""}</span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Deleted</span>
            <span>{User.isDeletedStatusText(user)}</span>
          </span>

        </div>
      </div>
    </div>
  )
}

export default UserCard;