import React, { useEffect, useState } from 'react';
import User from "../../models/User";
import Utils from "../../common/Utils";
import Company from "../../models/Company";
import {useAuth} from '../../context/AuthContext';
import DataTable from 'react-data-table-component';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import UserEditScreen from '../user/UserEdit';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcRefresh } from 'react-icons/fc';
import UserHistoryHorizontalScreen from './UserHistoryHorizontalScreen';
import UserCard from './UserCard';

const UserGrid = () => {
  const [users, setUsers] = useState([]);
  const {
    user,
    isAdminOrHigher,
    isSuperAdmin
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [userHistory, setUserHistory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleFilterModalClose = () => setShowModal(false);
  const handleFilterModalShow = () => setShowModal(true);
  const location = useLocation();
  const searchName = (new URLSearchParams(location.search)).get('q');
  const [userQuery, setUserQuery] = useState(searchName);
  const debouncedUserQuery = Utils.useDebounce(userQuery, 250);
  const defaultSearchOptions = {
    filters: [
      {"field": "full_name", "value": userQuery || "", "comparison": "LIKE"}
    ],
    sortOrder: "asc",
    sortField: "full_name",
    company_id: user.company_id || "0"
  };
  const [searchOptions, setSearchOptions] = useState(defaultSearchOptions);
  
  const updateCompanies = async () => {
    const allCompanies = await Company.all();
    setCompanies(allCompanies);
  }

  useEffect(() => {
    updateCompanies();
  }, [])
  
  useEffect(() => {
    searchUsers();
  }, [JSON.stringify(searchOptions)])

  useEffect(() => {
    setSearchOptions({
      ...setSearchOptions,
      filters: debouncedUserQuery ? searchOptions.filters.map(f => f.field === "full_name" ? {...f, value: debouncedUserQuery || ""} : f) : searchOptions.filters
    });
  }, [debouncedUserQuery])
  
  const onSubmit = async data => {
    const updatedSearchOptions = data.formData;
    setSearchOptions(updatedSearchOptions);
    handleFilterModalClose();
  }

  const userHistoryHorizontalScreen = React.useMemo(() => {
    return <UserHistoryHorizontalScreen
    userUUID={user.uuid}
    userID={user.id}
    queryUserInfo
    queryUploads
    queryChecks
    showArrows
    vertical
    noResultsMessage="No User History."
  ></UserHistoryHorizontalScreen>
  }, [user])

  const selectedUserHistoryHorizontalScreen = React.useMemo(() => {
    return <UserHistoryHorizontalScreen
    userUUID={userHistory ? userHistory.uuid : ""}
    userID={userHistory ? userHistory.id : ""}
    queryUserInfo
    queryUploads
    queryChecks
    multiRow
    noResultsMessage="No User History."
    daysAgoCutoff={1000}
  ></UserHistoryHorizontalScreen>
  }, [userHistory])

  const searchUsers = async () => {
    setLoading(true);
    const companyID = user && user.company_id;
    const searchAllCompanies = searchOptions.company_id === "0";
    const results = await User.filterBy([
      // {"field": "archived", "value": 0, "comparison": "="},
      searchAllCompanies ? {"field": "company_id", "value": `0`, "comparison": "<>"} : {"field": "company_id", "value": `${companyID}`, "comparison": "="},
      !Utils.isBlank(searchOptions.company_id) && searchOptions.company_id > 0 ? {"field": "company_id", "value": `${searchOptions.company_id}`, "comparison": "="} : {"field": "company_id", "value": "-1", "comparison":"<>"},
      ...searchOptions.filters
    ], searchOptions.sortField || "full_name", searchOptions.sortOrder || "asc", 1000000);
    results.data = _.orderBy(results.data, [
      user => user.archived || user.state === 'passive',
      user => user.full_name,
    ], ['asc', 'asc']);
    setUsers(results.data);
    console.log("user results", results)
    setLoading(false);
  }

  const columns = isAdminOrHigher() ? [
    {
      name: 'Email',
      selector: row => row["email"],
      sortable: true,
      right: true,
    },
    {
      name: 'Name',
      selector: row => row["full_name"],
      sortable: true,
      right: true,
    },
    {
      name: 'Company',
      selector: (row) => `${row.company ? row.company.title : ""}`,
      sortable: true,
      right: true,
    },
    {
      name: 'Group(s)',
      selector: (row) => `${User.allCompanyGroups(row).map(cg => cg?.title).join(', ')}`,
      sortable: true,
      right: true,
    },
    {
      name: 'Roles',
      selector: (row) => row.roles ? row.roles.map(r => r.name).join(', ') : "",
      sortable: true,
      right: true,
    },
    {
      name: 'Deleted',
      //  || row.state === 'passive' is used on old platform...
      selector: (row) => row.archived,
      cell: (row) => User.isDeletedStatusText(row),
      sortable: true,
      right: true,
    },
    {
      name: 'Actions',
      cell: ((row) => {
        return (
          <div>
            <Button variant="link" onClick={() => {
              setEditModalUserUUID(row.uuid);
            }}>
              Edit
            </Button>
          </div>
        )
      }),
      right: true
    }
  ]
  : 
  [
    {
      name: 'Email',
      selector: row => row["email"],
      sortable: true,
      right: true,
    },
    {
      name: 'Name',
      selector: row => row["full_name"],
      sortable: true,
      right: true,
    },
    {
      name: 'Actions',
      cell: ((row) => {
        return (
          <div>
            <Button variant="link" onClick={() => {
              setEditModalUserUUID(row.uuid);
            }}>
              Edit
            </Button>
          </div>
        )
      }),
      right: true
    }
  ];

  const onSearchChange = async (event) => {
    setUserQuery(event.target.value);
    if (event.target.value.length === 0){
      setSearchOptions({
        ...setSearchOptions,
        filters: searchOptions.filters.map(f => f.field === "full_name" ? {...f, value: ""} : f)
      });
    }
  }

  const searchUISchema = {
    "filters": {
      "ui:options": {
        "addable": true,
        "orderable": false,
        "removable": true 
      }
    },
    "company_id": {
      "ui:widget": !isSuperAdmin() ? "hidden" : "select"
    }
  }

  const searchValidationSchema = () => {
    return {
        "type": "object",
        "properties": {
            "filters": {
                "description": "",
                "title": "",
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                      "field": {
                        "type": "string",
                        "title": "Column",
                        "enum": ["email", "full_name", ...(isSuperAdmin() ? ["companies.title", "company_groups.title"] : [])],
                        "enumNames": ["Email", "Full name", ...(isSuperAdmin() ? ["Company", "Company Group (Assigned)"] : [])],
                        "default": "email"
                      },
                      "comparison": {
                        "type": "string",
                        "title": "Comparison",
                        "enum": ["=", ">", "<", "<>", "LIKE"],
                        "enumNames": ["Same As", "Greater Than", "Less Than", "Not Equal To", "Contains"],
                        "default": "LIKE"
                      },
                      "value": {
                        "type": "string",
                        "title": "Value"
                      },
                    }
                }
            },
            "company_id": {
                "title": "User Company",
                "type": "string",
                "enum": companies ? [
                  ...(isSuperAdmin() ? ["0"] : []),
                  ...companies.map(c => `${c.id}`)
                ] : [],
                "enumNames": companies ? [
                  ...(isSuperAdmin() ? ["All Companies"] : []),
                  ...companies.map(c => c.title)] : [],
                "default": `${user.company_id || 0}`
            },
            "sortField": {
              "type": "string",
              "title": "Sort Field",
              "enum": ["email", "full_name"], //, "company_id"],
              "enumNames": ["Email", "Full name"], //, "Company"],
              "default": searchOptions.sortField || "full_name"
            },
            "sortOrder": {
              "type": "string",
              "title": "Sort Order",
              "enum": ["asc", "desc"],
              "enumNames": ["Ascending", "Descending"],
              "default": searchOptions.sortOrder || "asc"
            },
        }
    };
  }

  const [editModalUserUUID, setEditModalUserUUID] = useState(null);
  const handleEditModalClose = (updatedUser) => {
    setEditModalUserUUID(null);
    if (updatedUser) {
      const matchingUserRow = _.first(users.filter(x => x.uuid === updatedUser.uuid));
      if (matchingUserRow) {
        setUsers([...(users.filter(x => x.uuid !== updatedUser.uuid)), {...matchingUserRow, ...updatedUser}])
      }
    }
  }

  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const handleNewModalClose = () => {
    setShowNewUserModal(false);
  }

  return (
  <>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        {userHistory === null &&
        <div className="d-flex flex-column bg-white flex-grow-1 pl-3 pr-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 ml-2 mr-2 mb-3 ">
              <h1 className="h2">Users</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <Modal show={editModalUserUUID !== null} onHide={handleEditModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <UserEditScreen
                      uuid={editModalUserUUID}
                      isModal={true}
                      modalDismiss={handleEditModalClose}
                    ></UserEditScreen>
                  </Modal.Body>
                </Modal>

                <Modal show={showNewUserModal} onHide={handleNewModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <UserEditScreen
                      uuid={null}
                      isModal={true}
                      modalDismiss={handleNewModalClose}
                    ></UserEditScreen>
                  </Modal.Body>
                </Modal>

                <Modal show={showModal} onHide={handleFilterModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      schema={searchValidationSchema()}
                      uiSchema={searchUISchema}
                      formData={searchOptions}
                      onSubmit={onSubmit}
                    />
                  </Modal.Body>
                </Modal>

                <input
                id="search"
                type="text"
                autoComplete="false" 
                placeholder="Quick Search by Name"
                aria-label="Search by Name"
                value={userQuery || ''}
                // onSubmit={e => e.preventDefault()}
                className="mt-2"
                onChange={onSearchChange} />

                <ButtonToolbar>
                  <ButtonGroup className="ml-2 mt-2">
                    <Button className="btn btn-primary" onClick={handleFilterModalShow}>
                    Advanced Filters
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-2 mt-2 mr-2">
                    <Button variant="success" onClick={() => { setShowNewUserModal(true); }}>
                    New User
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-1 mt-2 mr-1">
                    <a className={`btn btn-outline-secondary`} onClick={() => {
                      searchUsers();
                    }}>
                      <div className="d-flex flex-row text-left">
                        <FcRefresh style={{fontSize: 24}}/>
                        <div className="pl-2">Refresh</div>
                      </div>
                    </a>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
              {users.map(user => {
                return <UserCard 
                  user={user}
                  onUserSelect={(row) => {setUserHistory({id: row.id, uuid: row.uuid}) }}
                ></UserCard>
              })}
            </div>
          </div>
        }
        {userHistory !== null &&
          <div className="d-flex flex-column bg-white flex-grow-1"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
            <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 pl-2 pr-2 mr-2 mb-3 ">
              <h1 className="h2">Selected User's History</h1>
              <div className="btn-toolbar mb-2 mb-md-0">

                <ButtonToolbar>
                  <ButtonGroup className="ml-2 mt-2">
                    <Button className="btn btn-primary" onClick={() => setUserHistory(null)}>
                    Back
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            {selectedUserHistoryHorizontalScreen}
          </div>
        }

    </div>

    
    </>
  )
}


export default UserGrid;