import Model from "./Model";
import { TASK_TYPES } from '../constants/Constants';
import { FcCheckmark, FcVideoFile, FcInspection, FcCalculator, FcCalendar, FcCollect, FcElectricity, FcKindle, FcPicture } from 'react-icons/fc';

export default class Check extends Model {
    static filterBy = async (searchList=[], sortField="uuid", sortOrder="desc", first, after, last, before) => {

        const data = await super.filterBy(CheckFilter, searchList, sortField, sortOrder, first, after, last, before);
        const result = data && data.checkFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }


  static getTaskCodeProperName = type_code => {
    if (type_code in TASK_TYPES){
      return TASK_TYPES[type_code].name;
    }
    return "Unknown Task Name";
  }

  static formatCheckValue = checkContext => {
    const {v, type_code, image, complete} = checkContext;
    let parsed = {}
    try{
      parsed = JSON.parse(v)
    }catch{
      return ''
    }
    if (type_code === TASK_TYPES.BOOL.code){
      return complete ? <FcCheckmark style={{fontSize:20}}></FcCheckmark> : "Not Checked"
    // }else if (type_code === TASK_TYPES.PERC.code){
    //     return parsed.value + '%';
    }else if (parsed.hasOwnProperty("value")){
      return parsed.value;
    }else if ((type_code === TASK_TYPES.VIDEO.code || type_code === TASK_TYPES.PHOTO.code) || (image && image.key && image.bucket)){
      if (image === null || image ===  undefined){
        return ""
      }
      const destinationUrl = `https://mv.dstapp.com/${image.bucket}/${image.key}`
      return <a target="_blank" rel="noreferrer noopener" href={destinationUrl}>View Here</a>;
    }else if (parsed.hasOwnProperty("amp1")){
      return `${parsed.amp1 || "_"}, ${parsed.amp2 || "_"}, ${parsed.amp3 || "_"}`;
    }else {
      return `${v}`;
    }
  }

  static formatCheckValueTextOnly = checkContext => {
    const {v, type_code, image, complete} = checkContext;
    let parsed = {}
    try{
      parsed = JSON.parse(v)
    }catch{
      return ''
    }
    if (type_code === TASK_TYPES.BOOL.code){
      return complete ? "Checked" : "Not Checked"
    }else if (parsed.hasOwnProperty("value")){
      return parsed.value;
    }else if ((type_code === TASK_TYPES.VIDEO.code || type_code === TASK_TYPES.PHOTO.code) || (image && image.key && image.bucket)){
      if (image === null || image ===  undefined){
        return ""
      }
      const destinationUrl = `https://mv.dstapp.com/${image.bucket}/${image.key}`
      return destinationUrl;
    }else if (parsed.hasOwnProperty("amp1")){
      return `${parsed.amp1 || "_"}, ${parsed.amp2 || "_"}, ${parsed.amp3 || "_"}`;
    }else {
      return `${v}`;
    }
  }
}

const GQL_SHALLOW_INSPECTION_FRAGMENT = `{
    id
    uuid
    created_at
    updated_at
    name
    task_uuid
    type_code
    complete
    v
    recorded_at
    app_version
    user_id
    user {
      uuid
      id
      full_name
  }
    device_uuid
    fig_uuid
    fig {
      name
      fig_image_ext
      fig_image_key
      image{
          bucket
          key
          region
      }
  }
    image {
      bucket
      key
    }
    task {
      name
    }
}`

const CheckFilter = `
query CheckFilter($input: FilterConnectionInput){
    checkFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_SHALLOW_INSPECTION_FRAGMENT}
        
      }
    }
  }
`