import { useEffect, useState, useRef, Component, useMemo } from 'react';
import Company from "../../models/Company";
import Property from "../../models/Property";
import UserCompanyGroup from "../../models/UserCompanyGroup";
import Systems from "../../models/Systems";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import Utils from "../../common/Utils";
import {useAuth} from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import User from '../../models/User';
import {
    useParams,
    useHistory
  } from "react-router-dom";
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import Roles from '../../models/Roles';
import { ImSpinner3 } from 'react-icons/im';
import '../../styles/iconStyles.css';
import { USER_ROLES } from '../../constants/Constants';
import CompanyGroup from '../../models/CompanyGroup';
import MixedTags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import { Button } from 'react-bootstrap';
import {NavSidebar} from '../components/NavSidebar';
import { ConsoleLogger } from '@aws-amplify/core';


const UserEditScreen = (props) => {
    const { user, isSubdomainAdminOrHigher, isSuperAdmin, isLimitedAdminOrHigher, signout } = useAuth();
    const history = useHistory();
    let {uuid} = useParams();
    uuid = uuid || props.uuid;
    const isModal = props.isModal;
    const modalDismiss = props.modalDismiss;
    const goBackFn = props.goBackFn;
    
    const newUser = uuid === undefined || uuid === null;
    const [updatedUserJSON, setUpdatedUserJSON] = useState({});
    const [foundUser, setFoundUser] = useState({});
    

    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true);
    const [canEditPassword, setCanEditPassword] = useState(false);
    

    // Users editing themselves should not be able to change their own role (except for super admins)
    const [showRoleSelection, setShowRoleSelection] = useState(true);
    let allRolesPossible = [];
    let allCompanyGroups = [];
    let allCompanies = [];
    const [rolesShown, setRolesShown] = useState([]); // Limit what roles are shown 
    const [companyGroupsShown, setCompanyGroupsShown] = useState([]); // Limit what company groups are shown
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);
    const [roleSelectionDisabled, setRoleSelectionDisabled] = useState(false);
    const [companyGroupSelectionDisabled, setCompanyGroupSelectionDisabled] = useState(true);
    
    useEffect(() => {
        loadUserAndFormOptions();
        console.log("rolesShown", rolesShown); 
    }, [uuid])

    const setDefaults = () => {
        setUpdatedUserJSON({});
        setRolesShown([]);
        setRoleSelectionDisabled(false);
        setShowRoleSelection(true);
        setHasPermissionToEdit(true);
    }

    const updateRoles = async () => {
        allRolesPossible = await Roles.all();
    }
    const updateCompanyGroups = async () => {
        allCompanyGroups = await CompanyGroup.all()
    }
    const updateCompanies = async () => {
        allCompanies = await Company.all()
    }

    const loadUserAndFormOptions = async () => {
        setLoading(true);
        setDefaults();
        const loggedInUser = await User.findByUUID(user.uuid);
        await Promise.allSettled([
            updateRoles(), 
            updateCompanies(), 
            updateCompanyGroups()
        ]);

        updateDropdowns(loggedInUser);
        if (newUser){
            setCanEditPassword(true);
            setRoleSelectionDisabled(false);
            setShowRoleSelection(true);
            setHasPermissionToEdit(true);
            setUpdatedUserJSON({
                uuid: uuidv4(),
                company_id: loggedInUser.company_id
            })
        }else{
            await loadExistingUser(loggedInUser);
        }

        setLoading(false);
    }
    const updateDropdowns = (loggedInUser) => {
        // Limit shown dropdown options
        const loggedInUserHighestRole = User.highestRole(loggedInUser);
        updateCompaniesDropdownValues(loggedInUser, allCompanies);
        updateRoleDropdownValues(loggedInUser, allRolesPossible);
        setCompanyGroupsShown(allCompanyGroups);
    }

    const loadExistingUser = async (loggedInUser) => {
        
        console.log("IS SUPER ADMIN?", isSuperAdmin());
        const loggedInUserHighestRole = User.highestRole(loggedInUser);
        console.log("loggedInUserHigherRole", loggedInUserHighestRole);
        const foundUser = await User.findByUUID(uuid);
        console.log("foundUser", foundUser);

        setFoundUser(foundUser);
        const foundUserHighestRole = _.head((foundUser.roles || []).sort((a,b) => a.clearance_level - b.clearance_level)) || 0;

        if ((loggedInUser.roles || []).length === 0){
            setShowRoleSelection(false);
        }
        
        // Adding or removing a user from a company group requires super admin
        if (isSuperAdmin()){
            setCompanyGroupSelectionDisabled(false);
        }

        // Unless your a super admin, someone else has to change YOUR user permissions for you
        // Prevents someone from increasing their own permissions
        if (loggedInUser.uuid === foundUser.uuid && !isLimitedAdminOrHigher()){
            setRoleSelectionDisabled(true);
        }

        // When editing other users, only users with higher permissions can make changes
        if (loggedInUserHighestRole.clearance_level > foundUserHighestRole.clearance_level && foundUser.roles_bf !== 0){
            toast.error(
                `Logged in user does not have higher privileges than the editing user. Please make these changes via a higher privileged user.`,
            );
            setHasPermissionToEdit(false);
            setCanEditPassword(false);
        }else{
            if (user.uuid === foundUser.uuid || isSubdomainAdminOrHigher()){
                setCanEditPassword(true);
            }
        }

        setUpdatedUserJSON({
            ...foundUser,
            email: foundUser.email && foundUser.email.trim() || '',
            phone: foundUser.phone || '',
            newRolesBitField: User.rolesBFToSelectionArray(foundUser.roles_bf)
        });
    }

    const updateRoleDropdownValues = (loggedInUser, allRolesPossible) => {
        const loggedInUserHighestRole = User.highestRole(loggedInUser);
        let availableRoleOptions = allRolesPossible
            .filter(v => v.clearance_level >= loggedInUserHighestRole.clearance_level)
            .filter(v => v.name != USER_ROLES.guest);
        
        // Map the array to modify role titles
        availableRoleOptions = availableRoleOptions.map(role => {
            if (role.title === "Subdomain - Admin") {
                return { ...role, title: "Administrator" };
            }
            if (role.title === "Subdomain - Tech") {
                return { ...role, title: "Technician" };
            }
            return role;
        });
    
        // Filter out the "Subdomain - Customer" role
        availableRoleOptions = availableRoleOptions.filter(role => role.title !== "Subdomain - Customer");
    
        setRolesShown(availableRoleOptions);
         
    }
    

    const updateCompaniesDropdownValues = (loggedInUser, allCompanies) => {
        if (isLimitedAdminOrHigher()){
            setCompanies(allCompanies);
        }else{
            setCompanies(allCompanies.filter(c => c.id === loggedInUser.company_id));
        }
    }

    const ErrorListTemplate = (props) => {
        const { errors } = props;
        return (
            <div className="alert-danger p-3 m-2">
                <h2>Validation Errors</h2>
                <ul>
                    {errors.map(error => (
                        <li key={error.stack}>
                            {error.readableStack}: 
                            <br/>
                            <ul>
                                <li>{error.message}</li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const transformErrors = (errors) => {
        console.log(errors)
        return errors.map(error => {
            const pathSegments = error.property.split('.');
            const attributeName = pathSegments.slice(pathSegments.length - 1)
            error.readableStack = 
            [
                `${attributeName}`
            ]
            return error;
        });
    }

    const onError = errors => {
        if (errors.length > 0){
            window.scrollTo(0, 0)
            toast.error(
                `Validation Error: Check error list for specific validation warnings and recommendations.`,
            );
        }
    }

    const onChange = data => {
        console.log("on change data.formData:", data.formData)
        // Keep the newRolesBitField(the checkboxes) in sync with the actual roles_bf value
        const targetRoleBF = data.formData.newRolesBitField || [];
        let newRoleLength = Math.max(...targetRoleBF) + 1;
        // console.log(targetRoleBF, newRoleLength)
        newRoleLength = newRoleLength < 0 ? 0 : newRoleLength;
        // console.log(newRoleLength)
        const newRolesBits = new Array(newRoleLength).fill(0);
        targetRoleBF.map((bit, index) => {
            newRolesBits[bit] = 1;
        })
        const newRolesBF = parseInt( newRolesBits.reverse().join(''), 2) || 0;
        // console.log("updating roles bf from ", updatedUserJSON.roles_bf, " to ", newRolesBF);
        // console.log("updating roles from ", updatedUserJSON.newRolesBitField, " to ", data.formData.newRolesBitField);
        setUpdatedUserJSON({
            ...data.formData,
            roles_bf: newRolesBF
        })
    }

    const updateArchivedStatus = async (archived = true) => {
        toast.info(
            `${archived ? 'Deleting' : 'Restoring'} ${foundUser.full_name || foundUser.first_name + " " + foundUser.last_name}`,
            {autoClose: 5000}
        );
        const newUserFields = {uuid: foundUser.uuid, archived: archived, state: archived ? 'passive': 'active'};
        await User.update(newUserFields);
        if (isModal){
            modalDismiss({...foundUser, ...newUserFields});
        }
    }   

    const onSubmit = async data => {
        console.log("on Submit, form data:", data.formData);
        const updatedUser = data.formData;
        updatedUser.company_group_uuids = updatedUser.company_groups.map(cg => cg.uuid);
        if (!updatedUser.full_name) {
            updatedUser.full_name = `${updatedUser.first_name} ${updatedUser.last_name}`;
        }
        

        console.log("updatedUser", updatedUser)

        // Only overwrite password if it is populated
        if (!Utils.isBlank(updatedUser.password) && updatedUser.password.length > 1){
            updatedUser.password_hash = md5(updatedUser.password)
            updatedUser.password = '';
        }
        
        setUpdatedUserJSON(updatedUser);

        if (newUser){
            updatedUser.email = `${updatedUser.email}`.trim() // prevent a few common typos 
            const emailAvailable = await User.isEmailAlreadyUsed(updatedUser.email);
            if (!emailAvailable){
                toast.error(
                    `${updatedUser.email} is already used by another user. Please contact DST support`,
                    {autoClose: 5000}
                );
                return;
            }

            toast.info(
                `Creating ${updatedUser.full_name || updatedUser.first_name + " " + updatedUser.last_name}`,
                {autoClose: 5000}
            );

            await User.create(updatedUser);
            console.log("THIS IS THE UPDATED USER THAT WAS CREATED", updatedUser);
            if (updatedUser.company_group_uuids && updatedUser.company_group_uuids.length >= 0){
                await UserCompanyGroup.create({
                    user_uuid: updatedUser.uuid,
                    company_group_uuids: updatedUser.company_group_uuids
                })
            }
            if (!isModal){
               // history.goBack();
            }
        }else{
            toast.info(
                `Updating ${updatedUser.full_name}`,
                {autoClose: 5000}
            );
            await User.update(updatedUser);
            console.log("updatedUser.company_group_uuids && updatedUser.company_group_uuids.length > 0", updatedUser.company_group_uuids && updatedUser.company_group_uuids.length > 0)
            if (updatedUser.company_group_uuids && updatedUser.company_group_uuids.length >= 0){
                await UserCompanyGroup.create({
                    user_uuid: updatedUser.uuid,
                    company_group_uuids: updatedUser.company_group_uuids
                })
            }
            if (user.uuid === updatedUser.uuid){
                signout()
                history.push("/auth")
                history.push("/")
            }else{
                if (!isModal){
                    //history.goBack();
                }
            }
        }

        if (isModal){
            modalDismiss(newUser ? null : updatedUser);
        }

        goBackFn();
    }

    const CompanyGroupSelector = (props) => {
        const {disabled} = props;
        const update = (e) => {
            const selectedCompanyGroups = e.detail.tagify.getCleanValue()
            props.onChange(selectedCompanyGroups);
        }

        return (
            <>
                <p>Assigned Company Group(s)</p>
                {foundUser && foundUser.company && foundUser.company.companyGroup.length > 0
                    ? <span className="small text-muted">Pre-assigned Company Group: {_.first(foundUser.company.companyGroup).title}</span>
                    : <></>
                }
                <MixedTags
                    whitelist={companyGroupsShown.map(x => {
                        return {value: x?.title, uuid: x?.uuid}
                    })}
                    settings={{
                        dropdown: {
                            enabled: 0,
                            highlightFirst: true,
                            closeOnSelect : true,
                            maxItems: 10,
                            fuzzySearch: true
                        },
                        editTags: false
                    }}
                    value={props.formData ? props.formData : (newUser ? [] : User.assignedCompanyGroups(foundUser).map(x => {
                        return {value: x?.title, uuid: x.uuid}
                    }))}
                    //readOnly={disabled}
                    readOnly={isSubdomainAdminOrHigher ? disabled : true}

                    onChange={update}
                    onRemove={update}
                    onBlur={update}
                />
            </>
          );
    }


    const CustomPasswordWidget = (props) => {
        const [inputType, setInputType] = useState('password');
        const togglePasswordVisibility = () => {
          setInputType(inputType === 'password' ? 'text' : 'password');
        };
    
        return (
          <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 ml-n3">
            <div>
              <label className="pb-2">{props.schema.title}</label>
            </div>
            <div className="d-flex justify-content-start">
              <div className="w-100">
                <input
                  type={inputType}
                  className="form-control rounded-lg" 
                  value={props.value}
                  onChange={(event) => props.onChange(event.target.value)}
                  autoComplete="new-password"
                />
              </div>
              <button className="btn btn-primary ml-2" type="button" onClick={togglePasswordVisibility}>
                {inputType === 'password' ? 'Show' : 'Hide'}
              </button>
            </div>
          </div>
        );
    };
    
    

    
    
    
      

    const uiSchema = useMemo(() => {
        return {
            "first_name": {
            "ui:autofocus": true,
            "ui:emptyValue": "",
            },
            "last_name": {
            "ui:emptyValue": "",  
            },
            "title": {
            "ui:emptyValue": "",
            },
            "phone": {
            "ui:emptyValue": "",
            },
            "newRolesBitField": {
            "ui:widget": "checkboxes",
            "ui:disabled": roleSelectionDisabled
            },
            "email": {
                "ui:autoComplete":"new-password",
                "ui:readonly": !newUser,
            },
            "password": {
                "ui:widget": CustomPasswordWidget,
                "ui:autoComplete":"new-password",
            },
            "company_groups": {
                "ui:field": "TagSelector",
                "ui:disabled": companyGroupSelectionDisabled
            },
        }
    }, [companyGroupSelectionDisabled, roleSelectionDisabled]);
    
    const validationSchema = () => {
        return {
            "type": "object",
            "required": [
                "uuid",
                "first_name",
                "last_name",
                "email", 
                "phone"
            ],
            "properties": {
                "first_name": {
                    "type": "string",
                    "title": "First Name",
                    "maxLength": 500,
                    "minLength": 1,
                },
                "last_name": {
                    "type": "string",
                    "title": "Last Name",
                    "maxLength": 500,
                    "minLength": 1,
                },
                "title": {
                    "type": "string",
                    "title": "Title",
                    "maxLength": 500,
                },
                "company_id": {
                    "enum": Utils.isBlank(companies) ? [] : companies.map(c => c.id),
                    "enumNames": Utils.isBlank(companies) ? [] : companies.map(c => c.title),
                    "type": "string",
                    "title": "Company"
                },
                "company_groups": {
                    "type": "array",
                    "title": "Company Groups",
                    "items": {
                        "type": "object",
                    },
                    "default": (newUser ? [] : User.assignedCompanyGroups(foundUser)?.map(x => {
                        return {value: x?.title, uuid: x?.uuid}
                    }))
                },
                "email": {
                    "type": "string",
                    "format": "email",
                    "title": "Email",
                    "maxLength": 500,
                    "minLength": 5,
                },
                "phone": {
                    "type": "string",
                    "format": "phone",
                    "title": "Phone",
                    "default": "",
                },
                "password": !canEditPassword ? {} : {
                    "type": "string",
                    "format": "password",
                    "title": "Password ",
                    "maxLength": 500,
                    "minLength": 1,
                },
                "newRolesBitField": !showRoleSelection || rolesShown.length === 0 ? {} : {
                    "type": "array",
                    "title": "Roles",
                    "items": {
                        "type": "number",
                        "enum": (rolesShown || []).map(r => r.bit_idx),
                        "enumNames": (rolesShown || []).map(r => r.title)
                    },
                    "uniqueItems": true,
                    "maxItems": (rolesShown || []).length,
                },
            }
        };
    }

    const customFields = {TagSelector: CompanyGroupSelector};

    return (
        <div className={`d-flex flex-row flex-sm-row ${isModal ? 'justify-content-center' : 'justify-content-start'}`}>
            <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2" style={isModal ? {} : {width:'87vw', minWidth: '800px'}}>
                <div className="d-flex flex-column align-self-center" style={{maxWidth: "450px"}}>
                {!isModal && <h3 className="pb-4">{newUser ? "Create User" : "Edit User"} {loading && <ImSpinner3 className="icon-spin" style={{fontSize: 20}}/>}</h3>}
                <>
                    {/* Fake inputs that Chrome can autofill, might help */}
                    <input style={{display:"none"}} type="text" name="fakeusernameremembered" />
                    <input style={{display:"none"}} type="password" name="fakepasswordremembered" />
                    <input style={{display:"none"}} type="text" name="fakeemailremembered" />
                    <input style={{display:"none"}} type="text" name="fakephoneremembered" />

                    <Form
                        autoComplete={"off"}
                        fields={customFields}
                        disabled={!hasPermissionToEdit || loading}
                        schema={validationSchema()}
                        transformErrors={transformErrors}
                        uiSchema={uiSchema}
                        formData={updatedUserJSON}
                        onChange={onChange}
                        showErrorList={true}
                        // extraErrors={extraErrors}
                        // validate={validate}
                        onSubmit={onSubmit}
                        onError={onError}
                        ErrorList={ErrorListTemplate} 
                    />
                    {!newUser && isSuperAdmin() &&
                        User.isDeleted(foundUser)
                        ? <div className="d-flex flex-row justify-content-end">
                            <a style={{color: 'green', transform: 'translate(0,-1.5em)', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                updateArchivedStatus(false);
                            }} disabled={loading}>Restore User</a>
                        </div>
                        : <div className="d-flex flex-row justify-content-end">
                            <a style={{color: 'red', transform: 'translate(0,-1.5em)', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                const deleteConfirmed = confirm(`Are you sure you want to delete this user?`)
                                if (deleteConfirmed) {
                                    updateArchivedStatus(true);
                                }
                            }} disabled={loading}>Delete User</a>
                        </div>
                    }
                    </>
                </div>
                
            </div>
        </div>
    );
}


export default UserEditScreen;


