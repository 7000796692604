import Utils from '../common/Utils';
import moment from 'moment-timezone';

const NO_DATA_V = 22616;
const NORMAL        = 0;
const FLAGGED       = 2;
const OVERBASELINE  = 4;

class Reading {

    static getTagNames(reading){
        const a = [];
        if (Reading.hasAcceleratingSeTag(reading)){
            a.push("Accelerating SE");
        }
        if (Reading.isFlagged(reading)){
            a.push("Flagged");
        }
        if (Reading.hasOverbaselineTag(reading)){
            a.push("Overbaseline");
        }
        if (Reading.isSecondaryNoise(reading)){
            a.push("Secondary Noise");
        }
        return a;
    }

    static getTagNameList(reading){
        return Reading.getTagNames(reading).join(", ");
    }

    static getSoundSignatureList(reading, minimumProbability = 0.001){
        if (reading && reading.signatures && reading.signatures.length > 0){
            return reading.signatures
                .filter(x => x.pr >= minimumProbability)
                .map(x => `${x.signature.name}` )
                .join(', ');
        }
        return null;
    }

    static getActionList(reading, minimumProbability = 0.001){
        if (reading && reading.actions && reading.actions.length > 0){
            return reading.actions
                .filter(x => x.pr >= minimumProbability)
                .map(x => `${x.action.name}` )
                .join(', ');
        }
        return null;
    }

    static hasOverbaselineTag(reading){
        return reading.overb;
    }

    static hasAcceleratingSeTag(reading){
        return reading.accelse;
    }

    static techNotesExist(reading){
        return reading && !Utils.isBlank(reading.desc);
    }

    static recNotesExist(reading){
        return reading && !Utils.isBlank(reading.rec);
    }

    static isSecondaryNoise(reading){
        return reading && Utils.existsAndTruthy(reading, 'secondary_noise');
    }

    static isFlagged(reading){
        return reading && reading.cond == FLAGGED;
    }

    static isCheck(reading){
        return reading && reading.v == NO_DATA_V;
    }

    static isVisibleOnChart(reading){
        return !Reading.isCheck(reading) &&
            !Reading.isSecondaryNoise(reading);
    }

    static sampleList(){
        const a = [
            {
                recorded_at:"2020-03-07 03:17:00",
                color: "#FFD700",
                comment: false,
                v: 33,
            },
            {
                recorded_at:"2020-03-01 03:17:00",
                color: "red",
                comment: true,
                v: 37,
            },
            {
                recorded_at:"2020-02-19 03:17:00",
                color: "red",
                comment: true,
                v: 28,
            },
            {
                recorded_at:"2020-01-15 03:17:00",
                color: "#FFD700",
                comment: true,
                v: 4,
            },
            {
                recorded_at:"2019-12-07 03:17:00",
                color: "#efefef",
                comment: false,
                v: 2,
            },
            {
                recorded_at:"2019-09-09 03:17:00",
                color: "#FFD700",
                comment: false,
                v: 17,
            },
            {
                recorded_at:"2019-02-12 03:17:00",
                color: "#efefef",
                comment: false,
                v: 6,
            },
        ]
        return a.map(x => {
            x.date = moment.tz(x.recorded_at, Utils.UTC_TZ).toDate();
            return x;
        });
    }
}

export default Reading;