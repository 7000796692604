import React from 'react';
import {
  Link,
  useHistory
} from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import {Dropdown} from 'react-bootstrap';
import { FcInspection, FcDocument, FcFactoryBreakdown, FcElectricalSensor} from 'react-icons/fc';
import {FaUserCircle} from 'react-icons/fa';

export const TopNavBar = () => {
    return (
        <nav className="navbar navbar-dark d-flex justify-content-between bg-light flex-lg-nowrap p-0 m-0 mb-1 " style={{boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)"}}>
            <div style={{width: '250px'}}>
              <Logos/>
            </div>
            <div className="d-flex flex-column col-6 ml-3 mr-3">
              <TopNavIconList iconSize={36}/>
            </div>
            <div style={{width: '100px'}}>
              <NavigationLinkList />
            </div>
            
        </nav>
    );
};


const Logos = () => {
    const {user} = useAuth();
  
    return (
      <div className="d-flex flex-row justify-content-left">
        {(user && user.company && user.company.image) ? 
          <Link to="/">
            <img
              className="ml-2 mb-1 align-self-end image-responsive"
              style={{ maxHeight: "90px", width: "auto"}}
              src={`https://s3.amazonaws.com/${user.company.image.bucket}/${user.company.image.key}?cachebust=${(new Date().valueOf())}`}
              alt="Company Logo"
            />
          </Link>
          :
          <Link className="navbar-brand col-xs-1 mr-0 ml-1" style={{ height: "120px" }} to="/start">
            <img
              className="mb-1"
              style={{ height: "110px"}}
              src="/DST_animated_logo_once1.gif"
              alt="DST Logo"
            />
          </Link>
        }
      </div>
    )
}
  
const TopNavIconList = ({iconSize}) => {
    const {isCustomerTechOrHigher, user, signout} = useAuth();

    if (!user){
        return <div/>;
    }

    return (
        <div className="d-flex flex-row justify-content-around align-content-center align-items-center">
          {isCustomerTechOrHigher() && <Link to={{pathname: `/inspections/`}}>
              <div className="btn d-flex flex-column align-items-center">
              <FcInspection style={{fontSize: iconSize}} />
              Inspections
              </div>
          </Link>}

          {/* {isCustomerTechOrHigher() && 
              <Link className="btn" to={`/analytics`}>
              <div className="btn d-flex flex-column align-items-center">
              <FcElectricalSensor style={{fontSize: iconSize}} />
              Analysis
              </div>
          </Link>} */}

          {isCustomerTechOrHigher() && <Link  to={{pathname: `/properties/`}}>
              <div className="btn d-flex flex-column align-items-center">
                <FcFactoryBreakdown style={{fontSize: iconSize}} />
                Property (Edit)
              </div>
          </Link>}

          {isCustomerTechOrHigher() && <Link  to={`/reports`}>
              <div className="btn d-flex flex-column align-items-center" >
              <FcDocument style={{fontSize: iconSize}} />
              Reports
              </div>
          </Link>}
        </div>
    )
}

const NavigationLinkList = () => {
    const {user, signout} = useAuth();
    const history = useHistory();
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        style={{color: 'black'}}
      >
        {children}
        {/* &#x25be; */}
      </a>
    ));
  
    if (!user && history.location.pathname.indexOf('auth') >= 0){
      return <div/>;
    }
  
    return (
      <ul className="nav px-3 align-items-center">
        <li className="nav-item ml-2 mr-2">
          {!user ? <></>
            :
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="user-dropdown">
                <FaUserCircle style={{fontSize: 36}}/>
              </Dropdown.Toggle>
  
              <Dropdown.Menu>
                <Dropdown.Item key={1}>
                  <div>{user && user.full_name || ""}</div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item key={2}>
                  <Link className="nav-link text-dark" style={{padding: 0, paddingLeft: 10}} to={`/user/edit/${user.uuid}`}>Edit Settings</Link>
                </Dropdown.Item>
                <Dropdown.Item key={3}>
                  <Link className="nav-link text-dark" style={{padding: 0, paddingLeft: 10}} to="/auth" onClick={() => {
                    signout();
                    history.push("/auth")
                  }}>Sign Out</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </li>
      </ul>
    )
  }