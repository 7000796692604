import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import PrivateRoute from '../../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useLocation,
  useHistory
} from "react-router-dom";
import '../../styles/grid.css';
import moment from 'moment';
import Val from '../../models/Val';
import Equipment from '../../models/Equipment';

import Check from '../../models/Check';

import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD } from '../../constants/Constants';
import {EvaluationMark} from './EvaluationMark';
import _ from 'lodash';
import { FcDownload } from 'react-icons/fc';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Utils from '../../common/Utils';
import { FaStickyNote, FaRegFlag, FaFileDownload, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import {POINT_CONDITIONS} from '../../constants/Constants';
import ExpandedValAnalyticsRow from './ExpandedValAnalyticsRow';
import { toast } from 'react-toastify';
import { EditModal } from './GenericEditModal';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import {useFilterContext} from '../../context/SharedFilterContext';


const PointInspectionTable = (props) => {
  const {locked, setShowPointInspectionTable, previewMode  } = props;
  const { user } = useAuth();
  const history = useHistory();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const {setSelectedProperty, setSelectedRoutine, selectedProperty} = useFilterContext();
  const {pathname} = useLocation();
  const showBackButton = pathname.includes('/table/');
  // const {selectedPointNumbers, selectedEquipment, selectedProperty} = props;
  const {selectedPointNumbers, selectedEquipment} = props;
  let rawUploads = [];
  const [uploadPage, setUploadPage] = useState([]);
  const [loadingInspections, setLoadingInspections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewRequired, setReviewRequired] = useState(false);
  const [allFlagged, setAllFlagged] = useState(false);
  const [reviewRequiredWarning, setEvaluationStatusWarning] = useState(false);
  const [inspections, setInspections] = useState([])
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && user.selectedProperty && user.selectedProperty.uuid) ? [user.selectedProperty.uuid] : []
    )
  );
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedRootEquipment, setSelectedRootEquipment] = useState(null);
  const [selectedSubcomponentList, setSelectedSubcomponentList] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [sendReportModal, setSendReportModal] = useState({});
  const [refreshPropertySelector, setRefreshPropertySelector] = useState(false);
  const [showDatatable, setShowDatatable] = useState(false);
  const { startDate, endDate } = useFilterContext();
  const [detailedEquipment, setDetailedEquipment] = useState({});


  const isPointNameDisplayed = (selectedEquipment && !selectedPointNumbers) || locked;


  let currentMusic = new Audio();

  let checks = [];
  let uploads = [];

  const updateSingleUpload = (updatedUpload) => {
    const uploadList = inspections;
    const indexOfExistingUpload = uploadList.findIndex(x => x.uuid === updatedUpload.uuid);
    if (indexOfExistingUpload === -1){
      console.log("Couldn't find upload row")
      return
    }
    uploadList[indexOfExistingUpload] = {...uploadList[indexOfExistingUpload], ...updatedUpload};
    setInspections([...uploadList]);
  }
  
  const isDisabled = () => {
    return loadingRowUUIDs.length > 0 || locked
  }

  const markAsReviewed = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    console.log("Mark as Reviewed");
    console.log(row.uuid, user.id);
    const returnedRow = await Val.markAsReviewed(row.uuid, user.id);
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const editRecommendations = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    // const newRecommendations = prompt("Update Recommendations or Repair", row.rec || "");
    // updateSingleUpload(uploads, {...row, rec: newRecommendations});
    // await Val.submitRecommendation(row.uuid, user.id, newRecommendations);
    setEditModal({
      title: "Update Recommendations",
      value: row,
      selector: row => row.rec || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Val.submitRecommendation(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
    
  };

  const editFieldNotes = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Field Note",
      value: row,
      selector: row => row.desc || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Val.submitReview(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }

  const toggleFlagged = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await Val.updateConditions(
      row.uuid,
      user.id,
      (row.cond === POINT_CONDITIONS.FLAGGED) ? POINT_CONDITIONS.NORMAL : POINT_CONDITIONS.FLAGGED,
      row.secondary_noise
    );
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const toggleSecondaryNoise = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await Val.updateConditions(
      row.uuid,
      user.id,
      row.cond,
      !row.secondary_noise
    );
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const updateEvaluation = async (row) => {
    if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Evaluation",
      value: row,
      selector: row => row.criticality || 0,
      type: "evaluation",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        console.log(newValue)
        const returnedRow = await Val.submitEval(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }

  // const getFigNameHierarchy = (row) => {
  //   return [
  //     row.point_info && row.point_info.adam_fig_name,
  //     row.point_info && row.point_info.adam_fig_name ? row.point_info && row.point_info.grandparent_fig_name : false,
  //     row.point_info && row.point_info.grandparent_fig_name ? row.point_info && row.point_info.parent_fig_name : false,
  //     row.point_info && row.point_info.parent_fig_name ? row.point_info && row.point_info.fig_name : false,
  //   ].filter(x => !!x).join(" ⮕ ")
  // }

  const getFigNameHierarchy = (row) => {
    const hierarchy = {
      adamFigName: row.point_info?.adam_fig_name,
      component1: row.point_info?.parent_fig_name ? row.point_info?.fig_name : "",
      component2: row.point_info?.grandparent_fig_name ? row.point_info?.parent_fig_name : "",
      component3: row.point_info?.adam_fig_name ? (row.point_info?.grandparent_fig_name ? row.point_info.grandparent_fig_name : "") : "",
    };
  
    return hierarchy;
  };

  const extractRootComp = (row) => {
    const hierarchy = getFigNameHierarchy(row);
    return hierarchy.component1 ? `${hierarchy.adamFigName} / ${hierarchy.component1}` : hierarchy.adamFigName;
  };
  
  

  const convertMp3Url = (mp3Url) => {
    //https://dst-mp3s.s3.amazonaws.com/c7820a9a-3d3f-4a2b-8e42-63f88f0d642c.mp3
    //mp3.dstapp.com/c7820a9a-3d3f-4a2b-8e42-63f88f0d642c.mp3
    return mp3Url.replace('dst-mp3s.s3.amazonaws.com/', 'mp3.dstapp.com/');
  }

  const extractFigImageUrl = row => row.point_info && row.point_info.fig && row.point_info.fig.image;
  const extractPropertyName = row => row.point_info && row.point_info.property_name;
  const extractEquipmentHierarchy = row => <span>{getFigNameHierarchy(row)}</span>;
  const extractPointName = row => row.point_info ? row.point_info.measurement_name : "";
  const renderCreatedAtFormatted = row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>
  const extractCreatedAtFormattedYMD = row => moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
  const renderRecordedAtFormatted = row => <span>{moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>
  const extractRecordedAtFormattedYMD = row => moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
  const extractRecordedAtSortable = row => {
   return moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
  };
  const extractSE = row => row.v === NOD_SE_VALUE ? -1 : row.v;
  const renderSEAndPlayButton = row => 
    row.v === NOD_SE_VALUE ? "VISUAL" : 
    <><div role="button" onClick={() => {
      const newPlayerUrl = row.wav && row.wav.mp3Url;
      if (currentMusic.src === newPlayerUrl) {
        if (currentMusic.paused) {
          currentMusic.play();
        }else{
          currentMusic.pause();
        }
        return; 
      }
      currentMusic.pause();
      currentMusic.src = newPlayerUrl;
      let userConsentsToPlayingLoudInspection = true;
      if (row.v > 65){
        userConsentsToPlayingLoudInspection = confirm("The selected inspection may be very loud. Please turn down your volume or take off headphones/earpieces before continuing.")
      }
      console.log({userConsentsToPlayingLoudInspection})
      if (userConsentsToPlayingLoudInspection){
        currentMusic.play();
      }
    }}>
      {row.v}
      {row.wav && <img src="/icons/playpause.svg" className="ml-2 mr-2" width="17px" alt="Play/Pause"></img>}      
    </div>
    {row.wav && row.wav.mp3Url && 
      <a class="ml-1" href={`${convertMp3Url(row.wav.mp3Url)}`} download>
        <FaFileDownload />
      </a>
    }
    </>
  const extractEvaluation = row => row.sig;
  const renderEvaluationmark = row => <EvaluationMark criticality={row.sig} onClick={async (e) => updateEvaluation(row, e)}/>;
  const extractAiEvaluation = row => row.aiSig;
  const renderAiEvaluationMark = row => <EvaluationMark criticality={row.aiSig}/>;
  const renderPLR = row => row.v === NOD_SE_VALUE ? ' ' : `${Math.round(row.plr)}%`;
  const extractTags = row => [
    !Utils.isBlank(row.reviewed_at) ? "reviewed" : (row.warningMode ? "review-required" : null),
    Val.isFlagged(row)
        ? (
          Val.isFlaggedImplicitly(row) ? "system-flagged-high-evaluation-or-has-notes": "flagged"
        ) : null,
    row.secondary_noise ? "secondary-noise" : null,      
  ].filter(x => x !== null).join(',')

  const renderTags = row => {
    const filteredTags = [
      !Utils.isBlank(row.reviewed_at) ? // Show green exclamation if reviewed
        <FaExclamationTriangle style={{color: "#009900", fontSize: 30}} className="p-1" onClick={() => alert("Already Reviewed")}/>
      : row.warningMode && // Not reviewed and in warning mode, show yellow review required icon
      <img src="/icons/red-stop-sign.svg" style={{width: "30px", height: "30px"}} className="p-1 reviewRequiredIcon" onClick={() => markAsReviewed(row)}/>
      , Val.isFlagged(row)
        ? (
          Val.isFlaggedImplicitly(row) 
          ? <FaFlag role="button" className="p-1" style={{color: 'rgb(217, 72, 47)', fontSize: 28}} onClick={() => 
            toast.info("System flagged due to the high Evaluation or existence of field notes/recommendations. Modify those to resolve this flag.")
          }/>
          : <FaFlag role="button" className="p-1" style={{color: 'black', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
        )
        : <FaRegFlag role="button" className="p-1" style={{color: 'gray', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
      , row.secondary_noise
        ? <RiVolumeVibrateFill role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'black', fontSize: 32}}/>
        : <RiVolumeVibrateLine role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'gray', fontSize: 32}}/>,
      
    ].filter(x => x != false)

    return (
      <div className="d-flex flex-row justify-content-center flex-wrap ml-1 mt-1 mb-1">
        {filteredTags.map(x => _.isString(x) ? <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div> : (x || ''))}
      </div>
    )
  }
  const extractSoundSignatures = row => [
      (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
    row.accelse ? "Accelerating SE" : null,
      ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)).map(x => x.signature.name) : [])
    ].filter(x => x != null).join(',')
  // const renderSoundSignatures = row => {
  //   const filteredSignatures = [
  //     (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
  //     row.accelse ? "Accelerating SE" : null,
  //     ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)) : [])
  //   ].filter(x => x != null)
  //   return (
  //     <div className="d-flex flex-row justify-content-center flex-wrap">
  //       {filteredSignatures.map(x => <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x.signature ? x.signature.name : x}</div>)}
  //     </div>
  //   )
  // };
  const renderSoundSignatures = row => {
    const filteredSignatures = [
       // (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
      row.accelse ? "Accelerating SE" : null,
        ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)).map(x => x.signature.name) : [])
    ].filter(x => x != null);
    
    return filteredSignatures.join(' ');
};


  const extractEquipmentImageUrl = row => {
    if (row.point_info && row.point_info.fig && row.point_info.fig.image) {
      const { bucket, key, region } = row.point_info.fig.image;
      if (bucket && key) {
        return bucket.includes('media3')
          ? `https://s3.amazonaws.com/${bucket}/${key}?cachebust=${(new Date().valueOf())}`
          : `https://${bucket}.s3.${region}.amazonaws.com/${key}?cachebust=${(new Date().valueOf())}`;
      }
    }
    return null;
  };
  
  const renderEquipmentImage = row => {
    const imageUrl = extractEquipmentImageUrl(row);
    if (imageUrl) {
      return <img src={imageUrl} alt="Equipment" style={{ width: '50px', height: '50px' }} />;
    }
    return null; // or you can return a placeholder image if you prefer
  };
  

  const extractActions = row => {
    const filteredActions = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && x.action.name).filter(x => x != null)
    return filteredActions.join(',')
  };

  const renderActions = row => {
    const filteredActionNames = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && `${x.action.name}`).filter(x => x != null)
    return (
      <div className="d-flex flex-row justify-content-center flex-wrap">
        {filteredActionNames.map(x => <div className="wrap rounded-sm bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div>)}
      </div>
    )
  };

  const extractFieldNotes = row => row.desc || "___";
  const renderFieldNotes = row => Utils.isBlank(row.desc)
  ? <FaRegStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)}/>
  : <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip">{row.desc}</Tooltip>}>
      <FaStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)} style={{fontSize: 16}}/>
    </OverlayTrigger>;
  const extractRecommendations = row => row.rec || "___";
  const renderRecommendations = row => Utils.isBlank(row.rec)
  ? <FaRegStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)}/>
  : <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip">{row.rec}</Tooltip>}>
      <FaStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)} style={{fontSize: 16}}/>
    </OverlayTrigger>

const renderPointNameCell = row => {
  if (previewMode) {
    if (row.warningMode) {
      return (
        <>
          <img src="/icons/red-stop-sign.svg" style={{width: "30px", height: "30px"}} className="p-1 reviewRequiredIcon" onClick={() => markAsReviewed(row)}/>
          {extractPointName(row)}
        </>
      );
    } else {
      return (
        <>
          <EvaluationMark criticality={row.sig} onClick={async (e) => updateEvaluation(row, e)}/>
          {extractPointName(row)}
        </>
      );
    }
  } else {
    return extractPointName(row); // Default behavior if not in previewMode
  }
};


  const columns =
    [
      (!selectedEquipment && !selectedPointNumbers) ?  {
        name: 'Equipment Photo',
        selector: extractEquipmentImageUrl,
        cell: renderEquipmentImage,
        sortable: false,
        center: true,
        compact: true,
        width: '60px'
      } : null,
      (!selectedEquipment && !selectedPointNumbers) ? {
        name: 'Root/Comp',
        selector: extractRootComp,
        sortable: true,
        wrap: true,
        center: true,
        compact: true,
        style: {
          backgroundColor: '#f0f0f0', // light grey background color
          color: '#000000', // black text color
        },
        width: '250px' 

      } : null,
      (!selectedEquipment && !selectedPointNumbers) ? {
        name: 'Root Equipment',
        selector: row => getFigNameHierarchy(row).adamFigName,
        sortable: true,
        wrap: true,
        center: true,
        compact: true,
        width: '100px' 

      } : null,
      (!selectedEquipment && !selectedPointNumbers) ? {
        name: 'Component',
        selector: row => getFigNameHierarchy(row).component1,
        sortable: true,
        wrap: true,
        center: true,
        compact: true,
        width: '150px' 
      } : null,
       {
        name: 'Point Name',
        selector: extractPointName,
        cell: renderPointNameCell, // Using the new cell method
        sortable: true,
        compact: true,
        grow: 2, 
        wrap: true,
        width: '200px'
      } ,
      {
        name: 'SoundSignatures™',
        selector: extractSoundSignatures,
        cell: row => <div className="d-flex flex-row justify-content-center">{renderSoundSignatures(row)}</div>,
        sortable: true,
        compact: true,
        wrap: true,
        center: true,
        // width: '150px'
        width: '100px' // Percentages instead of fixed pixel values

      },
      {
        name: 'Actions',
        selector: extractActions,
        cell: renderActions,
        sortable: true,
        center: true,
        compact: true,
        width: previewMode? '100px' : '200px'
        //width: '10%' // Percentages instead of fixed pixel values

      },
        
      !previewMode ? {
        name: 'Inspection Date',
        selector: extractRecordedAtSortable,
        cell: renderRecordedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      } : null,
      {
        name: 'Tags',
        selector: extractTags,
        cell: renderTags,
        sortable: true,
        compact: true,
        center: true,
        width: '10em'
      },
      {
        name: 'Upload Date',
        selector: extractCreatedAtFormattedYMD,
        cell: renderCreatedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      } ,
      !previewMode ? {
        name: 'SoundEnergy™',
        selector: extractSE,
        cell: renderSEAndPlayButton,
        sortable: true,
        center: true,
        compact: true,
        width: '90px'
      }: null,
      !previewMode ? {
        name: 'SE(%)',
        selector: row => Math.round(row.plr), // Use the numeric value for sorting
        format: row => `${Math.round(row.plr)}%`, // Format the displayed value
        sortable: true,
        center: true,
        compact: true,
        width: '125px'
      } : null,
      !previewMode ? {
        name: 'Evaluation',
        selector: extractEvaluation,
        cell: renderEvaluationmark,
        sortable: true,
        center: true,
        compact: true,
        width: '80px'
      } : null,
      !previewMode ? {
        name: 'SoundEvaluator™',
        selector: extractAiEvaluation,
        cell: renderAiEvaluationMark,
        sortable: true,
        center: true,
        compact: true,
        width: '110px'
      } : null,
    
    
      
      !previewMode ? {
        name: 'Field Notes',
        selector: extractFieldNotes,
        cell: renderFieldNotes,
        sortable: true,
        center: true,
        compact: true,
        width: '80px'
      } : null,
      !previewMode ? {
        name: 'Recommendations',
        selector: extractRecommendations,
        cell: renderRecommendations,
        sortable: true,
        center: true,
        compact: true,
        width: '120px'
      } : null
    ].filter(x => x != null)

  useEffect(() => {
    loadInspections();
   
  }, [(selectedPointNumbers || []).length, selectedPropertyUUIDs, selectedEquipment])

  
  const loadEquipmentForEdit = async () => {
    const fig = await Equipment.getEquipmentToEdit(selectedEquipment.uuid)
    setDetailedEquipment(_.first(fig.data));

  }
  
  useEffect(loadEquipmentForEdit, [])



  const loadInspections = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return
    }
    setLoading(true);
    checks = []
    uploads = []
    await Promise.allSettled([    
      loadChecks(),
      refreshUploads()
    ]);
    setInspections([]);
    let merged = [
      ...checks.map(c => {return {...c, isCheck: true}}),
      ...uploads.map(u => {return {...u, isUpload: true}}),
    ];
    if(selectedEquipment !== undefined){
      merged = _.orderBy(merged, [
        u => u.isCheck ? -1 : extractSE(u),
        u => u.isCheck ? -1 : u.warningMode,
      ], ['desc', 'desc'])
    }else{
      merged = _.orderBy(merged, [
        upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
        upload => upload?.isCheck ? (upload?.fig && upload?.fig?.parent_key === null ? upload?.fig?.key + 1 : upload?.fig?.parent_key) : (upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key),
      ], ['desc', 'desc'])
    }
    
    setInspections(merged);  
    setLoading(false);
  }

  const loadChecks = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return;
    }
  
    // if (!selectedEquipment){
    //   return;
    // }
    setLoadingChecks(true);
    const figChecks = await Check.filterBy([
      // selectedProperty ? {field: "properties.uuid", value: selectedProperty.uuid, comparison: "="} : null,//TODO: will be needed for layout to work
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
      selectedEquipment ? {field: 'fig_uuid', value: selectedEquipment.uuid, comparison: "="} : null,
      {field: "archived", value: 0, comparison: "="},
    ].filter(x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true );
    checks = figChecks.data;
    setLoadingChecks(false);
  }



  const generatePointData = () => {
    return !(detailedEquipment && detailedEquipment.system && detailedEquipment.system.measurements) ? [] : _.orderBy(detailedEquipment.system.measurements.map(x => {
      if (!x.measurement || x.measurement.name.length < 3){
        return null;
      }
      return {
        measurement: x.measurement,
        point: _.first((detailedEquipment.pointInfos || []).filter(y => x.measurement && y.measurement_id == x.measurement.id))
      }
    }).filter(x => x != null), [
      row => !!row.point,
      row => row.measurement && row.measurement.sort_idx
    ], ['desc', 'asc'])
  }

  const refreshUploads = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return
    }


    setLoadingInspections(true);
    const newUploads = await Val.filterBy([
      {field: 'archived', value: 0, comparison: "="},
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
      // selectedProperty ? {field: "properties.uuid", value: selectedProperty.uuid, comparison: "="} : null,//TODO: will be needed for layout to work
      selectedEquipment ? {field: 'fig_id', value: selectedEquipment.id, comparison: "="} : null,
      selectedPointNumbers ? {field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "="} : null,
    ].filter(x => x != null), "recorded_at", "desc", 10000, undefined, undefined, undefined, true );


    const newChecks = await Check.filterBy([
      {field: 'archived', value: 0, comparison: "="},
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
     //selectedEquipment ? {field: 'fig_id', value: selectedEquipment.id, comparison: "="} : null,
      //selectedPointNumbers ? {field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "="} : null,
    ].filter(x => x != null), "recorded_at", "desc", 10000, undefined, undefined, undefined, true );
    
    let uploadList = newUploads.data || []
  

    uploadList = uploadList.map(upload => {
      return {
        ...upload,
        sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
        parent_fig_key: upload?.point_info?.parent_fig_key,
        childSort: upload?.point_info?.parent_fig_key === null ? upload?.point_info?.parent_fig_key - 1 : upload?.point_info?.parent_fig_key
      }
    })

    if (allFlagged){
      uploadList = uploadList.filter(upload => Val.isFlagged(upload))
    }


    uploadList = _.orderBy(uploadList, [
      upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
      upload => upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key,
    ], ['desc', 'desc', 'desc'])



    if (reviewRequiredWarning){
        uploadList = _.orderBy(uploadList, [
          upload => upload.warningMode,
          upload => !Utils.isBlank(upload.reviewed_at),
          upload => upload.cond
        ], ['desc', 'desc', 'desc'])
    }

    rawUploads = uploadList;

    if(selectedEquipment !== undefined){
      uploadList = _.flatten(
        Object.values(
          _.groupBy(uploadList, row => extractPointName(row)
        )
      )
      // sort by 
      .map(x => _.first(x)))
      .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx)
    }
    uploads = uploadList;



    setUploads(uploadList);
    setUploadPage(newUploads.pageInfo);
    setLoadingInspections(false);
  }

  const transformAndMergeData = (newDataArray, existingInspections) => {
    // Transform the new data to match the expected format
    const transformedNewData = newDataArray.map(item => {
      return {
        // Set to defaults or nulls as these are not provided in the new data format
        accelse: null,
        actions: null,
        aiSig: item.point.evalNum || null,
        alpha: null,
        childSort: null,
        cond: null,
        created_at: item.point.created_at,
        criticality: null,
        desc: null,
        id: null,
        isUpload: null,
        overb: null,
        parent_fig_key: null,
        plr: null,
        point_info: {
          // Extract necessary data for the point name
          measurement_name: item.measurement.name || item.point.measurement_name,
          fig_name: item.point.parent_fig_name,
          // Set all other properties as null or with defaults if needed
          adam_fig_name: null,
          created_at: null,
          fig: { fig_image_ext: null, fig_image_key: null, image: null, __typename: 'Fig' },
          fig_criticality: null,
          fig_key: null,
          image: null,
          measurement_sort_idx: item.measurement.sort_idx,
          num: item.point.num,
          parent_fig_key: null,
          parent_fig_name: item.point.parent_fig_name,
          parent_fig_uuid: null,
          property_name: null,
          updated_at: item.point.updated_at,
          uuid: item.point.uuid,
          __typename: 'PointInfo',
        },
        rec: null,
        recorded_at: null,
        reviewed_at: null,
        secondary_noise: null,
        sig: item.point.evalNum || null,
        signatures: null,
        sortDay: null,
        uuid: item.point.uuid,
        v: null,
        warningMode: null,
        wav: null,
      };
    });
  
    // Merge the existing inspections with the transformed new data
    return [...existingInspections, ...transformedNewData];
  };


let newArrayData = generatePointData(); 
const filteredNewData = newArrayData.filter(item => item.point && item.point.evalNum === null);
const filteredInspections = inspections.filter(item => {
  // Use item.reviewed_at if it exists, otherwise fall back to item.recorded_at
  const inspectionDate = item.reviewed_at ? moment(item.reviewed_at).startOf('day') : moment(item.recorded_at).startOf('day');
  const start = moment(startDate).startOf('day');
  // Set end to the start of the day after endDate
  //const end = moment(endDate).add(1, 'days').startOf('day');
  const end = moment(endDate).startOf('day');
  // Use the updated end date for comparison
  return inspectionDate.isBetween(start, end, null, '[]');
});

const combinedData = transformAndMergeData(filteredNewData, filteredInspections);


const sortedInspections = combinedData
  .filter(item => item.recorded_at != null) // Filter out items with null recorded_at
  .sort((a, b) => {
    if (isPointNameDisplayed) {
      return extractPointName(a).localeCompare(extractPointName(b));
    }
    // Any other default sorting logic here or return 0 to leave as is
    return 0;
  });

const hasWarnings = filteredInspections.some(row => row.warningMode);
const handleAcceptAll = () => {
  // Using filteredInspections as you've mentioned
  filteredInspections.forEach(row => {
      if (row.warningMode) {
          markAsReviewed(row);
      }
  });
};

const exportToCSV = () => {
  let csvTitle;
  if (!selectedEquipment && !selectedPointNumbers) {
    csvTitle = `Date Range: ${startDate} to ${endDate}, Property: ${selectedProperty.name}`;
  } else {
    csvTitle = `Date Range: ${startDate} to ${endDate}, Property: ${selectedProperty.name}, Equipment: ${selectedEquipment.name}`;
  }

  const columnNames = columns.map(col => col.name);
  const dataRowsAsTextOnly = sortedInspections.map(row => 
    columns.map(col => {
      if (col.selector) {
        const value = col.selector(row);
        return typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value;
      }
      return '';
    })
  );

  const csvCombined = [
    csvTitle,
    columnNames.join(','),
    ...dataRowsAsTextOnly.map(row => row.join(','))
  ].join('\n');

  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvCombined));
  element.setAttribute('download', `export.csv`);
  document.body.appendChild(element);
  element.click();
};


  return (
    <>
    <div className="d-flex flex-row justify-content-start align-items-start">
        <EditModal options={editModal}/>
        <div className="d-flex flex-column rounded-lg bg-white mt-2 "  
          style={{width: '2000px'}}
        >
          {showBackButton && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                  setShowPointInspectionTable(false);

                    setSelectedRoutine(null)
                    setSelectedProperty(null)
                    //history.push("/inspections/properties")
                    history.push(`/inspections/${selectedPropertyUUIDs}`)
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>MSi Inspections - {selectedProperty.name}</h4>
                </div>
            </div>}
          <div className="d-flex flex-column justify-content-start align-items-top flex-wrap mt-3"> 
          {hasWarnings && <button onClick={handleAcceptAll} className="btn btn-primary mb-2" style={{ width: '150px' }}>Accept All</button>}

            <DataTable
              title="MSi Inspections and Tasks"
              keyField="uuid"
              columns={columns}
              // data={inspections}
                data={sortedInspections}
              expandableRows={!previewMode}
              noDataComponent = {"No matching results. Please select a different property or adjust filters."}
         
              responsive={true}
              dense
            
              progressPending={loading}
              persistTableHead
              noHeader
              highlightOnHover
              expandableRowsComponent={previewMode ? null : (props => props.data && props.data.isCheck ? ExpandedCheckAnalyticsRow(props) : ExpandedValAnalyticsRow(props))}
              {...(isPointNameDisplayed ? { defaultSortField: extractPointName, defaultSortAsc: true } : {})}

             
              disabled={loadingRowUUIDs.length > 0} //Appears as greyed out
              
            />
          </div>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={exportToCSV}>
      <FcDownload/>
    </button>
  </div>
  </>
  )
}

export default PointInspectionTable;