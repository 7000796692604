import Model from "./Model";

export default class Roles extends Model{
    static all = async() => {
        const res = await this.runQueryNetworkOnly(AllRoles, {});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Roles.runQuery [COMPLETE]`);
        return res.data.roles ? res.data.roles.sort((a,b) => a.clearance_level - b.clearance_level) : [];
    }
}

const AllRoles = `
query allRoles {
    roles {
        id
        uuid
        name
        title
        clearance_level
        bit_idx
    }
}
`;
