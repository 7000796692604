import React from 'react';
import { Card } from 'react-bootstrap';
import _ from 'lodash'; 
import '../../styles/Panel.css';
import PointInspectionTable from './PointInspectionTable';
import { useFilterContext } from "../../context/SharedFilterContext";


const PointTableCard = (props) => {
  const { setShowPointTable, equipment } = props;

  const {
    startDate,
  } = useFilterContext();

  // Logic to calculate enabled points
  const enabledPointsCount = new Set(equipment.points.map(point => point.measurement.id)).size || 0;

  // Logic to calculate val checks
  const valChecksCount = equipment.vals && Object.keys(_.groupBy(equipment.vals.edges, val => val.node && val.node.point_num)).length;
  // Determine the URL for the equipment image
  const imageUrl = equipment?.image?.bucket.includes('media3')
    ? `https://s3.amazonaws.com/${equipment?.image?.bucket}/${equipment?.image?.key}?cachebust=${(new Date().valueOf())}`
    : `https://${equipment?.image?.bucket}.s3.amazonaws.com/${equipment?.image?.key}?cachebust=${(new Date().valueOf())}`;

    let url = equipment.image ? imageUrl : "https://web.dstapp.com/icons/image-placeholder3.svg"; 


return (
    <Card className="user-panel-card" style={{ width: '500px' }} onClick={() => setShowPointTable(false)}>
      <Card.Img
        className="image-thumbnail rounded-lg"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          backgroundImage: `url("https://web.dstapp.com/icons/image-placeholder3.svg")`
        }}
        src={url}
      />      <Card.Body>
        <Card.Title className="user-panel-title">MSi Inspections</Card.Title>
        <Card.Text className="user-panel-description">
          Points ({enabledPointsCount})
          <span className="badge badge-dark float-right">
            {valChecksCount} &nbsp;/&nbsp; {enabledPointsCount}
          </span>
        </Card.Text>

  {/* Insert PointTableComponent */}
  {/* <div 
          style={{ 
            fontSize: '0.8rem', 
            overflowY: 'auto', 
            maxHeight: '300px', 
          }}
          onClick={(e) => e.stopPropagation()} // Stop event propagation
        >
          <PointInspectionTable 
      selectedEquipment={equipment}
      startDate={startDate} 
      previewMode={true}


          />
        </div> */}



      </Card.Body>
    </Card>
  );
};

export default PointTableCard;
