import Model from "./Model";
import Utils from "../common/Utils";
import _ from 'lodash';

export default class User extends Model {
    static highestRole = (loggedInUser) => {
        return (_.head((loggedInUser.roles || []).sort((a,b) => a.clearance_level - b.clearance_level)) || {});
    }

    static assignedCompanyGroups = (user) => {
        return user && user.company ? [...(user.companyGroup || [])].filter(x => !!x) : [];
    }

    static inheritedCompanyGroups = (user) => {
        return user && user.company ? [...(user.company.companyGroup || [])] : [];
    }

    static allCompanyGroups = (user) => {
        return _.uniqBy([...this.assignedCompanyGroups(user), ...this.inheritedCompanyGroups(user)], 'uuid');
    }

    static getInitials(self){
        const {first_name, last_name, initials} = self;
        if (!Utils.isBlank(initials)){
            return initials.toLowerCase();
        }
        return `${first_name[0]}${last_name[0]}`.toLowerCase();
    }

    static isFieldConnectUser(user){
        return (user.company.name === "soefker" || user.company.name === "fieldconnecttest")
    }
    
    static myDatasets = async(fields) => {
        const {id} = fields;
        const res = await this.runQueryNetworkOnly(MyDatasets, {id});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.myDatasets.runQuery [COMPLETE]`);
        //00cc6a22-6565-44ab-9d55-b3b39ce20dd5
        return res.data.user.soundSets;
    }
    
    static otherUserDatasets = async(fields) => {
        const {id} = fields;
        const res = await this.runQueryNetworkOnly(OtherUserDatasets, {id});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.notMyDatasets.runQuery [COMPLETE]`);
        return res.data.user.otherUserDatasets;
    }

    static update = async(fields) => {
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update user - no uuid specified")
            return
        }
        const res = await this.runQueryNetworkOnly(UpdateUser, {input: {
            uuid: fields.uuid,
            full_name: fields.full_name,
            first_name: fields.first_name,
            last_name: fields.last_name,
            email: fields.email,
            last_login: fields.last_login,
            company_id: fields.company_id,
            roles_bf: fields.roles_bf,
            password_hash: fields.password_hash,
            phone: fields.phone,
            archived: fields.archived,
            state: fields.state,
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.updateUser.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }

    static create = async(fields) => {
        const res = await this.runQueryNetworkOnly(CreateUser, {input: {
            uuid: fields.uuid,
            full_name: fields.full_name,
            first_name: fields.first_name,
            last_name: fields.last_name,
            email: fields.email,
            company_id: fields.company_id,
            roles_bf: fields.roles_bf,
            password_hash: fields.password_hash,
            phone: fields.phone 

        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.createUser.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }

    static isEmailAlreadyUsed = async(email) => {
        const anyExistingUserWithEmail = await this.filterBy([{"field":"email", "value":email, "comparison":"="}], undefined, undefined, 1);
        return anyExistingUserWithEmail.data.length === 0;
    }

    static isDeleted = (user) => {
        return user.archived
        //  || user.state === 'passive' // The legacy platform also uses the user.state attribute. We are not anymore.
    }

    static isDeletedStatusText = (user) => {
        return User.isDeleted(user) ? "Yes" : "No";
    }
    

    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before) => {
        const data = await super.filterBy(UserFilter, searchList, sortField, sortOrder, first, after, last, before, true);
        const result = data && data.userFilter;
        return {
            data: _.uniqBy(result && (result.edges || []).map(e => e.node) || [], 'uuid'),
            pageInfo: result && result.pageInfo
        }
    }

    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": `${uuid}`, "comparison": "="}
        ], "uuid", "asc", 1);
        console.log({results})
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }

    static findById = async (id) => {
        const results = await this.filterBy([
            {"field": "id", "value": `${id}`, "comparison": "="}
        ], "uuid", "asc", 1);
        console.log({results})
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }
    static rolesBFToSelectionArray = (roles_bf) => {
        // roles_bf of 2 -> selected role indexes of [0,1]    //0 is superadmin and 1 is admin checkboxes
        let matchedRolesBitArray = (roles_bf >>> 0).toString(2).split('').reverse().map((v,i) => v === "1" ? i : null).filter(v => v !== null);
        if (roles_bf === 0){
            matchedRolesBitArray = []
        }
        return matchedRolesBitArray;
    }
}

const UpdateUser = `
mutation updateUser($input: UserUpdateInput){
    updateUser(input: $input){
      id
    }
  }
`


const CreateUser = `
mutation createUser($input: UserCreateInput){
    createUser(input: $input){
      id
    }
  }
`

const MyDatasets = `
    query MyDatasets($id: Int){
        user(id: $id){
            soundSets{
                id
                user_id
                global
                uuid
                name
                title
            }
        }
    }
`;

const OtherUserDatasets = `
    query OtherUserDatasets($id: Int){
        user(id: $id){
            otherUserDatasets{
                id
                user_id
                global
                uuid
                name
                title
            }
        }
    }
`;

const UserFilter = `
query UserFilter($input: FilterConnectionInput){
    userFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            id
            uuid
            app_version
            created_at
            updated_at
            last_login
            first_name
            last_name
            full_name
            company_id
            roles_bf
            email
            phone
            archived
            state
            companyGroup{
                id
                uuid
                title
            }
            company{
                id
                uuid
                name
                title
                image{
                    bucket
                    region
                    key
                }
                email
                companyGroup{
                        id
                        uuid
                        title
                    }
            }
            roles{
                id
                uuid
                name
                title
                clearance_level
            }
        }
      }
    }
  }
`