import Model from "./Model";

export default class Wav extends Model {
    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before) => {
        const data = await super.filterBy(UploadFilter, searchList, sortField, sortOrder, first, after, last, before);
        const result = data && data.uploadFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static getFigNameHierarchy = (wav) => {
        const row = wav.val || {};
        return [
            row.point_info && row.point_info.adam_fig_name,
            row.point_info && row.point_info.adam_fig_name ? row.point_info && row.point_info.grandparent_fig_name : false,
            row.point_info && row.point_info.grandparent_fig_name ? row.point_info && row.point_info.parent_fig_name : false,
            row.point_info && row.point_info.parent_fig_name ? row.point_info && row.point_info.fig_name : false,
        ].filter(x => !!x).join(" ⮕ ")
    }
}

const GQL_SHALLOW_INSPECTION_FRAGMENT = `{
    id
    uuid
    recorded_at
    device_uuid
    user_id
    property_id
    val{
        v
        plr
        sig
        aiSig
        warningMode
        reviewed_at
        desc
        rec
        secondary_noise
        overb
        accelse
        criticality
    }
}`

const GQL_FULL_INSPECTION_FRAGMENT = `{
    id
    uuid
    recorded_at
    created_at
    user_id
    duplicate
    
    # spectrogram{
    #   minimum{
    #     bucket
    #     key
    #     region
    #   }
    # }
    val{
    point_info {
        uuid
        fig_name
        adam_fig_name
        parent_fig_name

        measurement_name
        property_name
        parent_fig_uuid
    }
    user {
        uuid
        full_name
    }
    wav{
        mp3Url
        user_id
    }
    cond
    v
    plr
    criticality
    sig
    aiSig
    warningMode
    reviewed_at
    recorded_at
    desc
    rec
    cond
    secondary_noise
    overb
    rec
    desc
    warningMode
    reviewed_at
    accelse
    signaturesJsonStr
    signatures {
        signature {
        name
        desc
        }
        pr
        score
    }
    pointBaseline {
        baseline
    }
    }
}`


// referenceSoundFiles {
//     val_dec
//     notes
//     wav {
//         uuid
//         ver
//         mp3_uuid
//         mp3Upload {
//             rand_key
//             filename
//         }
//     }
// }

/*
point_info {
        fig_criticality
        adam_fig_name
        fig_name
        measurement_id
        valHistory{
            id
            uuid
            recorded_at
            cond
            v
            criticality
            sig
            aiSig
            warningMode
            reviewed_at
            desc
            rec
            secondary_noise
            overb
        }
        measurementStats {
            v_avg
            v_sd
        }
    }
*/

const UploadFilter = `
query UploadFilter($input: FilterConnectionInput){
    uploadFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_FULL_INSPECTION_FRAGMENT}
        
      }
    }
  }
`