import React, { useEffect, useState, useMemo } from 'react';

import {
  BrowserRouter as Router,
  Link,
  useParams,
  useLocation,
  useHistory
} from "react-router-dom";
import Equipment from '../../models/Equipment';
import Evaluation from '../../models/Evaluation';

import MultipointEvaluation from '../../models/MultipointEvaluation';
import { ToastContainer, toast } from 'react-toastify';

import Check from '../../models/Check';
import _, { sortBy } from 'lodash';
import { OverlayTrigger, Tooltip, ProgressBar, Modal } from 'react-bootstrap';
import { alphaNumberSort, lastValUploadedAsUnix } from './EquipmentGrid';
import moment from 'moment-timezone';
import { useFilterContext } from "../../context/SharedFilterContext";
import ImageUploading from 'react-images-uploading';
import { ImSpinner3 } from 'react-icons/im';
import { FaCamera, FaEdit, FaPlusCircle, FaHatWizard, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { MdPlaylistAddCheck, MdEdit, MdCheck, MdClear } from 'react-icons/md';

import { EditModal } from './GenericEditModal';
import { BsWindowSidebar } from 'react-icons/bs';
import { extractBase64FromDataURL, heicConversion } from "../utils/utils";
import heic2any from "heic2any";
import { image } from 'd3';
import {EvaluationBar} from './EvaluationBar';
import Utils from "../../common/Utils";
import PointInspectionTable from './PointInspectionTable';
import MPETable from './MPETable';

import { useAuth } from '../../context/AuthContext';
import Val from '../../models/Val';
import {POINT_CONDITIONS} from '../../constants/Constants';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD } from '../../constants/Constants';



// Indicates if uploading a primary image or nameplate image
var uploadFlag = false;

const EnabledPointsTable = ({ selectedEquipment }) => {
  if (!selectedEquipment.pointInfos || selectedEquipment.pointInfos.length === 0) {
    return <div>No Enabled Points.</div>;
  }

  // Sort the pointInfos array alphabetically by measurement_name
  const sortedPointInfos = selectedEquipment.pointInfos.sort((a, b) => {
    if (a.measurement_name < b.measurement_name) return -1;
    if (a.measurement_name > b.measurement_name) return 1;
    return 0;
  });
  
  return (
    <div style={{ fontSize: '0.8rem', overflowY: 'auto', maxHeight: '200px' }}>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Enabled Points ({sortedPointInfos.length})</th>
          </tr>
        </thead>
        <tbody>
          {sortedPointInfos.map((pointInfo, index) => (
            <tr key={index}>
              <td>{pointInfo.measurement_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



const EquipmentCard = (props) => {
  const {
    equipmentSelectFunction,
    e,
    subcomponents,
    routine,
    hideAnalysisColors,
    hideExpandedPointAnalysisColors,
    subcomponentSelectFunction,
    pointSelectFunction,
    hideInspectionCounts,
    hideSheetsSection,
    sheetSelectFunction,
    allowHidingComponentSection,
    allowHidingPointsSection,
    hideValCheckNumerator,
    hideValInspectionCountNumerator,
    refresh,
    setShowComponentsCard,
    isInspectionPage,
    sortByInspections,
    setShowPointTable,
    isComponent,
    setEditEvaluationModal,
    setShowEvaluationTable,
    setRefreshCounter

  } = props;
  const [expandedEquipmentUUIDs, setExpandedEquipmentUUIDs] = useState([])
  const [expandedPointUUIDs, setExpandedPointUUIDs] = useState([])
  const [expandedSheetUUIDs, setExpandedSheetUUIDs] = useState([])
  const [loading, setLoading] = useState(false)
  const [isLoadingMPe, setIsLoadingMPe] = useState(false);
  
  const { startDate, endDate, setSelectedProperty, setSelectedRoutine, selectedProperty } = useFilterContext();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const { user, isSuperAdmin } = useAuth();
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && user.selectedProperty && user.selectedProperty.uuid) ? [user.selectedProperty.uuid] : []
    )
  );
  const [inspections, setInspections] = useState([])

  const [parentName, setParentName] = useState('');
  
  const [showMPETable, setShowMPETable] = useState(() => {
    return e.evaluations?.some(evaluation => evaluation.net_samples_uuid) || false;
  });
  
  
  const [showMPETableComponents, setShowMPETableComponents] = useState(false);

  const [superWizardResult, setSuperWizardResult] = useState(null);
  const [showSuperWizardTable, setShowSuperWizardTable] = useState(false);
  const [isValidMultipoint, setIsValidMultipoint] = useState(false);
  const [isValidMultipointForSubcomponents, setIsValidMultipointForSubcomponents] = useState(false);
  const [showMPeAlreadyRunButton, setShowMPeAlreadyRunButton] = useState(false); // State to show/hide non-clickable MPe button

  const [showValidButton, setShowValidButton] = useState(false);
  const [progress, setProgress] = useState(0); // State to track progress
  const [totalSteps, setTotalSteps] = useState(0); // State to track total steps
  const [showProgressModal, setShowProgressModal] = useState(false); // State to show/hide progress modal
  
  const [showPointInspectionTable, setShowPointInspectionTable] = useState(false); // Add this state

  const [equipment, setEquipment] = useState(e);

  const [pointTotalCount, setPointTotal] = useState(0)

  const [editModal, setEditModal] = useState({});
  const [valChecksCount, setValChecksCount] = useState(0);

  const [isImageZoomed, setIsImageZoomed] = useState(false);
  const [image_URL, setImage_URL] = useState(null);
  const [image_File, setImage_File] = useState(null);

  useEffect(() => {
    console.log("Equipment", e, e.name); 
    setEquipment(e);
    setImage_URL(null);
    setImage_File(null);
    loadChecks(); 
  }, [JSON.stringify(e), refresh])


  const cardWidth = () => {
    if(sortByInspections && showPointInspectionTable) return '800px';
    if (sortByInspections && isComponent) {
      return '500px';
    } else if (showMPETableComponents && sortByInspections && equipment.parent_key === null) {
      return '500px';
    } else {
      return 'auto';
    }
  };

  

  const extractPointName = row => row.point_info ? row.point_info.measurement_name : "";

  
  const generateHash = async (inspectionUuidList) => {
    const sortedData = (inspectionUuidList || []).map(uuid => ({ uuid: uuid })).sort((a, b) => {
      const aStr = JSON.stringify(a);
      const bStr = JSON.stringify(b);
      return aStr.localeCompare(bStr, undefined, { numeric: true, sensitivity: 'base' });
    });
    const jsonString = JSON.stringify(sortedData);
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  const getInspections = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return
    }
    let selectedPointNumbers = null; 

    let parentKey = await Equipment.searchByEquipmentKey(equipment?.parent_key);
  

    const newUploads = await Val.filterBy([
      {field: 'archived', value: 0, comparison: "="},
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
      equipment ? {field: 'fig_id', value: equipment.id, comparison: "="} : null,
      selectedPointNumbers ? {field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "="} : null,
    ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true );


    
    let uploadList = newUploads.data || []

    uploadList = uploadList.map(upload => {
      return {
        ...upload,
        sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
        parent_fig_key: upload?.point_info?.parent_fig_key,
        childSort: upload?.point_info?.parent_fig_key === null ? upload?.point_info?.parent_fig_key - 1 : upload?.point_info?.parent_fig_key
      }
    })


    uploadList = _.orderBy(uploadList, [
      upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
      upload => upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key,
    ], ['desc', 'desc', 'desc'])



    if(equipment !== undefined){
      uploadList = _.flatten(
        Object.values(
          _.groupBy(uploadList, row => extractPointName(row)
        )
      )
      // sort by 
      .map(x => _.first(x)))
      .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx)
    }

    setInspections(uploadList);
  }

  useEffect(() => {
   getInspections();
  }, [equipment])




const checkValidMultipoint = async () => {
  setIsLoadingMPe(true);

  let allAlreadyRun = true;
  let hasValidMPe = false;

  // Collect UUIDs of main equipment and subcomponents
  const equipmentUUIDs = [e.uuid];
  if (e.allSubcomponents && e.allSubcomponents.length > 0) {
    const subcomponentUUIDs = e.allSubcomponents.map(subcomponent => subcomponent.uuid);
    equipmentUUIDs.push(...subcomponentUUIDs);
  }

  // Call validMultipointFromEquipment with the collected UUIDs
  const validityResponse = await MultipointEvaluation.validMultipointFromEquipment(equipmentUUIDs, startDate, endDate);

  // Parse the response
  const equipmentValidity = validityResponse?.equipmentValidity;

  // Check validity for main equipment
  const mainEquipmentValidity = equipmentValidity?.find(item => item.equipment_uuid === e.uuid);
  if (mainEquipmentValidity && mainEquipmentValidity.isValid) {
    const inspections = await getInspectionsForEquipment(e, selectedPropertyUUIDs);
    const filteredInspections = filterVals(inspections);

    const inspectionUuidList = filteredInspections.map(inspection => inspection.uuid);
    const currentHash = await generateHash(inspectionUuidList);
    const recentEvaluation = e.evaluations && e.evaluations.find(evaluation => evaluation.recorded_yhat_json !== null);
    if (!recentEvaluation || recentEvaluation.hash !== currentHash) {
      console.log("p1", e.name, currentHash, recentEvaluation, recentEvaluation?.hash, inspections, filteredInspections, inspectionUuidList);
      hasValidMPe = true;
      setIsValidMultipoint(true);
      setShowMPeAlreadyRunButton(false);
      allAlreadyRun = false;
    } else {
      setIsValidMultipoint(false);
      console.log("p2", e.name);

    }
  } else {
    console.log("p3", e.name);
    setIsValidMultipoint(false);
  }

  // Check validity for subcomponents
  for (let subcomponent of e.allSubcomponents) {
    const subcomponentValidity = equipmentValidity?.find(item => item.equipment_uuid === subcomponent.uuid);
    if (subcomponentValidity && subcomponentValidity.isValid) {
      const subcomponentInspections = await getInspectionsForEquipment(subcomponent, selectedPropertyUUIDs);
      const subcomponentFilteredInspections = filterVals(subcomponentInspections);

      const subcomponentInspectionUuidList = subcomponentFilteredInspections.map(inspection => inspection.uuid);
      const subcomponentCurrentHash = await generateHash(subcomponentInspectionUuidList);
      const recentSubcomponentEvaluation = subcomponent.evaluations && subcomponent.evaluations.find(evaluation => evaluation.recorded_yhat_json !== null);

      if (!recentSubcomponentEvaluation || recentSubcomponentEvaluation.hash !== subcomponentCurrentHash) {
        hasValidMPe = true;
        setIsValidMultipointForSubcomponents(true);
        setShowMPeAlreadyRunButton(false);
        allAlreadyRun = false;
        break;
      } else {
        setIsValidMultipointForSubcomponents(false);
      }
    } else {
      setIsValidMultipointForSubcomponents(false);
    }
  }

  // Update state based on overall validity
  if (hasValidMPe && allAlreadyRun) {
    setIsValidMultipoint(false);
    setIsValidMultipointForSubcomponents(false);
    setShowMPeAlreadyRunButton(true);
  }
  else if(allAlreadyRun) {

    setShowMPeAlreadyRunButton(true);
  } else {
    setShowMPeAlreadyRunButton(false);
  }
  setIsLoadingMPe(false);

};

// useEffect(() => {
//   checkValidMultipoint();
// }, [e, selectedPropertyUUIDs]);

const getInspectionsForEquipment = async (equipmentToFetch, propertyUUIDs) => {
  if (Utils.isBlank(propertyUUIDs) || propertyUUIDs.length === 0) {
    return [];
  }
  let selectedPointNumbers = null;


  const newUploads = await Val.filterBy([
    { field: 'archived', value: 0, comparison: "=" },
    propertyUUIDs ? { field: "properties.uuid", value: _.first(propertyUUIDs), comparison: "=" } : null,
    equipmentToFetch ? { field: 'fig_id', value: equipmentToFetch.id, comparison: "=" } : null,
    selectedPointNumbers ? { field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "=" } : null,
  ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true);

  let uploadList = newUploads.data || [];

  uploadList = uploadList.map(upload => {
    return {
      ...upload,
      sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
      parent_fig_key: upload?.point_info?.parent_fig_key,
      childSort: upload?.point_info?.parent_fig_key === null ? upload?.point_info?.parent_fig_key - 1 : upload?.point_info?.parent_fig_key
    };
  });



  uploadList = _.orderBy(uploadList, [
    upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
    upload => upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key,
  ], ['desc', 'desc', 'desc']);

 
  if(equipmentToFetch !== undefined){
    uploadList = _.flatten(
      Object.values(
        _.groupBy(uploadList, row => extractPointName(row)
      )
    )
    // sort by 
    .map(x => _.first(x)))
    .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx)
  }

  return uploadList;
};


const handleValidClick = async (event) => {
  event.stopPropagation(); // Prevent the li onClick from firing
  setLoading(true);
  // Show a pop-up message
  toast.success('Starting Validity Checks', {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      background: '#4caf50',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  });

  const updatedEquipmentNames = [];

  const processEquipment = async (equipmentToProcess) => {
    if (equipmentToProcess.is_valid_mpe === null) {
      const inspections = await getInspectionsForEquipment(equipmentToProcess, selectedPropertyUUIDs);
      const readingGroups = [{ uuids: inspections.map(point => point?.uuid).filter(uuid => uuid != null), staging: false }];
      const isValidArray = await MultipointEvaluation.validMultipointFromReadings(readingGroups);
      const isValidMultipoint = isValidArray?.includes(true);
      console.log("Equipment and is valid?", equipmentToProcess.name, isValidMultipoint);

      // Update the equipment's is_valid_mpe field
      await Equipment.update({ ...equipmentToProcess, is_valid_mpe: isValidMultipoint });
      updatedEquipmentNames.push(equipmentToProcess.name);
    }
  };

  // Process the main equipment
  await processEquipment(e);

  // Process each subcomponent
  for (let subcomponent of e.allSubcomponents) {
    await processEquipment(subcomponent);
  }

  setLoading(false);

  let equipmentResults = await Equipment.filterBy([
    {"field": "uuid", "value": `${equipment.uuid}`, "comparison": "="},
    {"field": "archived", "value": 0, "comparison": "="}
  ].filter(x => x != null), [
    {"field": "recorded_at", "value": `${startDate || '2000-01-01'}`, "comparison": ">"},
    {"field": "recorded_at", "value": `${moment(endDate).add(1, 'days').format('YYYY-MM-DD')}`, "comparison": "<"},
  ], "name", "asc", 100000, undefined, undefined, undefined, true);
  const data = equipmentResults?.data;
  if (data && data.length > 0) {
    setEquipment(data[0]);
  }

  // Show final toast with the names of updated equipment or a message if all equipment have been checked
  const toastMessage = updatedEquipmentNames.length > 0 
    ? `Validation Finished for: ${updatedEquipmentNames.join(', ')}`
    : 'All Equipment Have Been Checked';

  toast.success(toastMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      background: '#4caf50',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  });
};
const handleWizardClick = async (event) => {
  setLoading(true);
  setIsLoadingMPe(true);
  event.stopPropagation(); // Prevent the li onClick from firing
  console.log('Test button clicked');
  console.log("This is the equipment", equipment);
  toast.success(`MPe Started`, {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      background: '#4caf50',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  });
  try {
    const equipmentUUIDs = [equipment.uuid];

    // Check if there are subcomponents and add their UUIDs to the list
    if (equipment.allSubcomponents && equipment.allSubcomponents.length > 0) {
      const subcomponentUUIDs = equipment.allSubcomponents.map(subcomponent => subcomponent.uuid);
      equipmentUUIDs.push(...subcomponentUUIDs);
    }


    const userId = user.id;
    const createEvaluations = true;

    console.log("vars being sent: equipmentUUIDs, startDate, endDate, userId, createEvaluations", equipmentUUIDs, startDate, endDate, userId, createEvaluations);

    const result = await MultipointEvaluation.createMultipointFromEquipment(equipmentUUIDs, startDate, endDate, userId, createEvaluations);
    console.log('Multipoint Evaluation Result:', result);
  


    let equipmentResults = await Equipment.filterBy([
      {"field": "uuid", "value": `${e.uuid}`, "comparison": "="},
      {"field": "archived", "value": 0, "comparison": "="}
    ].filter(x => x != null), [
      {"field": "recorded_at", "value": `${startDate || '2000-01-01'}`, "comparison": ">"},
      {"field": "recorded_at", "value": `${moment(endDate).add(1, 'days').format('YYYY-MM-DD')}`, "comparison": "<"},
    ], "name", "asc", 100000, undefined, undefined, undefined, true);

    const data = equipmentResults?.data;

    if (data && data.length > 0) {

      setEquipment(data[0]);
    }

    toast.success('Multipoint Evaluation has Finished', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 'bold',
      },
    });

    setLoading(false);
    setIsLoadingMPe(false);
    setIsValidMultipoint(false);
    setIsValidMultipointForSubcomponents(false);
    setShowMPeAlreadyRunButton(true);
  
  } catch (error) {
    console.error('Error in createMultipointFromEquipment:', error);
    setLoading(false);
  }
};

const handleTestClick = async (event) => {
  event.stopPropagation(); // Prevent the li onClick from firing
  setLoading(true);
  try {

    const inspections = await getInspectionsForEquipment(e, selectedPropertyUUIDs);
    const filteredInspections = filterVals(inspections);
    console.log("FILTERED INSPECTIONS LIST", filteredInspections);
    console.log("INSPECTIONS LIST", inspections);
    const inspectionUuidList = filteredInspections.map(inspection => inspection.uuid);
    console.log("INSPECTIONS UUID LIST", inspectionUuidList);

    const currentHash = await generateHash(inspectionUuidList);
    console.log("This is the equipment", equipment, equipment.name);
    console.log("THIS IS THE CURRENT HASH WE CALCULATED", currentHash, equipment.name);


    const equipmentUUIDs = [equipment.uuid];

    // Check if there are subcomponents and add their UUIDs to the list
    if (equipment.allSubcomponents && equipment.allSubcomponents.length > 0) {
      const subcomponentUUIDs = equipment.allSubcomponents.map(subcomponent => subcomponent.uuid);
      equipmentUUIDs.push(...subcomponentUUIDs);
    }

    const userId = user.id;

    const result = await MultipointEvaluation.validMultipointFromEquipment(equipmentUUIDs, startDate, endDate, userId);
    console.log('Validity Result:', result, equipment.name);

    setLoading(false);
  } catch (error) {
    console.error('Error in validMultipointFromEquipment:', error);
    setLoading(false);
  }
};


const handleSuperWizardClick = async (event) => {
  event.stopPropagation(); // Prevent the li onClick from firing

 // Show a pop-up message
 toast.success('Starting SuperAdmin Multipoint Evaluation', {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  style: {
    background: '#4caf50',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
  },
});
const uuidList = inspections.map(point => point?.uuid).filter(uuid => uuid != null);



  const results = await MultipointEvaluation.createMultipointFromReadings([{ uuids: uuidList, staging: true }]);



  if (results && results.length > 0) {
    const result = results[0];
    const recordedYHat = result?.recordedYHat;

    if (recordedYHat && recordedYHat?.nodes && recordedYHat?.nodes?.length > 0) {
      const node = recordedYHat.nodes[0];
      const evalColorIcon = node.evalLevel;
      const mpeActions = JSON.stringify(node.actionUuids);
      const mpeTags = JSON.stringify(node.tagUuids);
      const evalCreatedAt = new Date().toISOString();

      setSuperWizardResult({ evalColorIcon, mpeActions, mpeTags, evalCreatedAt });
      setShowSuperWizardTable(true);
    }
  }

  // Show a pop-up message
  toast.success('Multipoint Evaluation has Finished in SuperAdmin Mode', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      background: '#4caf50',
      color: '#fff',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  });


};


 useEffect(() => {
  let checkCounts = equipment?.vals && Object.keys(_.groupBy(equipment.vals?.edges, val => val.node && val?.node?.point_num)).length;
  setValChecksCount(checkCounts); 
}, equipment)



  // ----------------

  const filterVals = (vals) => {
    return vals.filter(val => {
      const createdAtDate = moment(val?.recorded_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      return moment(createdAtDate).isBetween(moment(startDate), moment(endDate), undefined, '[]');
    });
  }

  const {
    allMatchedPointInfoChildren,
    allMatchedPointInfoEquipment,
    allMatchedPointInfoIncludingChildren,

    allPointInfosIncludingChildren
  } = useMemo(() => {
    // Point Info Consolidation
    let allPointInfosOnlyEquipment = e && e?.pointInfos ? e?.pointInfos || [] : [];
    let allPointInfosOnlyChildren = e && e?.pointInfos ? [
      ...(e?.allSubcomponents || []).flatMap((child) => child?.pointInfos),
    ] : [];

    // Vals Consolidation
    let allValsOnlyEquipment = filterVals(e && e.vals ? e.vals?.edges?.map(edge => edge.node) || [] : []);
    let allValsOnlyChildren = filterVals(e && e.allSubcomponents ? [
      ...(e.allSubcomponents || []).flatMap((child) => child.vals?.edges?.map(edge => edge.node) || []),
    ] : []);

    if (allPointInfosOnlyChildren.length === 0) {
      allPointInfosOnlyChildren = [];
    }
    if (allPointInfosOnlyEquipment.length === 0) {
      allPointInfosOnlyEquipment = [];
    }

    const allPointInfosIncludingChildren = [...allPointInfosOnlyChildren, ...allPointInfosOnlyEquipment];


    const allMatchedPointInfoChildren = allPointInfosOnlyChildren.filter(pointInfo => {
      return allValsOnlyChildren.map(val => val?.point_num).includes(pointInfo?.num);
    });

    const allMatchedPointInfoEquipment = allPointInfosOnlyEquipment.filter(pointInfo => {
      return allValsOnlyEquipment.map(val => val?.point_num).includes(pointInfo?.num);
    });

    const allMatchedPointInfoIncludingChildren = [...allMatchedPointInfoChildren, ...allMatchedPointInfoEquipment]


    
    return {
      allMatchedPointInfoChildren,
      allMatchedPointInfoEquipment,
      allMatchedPointInfoIncludingChildren,

      allPointInfosIncludingChildren
    };
  }, [e, e.pointInfos, e.allSubcomponents, startDate, endDate]);


  // Calculate Perpetual Score
  const calculatePerpetualScore = (points) => {
  
    const multipliers = { E1: 4, E2: 3, E3: 2, E4: 1, E5: 0 };
  
    // If allMatchedPointInfoChildren is empty, use the current logic
    if (allMatchedPointInfoChildren.length === 0) {
      let numerator = 0;
      let denominator = points.length * 4;
  
      points.forEach(point => {
        if (point.evalNum !== null) {
          let category;
          if (point.evalNum >= 1 && point.evalNum <= 5) category = 'E1';
          else if (point.evalNum >= 6 && point.evalNum <= 10) category = 'E2';
          else if (point.evalNum >= 11 && point.evalNum <= 15) category = 'E3';
          else if (point.evalNum >= 16 && point.evalNum <= 20) category = 'E4';
          else if (point.evalNum >= 21 && point.evalNum <= 25) category = 'E5';
  
          if (category) {
            numerator += multipliers[category];
          }
        }
      });
  
      return (numerator / denominator) * 100;
    } else {
      // Group points by fig_uuid
      const groupedPoints = _.groupBy(allMatchedPointInfoIncludingChildren, 'fig_uuid');
      let totalNumerator = 0;
      let totalDenominator = 0;
  
      // Calculate numerator and denominator for each group
      Object.values(groupedPoints).forEach(group => {
        let groupNumerator = 0;
        let groupDenominator = group.length * 4;
  
        group.forEach(point => {
          if (point.evalNum !== null) {
            let category;
            if (point.evalNum >= 1 && point.evalNum <= 5) category = 'E1';
            else if (point.evalNum >= 6 && point.evalNum <= 10) category = 'E2';
            else if (point.evalNum >= 11 && point.evalNum <= 15) category = 'E3';
            else if (point.evalNum >= 16 && point.evalNum <= 20) category = 'E4';
            else if (point.evalNum >= 21 && point.evalNum <= 25) category = 'E5';
  
            if (category) {
              groupNumerator += multipliers[category];
            }
          }
        });
  
        totalNumerator += groupNumerator;
        totalDenominator += groupDenominator;
      });
  
      return (totalNumerator / totalDenominator) * 100;
    }
  };

const perpetualScore = useMemo(() => calculatePerpetualScore(allMatchedPointInfoIncludingChildren), [allMatchedPointInfoIncludingChildren]);

  useEffect(() => {
    let checkCounts = equipment?.vals && Object.keys(_.groupBy(equipment.vals?.edges, val => val.node && val?.node?.point_num)).length;
    setValChecksCount(checkCounts); 
  }, [equipment, refresh])

  // MSI Inspection Row 
  const inspectionNumerator = useMemo(() => {
    return allMatchedPointInfoEquipment?.length || 0;
  }, [equipment, equipment.vals]);

  const inspectionDenominator = useMemo(() => {
    return equipment?.points?.length || 0
  }, [equipment, equipment.pointInfos])

  // Component Row
  const componentInspectionNumerator = useMemo(() => {
    return allMatchedPointInfoChildren?.length || 0;
  }, [subcomponents?.length])

  const componentInspectionDenominator = useMemo(() => {
    return subcomponents.reduce((acc, component) => {
      return acc + (component.points || []).length
    }, 0)
  }, [subcomponents?.length])


  // MSi Evaluation Segments
  const red = allMatchedPointInfoIncludingChildren.filter(pi => pi.evalNum && pi.evalNum > 20).length
  const amber = allMatchedPointInfoIncludingChildren.filter(pi => pi.evalNum && pi.evalNum > 15 && pi.evalNum <= 20).length
  const yellow = allMatchedPointInfoIncludingChildren.filter(pi => pi.evalNum && pi.evalNum > 10 && pi.evalNum <= 15).length
  const lime = allMatchedPointInfoIncludingChildren.filter(pi => pi.evalNum && pi.evalNum > 5 && pi.evalNum <= 10).length
  const green = allMatchedPointInfoIncludingChildren.filter(pi => pi.evalNum && pi.evalNum >= 0 && pi.evalNum <= 5).length
  const total = componentInspectionDenominator + inspectionDenominator


  // ----------------


  const imageStyle = {
    width: '100%',
    maxWidth: isComponent ? (isImageZoomed ? '450px' : '200px') : '350px',
    height: 'auto',
    maxHeight: '300px',
  };

  const onImageClick = () => {
    if (isComponent) {
      setIsImageZoomed(!isImageZoomed);
    } else {
      // Other click functionality for when isComponent is false
    }
  };

  const loadChecks = async () => {
    let sections = equipment?.sheets?.length !== 0 ? equipment?.sheets[0]?.sections : [];
    let tasks = equipment?.sheets?.length !== 0 ? equipment?.sheets[0]?.tasks : [];
    const taskUUIDs = [...(sections?.map(section => section?.tasks && section?.tasks?.map(task => task?.uuid)) || []), ...(tasks?.map(task => task?.uuid) || [])].filter(x => x !== undefined);
    const figChecks = await Check.filterBy([
      {field: 'task_uuid', value: JSON.stringify(taskUUIDs), comparison: "IN"},
      {field: "archived", value: 0, comparison: "="},
    ], "created_at", "desc", 250, undefined, undefined, undefined, true );
    let resultChecks = figChecks?.data
   
    if (startDate && endDate){
      const oneDayAfterEndDate = moment(endDate).add(1, 'day');
      resultChecks = resultChecks?.filter(x => moment.tz(x?.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate));
    }
    resultChecks = resultChecks?.filter(x => x?.fig_uuid === equipment?.uuid)

  }







  const onRescan = async (imageDataURL = image_URL, imageFile = image_File) => {
    if (image_URL === null) {
      // set the values for the already uploaded image

      if (!equipment.faceplate_image) {
        alert("Pick a nameplate image to rescan");
        return;
      }

      await fetch(`https://${equipment.faceplate_image.bucket}.s3.amazonaws.com/${equipment.faceplate_image.key}?cachebust=${(new Date().valueOf())}`)
        .then(response => response.blob())
        .then(imageBlob => {
          // Then create a local URL for that image and print it 
          var url = URL.createObjectURL(imageBlob);
          var file = new File([imageBlob], `${equipment.faceplate_image.key}`, { type: "image/jpeg", lastModified: new Date().getTime() })

          imageDataURL = url;
          imageFile = file;
        });


    }
    if (imageFile.type === 'image/heic') {
      setLoading(true);

      let converted = await heicConversion(imageDataURL, imageFile.name);
      imageDataURL = converted.url;
      imageFile = converted.file;

      setLoading(false);
    }


    setLoading(true);

    setEditModal({
      title: "Nameplate Photo",
      fp_image: imageDataURL,
      type: 'load',

      jsonFlag: true,

      onUpload: () => {
        uploadFlag = true;
      },
      onHide: () => {
        setEditModal({})
      },
      onSubmit: async (e, row, modelNum, serialNum) => {


      }
    });


    let justBase64 = await extractBase64FromDataURL(imageFile);


    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Content-Type": "application/json"
    }

    let bodyContent = JSON.stringify({
      "base64Image": `${justBase64}`

    });
    let response;

    try {
      response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
        method: "POST",
        body: bodyContent,
        headers: headersList
      });
    } catch (err) {
      alert("There was an error trying to get response from OCR Api");
    }

    let data = await response.text();
    let json = JSON.parse(data);
    setLoading(false);

    setLoading(true);
    setEditModal({
      title: "Nameplate Photo",
      fp_image: imageDataURL,

      value: equipment,
      type: 'fp_text',
      model_num: (json.mn === null ? '' : json.mn),
      serial_num: (json.sn === null ? '' : json.sn),
      json: json,
      jsonFlag: true,

      onUpload: () => {
        uploadFlag = true;
      },
      onHide: () => {
        setEditModal({})
      },
      onSubmit: async (e, row, modelNum, serialNum) => {
        e.preventDefault();
        if (row.parent_key === null) {
          let returnedEquipment = await Equipment.updatePhoto(equipment.uuid, imageDataURL, imageFile, true, equipment);
          await Equipment.update({ uuid: equipment.uuid, model_num: modelNum, serial_num: serialNum })
          setImage_URL(null);
          setImage_File(null);

        }

      }
    });
    setLoading(false);

  };



  const onChange = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0 && !uploadFlag) {
      uploadFlag = false;

      if (imageList[0].file.type === 'image/heic') {
        setLoading(true);

        let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);

        await Equipment.updatePhoto(equipment.uuid, converted.url, converted.file, false, equipment);

        setLoading(false);
      } else {
        setLoading(true);
        await Equipment.updatePhoto(equipment.uuid, imageList[0].dataURL, imageList[0].file, false, equipment);

        setLoading(false);
      }

    } else if (imageList.length > 0 && uploadFlag) {
      uploadFlag = false;

      let imageDataURL = imageList[0].dataURL;
      let imageFile = imageList[0].file;

      setImage_URL(imageDataURL);
      setImage_File(imageFile);

      if (imageList[0].file.type === 'image/heic') {
        setLoading(true);

        let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);
        imageDataURL = converted.url;
        imageFile = converted.file;

        setLoading(false);
      }


      setLoading(true);

      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'load',

        jsonFlag: true,

        onUpload: () => {
          uploadFlag = true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {


        }
      });


      let justBase64 = await extractBase64FromDataURL(imageFile);


      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json"
      }

      let bodyContent = JSON.stringify({
        "base64Image": `${justBase64}`

      });
      let response;

      try {
        response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
          method: "POST",
          body: bodyContent,
          headers: headersList
        });
      } catch (err) {
        alert("There was an error trying to get response from OCR Api");
      }

      let data = await response.text();
      let json = JSON.parse(data);
      setLoading(false);

      setLoading(true);
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,

        value: equipment,
        type: 'fp_text',
        model_num: (json.mn === null ? '' : json.mn),
        serial_num: (json.sn === null ? '' : json.sn),
        json: json,
        jsonFlag: true,

        onUpload: () => {
          uploadFlag = true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          e.preventDefault();
          if (row.parent_key === null) {
            let returnedEquipment = await Equipment.updatePhoto(equipment.uuid, imageDataURL, imageFile, true, equipment);
            await Equipment.update({ uuid: equipment.uuid, model_num: modelNum, serial_num: serialNum })
            setImage_URL(null);
            setImage_File(null);

          }

        }
      });
      setLoading(false);

    };


  }

  return (
<div 
    className={`card btn rounded-lg mr-2 mb-2 ${sortByInspections && isComponent ? '' : ''}`} 
    key={e.uuid} 
    style={{
      width: cardWidth(),
      maxWidth: '600px',
      backgroundColor: isComponent ? '#D7E8FA' : '', // Conditional background color

    }}>      {/* <EditModal options={editModal}/> */}
      <div className={`d-flex flex-column justify-content-center align-items-start`}>
     

        <ImageUploading
          multiple={false}
          onChange={onChange}
          maxNumber={1}
        >

          {({ dragProps, isDragging, onImageUpload }) => (

            <div {...dragProps} style={{ width: '100%' }} >
              <EditModal onRescan={onRescan} onImageUpload={onImageUpload} options={editModal} />
              {loading && <ImSpinner3 className="icon-spin" style={{ fontSize: 30, marginTop: '1em', marginBottom: '1em', minWidth: '300px' }} />}
              {!loading && <>
                {equipment.image
                  ? <>
                    <img className="image-thumbnail rounded-lg"
                      // style={{ maxWidth: '100%', maxHeight: '100%', backgroundImage: `url("https://web.dstapp.com/icons/image-placeholder3.svg")` }}
                      style={imageStyle}
                      onClick={() => { 
                        if(!isComponent){
                          equipmentSelectFunction(e) 
                        }
                        else onImageClick();
                      }}
                      src={equipment.image.bucket.includes('media3') ? `https://s3.amazonaws.com/${equipment.image.bucket}/${equipment.image.key}?cachebust=${(new Date().valueOf())}` : `https://${equipment.image.bucket}.s3.amazonaws.com/${equipment.image.key}?cachebust=${(new Date().valueOf())}`}
                    />
                    <div onClick={e => {
                      setEditModal({
                        title: "Nameplate Photo",
                        value: equipment,
                        type: "fp_text",
                        faceplate: true,
                        model_num: equipment.model_num,
                        serial_num: equipment.serial_num,
                        jsonFlag: false,
                        onUpload: () => {
                          uploadFlag = true;
                        },

                        onHide: () => {
                          setEditModal({})
                        },

                        onSubmit: async (e, row, modelNum, serialNum) => {
                          e.preventDefault();
                          await Equipment.update({ uuid: equipment.uuid, model_num: modelNum, serial_num: serialNum });
                        }

                      })
                      if (!equipment.faceplate_image) {
                        uploadFlag = true;
                        onImageUpload(e);
                      }

                    }


                    } style={{ color: 'white', position: 'absolute', textAlign: 'left', padding: '0', margin: '0', left: '2em', top: '0.3em' }}>
                      <FaEdit style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }} />

                    </div>

                    <div onClick={e => { uploadFlag = false; onImageUpload(e); }} style={{ color: 'white', position: 'absolute', textAlign: 'left', padding: '0', margin: '0', left: '.85em', top: '0.3em' }}>
                      <FaCamera style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }} />

                    </div>

                  </>
                  : <>
                    {/* <img onClick={onImageUpload} src="/icons/image-placeholder3.svg" className="image-thumbnail rounded-lg" style={{ width: '100%', maxWidth: '350px', height: 'auto', maxHeight: '300px' }} /> */}
                    <img onClick={() => { 
                      uploadFlag = false; 
                      onImageUpload(e) 
                      }} src="/icons/image-placeholder3.svg" className="image-thumbnail rounded-lg" style={{ width: '100%', maxWidth: '350px', height: 'auto', maxHeight: '300px' }} />

                    <div onClick={e => {
                      setEditModal({
                        title: "Nameplate Photo",
                        value: equipment,
                        type: "fp_text",
                        faceplate: true,
                        model_num: equipment.model_num,
                        serial_num: equipment.serial_num,
                        jsonFlag: false,
                        onUpload: () => {
                          uploadFlag = true;
                        },

                        onHide: () => {
                          setEditModal({})
                        },

                        onSubmit: async (e, row, modelNum, serialNum) => {
                          e.preventDefault();
                          await Equipment.update({ uuid: equipment.uuid, model_num: modelNum, serial_num: serialNum });
                        }

                      })
                      if (!equipment.faceplate_image) {
                        uploadFlag = true;
                        onImageUpload(e);
                      }

                    }} style={{ color: 'white', position: 'absolute', textAlign: 'left', padding: '0', margin: '0', left: '2em', top: '0.3em' }}>
                      <FaEdit style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }} />

                    </div>

                    <div onClick={e => { uploadFlag = false; onImageUpload(e); }} style={{ color: 'white', position: 'absolute', textAlign: 'left', padding: '0', margin: '0', left: '.85em', top: '0.3em' }}>
                      <FaCamera style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }} />

                    </div>
                  </>
                }
              </>}
              {/* {isDragging ? "Drop here" : "Upload new image"}
          {imageList.map((image, index) => (
            <img key={index} src={image.data_url} />
          ))} */}
            </div>
          )}





        </ImageUploading>
      </div>


      <div className="card-body pt-1 pl-1 pr-0 pb-0 mt-2" onClick={() => {equipmentSelectFunction(e); setShowComponentsCard(false);}}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 400, hide: 400 }}
          overlay={
            <div className="d-flex flex-column rounded-lg p-3 ml-1" style={{ backgroundColor: '#000', color: 'white', fontSize: '1.4em' }}>
              {/* <span>Nameplate Photo: {equipment.faceplate_image ? 'Yes' : 'No'}</span> */}
              <span>Make: {equipment.make || "N/A"}</span>
              <span>Model: {((equipment.model_num === null ? 0 : equipment.model_num.length) > 15 ? equipment.model_num.substring(0, 15) + "..." : equipment.model_num) || "N/A"}</span>
              <span>Serial: {equipment.serial_num || "N/A"}</span>
              <span>System: {equipment?.system?.name || "N/A"}</span>
              <span>Year: {equipment.year || "N/A"}</span>
            </div>
          }
        >
          <h5 className="card-title" onClick={(event) => {
            event.preventDefault();
            equipmentSelectFunction(e)
          }}>
            {/* {!hideAnalysisColors && <span className="mr-2">{Equipment.calcAnalysisComponentIcon(e)}</span>} */}
            {equipment.name}
            
            {equipment.faceplate_image && <MdPlaylistAddCheck className="ml-2" style={{ color: 'green' }} />}
          </h5>
        </OverlayTrigger>

        {/* {showValidButton && (
            <span 
              style={{ 
                cursor: 'pointer', 
                fontSize: '18px', 
                color: 'blue', 
                fontWeight: 'bold' 
              }} 
              onClick={handleValidClick}
            >
              Valid?
            </span>
          )} */}

     { sortByInspections &&  <div className="d-flex flex-row">
          {allPointInfosIncludingChildren && <>
              <EvaluationBar
                criticalityEquivalentColor={25}
              count={red}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={20}
              count={amber}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={15}
              count={yellow}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={10}
              count={lime}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={5}
              count={green}
              total={total} />
              <EvaluationBar
                criticalityEquivalentColor={null}
              count={total - red - amber - yellow - lime - green}
              total={total} />
            </>
            }
        </div>}

        <div className="d-flex flex-row">
          {!allPointInfosIncludingChildren && (total != 0) &&
 <>
              <EvaluationBar
                criticalityEquivalentColor={25}
                count={0}
              total={total} />
              <EvaluationBar
              criticalityEquivalentColor={20}
                count={0}
              total={total} />
              <EvaluationBar
              criticalityEquivalentColor={15}
              count={0}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={10}
              count={0}
              total={total} />
            <EvaluationBar
              criticalityEquivalentColor={5}
              count={0}
              total={total} />
              <EvaluationBar
                criticalityEquivalentColor={null}
              count={total}
              total={total} />
            </>
            }
        </div>



            
        {/* Components */}
        {(subcomponents?.length !== 0 || !allowHidingComponentSection) && (subcomponents?.length !== 0) && 
          <>
           <li 
              className="list-group-item d-flex align-items-center flex-row justify-content-between" 
              onClick={() => {
                  setShowComponentsCard(true);
                  if (expandedEquipmentUUIDs.includes(equipment.uuid)) {
                      setExpandedEquipmentUUIDs(expandedEquipmentUUIDs.filter(x => x !== equipment.uuid));
                  } else {
                      setExpandedEquipmentUUIDs([...expandedEquipmentUUIDs, equipment.uuid]);
                  }
    }}
>

              {!hideAnalysisColors && Equipment.calcAnalysisComponentsIcon(e)}
              <div>
                
              Components {hideValCheckNumerator ? <></> : <>({subcomponents.length || 0})</>}
              <button
              style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '10px' }}
              onClick={(event) => {event.stopPropagation(); setShowMPETableComponents(!showMPETableComponents)}}
            >
              {showMPETableComponents ? <FaCaretUp size={20} /> : <FaCaretDown size={20} />}
            </button>
              </div>
              {/* <span className="badge badge-dark float-right">{subcomponents.length}</span> */}
              {!hideInspectionCounts && <span className="badge badge-dark mr-1 ml-1 float-right">

                {!hideValCheckNumerator && <>
                  {!hideValInspectionCountNumerator && <>
                  {
                    componentInspectionNumerator
                    }
                    &nbsp;/&nbsp;
                  </>}
                </>}
              {
                componentInspectionDenominator
                }
              </span>}
            </li>
{showMPETableComponents && equipment.allSubcomponents && equipment.allSubcomponents.map(component => (
  <div key={component.uuid} style={{ marginTop: '1.5rem',marginBottom: '1rem', padding: '1rem', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
    <h5 style={{ fontSize: '1.2rem', fontWeight: '600', margin: '0.5rem 0', color: '#333' }}>{component.name}</h5>
    <MPETable equipmentUUID={component.uuid} equipment={component} />
  </div>
))}
            {expandedEquipmentUUIDs.filter(x => x === equipment.uuid).length > 0 && _.orderBy(subcomponents, [
              fig => fig.lastValUploaded && moment(fig.lastValUploaded.recorded_at).unix() || -1,
              fig => alphaNumberSort(fig.name)
            ], ['desc', 'desc']).map(component => {
              return (
                <>

                  <li className="list-group-item ml-2 p-1 d-flex flex-row justify-content-between" onClick={(event) => {
                    event.preventDefault();
                    subcomponentSelectFunction(component, equipment)
                  }}>
                    {!hideAnalysisColors && <span className="mr-2 ml-2">{Equipment.calcAnalysisComponentIcon(component)}</span>}
                  
                    <abbr className='' title={component.lastValUploaded ? moment(component.lastValUploaded.recorded_at).local().format('M-D-YY HH:mm') : null}>{component.name}</abbr>
                   
                    {!hideInspectionCounts && !hideValCheckNumerator && <span className="badge badge-dark mr-1 ml-1 float-right" style={{ height: '1.5em' }}>{
                      component.vals && Object.keys(_.groupBy(
                        component.vals.edges.map(v => v.node).filter(v => moment(v.recorded_at).isBetween(moment(startDate), moment(endDate)))
                        , val => val && val.point_num)).length} &nbsp;/&nbsp; {(component.points || []).length
                      }</span>}
                  </li>
                </>
              )
            })
            }
          </>
        }


        {/* Points */}
        {(equipment.points.length !== 0 || !allowHidingPointsSection) && sortByInspections &&
          <>
           <li className="list-group-item d-flex align-items-center flex-row justify-content-between" 
    onClick={() => {
      setShowPointTable(true);
        if (expandedPointUUIDs.includes(equipment.uuid)) {
            setExpandedPointUUIDs(expandedPointUUIDs.filter(x => x !== equipment.uuid));
        } else {
            setExpandedPointUUIDs([...expandedPointUUIDs, e.uuid]);
        }
    }}>
            <span style={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                {!hideAnalysisColors && Equipment.calcAnalysisPointsIcon(equipment)}    
            </span>
              MSi Inspections
              {(!hideInspectionCounts) && <span className="badge badge-dark float-right">
                {!hideValInspectionCountNumerator && <>
                  {!hideValCheckNumerator && <>
                  {inspectionNumerator}
                  &nbsp;/&nbsp;
                  </>}
              </>}
                {
                inspectionDenominator
              }

              </span>}
            </li>

            {expandedPointUUIDs.filter(x => x === equipment.uuid).length > 0 && (equipment.points || [])
              .filter(point => routine ? routine.routinePoints.filter(rp => rp.point_uuid === point.uuid).length > 0 : true)
              .sort((a, b) => a.measurement && b.measurement && a.measurement.sort_idx - b.measurement.sort_idx)
              .map(point => {
                return (
                  <>
                    <li className="list-group-item p-2 ml-2 text-left" onClick={(event) => {
                      event.preventDefault();
                      pointSelectFunction(point, e)
                    }}>
                      {!hideExpandedPointAnalysisColors && <span className="mr-2">{Equipment.calcAnalysisPointIcon(point)}</span>}
                      <span>{point.measurement && point.measurement.name}</span>
                      {!hideValCheckNumerator &&
                        <span className="d-flex flex-row justify-content-end">
                          <span className="badge badge-dark mr-1 ml-1">{
                            // (e.vals.edges.map(val => val.node).filter(val => val.point_num === point.num).length > 0 ? '✓' : '')
                            (_.first(equipment.vals && equipment.vals.edges.map(val => val.node).filter(val => val.point_num === point.num)) || {}).recorded_at
                          }</span>
                        </span>
                      }
                    </li>
                  </>
                )
              })}
          </>
        }
 
 
        {/* Evaluations */}
      
{sortByInspections &&
  <>
    <li 
        className="list-group-item d-flex align-items-center flex-row justify-content-between clickable-item" 
        onClick={() => setShowEvaluationTable(true)}

        //onClick={(event) =>  {event.stopPropagation(); setShowMPETable(!showMPETable)}}
    >

        {!hideAnalysisColors && Equipment.calcAnalysisEvalIcon(equipment)}

        <>
          {!isLoadingMPe && (
            <span 
              style={{ 
                cursor: 'pointer', 
                fontSize: '18px', 
                marginRight: '30px', 
                fontWeight: 'bold',
                color: isValidMultipoint ? 'green' : 'inherit' // Change color if isValidMultipoint is true
              }} 
              onClick={handleWizardClick}
            >
              MP<sup>e</sup>
            </span>
          )}

         
            {/* {!isLoadingMPe && showMPeAlreadyRunButton && (
                    <span 
                      style={{ 
                        cursor: 'default', 
                        fontSize: '18px', 
                        marginRight: '30px', 
                        fontWeight: 'bold',
                        color: 'grey'
                      }} 
                    >
                      MP<sup>e</sup>
                    </span>
                  )} */}

{isLoadingMPe ? <ImSpinner3 className="icon-spin" style={{ fontSize: 18 }} /> : ''}



          {isSuperAdmin() && (
            <span 
              style={{ 
                cursor: 'pointer', 
                fontSize: '18px', 
                color: 'blue', 
                fontWeight: 'bold' 
              }} 
              onClick={handleSuperWizardClick}
            >
              MP<sup>e</sup>
            </span>
          )}
              
        </>
        {equipment.parent_key === null ? 'Equipment Evaluations' : 'Evaluations'}
        {equipment.parent_key !== null && (
          <div>
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer', marginRight: '13px' }}
              onClick={(event) => {event.stopPropagation(); setShowMPETable(!showMPETable)}}
            >
              {showMPETable ? <FaCaretUp size={20} /> : <FaCaretDown size={20} />}
            </button>
          </div>
        )}
        {hideInspectionCounts && <>{" "}({(equipment.evaluations || []).length})</>}
        {!hideInspectionCounts && <span className="badge badge-dark float-right">

        {[
          ...(equipment?.evaluations || []),
          ...((equipment?.allSubcomponents || []).flatMap(subcomponent => subcomponent?.evaluations || []))
        ].filter(evaluation => {
          const createdAt = moment(evaluation.created_at, 'YYYY-MM-DD').startOf('day');
          const start = moment(startDate).startOf('day');
          const end = moment(endDate).startOf('day');
          return createdAt.isBetween(start, end, null, '[]');
        }).length}

        </span>}
    </li>
    {showMPETable && !showSuperWizardTable && <MPETable equipmentUUID={equipment.uuid} equipment={equipment} />}
    {showMPETable && showSuperWizardTable && <MPETable data={superWizardResult} equipment={equipment} />}
    {/* CSS styles to make the list item look clickable */}
    <style jsx>{`
        .clickable-item {
            cursor: pointer;
        }
        .clickable-item:hover {
            background-color: #f5f5f5;  // Or any other color you find suitable
        }
    `}</style>
</>


}


{ !isNaN(perpetualScore) && sortByInspections && (
            <>
              <li 
                className="list-group-item d-flex align-items-center flex-row justify-content-between" 
                onClick={() => {
                  // nothing right now
                }}
                >
                DST Health Score 
                <span className="badge badge-dark mr-1 ml-1 float-right">
                  {`${perpetualScore.toFixed(2)}%`}
                </span>
              </li>
            </>
          )}


    {/* Sheets */}
    {(() => {
    //const uniqueSheets = hideSheetsSection ? [] : _.uniqBy(e.sheets || [], 'system_id');
    const uniqueSheets = e?.sheets;
  
    if (uniqueSheets?.length === 0) {
        return (
            <li className="list-group-item d-flex align-items-center flex-row justify-content-between" onClick={() =>
              sheetSelectFunction(e.sheets)
          }>
                Tasking Sheet <span className="font-weight-light"></span>
                {!hideInspectionCounts && <span className="badge badge-dark float-right">
                    0
                </span>}
            </li>
        );
    }
    return uniqueSheets.map(sheet => {
        // Don't display empty sheets 
        if (sheet.id === null) return null;

        return (
            <>
                <li className="list-group-item d-flex align-items-center flex-row justify-content-between" onClick={() =>
                    sheetSelectFunction(sheet)
                }>
<div style={{ display: 'inline-block', maxWidth: '250px', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        Tasking Sheet <span className="font-weight-light" style={{ display: 'inline-block', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {(sheet || {}).name}
        </span>
    </div>                
                    {!hideInspectionCounts && <span className="badge badge-dark float-right">
              {!hideValInspectionCountNumerator && <>
                {!hideValCheckNumerator && <>
                  {e.checks && Object.keys(_.groupBy(e.checks.edges, check => check.node && check.node.task_uuid)).length}
                  &nbsp;/&nbsp;
                </>}
                </>}
                {(sheet.tasks || []).length + (sheet.sections || []).reduce((acc, section) => acc + (section.tasks || []).length, 0)}
              </span>}
                 


                </li>

                {expandedSheetUUIDs.filter(sheetUUID => sheetUUID === sheet.uuid).length > 0 && (sheet.sections || []).map(section => {
                    return (
                        <>
                            <li className="list-group-item ml-2 p-2 text-left" onClick={(event) => { }}>
                                {section.name}
                            </li>
                            {(sheet.tasks || []).filter(x => x.section_uuid === section.uuid).map(task => {
                                return (
                                    <li className="list-group-item ml-4 p-2 text-left" onClick={(event) => { }}>
                                        {task.name}
                                        {!hideInspectionCounts && <span className="badge badge-dark mr-1 ml-1 float-right">
                                            {(e.checks.edges.map(check => check.node).filter(check => check.task_uuid === task.uuid).length > 0 ? '✓' : '')}
                                        </span>}
                                    </li>
                                );
                            })}
                        </>
                    );
                })}
            </>
        );
    });
})()}
        
      {/* InspectionTable */}
 {sortByInspections && isComponent && valChecksCount !== 0 && 

<div>
  <button
    onClick={(e) => { e.stopPropagation(); setShowPointInspectionTable(!showPointInspectionTable); }}
    style={{
      padding: '5px 10px',
      margin: '10px 0',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
      fontSize: '0.9rem',
      display: 'inline-block',
    }}
  >
    {showPointInspectionTable ? 'Hide Point Inspection Table' : 'Show Point Inspection Table'}
  </button>

  {showPointInspectionTable && (
    <div
      style={{
        fontSize: '0.8rem',
        overflowY: 'auto',
        maxHeight: '400px',
        marginTop: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
      onClick={(e) => e.stopPropagation()} // Stop event propagation
    >
      <PointInspectionTable 
        selectedEquipment={equipment}
        startDate={startDate} 
        previewMode={true}
      />
    </div>
  )}
</div>
 }


{/* Progress Modal */}
<Modal 
  show={showProgressModal} 
  onHide={() => setShowProgressModal(false)} 
  centered
  style={{ fontFamily: 'Arial, sans-serif', color: '#333', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
>
  <Modal.Header closeButton style={{ borderBottom: 'none', padding: '1rem 2rem' }}>
    <Modal.Title style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>Processing Multipoint Evaluation</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ padding: '2rem', textAlign: 'center' }}>
    {progress === 0 && (
      <div style={{ marginBottom: '1rem', color: '#ff0000', fontWeight: 'bold' }}>
        Initializing, please wait...
      </div>
    )}
    <ProgressBar 
      now={(progress / totalSteps) * 100} 
      label={`${Math.round((progress / totalSteps) * 100)}% (${progress}/${totalSteps} samples)`} 
      style={{ height: '1.5rem', fontSize: '1rem', backgroundColor: '#e9ecef', borderRadius: '5px' }}
    />
  </Modal.Body>
</Modal>


 {!sortByInspections &&

<div 
style={{ 
  fontSize: '0.8rem', 
  overflowY: 'auto', 
  maxHeight: '300px', 
}}
onClick={(e) => e.stopPropagation()} 
>
<EnabledPointsTable 
selectedEquipment={equipment}
/>
</div>
}

      </div>
    </div>
  )
}

export default EquipmentCard;