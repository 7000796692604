import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import User from '../../models/User';
import { REPORT_TYPE_DESC, READING_REPORT_TYPES, ANALYSIS_REPORT_TYPES, EQUIPMENT_REPORT_TYPES, EXPORT_REPORT_TYPES, NON_DST_REPORT_TYPES, REPORT_TYPE, REPORT_TEMPLATE_NAME, REPORT_EMAIL_TEMPLATE_NAME } from '../../constants/Constants'
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import deepmerge from 'deepmerge';
import Report from '../../models/Report';
import { ImSpinner3 } from 'react-icons/im';
import { useFilterContext } from "../../context/SharedFilterContext";


const SendReportModal = (props) => {
  const { options } = props;
  const { user } = useAuth();
  const [reportData, setReportData] = useState({});
  const [sending, setSending] = useState(false);
  const [isNewReport, setIsNewReport] = useState(true);

  const [readingBasedReportSelected, setReadingBasedReportSelected] = useState(false);
  const [analysisSelected, setAnalysisSelected] = useState(false);
  const [nonDSTReportSelected, setNonDSTReportSelected] = useState(false);
  const [equipmentReportSelected, setEquipmentReportSelected] = useState(false);
  const [exportReportSelected, setExportReportSelected] = useState(false);
  const [qrCodeReportSelected, setQRCodeReportSelected] = useState(false);
  const {
    
    selectedRoutine,
  
  } = useFilterContext();


  useEffect(() => {
    if (!options.context) return;
    setIsNewReport(false);

    const reportParams = options.context && options.context.itemReportInput || {};
    console.log('options.context', options.context)

    // New Report Defaults (interpreted on modal init)
    const report = options.context ? {
      ...options.context,
      itemReportInput: {
        ...(options.context.itemReportInput),
        startTimestamp: generateDefaultStartDate(reportParams.startTimestamp).format('YYYY-MM-DD'),
        endTimestamp:  generateDefaultEndDate(reportParams.endTimestamp).format('YYYY-MM-DD'),
        showTags: reportParams.hideTags === undefined ? false : !reportParams.hideTags,
        showActions: reportParams.hideActions === undefined ? false : !reportParams.hideActions,
        showTime: reportParams.hideTime === undefined ? true : !reportParams.hideTime,
        condensePages: reportParams.usePageBreaks === undefined ? true : !reportParams.usePageBreaks,
        showTechComments: reportParams.hideTechComments === undefined ? true : !reportParams.hideTechComments,
        showCustomerComments: reportParams.hideCustomerComments === undefined ? true : !reportParams.hideCustomerComments,
        showDuplicateReadings: reportParams.showOnlyAlphaReadings === undefined ? false : !reportParams.showOnlyAlphaReadings,
        showFilters: reportParams.showFilters === undefined ? true : reportParams.showFilters,
        showBelts: reportParams.showBelts === undefined ? true : reportParams.showBelts,
        showPrices: reportParams.showPrices === undefined ? true : reportParams.showPrices,
        showAutoEvaluation: reportParams.showAutoEvaluation === undefined ? false : reportParams.showAutoEvaluation,
        outputComponentQRCodes: reportParams.outputComponentQRCodes === undefined ? true : reportParams.outputComponentQRCodes,
        showComponentInfoRow: reportParams.showComponentInfoRow === undefined ? true : reportParams.showComponentInfoRow,
        showReadingInfoTable: reportParams.showReadingInfoTable === undefined ? true : reportParams.showReadingInfoTable,
        showCheckInfoTable: reportParams.showCheckInfoTable === undefined ? true : reportParams.showCheckInfoTable,
        showPointTable: reportParams.showPointTable === undefined ? true : reportParams.showPointTable,
        workOrder: reportParams.workOrder === undefined ? "" : reportParams.workOrder,
      }
    } : {};

    console.log({ report })


    const reportTypeIndex = REPORT_TYPE_DESC.findIndex(v => v === report.itemReportInput.reportTypeName);
    updateReportOptions(reportTypeIndex)

    setReportData(report)
    console.log({ report })
  }, [ JSON.stringify(options) ])

  const transformErrors = (errors) => {
    return errors.filter(e => !e.message.includes("is an invalid additional property"));
  }

  const isWorkOrderShown = () => {
    return (readingBasedReportSelected || nonDSTReportSelected) && !exportReportSelected && !qrCodeReportSelected
  }

  const onChange = (data) => {
    const formData = data.formData;
    const selectedReportTypeName = formData.itemReportInput && formData.itemReportInput.reportTypeName;
    const reportTypeIndex = REPORT_TYPE_DESC.findIndex(v => v === selectedReportTypeName);
    updateReportOptions(reportTypeIndex)

    setReportData({
      ...reportData,
      ...formData
    })
  }

  const updateReportOptions = (reportTypeIndex) => {
    // console.log(reportTypeIndex, JSON.stringify(NON_DST_REPORT_TYPES))
    setReadingBasedReportSelected(READING_REPORT_TYPES.indexOf(reportTypeIndex) >= 0);
    setAnalysisSelected(ANALYSIS_REPORT_TYPES.indexOf(reportTypeIndex) >= 0);
    setEquipmentReportSelected(EQUIPMENT_REPORT_TYPES.indexOf(reportTypeIndex) >= 0);
    setExportReportSelected(EXPORT_REPORT_TYPES.indexOf(reportTypeIndex) >= 0);
    setNonDSTReportSelected(NON_DST_REPORT_TYPES.indexOf(reportTypeIndex) >= 0);
    setQRCodeReportSelected(REPORT_TYPE.QR_CODE_PDF === reportTypeIndex);
  } 


  const generateDefaultStartDate = startTimestamp => {
    const defaultStartDate = moment().subtract(3, 'months');
    const parsedStartDate = moment(startTimestamp || defaultStartDate);
    const earliestPossibleDate = moment('2000-01-01 00:00:00');
    const defaultDate = parsedStartDate || earliestPossibleDate;
    return defaultDate.utc().startOf('day');
  }

  const generateDefaultEndDate = endTimestamp => {
    const parsedEndDate = moment(endTimestamp || moment());
    const isAfterToday = parsedEndDate.isAfter(moment());
    const latestPossibleDate = moment();
    const defaultDate = (isAfterToday ? latestPossibleDate : parsedEndDate)
    return defaultDate.utc().endOf('day')
  }

  const onSubmit = async (data) => {
    const formData = data.formData;
    const selectedReportTypeName = formData.itemReportInput && formData.itemReportInput.reportTypeName;
    const reportType = REPORT_TYPE_DESC.findIndex(v => v === selectedReportTypeName);
    const reportParams = formData.itemReportInput || {};
    const emailContext = (formData.emailInput && formData.emailInput.context) || {}
    console.log("data", data)
    console.log("user", user)
    const routine_uuid = selectedRoutine ? selectedRoutine.uuid : null; 

    if (isWorkOrderShown() && User.isFieldConnectUser(user) && (reportParams.workOrder || "").length === 0){
      alert("No Work Order Provided", "A Work Order is required in order to relate the Work Order to the generated report.")
      return;
  }



    let specificParams = {};
    let defaultParams = {
      itemReportInput: {
          reportTemplate: 'reading-report',
          reportTypeName: selectedReportTypeName,
          sendEmail: true,
          propertyUUID: reportParams.propertyUUID,
          // pointUUIDList: null,
          // totalPointUUIDList: null,
          // equipmentUUIDList: null,
          surveyId: null,
          // routineUUID: activeRoutineUuid,
          ignoreSurveyDateRange: null,
          //routineUUID: null,
          routineUUID: routine_uuid,
          // pointsComplete: activeRoutine ? routineInspectedPointUuids.length : propertyInspectedPointUuids.length,
          // pointsTotal: activeRoutine ? routinePointUuids.length : allPropertyPointUuids.length,
          hideCompletionRatio: true, // We don't have any information from the phone to populate this with
          minimumCriticality: null,
          showOnlyFlagged: false,
          startTimestamp: generateDefaultStartDate(reportParams.startTimestamp).format('YYYY-MM-DD 00:00:00'),
          endTimestamp: generateDefaultEndDate(reportParams.endTimestamp).format('YYYY-MM-DD 23:59:59'),
          headerComment: (reportParams.headerComment || "").replace(/(\r\n\t|\n|\r\t)/gm, ''),
          hideTechComments: !reportParams.showTechComments,
          hideCustomerComments: !reportParams.showCustomerComments,
          showOnlyAlphaReadings: !reportParams.showDuplicateReadings,
          hideTags: !reportParams.showTags,
          hideActions: !reportParams.showActions,
          showPointsWithNoReadings: null,
          showPointsWithNoReadingsAfterFiltering: null,
          usePageBreaks: !reportParams.condensePages,
          showFilters: reportParams.showFilters,
          showBelts: reportParams.showBelts,
          showPointTable: reportParams.showPointTable,
          showAutoEvaluation: reportParams.showAutoEvaluation,
          hideTime: !reportParams.showTime,
          hidePrices: !reportParams.showPrices,
          showReadingInfoTable: reportParams.showReadingInfoTable,
          showCheckInfoTable: reportParams.showCheckInfoTable,
          showComponentInfoRow: reportParams.showComponentInfoRow,
          workOrder: reportParams.workOrder || ""
      },
      emailInput: {
          from: 'noreply@dsoundtech.com',
          to: user.email,
          cc: null,
          // bcc: 'discoverysoundtechnology@gmail.com',
          subject: null,
          template: 'flaggedItemReport',
          context: {
              firstName: emailContext.firstName || user.first_name,
              companyId: emailContext.companyId || user.company_id || "",
              userUuid: user.uuid || "",  // If 're-running' a report, use the current user's uuid, not the original user's uuid
              reportType: selectedReportTypeName,
          },
      },
    };


    if (reportType === REPORT_TYPE.NON_DST_INSPECTION_ALL) {
      specificParams = {
        itemReportInput: {
            reportTemplate: 'non-dst-report',
            equipmentUUIDList: [],
            showOnlyFlagged: false,
            showGraphs: false,
            hideCompletionRatio: true,
            hideTags: false
        },
      };
    }else if (reportType === REPORT_TYPE.INSPECTION_ALL) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: null,
                showOnlyFlagged: false,
                hideCompletionRatio: true
            },
        };
    } else if (reportType === REPORT_TYPE.INSPECTION_FLAGGED) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: [],
                showOnlyFlagged: true,
            },
        };
    } else if (reportType === REPORT_TYPE.PROPERTY_ALL) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: null,
                showOnlyFlagged: false,
                hideCompletionRatio: true,
                showPointsWithNoReadings: true,
                showPointsWithNoReadingsAfterFiltering: true,
            },
        };
    } else if (reportType === REPORT_TYPE.PROPERTY_FLAGGED) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: null,
                showOnlyFlagged: true,
                hideCompletionRatio: true,
            },
        };
    } else if (reportType === REPORT_TYPE.ANALYSIS_ALL) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: null,
                hideCompletionRatio: false,
                showOnlyFlagged: false,
            },
        };
    } else if (reportType === REPORT_TYPE.ANALYSIS_FLAGGED) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'non-dst-report',
                showReadingInfoTable: true,
                pointUUIDList: null,
                hideCompletionRatio: false,
                showOnlyFlagged: true,
            },
        };
    } else if (reportType === REPORT_TYPE.EQUIPMENT_DETAIL) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'equipment-detail',
                showPointsWithNoReadings: true,
                showPointsWithNoReadingsAfterFiltering: true,
            },
            emailInput: {
                template: 'equipmentReport',
            },
        };
    } else if (reportType === REPORT_TYPE.EQUIPMENT_OVERVIEW) {
        specificParams = {
            itemReportInput: {
                reportTemplate: 'equipment-overview',
                showPointsWithNoReadings: true,
                showPointsWithNoReadingsAfterFiltering: true,
            },
            emailInput: {
                template: 'equipmentReport',
            },
        };
    } else if (reportType === REPORT_TYPE.BLUEFIN_EXPORT) {
        specificParams = {
            itemReportInput: {
                routineUUID: null,
                sendEmail: false,
                sendJSONToCompany: true,
                usePageBreaks: false,
                showFilters: true,
                showBelts: true,
                headerComment: '',
                pointsComplete: null,
                pointsTotal: null,
                hideTechComments: false,
                hideCustomerComments: false,
                showAutoEvaluation: true,
                showOnlyAlphaReadings: false,
                hideTags: null,
                reportTemplate: 'reading-report',
                showPartTable: true,
                showPointTable: true
            }
        }
    }

    let params = deepmerge(defaultParams, specificParams);
    setSending(true);

    if (qrCodeReportSelected){
      await Report.sendQRCodeReport({
        propertyUUID: reportParams.propertyUUID,
        outputComponentQRCodes: reportParams.outputComponentQRCodes,
        destinationEmail: user.email});
    }else{
      await Report.send({input: params});
    }

    setSending(false);
    options.onHide();
  }

  return (
    <Modal show={options.context || options.show} onHide={options.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Report</Modal.Title>
      </Modal.Header>
      {!sending && user && <Modal.Body>
        <div>
          Report will be sent to <a href={`mailto:${user.email}`}>{user.email}</a>
          {/* {user.company && user.company.email ? <div>&nbsp;and&nbsp;<a href={`mailto:${user.company.email}`}>{user.company.email}</a></div> : null} */}
        </div>
        <br/>

        {reportData.itemReportInput && reportData.itemReportInput.reportTypeName === undefined ?
          <Form
          schema={{
            "$ref": "#/definitions/input",
            "definitions": {
              "input": {
                "type": "object",
                "properties": {
                  "itemReportInput": {
                    "properties": {
                      "reportTypeName": {
                        "type": "string",
                        "title": "Report Type",
                        "enum": REPORT_TYPE_DESC
                      },
                    },
                    "title": "",
                  }
                }
              }
            }
          }}
          onChange={onChange}
          formData={reportData}
        />
        :
        <Form
          omitExtraData={true}
          liveOmit={true}
          schema={{
            "$ref": "#/definitions/input",
            "definitions": {
              "input": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "itemReportInput": {
                    "$ref": "#/definitions/itemReportInput",
                    "title": "",
                  },
                },
                "required": [
                  "itemReportInput"
                ]
              },
              "emailInput": {
                "title": "Email Options",
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "from": {
                    "type": "string",
                    "format": "email"
                  },
                  "to": {
                    "type": "string",
                    "format": "email"
                  },
                  "cc": {
                    "type": ["string", "null"],
                    "format": "email"
                  },
                  "bcc": {
                    "type": "string"
                  },

                  "context": {
                    "$ref": "#/definitions/context"
                  },
                  "subject": {
                    "type": ["string", "null"]
                  },
                  "template": {
                    "type": "string"
                  }
                },
                "required": [
                  "from",
                  "to"
                ]
              },
              "context": {
                "title": "Email Context",
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "userUuid": {
                    "type": "string"
                  },
                  "companyId": {
                    "type": "integer"
                  },
                  "firstName": {
                    "type": "string"
                  },
                  "reportType": {
                    "type": "string"
                  }
                },
                "required": [
                  "companyId",
                  "firstName",
                  "reportType",
                  "userUuid"
                ]
              },
              "itemReportInput": {
                "type": "object",
                "additionalProperties": false,
                "properties": {
                  "reportTypeName": {
                    "type": "string",
                    "title": "Report Type",
                    "enum": REPORT_TYPE_DESC
                  },
                  ...(qrCodeReportSelected) ? {} : {
                  "headerComment": {
                    "type": "string",
                    "title": "Header Comment"
                  }},
                  ...(isWorkOrderShown() && user && User.isFieldConnectUser(user)) ? {
                    "workOrder": {
                      "type": "string",
                      "title": "Work Order"
                  }} : {},
                  ...(equipmentReportSelected || qrCodeReportSelected) ? {} : {
                  "startTimestamp": {
                    "type": "string",
                    "format": "date",
                    "title": "Start Date"
                  }},
                  ...(equipmentReportSelected || qrCodeReportSelected) ? {} : {
                  "endTimestamp": {
                    "type": "string",
                    "format": "date",
                    "title": "End Date"
                  }},
                  ...!(readingBasedReportSelected && !nonDSTReportSelected) ? {} : {
                  "showTags": {
                    "type": "boolean",
                    "title": "SoundSignature™"
                  }},
                  ...!(analysisSelected || nonDSTReportSelected) ? {} : {
                    "showActions": {
                      "type": "boolean",
                      "title": "Show Recommended Actions"
                    }},
                  ...(!(readingBasedReportSelected && !nonDSTReportSelected)) ? {} : {
                  "showAutoEvaluation": {
                    "type": "boolean",
                    "title": "Show SoundEvaluator™"
                  }},
                  ...(!nonDSTReportSelected) ? {} : {
                  "showComponentInfoRow": {
                    "type": "boolean",
                    "title": "Show Component Info"
                  }},
                  ...(!nonDSTReportSelected) ? {} : {
                  "showReadingInfoTable": {
                    "type": "boolean",
                    "title": "Show DST Inspections"
                  }},
                  ...(!nonDSTReportSelected) ? {} : {
                  "showCheckInfoTable": {
                    "type": "boolean",
                    "title": "Show Tasking Sheet Inspections"
                  }},
                  ...(!(readingBasedReportSelected || nonDSTReportSelected)) ? {} : {
                  "showDuplicateReadings": {
                    "type": "boolean",
                    "title": "Duplicate Readings",
                  }},
                  ...(!(readingBasedReportSelected || nonDSTReportSelected)) ? {} : {
                  "showTechComments": {
                    "type": "boolean",
                    "title": "Field Technician Notes"
                  }},
                  ...(!(readingBasedReportSelected || nonDSTReportSelected)) ? {} : {
                  "showCustomerComments": {
                    "type": "boolean",
                    "title": "Recommendations / Repairs"
                  }},
                  ...(!(readingBasedReportSelected || nonDSTReportSelected)) ? {} : {
                  "showTime": {
                    "type": "boolean",
                    "title": "showInspectionTime",
                    "title": "Show Inspection Time"
                  }},
                  ... (!equipmentReportSelected) ? {} : {
                  "showPointTable": {
                    "type": "boolean",
                    "title": "Points"
                  }},
                  ... (!equipmentReportSelected) ? {} : {
                  "showFilters": {
                    "type": "boolean",
                    "title": "Filters"
                  }},
                  ... (!equipmentReportSelected) ? {} : {
                  "showBelts": {
                    "type": "boolean",
                    "title": "Belts"
                  }},
                  ... (!equipmentReportSelected) ? {} : {
                  "showPrices": {
                    "type": "boolean",
                    "title": "Show Prices"
                  }},
                  ...(!qrCodeReportSelected) ? {} : {
                  "outputComponentQRCodes": {
                    "type": "boolean",
                    "title": "Generate Component QR Codes"
                  }},
                  ...(exportReportSelected || qrCodeReportSelected) ? {} : {
                  "condensePages": {
                    "type": "boolean",
                    "title": "Condense Pages"
                  }},
                },
                "title": "ReportParameters"
              }
            }
          }
          }
          uiSchema={{
            itemReportInput: {}
          }}
          onSubmit={onSubmit}
          onChange={onChange}
          formData={reportData}
          transformErrors={transformErrors}
        />
      }
      </Modal.Body>
      }
      {sending && <div className="d-flex flex-row justify-content-center mt-3 mb-3"><ImSpinner3 className="icon-spin" style={{fontSize: 30}}/></div>}
    </Modal>
  )
}

export default SendReportModal;