import { useAuth } from '../../context/AuthContext';
import PrivateRoute from '../../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import '../../styles/grid.css';
import moment from 'moment';
import Val from '../../models/Val';
import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT } from '../../constants/Constants';
import {EvaluationMark} from './EvaluationMark';
import _ from 'lodash';
import { FcDownload } from 'react-icons/fc';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Utils from '../../common/Utils';
import { FaStickyNote, FaRegFlag, FaExclamationTriangle, FaRegStickyNote, FaFlag, FaCamera, FaEdit} from 'react-icons/fa';
import {POINT_CONDITIONS} from '../../constants/Constants';
import ExpandedValAnalyticsRow from './ExpandedValAnalyticsRow';
import { toast } from 'react-toastify';
import { EditModal } from './GenericEditModal';
import Systems from "../../models/Systems";
import Property from "../../models/Property";
import { ButtonGroup, OverlayTrigger, Tooltip, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcInspection, FcTimeline, FcEngineering, FcCheckmark, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness, FcExport } from 'react-icons/fc';
import PropertyEditScreen from '../../screens/property/PropertyEdit';
import EquipmentEditScreen from '../../screens/equipment/EquipmentEdit';
import Toggle from 'react-toggle'
import { MdArrowBack } from 'react-icons/md';
import {useFilterContext} from '../../context/SharedFilterContext';
import {EquipmentEditModal} from '../../screens/components/InspectionEquipmentHierarchy';
import ZoomImage from './ZoomImage';

import React, { useEffect, useState } from 'react';
import Equipment from "../../models/Equipment";
import ImageUploading from 'react-images-uploading';
import { ImSpinner3 } from 'react-icons/im';
import {extractBase64FromDataURL, heicConversion} from "../utils/utils";
import heic2any from "heic2any";




// Indicates if uploading a primary image or nameplate image
var uploadFlag = false; 


const PropertyTable = (props) => {
  const history = useHistory();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const {setSelectedProperty, setSelectedRoutine} = useFilterContext();
  const {pathname} = useLocation();
  const showBackButton = pathname.includes('/table/');
  const { user } = useAuth();
  const {selectedEquipment, selectedProperty, onEditEquipment} = props;
  const [fullPropertyHierarchy, setFullPropertyHierarchy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [systemIdLookup, setSystemIdLookup] = useState({});
  const [editPropertyModal, setEditPropertyModal] = useState({});
  // const [selectedEquipmentUUIDs, setSelectedEquipmentUUIDs] = useState([]);
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && selectedProperty && selectedProperty.uuid) ? [selectedProperty.uuid] : []
    )
  );
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [editEquipmentModal, setEditEquipmentModal] = useState({});



  const updateCriticality = async (row) => {
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Criticality",
      value: row,
      selector: row => row.criticality || 0,
      type: "slider",
      min: 1,
      max: 5,
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Equipment.update({uuid: row.uuid, criticality: newValue, parent_key: row.parent_key});
        await updateSingleEquipment({uuid: row.uuid, criticality: returnedRow.criticality});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }
  
  const updateSingleEquipment = (updatedUpload) => {
    const equipmentList = fullPropertyHierarchy;
    const indexOfExistingUpload = equipmentList.findIndex(x => x.uuid === updatedUpload.uuid);
    if (indexOfExistingUpload === -1){
      console.log("Couldn't find upload row")
      return
    }
    equipmentList[indexOfExistingUpload] = {...equipmentList[indexOfExistingUpload], ...updatedUpload};
    setFullPropertyHierarchy([...equipmentList]);
  }

  useEffect(() => {
    refreshPropertyInformation();
    
  }, [selectedPropertyUUIDs, (selectedEquipment || {}).uuid])

  const updateUserSystems = async () => {
    const newSystemsDictionary = await Systems.getSystemsForCompanyId(user.company_id, 'id')
    setSystemIdLookup(newSystemsDictionary)

  }

  const refreshPropertyInformation = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return
    }

    setLoading(true);
    await updateUserSystems();
    const results = await Property.getFullPropertyByUUID(_.first(selectedPropertyUUIDs), user.id, undefined);

    const alphaNumberSort = (a) => {
      const numberMatch = (a || "").match(/(\d+)/g);
      const num = numberMatch && numberMatch.length > 0 ? Number(numberMatch[0]) : -1;
      return num;
    }

    results.figs = results.figs.map(fig => {
      const parentFigs = results.figs.filter(f => (f || {}).key === f.parent_key)
      return {
        ...fig,
        parent_name: (_.first(parentFigs) || {}).name,
        property_name: results.name // Add property name to each fig
      }
    })
    .filter(fig => selectedEquipment ? fig.key === selectedEquipment.key : true) // fig.parent_key === selectedEquipment.key || 

    results.figs = _.orderBy(results.figs, [
      fig => alphaNumberSort(fig.parent_name || fig.name) === -1 ? moment(fig.created_at).unix() : alphaNumberSort(fig.parent_name || fig.name),
      fig => fig.parent_key === null ? fig.key + 1 : fig.parent_key,
    ], ['asc', 'desc']);


    const allFigs = [
      ...(results.figs || []),
    ]
    setFullPropertyHierarchy(allFigs)
    setLoading(false);
  }

  const ExpandedRowComponent = (props) => {
    const expandedRow = props.data;
    const [detailedEquipment, setDetailedEquipment] = useState({});
    const [detailedParentEquipment, setDetailedParentEquipment] = useState({});
    const [loading, setLoading] = useState(true);
    const [checkedStatus, setCheckedStatus] = useState([])
    const [image_URL, setImage_URL] = useState(null);
    const [image_File, setImage_File] = useState(null);
  

    const loadEquipmentForEdit = async () => {
      const fig = await Equipment.getEquipmentToEdit(expandedRow.uuid)
      setDetailedEquipment(_.first(fig.data));

      if(expandedRow.parent_key){
        const parent_fig = await Equipment.getEquipmentToEditByKey(expandedRow.parent_key)
        setDetailedParentEquipment(_.first(parent_fig.data));
      }
      setImage_URL(null);
    setImage_File(null);
      setLoading(false)
    }


    useEffect(loadEquipmentForEdit, [])





    const onRescan = async (imageDataURL=image_URL, imageFile=image_File) => { 
      if(image_URL === null){
          if(!expandedRow.faceplate_image){
          alert("Pick a nameplate image to rescan");
          return;
        }
     
          await fetch(`https://${expandedRow.faceplate_image.bucket}.s3.amazonaws.com/${expandedRow.faceplate_image.key}?cachebust=${(new Date().valueOf())}`)
        .then(response => response.blob())
        .then(imageBlob => {
        var url = URL.createObjectURL(imageBlob);
        var file = new File([imageBlob], `${expandedRow.faceplate_image.key}`,{type:"image/jpeg", lastModified:new Date().getTime()})
        
        imageDataURL = url;
        imageFile = file; 
    });
  
  
      }
      if(imageFile.type === 'image/heic'){
        setLoading(true);
      
        let converted = await heicConversion(imageDataURL, imageFile.name);
        imageDataURL = converted.url; 
        imageFile = converted.file; 

        setLoading(false);
      }
      
       
      setLoading(true);
  
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'load', 
       
        jsonFlag: true,
  
        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          
        }
      });
  
  
      let justBase64 = await extractBase64FromDataURL(imageFile);
  
  
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json"
       }
        
       let bodyContent = JSON.stringify({
         "base64Image": `${justBase64}`
       
       });
       let response; 
       
       try{
                response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
                method: "POST",
                body: bodyContent,
                headers: headersList
              });
        }catch(err) {
            alert("There was an error trying to get response from OCR Api");
        }
       
            let data = await response.text();
            let json = JSON.parse(data);
            setLoading(false);
  
      setLoading(true);
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        
        value: expandedRow,
        type: 'fp_text', 
        model_num: (json.mn === null ? '' : json.mn),
        serial_num: (json.sn === null ? '' : json.sn),
        json: json, 
        jsonFlag: true,
  
        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          e.preventDefault();
          if(row.parent_key === null){
           let returnedEquipment=  await Equipment.updatePhoto(expandedRow.uuid, imageDataURL, imageFile, true, expandedRow);
            Equipment.update({uuid: expandedRow.uuid, model_num: modelNum, serial_num:serialNum})
            setImage_URL(null);
            setImage_File(null);
          }
        }
      });
      setLoading(false);
      
  };


    const onChange = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0 && !uploadFlag) {
      uploadFlag = false; 

      if(imageList[0].file.type === 'image/heic'){
        setLoading(true);
       
        let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);
      
        await Equipment.updatePhoto(expandedRow.uuid, converted.url, converted.file, false, expandedRow);
      
        setLoading(false);
      } else {
        setLoading(true);
        await Equipment.updatePhoto(expandedRow.uuid, imageList[0].dataURL, imageList[0].file, false, expandedRow);  
        setLoading(false);
      }

    } else if (imageList.length > 0 && uploadFlag){
      uploadFlag = false; 


      let imageDataURL = imageList[0].dataURL; 
      let imageFile = imageList[0].file;
      
      setImage_URL(imageDataURL);
      setImage_File(imageFile);

      if(imageList[0].file.type === 'image/heic'){
        setLoading(true);
   
        let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);
        imageDataURL = converted.url; 
        imageFile = converted.file; 

        setLoading(false);
      }

      setLoading(true);
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'load', 
        
        jsonFlag: true,

        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          
        }
      });

      
      let justBase64 = await extractBase64FromDataURL(imageFile);



      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json"
       }
        
       let bodyContent = JSON.stringify({
         "base64Image": `${justBase64}`
       
       });
       let response; 
       
       try{
                response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
                method: "POST",
                body: bodyContent,
                headers: headersList
              });
        }catch(err) {
            alert("There was an error trying to get response from OCR Api");
        }

     
            let data = await response.text();
            let json = JSON.parse(data);
            setLoading(false);

      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        
        value: expandedRow,
        type: 'fp_text', 
        model_num: (json.mn === null ? '' : json.mn),
        serial_num: (json.sn === null ? '' : json.sn),
        json: json, 
        jsonFlag: true,

        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          e.preventDefault();
          if(row.parent_key === null){
            await Equipment.updatePhoto(expandedRow.uuid, imageDataURL, imageFile, true, expandedRow);
            expandedRow.model_num = modelNum;
            expandedRow.serial_num = serialNum;
            Equipment.update({uuid: expandedRow.uuid, model_num: modelNum, serial_num:serialNum})

            setImage_URL(null);
            setImage_File(null);

          }
       
        }
      });
      
  };


}

    const generateData = () => {
      return !(detailedEquipment && detailedEquipment.system && detailedEquipment.system.measurements) ? [] : _.orderBy(detailedEquipment.system.measurements.map(x => {
        if (!x.measurement || x.measurement.name.length < 3){
          return null;
        }
        return {
          measurement: x.measurement,
          point: _.first((detailedEquipment.pointInfos || []).filter(y => x.measurement && y.measurement_id == x.measurement.id))
        }
      }).filter(x => x != null), [
        row => !!row.point,
        row => row.measurement && row.measurement.name
      ], ['desc', 'asc'])
    }
    const rowData = generateData();
    const totalPointsWithImages = rowData.filter(x => x.point && x.point.image).length;

    
    return (
      
      <>
      <div className="w-100 d-flex flex-row justify-content-start align-items-top flex-wrap ml-5"> 
           
        
      <div class="d-flex flex-row justify-content-start">
        <div style={{width:"800px"}}>
        <DataTable
          columns={[
            // {
            //   name: 'Equipment',
            //   selector: row => expandedRow.name,
            //   sortable: true,
            //   center: true,
            //   compact: true
            // },
            totalPointsWithImages > 0 && {
              name: 'Photo',
              cell: row => {
                // <img src={e.image.bucket.includes('media3') ? `https://s3.amazonaws.com/${e.image.bucket}/${e.image.key}` : `https://${e.image.bucket}.s3.amazonaws.com/${e.image.key}`} />
                if (row.image && row.image.bucket && row.image.key){
                  // return <img src={`https://mv.dstapp.com/${row.image.bucket}/${row.image.key}`}/>
                  return <a href={`https://mv.dstapp.com/${row.image.bucket}/${row.image.key}?cachebust=${(new Date().valueOf())}`} target="_blank">Image Link</a>
                }else{
                  return <div>N/A</div>
                }
              },
              sortable: true,
              center: true,
              compact: true
            },
            {
              name: 'Point Name',
              selector: row => row.measurement && row.measurement.name,
              sortable: true,
              center: true,
              compact: true,
              wrap: true, 
              grow: 2, 
            },
            // {
            //   name: 'Measurement Sort',
            //   selector: row => row.measurement && row.measurement.sort_idx,
            //   sortable: true,
            //   center: true,
            //   compact: true
            // },
            {
              name: 'Point Number',
              selector: row => row.point && row.point.num,
              cell: row => {

                return (<>
                  {!!row.point && <div class="mr-2">{row.point && row.point.num}</div>}
                    <Toggle
                      checked={!!row.point || !!checkedStatus.find(x => x === row.measurement.id)}
                      onChange={async (event) => {
                        const checked = event.target.checked;

                        if (checked){
                          setCheckedStatus([...checkedStatus, row.measurement.id])
                        }else{
                            // remove checkedStatus
                          const removedFromCheckStatus = checkedStatus.filter((value) => value !== row.measurement.id);
                          if (checkedStatus.length > removedFromCheckStatus.length) { //it was checked
                            setCheckedStatus(removedFromCheckStatus)
                            return;
                          }

                            if (confirm(`Warning! All inspection history on this equipment and components will be lost if this equipment is deleted. Please confirm this action by clicking OK.`)) {
                              console.log("disabling")
                              const result = await Equipment.disablePoints(row.point.uuid)
                              console.log({result})
                              await loadEquipmentForEdit();
                            }         
                        }
                      }}
                  />
                </>)
              },
              sortable: true,
              center: true,
              compact: true
            },
            {
              name: 'Created',
              selector: row => row.point && moment(row.point.created_at).unix(),
              cell: row => row.point ? <span>{moment.tz(row.point.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span> : '',
              sortable: true,
              center: true,
              compact: true
            },
          ].filter(x => x)}
          data={generateData()}
          noDataComponent = {"Please select a SYSTEM for the Equipment/Component. SYSTEMS will then define what INSPECTION POINTS can be selected."}
          responsive={true}
          dense
          persistTableHead
          noHeader
          highlightOnHover
          defaultSortAsc={false}
          disabled={loading}
              />

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '1em' }}>
                {checkedStatus.length > 0 && <Button style={{width: '15em'}} onClick={async () => {
                setLoading(true);
                const measurementsAndUUIDs = checkedStatus.map(measurement_id => {
                  return { uuid: Utils.uuid(), measurement_id }
                  // : row.measurement && row.measurement.id
                })
                if (measurementsAndUUIDs.length > 0) {
                  const result = await Equipment.enableNewPoints(
                    detailedEquipment.uuid,
                    detailedEquipment.parentFig ? detailedEquipment.parentFig.uuid : detailedEquipment.uuid,
                    measurementsAndUUIDs
                  )
                  setCheckedStatus([])
                  await loadEquipmentForEdit();
                }
                setLoading(false);
                }}>Submit point{checkedStatus.length > 1 ? 's' : ''}</Button>}
              </div>
        </div>


          
<ImageUploading
            multiple={false}
            onChange={onChange}
            maxNumber={1}
          >
          
      {({ dragProps, isDragging, onImageUpload }) => (
        
        <div {...dragProps} style={{width: '100%'}} >
          <EditModal onRescan={onRescan} onImageUpload={onImageUpload} options={editModal}/>
          {loading && <ImSpinner3 className="icon-spin" style={{fontSize: 30, marginTop: '1em', marginBottom: '1em', minWidth: '300px'}}/>}
          
          {!loading && <>
           

                <div style={{display:'flex', flexDirection:'column', alignContent:'center'}}>   
                <a style={{position:'relative', textAlign:'left', top:'1em', left: '8em'}}>
          Equipment Photo
          </a>

<div style={{position:"relative"}} className="container">
          {
            
          <img className="m-3" onClick={e => {uploadFlag=false; onImageUpload(e);}} style={{ maxWidth: 450, maxHeight: 400, height: 'auto', display:'block', marginLeft:'auto', marginRight:'auto'}} src={expandedRow.image ? `https://${expandedRow.image.bucket}.s3.amazonaws.com/${expandedRow.image.key}?cachebust=${(new Date().valueOf())}` : ((expandedRow.parent_key && detailedParentEquipment.image) ? `https://${detailedParentEquipment.image.bucket}.s3.amazonaws.com/${detailedParentEquipment.image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg')}></img>

          }
          {
          <div onClick={e => {uploadFlag=false; onImageUpload(e);}} style={{color: 'white', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '21em', top: '1.5em'}}>
                  <FaCamera style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }}/>

                  
          
                </div>
  }

</div>
          

<a style={{position:'relative', textAlign:'left', left: '8em'}}>
          Nameplate Photo
          </a>
      
<div className="container" style={{position:"relative"}}>
  {    
    <div onClick={e => {
      if(!expandedRow.faceplate_image){
        uploadFlag=true; 
        onImageUpload(e);
      }
    }}>     
     <ZoomImage smallSize = {false} src={expandedRow.faceplate_image ? `https://${expandedRow.faceplate_image.bucket}.s3.amazonaws.com/${expandedRow.faceplate_image.key}?cachebust=${(new Date().valueOf())}` : ((expandedRow.parent_key && detailedParentEquipment.faceplate_image) ? `https://${detailedParentEquipment.faceplate_image.bucket}.s3.amazonaws.com/${detailedParentEquipment.faceplate_image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg')}/>
      </div>

        }

        <div onClick={e => {
          if(!expandedRow.faceplate_image){
            uploadFlag=true; 
            onImageUpload(e);
          
          }else{
            setEditModal({
              title: "Nameplate Photo",
              value: expandedRow,
              type: "fp_text",
              faceplate: true,
              model_num: expandedRow.model_num,
              serial_num: expandedRow.serial_num,
              jsonFlag: false, 
              onUpload: () => {
                uploadFlag=true;
              },

              onHide: () => {
                setEditModal({})
              },

              onSubmit: async (e, row, modelNum, serialNum) => {
                e.preventDefault();
                if(row.parent_key === null){
                  //await Equipment.updatePhoto(expandedRow.uuid, imageList[0].dataURL, imageList[0].file, true, expandedRow);
                  expandedRow.model_num = modelNum;
                  expandedRow.serial_num = serialNum;
                  //await Equipment.update({parent_key: equipment.uuid, model_num: modelNum, serial_num:serialNum});
                  Equipment.update({uuid: expandedRow.uuid, model_num: modelNum, serial_num:serialNum})
    
                }
              }

          })
          }
         
        } } 
        
                style={{color: 'white', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '21em', top: '.3em'}}>
           
                  <FaEdit style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }}/>
                 
          
                </div>
  

  
</div>
        
         
        </div>
                
                

          </>}
        
        </div>
      )}
             
            
          </ImageUploading>              





        </div>
      </div>
      </>
    );
  }

  const columns = React.useMemo(() => 
    [
      // {
      //   name: 'Component',
      //   selector: row => row.parent_key === null,
      //   cell: row => row.parent_key === null ? <></> : <FcCheckmark style={{fontSize: 20 }}></FcCheckmark>,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   width:'7em'
      // },
      {
        name: `Name`,
        selector: row => row.name,
        cell: row => <a className={``} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Name",
              value: row,
              selector: row => row.name || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const returnedRow = await Equipment.update({uuid: row.uuid, name: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({uuid: row.uuid, name: returnedRow.name});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div className="pl-2">{row.name}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
        width: '14em'
      },
      {
        name: `Nameplate`,
        //selector: row => row.faceplate_image,
        cell: row =>
        <a className={``} onClick={async (e) => {
          window.open(`https://${row.faceplate_image.bucket}.s3-us-west-2.amazonaws.com/${row.faceplate_image.key}`);

          // setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
          // setEditModal({
          //   title: "Update Nameplate Photo",
          //   value: row,
          //   selector: row => row.name || "",
          //   type: "text",
          //   onHide: () => {
          //     setEditModal({})
          //     setLoadingRowUUIDs([]);
          //   },
          //   onSubmit: async (e, row, newValue) => {
          //     e.preventDefault();
          //     console.log(JSON.stringify({uuid: row.uuid, name: newValue}))
          //     const returnedRow = await Equipment.update({uuid: row.uuid, name: newValue, parent_key: row.parent_key});
          //     console.log("returnedRow", returnedRow)
          //     await updateSingleEquipment({uuid: row.uuid, name: returnedRow.name});
          //     setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
          //   }
          // });
          
        }}>
        {row.faceplate_image ? "Yes" : "No"}     
      </a>,
        sortable: true,
        center: true,
        compact: true,
        width: '8em'
      },
      {
        name: `Criticality`,
        selector: row => row.criticality,
        cell: row => <a className={`link`} onClick={async (e) => updateCriticality(row, e)}>{row.criticality}</a>,
        sortable: true,
        center: true,
        compact: true,
        width: '8em'
      },
      {
        name: `Make`,
        selector: row => row.make,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Make",
              value: row,
              selector: row => row.make || "",
              type: "text",
              fp: row.faceplate_image, 
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const returnedRow = await Equipment.update({uuid: row.uuid, make: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({...row, make: returnedRow.make});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.make || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Model`,
        selector: row => row.model_num,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Model Number",
              value: row,
              selector: row => row.model_num || "",
              type: "text",
              fp: row.faceplate_image, 
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const returnedRow = await Equipment.update({uuid: row.uuid, model_num: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({...row, model_num: returnedRow.model_num});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.model_num || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Serial`,
        selector: row => row.serial_num,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Serial Number",
              value: row,
              selector: row => row.serial_num || "",
              type: "text",
              fp: row.faceplate_image, 
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const returnedRow = await Equipment.update({uuid: row.uuid, serial_num: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({...row, serial_num: returnedRow.serial_num});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.serial_num || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Year`,
        selector: row => row.year || "",
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Year",
              value: row,
              selector: row => row.year || "",
              type: "number",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const returnedRow = await Equipment.update({uuid: row.uuid, year: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({...row, year: returnedRow.year});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.year || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
        // width: '5em'
      },
      // {
      //   name: `Points`,
      //   selector: row => (row.pointInfos || []).length,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   width: '5em'
      // },
      // {
      //   name: `Components`,
      //   selector: row => (fullPropertyHierarchy || []).filter(p => p.parent_key === row.key).length,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   width: '7em'
      // },
      {
        name: `System`,
        selector: row => row.system_id in systemIdLookup ? systemIdLookup[row.system_id].name : "",
        cell: row => {
          const name = row.system_id in systemIdLookup ? systemIdLookup[row.system_id].name : "";
          return <a className={`link`} onClick={() => {
            setEditEquipmentModal({
              onHide: () => {
                setEditEquipmentModal({})
                refreshPropertyInformation();
              },
              value: row.uuid,
              title: `Edit Equipment`
            })
          }}>
            {name || "__"}
          </a>
        },
        sortable: true,
        center: true,
        compact: true,
        width: '15em'
      },
      // {
      //   name: `System Type`,
      //   selector: row => row.system_id in systemIdLookup ? systemIdLookup[row.system_id].system_type_name : "",
      //   cell: row => {
      //     const name = row.system_id in systemIdLookup ? systemIdLookup[row.system_id].system_type_name : "";
      //     return <span className="text-center">{name}</span>
      //   },
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   width: '15em'
      // },
      // {
      //   name: 'Initiated By',
      //   selector: row => {
      //     const context = processContext(row.context)
      //     const email = context.emailInput && context.emailInput.to;
      //     return <a href={`mailto:${email}`}>{email}</a>
      //   },
      //   sortable: true,
      //   wrap: true,
      //   center: true,
      //   compact: true,
      //   width: '14em'
      // },
      // {
      //   name: 'Sent To',
      //   cell: row => {
      //     const context = processContext(row.context).emailInput || {};
      //     return [context.to, context.cc].filter(x => x!= null).map(email => 
      //       <a href={`mailto:${email}`}>{email}</a>)
      //   },
      //   sortable: true,
      //   wrap: true,
      //   center: true,
      //   compact: true,
      //   width: '14em'
      // },
      // {
      //   name: 'Created',
      //   selector: row => moment(row.created_at).unix(),
      //   cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   width: '7em'
      // },
      {
        name: 'Notes',
        selector: row => row.notes,
        cell: row => <a className={`link`} onClick={async (e) => {
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Note",
              value: row,
              selector: row => row.notes || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                console.log(JSON.stringify({uuid: row.uuid, notes: newValue}))
                const returnedRow = await Equipment.update({uuid: row.uuid, notes: newValue, parent_key: row.parent_key});
                await updateSingleEquipment({uuid: row.uuid, notes: returnedRow.notes});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.notes || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
      },
      // {
      //   name: 'Actions',
      //   selector: row => '',
      //   sortable: false,
      //   center: true,
      //   compact: true,
      //   cell: row => 
      //   <Button variant="link" onClick={() => {
      //     setEditEquipmentModal({
      //       onHide: () => {
      //         setEditEquipmentModal({})
      //         refreshPropertyInformation();
      //       },
      //       value:  row.uuid,
      //       title: row.parent_key === null ? "Edit Equipment" : "Edit Component"
      //   })}}>
      //     Edit
      //   </Button>
      // }
    ].filter(x => x!=null), [selectedPropertyUUIDs, JSON.stringify(fullPropertyHierarchy)]
  );
  
  // if (!selectedEquipment){
  //   return (<></>)
  // }

  return (
    <>
      <EquipmentEditModal options={editEquipmentModal}/>
      <div className="d-flex flex-row justify-content-start align-items-start">
          <EditModal options={editModal}/>
          <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
            style={{minWidth: '800px'}}
          >
            {showBackButton && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                    setSelectedRoutine(null)
                    setSelectedProperty(null)
                    history.push("/properties")
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Property</h4>
                </div>
            </div>}

            <div className="d-flex flex-column justify-content-start align-items-top flex-wrap ml-4"> 
              <DataTable
                title="MSi Inspections and Tasks"
                keyField="uuid"
                columns={columns}
                data={fullPropertyHierarchy}
                expandableRows
                expandableRowExpanded={row => showBackButton ? row.defaultExpanded=false : row.defaultExpanded=true}
                noDataComponent = {"Please select a SYSTEM for the Equipment/Component. SYSTEMS will then define what INSPECTION POINTS can be selected."}
                responsive={true}
                dense
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRowsComponent={(props) => ExpandedRowComponent(props)}
                selectableRows={false}
                disabled={loadingRowUUIDs.length > 0}
              />
            </div>
          </div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-end">
        <button type="button" className="btn" onClick={() => {
          const columnNames = [
            'Property ID',
            'Property Name',
            'Equipment Asset ID',
            ...columns.map(col => `'${col.name}'`)
          ];
          const dataRowsAsTextOnly = fullPropertyHierarchy.map(row => [
            `'${row.property_id}'`,
            `'${row.property_name}'`,
            `'${row.external_uuid}'`,
            ...columns.map(col => col.selector ? `'${col.selector(row)}'` : ``)
          ]);
          const csvCombined = [
            columnNames.join(','),
            ...(dataRowsAsTextOnly || []).map(row => row.join(','))
          ].join('\n');
            
          let element = document.createElement('a');
          element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
          element.setAttribute('download', `export.csv`);
          document.body.appendChild(element);
          element.click();
        }}>
        <FcDownload/>
      </button>
    </div>
  </>
  )
}

export default PropertyTable;