import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const DSTAppInBrowserScreen = () => {
  const history = useHistory();
  const emulatorIdleTimeoutInMinutes = 4; // Attempt to limit excess usage
  const idleTimeoutWarningInMinutes = 1;
  const emulatorMaxTimeInMinutes = 60; // Should match what is set on https://appetize.io/
  const emulatorMaxTimeWarningInMinutes = 5;
  let emulatorUsageSeconds = 0;
  let idleSeconds = 0;
  let idleWarningAlertHasHappened = false;
  let maxTimeAlertHasHappened = false;
  let idleTimerInterval = null;

  useEffect(() => {
    window.addEventListener('message', onIFrameMessage);
    return () => {
      clearInterval(idleTimerInterval);
      window.removeEventListener('message', onIFrameMessage);
    }
  }, []);

  const exitEmulator = () => {
    history.replace('/');
  }

  const resetIdleTimer = () => {
    idleSeconds = 0;
  }

  const onInteraction = () => {
    idleWarningAlertHasHappened = false;
    resetIdleTimer();
  }

  const checkForTimeouts = () => {
    if (emulatorUsageSeconds > emulatorMaxTimeInMinutes * 60){
      toast.info(`You've been working for over ${emulatorMaxTimeInMinutes} minutes, resetting the session...`);
      exitEmulator();
    }

    if (idleSeconds > emulatorIdleTimeoutInMinutes * 60){
      toast.info(`You've been idle for ${emulatorIdleTimeoutInMinutes} minutes, resetting the session...`);
      exitEmulator();
    }
  }

  const issueWarnings = () => {
    if (idleSeconds > ((emulatorIdleTimeoutInMinutes - idleTimeoutWarningInMinutes) * 60) && !idleWarningAlertHasHappened){
      toast.info(`Session will expire in ${idleTimeoutWarningInMinutes} minute. If you're still using the emulator, move the mouse.`);
      idleWarningAlertHasHappened = true;
    }

    if (emulatorUsageSeconds > ((emulatorMaxTimeInMinutes - emulatorMaxTimeWarningInMinutes) * 60) && !maxTimeAlertHasHappened){
      toast.info(`Session will expire in ${emulatorMaxTimeWarningInMinutes} minute. Please install the app on a phone you control if you wish to use the app for extended periods of time.`);
      maxTimeAlertHasHappened = true;
    }
  }

  const onIFrameMessage = (event) => {
    if (event.origin === "https://appetize.io"){
      if (event.data === "appLaunch"){
        resetIdleTimer();

        idleTimerInterval = setInterval(() => {
          idleSeconds++;
          emulatorUsageSeconds++;
          checkForTimeouts();
          issueWarnings();
        }, 1000)
      }else if (event.data === "userInteractionReceived"){
        onInteraction();
      }
    }
  };

  return (
    <div className="d-flex flex-column pt-5">
      <ToastContainer
        autoClose={10000}
        newestOnTop
        closeOnClick
        draggable
      />
      <div className="d-flex flex-row justify-content-around align-items-top flex-wrap">
        <div className="d-flex flex-column justify-content-center align-items-top flex-wrap">
          <iframe
            style={{
              height: 850,
              width: 420
            }}
            frameborder="0"
            scrolling="no"
            src="https://appetize.io/embed/xbrr8uyhwt771x94un1rch0ck8?device=pixel4&scale=100&orientation=portrait&osVersion=11.0&grantPermissions=true&disableVirtualKeyboard=true&xdocMsg=true"></iframe>
          <p style={{width: 380, textAlign: 'center'}}>
            App session will terminate after {emulatorIdleTimeoutInMinutes} minutes of inactivity or {emulatorMaxTimeInMinutes} minutes of continuous usage.
            <br/>
            To avoid these limitations, install the app from <a href="https://dstapp.com/">https://dstapp.com/</a>.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DSTAppInBrowserScreen;

