import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PropertyTableDuplicate from './components/PropertyTableDuplicate';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PointInspectionTable from './components/PointInspectionTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import EquipmentEditScreen from './equipment/EquipmentEdit';
import { useFilterContext } from "../context/SharedFilterContext";
import InspectionEquipmentHierarchy, {PropertyEditModal} from "./components/InspectionEquipmentHierarchy";

const PropertiesScreen = (props) => {
  let { path, url } = useRouteMatch();
  const {
    selectedProperty,
  } = useFilterContext();
  const [editPropertyModal, setEditPropertyModal] = useState({});


  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        <NavSidebar/>
        <PropertyEditModal options={editPropertyModal}/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={`${path}`}>
              {selectedProperty ?
                <Redirect to={`${path}/${selectedProperty.uuid}`} />
              :
                <PropertyActivityGrid
                  title={"Select a property to modify"}
                  noResultsMessage="No properties found."
                  hideAnalysisColors={true}
                  hideValCheck={true}
                  submitPath={`/properties`}
                  isInspectionPage={false}
                  hideEndDate={true}
                  hideStartDate={true}
                  hideStructureInfo={false}
                  hideInspectionInfo={true}
                  hideReportInfo={false}
                  additionalButtons={[
                    <a className={`btn mr-2 ml-2 btn-outline-secondary`} style={{"height": "2.5em"}} onClick={() => {
                      setEditPropertyModal({
                        onHide: () => setEditPropertyModal({}),
                        value:  null,
                        title: "Add Property"
                      });
                    }}>Add Property</a>
                  ]}
                />
            }
            </PrivateRoute>
            <PrivateRoute path={`${path}/table/:propertyUUID`}>
              <PropertyTable />
            </PrivateRoute>
            <PrivateRoute path={`${path}/:propertyUUID`}>
              <InspectionEquipmentHierarchy
                backPath={`/properties`}
                hideSheetsSection={false}
                sortByInspections={false}
                sortByName={true}
                hideAnalysisColors={true}
                hideExpandedPointAnalysisColors={true}
                hideDateSelector={true}
                hideStartDate={true}
                hideEndDate={true}
                hideLayoutButton={false}
                hideValCheck={false}
                hideValCheckNumerator={false}
                hideValInspectionCountNumerator={true}
                hideInspectionCounts={true}
                EquipmentEditComponent={props => {
                  const {selectedEquipment, selectedProperty} = props;
                  return <>
                    <PropertyTable {...props} />

                  </>
                }}
                EquipmentDuplicateComponent={props => {
                  const {selectedEquipment, selectedProperty} = props;
                  return <>
                    <PropertyTableDuplicate {...props} />

                  </>
                }}
                EquipmentAddComponent={props => {
                  const {selectedEquipment, selectedProperty, parentEquipment, uuid, goBackFn, refreshKey, duplicate} = props;
                  return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left'}}>
                    <div>
                    <EquipmentEditScreen refreshKey={refreshKey} goBackFn={goBackFn} uuid={uuid} parentEquipment={parentEquipment} selectedEquipment={selectedEquipment} property={selectedProperty} duplicate={duplicate}></EquipmentEditScreen>
                    </div>
                  </div>
                }}
                showPropertyEdit={true}
                showPropertyAdd={true}
                showEquipmentEdit={true}
                showEquipmentAdd={true}
                allowHidingComponentSection={false}
                allowHidingPointsSection={false}

              />
            </PrivateRoute>
          </Switch>
        </div>
    </div>
  )
}

export default PropertiesScreen;