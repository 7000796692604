import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';

const PropertyTableCard = ({ setShowPropertyTable, equipment }) => {
  // Function to render the table of sheet names
  const renderSheetNames = () => {
    if (!equipment.sheets || equipment.sheets.length === 0) {
      return null; // Do not render this section if no sheets are available
    }

    // Sort the sheets array alphabetically by name
    const sortedSheets = equipment.sheets.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    return (
      <div style={{ fontSize: '0.8rem', overflowY: 'auto', maxHeight: '400px', marginTop: '0px' }}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Tasking Sheets</th>
            </tr>
          </thead>
          <tbody>
            {sortedSheets.map((sheet, index) => (
              <tr key={index}>
                <td>{sheet.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Function to render the table of measurement names
  const renderMeasurementNames = () => {
    if (!equipment.pointInfos || equipment.pointInfos.length === 0) {
      return null; // Do not render this section if no enabled points are available
    }

    // Sort the pointInfos array alphabetically by measurement_name
    const sortedPointInfos = equipment.pointInfos.sort((a, b) => {
      if (a.measurement_name < b.measurement_name) return -1;
      if (a.measurement_name > b.measurement_name) return 1;
      return 0;
    });

    return (
      <div style={{ fontSize: '0.8rem', overflowY: 'auto', maxHeight: '400px', marginTop: '0px' }}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Enabled Points</th>
            </tr>
          </thead>
          <tbody>
            {sortedPointInfos.map((pointInfo, index) => (
              <tr key={index}>
                <td>{pointInfo.measurement_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Card className="user-panel-card" onClick={() => setShowPropertyTable(true)} style={{ cursor: 'pointer' }}>
      <Card.Body style={{ padding: '20px', display: 'flex', flexDirection: 'column',  backgroundColor: '#fff', boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)' }}>
        <h5 style={{ width: '100%',  marginBottom: '20px', color: '#343a40' }}>{equipment.name} - Details</h5>
        <div>
        <div style={{ fontSize: '1em', width: '100%', marginBottom: '20px', paddingBottom: '10px', borderBottom: '2px solid #e9ecef' }}>
          <div><strong>Make:</strong> {equipment.make || "N/A"}</div>
          <div><strong>Model:</strong> {equipment.model_num && equipment.model_num.length > 15 ? equipment.model_num.substring(0, 15) + "..." : equipment.model_num || "N/A"}</div>
          <div><strong>System:</strong> {equipment?.system?.name || "N/A"}</div>
          <div><strong>Serial:</strong> {equipment.serial_num || "N/A"}</div>
          <div><strong>Year:</strong> {equipment.year || "N/A"}</div>
        </div>
        <div>
        {renderSheetNames()}
        {renderMeasurementNames()}
        </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PropertyTableCard;
