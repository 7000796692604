import React, { useEffect, useState, useMemo } from 'react';
import { Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import { Card, Button, Modal, Popover, ProgressBar } from 'react-bootstrap';
import {EvaluationMark} from './EvaluationMark';
import { EvaluationBar } from './EvaluationBar';
import Evaluation from '../../models/Evaluation';

import MultipointEvaluation from '../../models/MultipointEvaluation';
import { FaExclamationTriangle, FaMapMarkerAlt, FaEdit, FaAsterisk, FaCircle, FaRegCircle } from 'react-icons/fa';
import { FcBusinesswoman } from 'react-icons/fc';
import { MdPlaylistAddCheck, MdEdit, MdCheck, MdClear } from 'react-icons/md';

import moment from 'moment';
import {useFilterContext} from '../../context/SharedFilterContext';
import { GOOGLEMAP_API_KEY } from '../../constants/Constants';
import {processContext} from './ReportTable';
import ImageUploading from 'react-images-uploading';
import Property from '../../models/Property';
import User from '../../models/User';
import Check from '../../models/Check';
import Utils from "../../common/Utils";
import { ImSpinner3 } from 'react-icons/im';
import { useAuth } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import Val from '../../models/Val';
import pLimit from 'p-limit';


const PropertyCard = (props) => {
  const { 
    index, 
    row, 
    hideAnalysisColors, 
    hideStructureInfo, 
    hideInspectionInfo,
    setSelectedPropertyResult, 
    setMapCoordinates, 
    hideMapLocation,
    hideReportInfo,
    mapLat, 
    mapLng, 
    hasExternalUuid, 
    sortByReports
  } = props;

  const { user, isSuperAdmin } = useAuth();

  const { startDate, endDate} = useFilterContext();
  const [property, setProperty] = useState({});
  const [pointInfos, setPointInfos] = useState([]);
  const [evaluationsCount, setEvaluationsCount] = useState(0);
  const [latestUserId, setLatestUserId] = useState(null);
  const [latestUserName, setLatestUserName] = useState('');
  const [mostRecentDate, setMostRecentDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingChecks, setLoadingChecks] = useState(false);
  const [cacheBust, setCacheBust] = useState(null);
  const [showMPeButton, setShowMPeButton] = useState(false); 
  const [showMPeAlreadyRunButton, setShowMPeAlreadyRunButton] = useState(false); // State to show/hide non-clickable MPe button
  const [mostRecentEvaluationDate, setMostRecentEvaluationDate] = useState(null);

  const [progress, setProgress] = useState(0); // State to track progress
  const [totalSteps, setTotalSteps] = useState(0); // State to track total steps
  const [showProgress, setShowProgress] = useState(false); // State to show/hide progress bar


const startMoment = useMemo(() => moment(startDate), [startDate]);
  const endMoment = useMemo(() => moment(endDate), [endDate]);

const filteredEdges = useMemo(() => {
  return (property.vals?.edges || []).filter(edge => {
    const recordedAtMoment = moment(edge.node.recorded_at).startOf('day');
    return recordedAtMoment.isBetween(startMoment, endMoment.add(1, 'day'), null, '[]');
  });
}, [property.vals?.edges, startMoment, endMoment]);

const uniquePointNumSet = useMemo(() => {
  return new Set(filteredEdges.map(edge => edge.node.point_num));
}, [filteredEdges, property.vals?.edges, startMoment, endMoment]);

  const uniquePointNumCount = Object.keys(_.groupBy(filteredEdges, x => x.node.point_num)).length;

  useEffect(() => {
    const uniquePointNums = [...new Set(property?.pointInfos?.edges?.map(pi => pi.node.num))];
    setPointInfos(uniquePointNums);
  }, [property, uniquePointNumSet]);

  const extractPointName = row => row.point_info ? row.point_info.measurement_name : "";

  const calculatePerpetualScore = (points) => {
    const multipliers = { E1: 4, E2: 3, E3: 2, E4: 1, E5: 0 };
    let numerator = 0;
    let denominator = points.length * 4;
    points.forEach(point => {
      if (point.evalNum !== null) {
        let category;
        if (point.evalNum >= 1 && point.evalNum <= 5) category = 'E1';
        else if (point.evalNum >= 6 && point.evalNum <= 10) category = 'E2';
        else if (point.evalNum >= 11 && point.evalNum <= 15) category = 'E3';
        else if (point.evalNum >= 16 && point.evalNum <= 20) category = 'E4';
        else if (point.evalNum >= 21 && point.evalNum <= 25) category = 'E5';
        if (category) {
          numerator += multipliers[category];
        }
      }
    });
    return (numerator / denominator) * 100;
  };

  // ----------------
  const filterVals = (vals) => {
    return (vals || []).filter(val => {
      const createdAtDate = moment(val?.recorded_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      return moment(createdAtDate).isBetween(moment(startDate), moment(endDate), undefined, '[]');
    });
  }

  let allVals = filterVals(property?.vals?.edges?.map(x => x.node)) || []
  const allPointInfos = property?.pointInfos?.edges?.map(x => x.node) || [];
 
  const allMatchedPointInfos = allPointInfos.map(pointInfo => {
  const matchingVal = allVals.find(val => val.point_num === pointInfo.num);
  if (matchingVal) {
    return {
      ...pointInfo,
      matchedValUuid: matchingVal.uuid,
    };
  }
  return pointInfo;
}).filter(pointInfo => pointInfo.matchedValUuid);
  const groupedPointInfos = _.groupBy(allMatchedPointInfos, 'fig_id');
  const figValUuids = Object.keys(groupedPointInfos).map(fig_id => ({
    fig: groupedPointInfos[fig_id][0].fig,
    valUuids: groupedPointInfos[fig_id].map(info => info.matchedValUuid)
  }));


  const generateHash = async (inspections) => {
    const selectAndSortFields = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map(selectAndSortFields);
      } else if (obj !== null && typeof obj === 'object') {
        const relevantFields = ['uuid', 'updated_at']; // List relevant fields
        const sortedObj = relevantFields.reduce((sorted, key) => {
          if (obj[key] !== undefined) {
            sorted[key] = selectAndSortFields(obj[key]);
          }
          return sorted;
        }, {});
        return sortedObj;
      }
      return obj;
    };
  
    const filteredInspections = inspections.map(selectAndSortFields);
  
    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(filteredInspections));
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex; 
  };
  
  


  const red = useMemo(() => {
    const value = allMatchedPointInfos?.filter(pi => pi?.evalNum && pi?.evalNum > 20)?.length;
    return value;
  }, [allMatchedPointInfos, property.name]);

  const amber = useMemo(() => {
    const value = allMatchedPointInfos?.filter(pi => pi?.evalNum && pi?.evalNum > 15 && pi?.evalNum <= 20)?.length;
    return value;
  }, [allMatchedPointInfos, property.name]);

  const yellow = useMemo(() => {
    const value = allMatchedPointInfos?.filter(pi => pi?.evalNum && pi?.evalNum > 10 && pi?.evalNum <= 15)?.length;
    return value;
  }, [allMatchedPointInfos, property.name]);

  const lime = useMemo(() => {
    const value = allMatchedPointInfos?.filter(pi => pi?.evalNum && pi?.evalNum > 5 && pi?.evalNum <= 10)?.length;
    return value;
  }, [allMatchedPointInfos, property.name]);

  const green = useMemo(() => {
    const value = allMatchedPointInfos?.filter(pi => pi?.evalNum && pi?.evalNum >= 0 && pi?.evalNum <= 5)?.length;
    return value;
  }, [allMatchedPointInfos, property.name]);

  const total = allPointInfos.length;

  const gray = useMemo(() => {
    const value = total - red - amber - yellow - lime - green;
    return value;
  }, [total, red, amber, yellow, lime, green, property.name]);

  const dstHealthScore = useMemo(() => calculatePerpetualScore(allMatchedPointInfos), [allMatchedPointInfos]);


  useEffect(() => {
    setProperty(row);
    const fetchPropertyFigs = async () => {
      const property_figs = await Property.getPropertyFigsByUUID(row.uuid);
      if (property_figs && Array.isArray(property_figs.figs) && property_figs.figs.length > 0) {
        const figs = property_figs.figs;

        // Calculate and set the evaluations count
        const evaluationsCount = figs.reduce((acc, fig) => {
          const validEvaluations = fig?.evaluations?.filter(evaluation =>
            moment(evaluation?.created_at).startOf('day').isBetween(moment(startDate).startOf('day'), moment(endDate).startOf('day'), null, '[]')
          ) || [];

          // Find the most recent evaluation date
          validEvaluations.forEach(evaluation => {
            const evaluationDate = moment(evaluation.created_at);
            if (!mostRecentEvaluationDate || evaluationDate.isAfter(mostRecentEvaluationDate)) {
              setMostRecentEvaluationDate(evaluationDate);
            }
          });
  
          return acc + validEvaluations.length;
        }, 0);
  
        setEvaluationsCount(evaluationsCount);

      } else {
        setEvaluationsCount(0);

      }
    };
  
    fetchPropertyFigs();
  }, [property, row, startDate, endDate]);


useEffect(() => {
  const recordedAt = row.vals?.edges[0]?.node?.recorded_at;
  const createdAt = row.checks?.edges[0]?.node?.created_at;
  const datesWithValues = [
    { date: recordedAt, userId: row.lastValUploaded?.wav?.user?.id?.toString() },
    { date: createdAt, userId: row.checks?.edges[0]?.node?.user_id?.toString() }
  ].filter(d => d.date);
  if (datesWithValues.length) {
      const mostRecent = datesWithValues.reduce((a, b) => moment(a.date).isAfter(moment(b.date)) ? a : b);
      setMostRecentDate(moment(mostRecent.date).local().format("M-D-YY"));
      setLatestUserId(mostRecent.userId); 
  } else {
      setMostRecentDate('N/A');
  }
}, [property, row, startDate, endDate]);


useEffect(() => {
  const fetchUserName = async (userId) => {
    if (userId) {
      const user = await User.findById(userId);
      if (user && user.full_name) {
        setLatestUserName(user.full_name);
      }
    }
  };
  fetchUserName(property?.lastValUploaded?.wav?.user_id); // Fetch and set the user's full name


}, [property]);



const getInspectionsForEquipment = async (equipmentToFetch) => {
  if (Utils.isBlank(property.uuid)) {
    return [];
  }

  let selectedPointNumbers = null;

  const newUploads = await Val.filterBy([
    { field: 'archived', value: 0, comparison: "=" },
    { field: "properties.uuid", value: property.uuid, comparison: "=" },
    { field: 'fig_id', value: equipmentToFetch?.id, comparison: "=" },
    selectedPointNumbers ? { field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "=" } : null,
  ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true);

  let uploadList = newUploads.data || [];

  uploadList = uploadList.map(upload => {
    return {
      ...upload,
      sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
      parent_fig_key: upload?.point_info?.parent_fig_key,
      childSort: upload?.point_info?.parent_fig_key === null ? upload?.point_info?.parent_fig_key - 1 : upload?.point_info?.parent_fig_key
    };
  });

  uploadList = _.orderBy(uploadList, [
    upload => moment(moment(upload?.recorded_at).format("YYYY-MM-DD")).unix(),
    upload => upload?.point_info?.parent_fig_key === null ? upload?.point_info?.fig_key + 1 : upload?.point_info?.parent_fig_key,
  ], ['desc', 'desc', 'desc']);

  if (equipmentToFetch !== undefined) {
    uploadList = _.flatten(
      Object.values(
        _.groupBy(uploadList, row => extractPointName(row)
      )
    )
    .map(x => _.first(x)))
    .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx);
  }

  return uploadList;
};


  return (
    <Card key={index}
      style={{ maxWidth: '18em', height: 'auto'}}
      className={`align-items-stretch m-2`}
      
    >
      <Card.Body className="p-2">
        <div style={{overflow:'auto'}} className={`d-flex flex-column justify-content-start align-items-center`}>

        
        <div className={`d-flex flex-column justify-content-center align-items-start`}>
          
          <ImageUploading
            multiple={false}
            onChange={async (imageList, addUpdateIndex) => {
              if (imageList.length > 0) {
                setLoading(true);
                await Property.updatePhoto(property.uuid, imageList[0].dataURL, imageList[0].file);
                setCacheBust(new Date().valueOf()); // Update cache bust only after image update
                property.image = {
                  bucket: "dst-photos",
                  key: `${property.uuid}`,
                  region: 'us-west-2',
                }
                setLoading(false);
              }
            }}
            maxNumber={1}
          >
            
           {({ dragProps, isDragging, onImageUpload }) => (
        <div {...dragProps} style={{ width: '100%', position: 'relative', height: '300px', overflow: 'hidden' }}>
            {loading && <ImSpinner3 className="icon-spin" style={{ fontSize: 30, marginTop: '1em', marginBottom: '1em' }} />}
            {!loading && <>
                {property.image
                    ? <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <img 
                            loading="lazy"
                            onClick={(e) => setSelectedPropertyResult(property)} 
                            className="image-thumbnail rounded-lg"
                            style={{ 
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain'
                            }} 
                            src={`https://s3-${property.image.region}.amazonaws.com/${property.image.bucket}/${property.image.key}${cacheBust ? `?cachebust=${cacheBust}` : ''}`}
                        />
                        <div 
                            onClick={e => onImageUpload(e)} 
                            style={{ 
                                position: 'absolute', 
                                top: '0', 
                                left: '0', 
                                color: 'white', 
                                padding: '0.5em', 
                                cursor: 'pointer'
                            }}>
                            <FaEdit style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }} />
                        </div>
                      </div>
                    : <img 
                        onClick={onImageUpload} 
                        src="/icons/image-placeholder3.svg" 
                        className="image-thumbnail rounded-lg"
                        style={{ 
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }}
                    />
                }
            </>}
        </div>
    )}
          
          
          </ImageUploading>
          </div>
   
          <div className={`d-flex flex-column justify-content-center align-items-center`} onClick={(e) => setSelectedPropertyResult(property)}>
            <h5 className="card-title pt-2 d-flex mb-2">
              <span className="d-inline-block mr-2">
                {hideAnalysisColors ? <></> : property.warningMode 
                  ? <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "25px"}}/>
                  : <></> // <EvaluationMark criticality={property.evalNum}  />
                }

                {!hideMapLocation && 
                    property.lat !== null && property.lng !== null &&
                      <FaMapMarkerAlt style={{ fontSize: '20px', color: property.lat === mapLat && property.lng === mapLng ? 'blue' : null }} onClick={(e) => {
                        e.stopPropagation();
                        if (property.lat !== null && property.lng != null) {
                          console.log("hovering", property.lat, property.lng)
                          setMapCoordinates(property.lat, property.lng);
                        } else {
                          console.log("no lat/lng", property.lat, property.lng)
                        }
                      }} />
                }
              </span>
              {property.name}
            </h5>

            <div className={`d-flex flex-column justify-content-between`}>
            {!hideInspectionInfo && <>
              <span className="d-flex flex-row justify-content-between">
             
              <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">
                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Last Inspection:&nbsp;</span>
                  <span className="small">
                    {mostRecentDate}
                  </span>
                </div>


              </span>
              <span className="d-flex flex-row justify-content-between">
              <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">
                <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '200px' }}>MSi Inspections:&nbsp;</span>
                <span className="small">
                  {/* {property.vals && Object.keys(_.groupBy(property.vals.edges, x => x.node.point_num)).length || 0} */}
                  {uniquePointNumCount || 0}
                  {property.pointInfos && ` / ${property.pointInfos.totalCount}`}
                </span>
                </div>
              </span>
              {hideAnalysisColors ? <></> 
                  : <div className="d-flex flex-row">
                    {pointInfos && <>
                      <EvaluationBar
                        criticalityEquivalentColor={25}
                        count={red}
                        total={total} />
                      <EvaluationBar
                        criticalityEquivalentColor={20}
                        count={amber}
                        total={total} />
                      <EvaluationBar
                        criticalityEquivalentColor={15}
                        count={yellow}
                        total={total} />
                      <EvaluationBar
                        criticalityEquivalentColor={10}
                        count={lime}
                        total={total} />
                      <EvaluationBar
                        criticalityEquivalentColor={5}
                        count={green}
                        total={total} />
                      <EvaluationBar
                        criticalityEquivalentColor={null}
                        count={gray}
                        total={total} />
                    </>
                    }
                </div>
                }
              <span className="d-flex flex-row justify-content-between">
              <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Last Task Upload:&nbsp;</span>
                <span className="small">{property.checks && property.checks.edges.length > 0 && moment(property.checks.edges[0].node.created_at).local().format("M-D-YY") || "N/A"}</span>
              </div>
              </span>
              <span className="d-flex flex-row justify-content-between">
              {!loadingChecks && <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '200px' }}>Tasks Completed:&nbsp;</span>
                <span className="small">
                  {property.checks?.totalCount && property.checks?.totalCount !== 0 ? <MdCheck /> : <MdClear />}
                </span>

                </div>}
                {loadingChecks && <ImSpinner3 className="icon-spin" style={{ fontSize: 20}} />}
              </span>
              </>}




              { !sortByReports && <span className="d-flex flex-row justify-content-between">
              <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

              <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Last Evaluation Update:&nbsp;</span>
              <span className="small">
              
                     {
                        mostRecentEvaluationDate
                          ? mostRecentEvaluationDate.local().format("M-D-YY")
                          : "N/A"
                      }
              </span>
            </div>
              </span> }




             {!sortByReports && <span className="d-flex flex-row justify-content-between">
              <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Evaluations:&nbsp;</span>
                {/* <span className="small">{evaluationsCount === 0 ? "No" : "Yes"}</span> */}
                <span className="small">{evaluationsCount}</span>

              </div>
              </span>
              }
            
              

              {!hideInspectionInfo && property.lastValUploaded && 
                <span className="d-flex flex-row justify-content-between">
                                  <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  {/* <FcBusinesswoman style={{ fontSize: '30px'}} className="mr-2" /> */}
                  <span className="small"  style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Last Uploader:&nbsp;</span>
                  <span className="small">
                    
                      {/* {property.lastValUploaded && property.lastValUploaded.wav && property.lastValUploaded.wav.user_id} */}
                      {latestUserName}
                  </span>
                  </div>
                </span>
              }

              {!hideReportInfo && property.lastReportRun && <>
              <span >  
                <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>Last Report Generated:&nbsp;</span>
                  <span className="small">
                    {property.lastReportRun && moment(property.lastReportRun.created_at).local().format("M-D-YY")}
                  </span>
                  </div>
                </span>

                <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '90px' }}>Report Type:&nbsp;</span>
                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '150px' }}>
                    {property.lastReportRun 
                      && processContext(property.lastReportRun.context).itemReportInput
                      && processContext(property.lastReportRun.context).itemReportInput.reportTypeName}
                  </span>
                  </div>
                </span>

                <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '90px' }}>Created By:&nbsp;</span>
                  <span className="small"  style={{
                  wordWrap: 'break-word',
                  maxWidth: '160px',
                  display: 'inline-block',
                  }}>
  {property.lastReportRun && processContext(property.lastReportRun.context).emailInput?.to}
</span>
                  </div>
                </span>

                <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small">Report Range:&nbsp;</span>
                  <span className="small">
                    {property.lastReportRun && `
                    ${moment(processContext(property.lastReportRun.context).itemReportInput?.startTimestamp).local().format("M-D-YY")}
                    to 
                    ${moment(processContext(property.lastReportRun.context).itemReportInput?.endTimestamp).local().format("M-D-YY")}`}
                  </span>
                  </div>
                </span>
                </span>
                </>   
                }

            {!sortByReports && !hideInspectionInfo && <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small" style={{ overflowWrap: 'break-word', maxWidth: '190px' }}>DST Health Score:&nbsp;</span>
                  <span className="small"  style={{
                  wordWrap: 'break-word',
                  maxWidth: '160px',
                  display: 'inline-block',
                  }}>
   {!isNaN(dstHealthScore) && `${dstHealthScore.toFixed(2)}%`}
</span>
                  </div>
                </span>}
                

                {property.sheets && property.sheets.length > 0 && <>
                <span className="d-flex flex-row justify-content-between">
                <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', padding: '0 15px' }} className="d-flex flex-row justify-content-between">

                  <span className="small">Tasking Sheet Count:&nbsp;</span>
                  <span className="small">
                    {property.sheets.length}
                  </span>
                  </div>
                </span>
                
                </>}

            </div>
            
          </div>

        </div>
      </Card.Body>
      {
                  hasExternalUuid ? (
                    property.external_uuid ? (
                      <>
                         <FaCircle style={{ marginRight: '5px' }} />
                      </>
                    ) : (
                      <>
                         <FaRegCircle style={{ marginRight: '5px' }} />
                      </>
                    )
                  ) : (
                    <>
                      
                    </>
                  )
                }
  {showMPeButton && (
  <>
    <span 
      style={{ 
        cursor: 'pointer', 
        fontSize: '18px', 
        marginRight: '30px', 
        fontWeight: 'bold',
        // color: isValidMultipoint ? 'green' : 'inherit' // Change color if isValidMultipoint is true
      }} 
      onClick={handlePropertyMPeClick}
    >
      MP<sup>e</sup>
    </span>
  
  </>
)}

{/* Progress Modal */}

   {showProgress && (
        <div style={{ width: '100%', padding: '0.5em', backgroundColor: '#f0f0f0', borderRadius: '5px', marginTop: '0.5em' }}>
          <ProgressBar 
            now={(progress / totalSteps) * 100} 
            label={`${Math.round((progress / totalSteps) * 100)}%`} 
            style={{ height: '1rem', fontSize: '0.75rem' }}
          />
        </div>
      )}


    </Card>
  )
}

export default PropertyCard;