import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';

const EvaluationTableCard = (props) => {
const {setShowEvaluationTable, equipment} = props; 



return (
    <Card className="user-panel-card" onClick={() => setShowEvaluationTable(true)}>
      <Card.Img className="user-panel-image" variant="top" src="/icons/test_7.svg"/>
      <Card.Body>
        <Card.Title className="user-panel-title">Evaluations</Card.Title>
        {equipment.evaluations.length !== 0 && (
          <Card.Text className="user-panel-description">
            Evaluations 
            <span className="badge badge-dark float-right">
              {equipment.evaluations.length}
            </span>
          </Card.Text>
        )}
        {equipment.evaluations.length === 0 && (
          <Card.Text className="user-panel-description">
            Evaluation Table
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default EvaluationTableCard;
