import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Property from '../../models/Property';
import {
  BrowserRouter as Router,
  useParams,
  Route,
  useHistory,
  Link,
  useLocation
} from "react-router-dom";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Utils from '../../common/Utils';
import moment from 'moment-timezone';
import { OverlayTrigger } from 'react-bootstrap';
import { ImSpinner3 } from 'react-icons/im';
import Equipment from '../../models/Equipment';
import _ from 'lodash';
import EquipmentCard from './EquipmentCard';
import { useFilterContext } from "../../context/SharedFilterContext";

const alphaNumNameCompare = (a,b) => {
  return alphaNumCompare(a.name,b.name);
}

const alphaNumCompare = (a, b) => {
  if (Utils.isBlank(a)){
      return 1;
  }
  if (Utils.isBlank(b)){
      return -1;
  }
  const chunkify = (t) => {
      let tz = [], x = 0, y = -1, n = 0, i, j;
      while (i = (j = t.charAt(x++)).charCodeAt(0)){
          const m = (i == 46 || (i >=48 && i <= 57));
          if (m !== n) {
              tz[++y] = "";
              n = m;
          }
          tz[y] += j;
      }
      return tz;
  }
  const aa = chunkify(`${a}`.trim());
  const bb = chunkify(`${b}`.trim());
  for (let x = 0; aa[x] && bb[x]; x++){
      if (aa[x] !== bb[x]){
          const c = Number(aa[x]), d = Number(bb[x]);
          if (c == aa[x] && d == bb[x]){
              return c - d;
          } else {
              return (aa[x] > bb[x]) ? 1 : -1;
          }
      }
  }
  return aa.length - bb.length;
}

export const alphaNumberSort = (a) => {
  const numberMatch = (a || "").match(/(\d+)/g);
  const num = numberMatch && numberMatch.length > 0 ? Number(numberMatch[0]) : -1;
  return num;
}

export const lastValUploadedOnComponent = (allEquipment, fig) => {
  return _.first(
      (allEquipment || [])
        .filter(c => c.parent_key === fig.key) // Subcomponents
        .map(c => c.lastValUploaded && moment(c.lastValUploaded.recorded_at).unix() || -1) // Subcomponent last val uploaded
        .sort((a,b) => b - a) // descending
    ) || -1
};

export const lastValUploadedAsUnix = (allEquipment, fig) => {
  if(fig.lastValUploaded && fig.lastValUploaded.recorded_at){
    return moment(fig.lastValUploaded.recorded_at).unix()
  }else{
    return lastValUploadedOnComponent(allEquipment, fig) || -1
  }
}


const EquipmentGrid = (props) => {
  const {
    startDate, 
    endDate,
    property, 
    sortByInspections, 
    sortByName,
    sortByAnalysis,
    hideExpandedPointAnalysisColors,
    hideAnalysisColors, 
    filterByParent, 
    rootEquipmentOnly, 
    equipmentSelectFunction, 
    subcomponentSelectFunction, 
    pointSelectFunction, 
    routine,
    hideInspectionCounts,
    hideSheetsSection,
    sheetSelectFunction,
    allowHidingComponentSection,
    allowHidingPointsSection,
    hideValCheckNumerator,
    sortByLastInspection,
    hideValInspectionCountNumerator,
    lastModifiedEquipment,
    lastSelectedEquipment,
    refresh, 
    setShowComponents, 
    isInspectionPage, 
    setShowPointTable, 
    showMode, 
    isComponent, 
    setEditEvaluationModal,
    setShowEvaluationTable,
    setRefreshCounter
  } = props;

  const {
    propertyEquipmentList,
    setPropertyEquipmentList,
  } = useFilterContext();


  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const pageSize = 50;
  const [pageInfo, setPageInfo] = useState({});
  const [properties, setProperties] = useState([]);
  const [equipment, setEquipment] = useState([])
  const [allEquipment, setAllEquipment] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {
    searchEquipment()
  }, [propertyEquipmentList.length, JSON.stringify(property), JSON.stringify(lastModifiedEquipment), JSON.stringify(lastSelectedEquipment), (filterByParent || {}).uuid, startDate, endDate, showMode, refresh]);

  const searchEquipment = async () => {
  
      setLoading(true);
      
      if (!!property){
        let equipmentResults = await Equipment.filterBy([
          {"field": "property_id", "value": `${property.id}`, "comparison": "="},

          {"field": "archived", "value": 0, "comparison": "="},

          filterByParent ? {"field": "parent_key", "value": `${filterByParent.key}`, "comparison": "="} : null,
        ].filter(x => x!= null), [
          {"field": "recorded_at", "value": `${startDate || '2000-01-01'}`, "comparison": ">"},
          {"field": "recorded_at", "value": `${moment(endDate).add(1, 'days').format('YYYY-MM-DD')}`, "comparison": "<"},
        ], "name", "asc", 100000, undefined, undefined, undefined, true);


        let equipmentList = equipmentResults && (equipmentResults.data || [])

        // default sort by name
        equipmentList = _.sortBy(_.sortBy(equipmentList, o => !isNaN(parseInt(o.name)), 'name'))

        if (sortByInspections){
          if(rootEquipmentOnly){
            if(showMode === 'Showing All'){
              // If show all is selected, this is the sorting logic used 
        //   equipmentList = _.orderBy(equipmentList, [
        //     // add additional filter so that the count is only counting components within the date range
        //     fig => (equipmentList || [])
        // .filter(x => x.parent_key === fig.key)
        // .filter(c => c.lastValUploaded === null || moment(c.lastValUploaded.recorded_at).isBetween(moment(startDate), moment(endDate)))
        // .reduce((acc, component) => {
        //   return acc + (component.vals && Object.keys(_.groupBy(component.vals.edges, val => val.node && val.node.point_num)).length);
        // }, 0),
        //     fig => fig.vals && Object.keys(_.groupBy(fig.vals.edges, val => val.node && val.node.point_num)).length,
        //     // fig => fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length,
            
        //     // Add Check for evaluation as well as it's part of 'Inspections'
        //     fig => fig.evaluations ? fig.evaluations.length : 0,
        //     //fig => fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length,
        //     fig => {
        //       if (startDate && endDate){
        //           const oneDayAfterEndDate = moment(endDate).add(1, 'day');
                  
        //           return fig.checks && Object.keys(_.groupBy(
        //               fig.checks.edges.filter(check => 
        //                   moment.tz(check.node.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate)
        //               ), check => check.node && check.node.task_uuid
        //           )).length;
        //       } else {
        //           return fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length;
        //       }
        //   },
        //     fig => fig.name  // Final sorting criterion (alphanumerical by name)

        //   ], ['desc', 'desc', 'desc', 'desc', 'desc', 'desc']);
        equipmentList.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
          setAllEquipment(equipmentList);

          } 
          
            // if show Inspected is used, we filter out all of the equipment that don't have either Msi Inspections, Tasking Sheets, or Evaluations
          // Then the remaining equipmentList is sorted alphaNumerically 
          else {
            // Filter equipmentList based on the specified criteria
            equipmentList = equipmentList.filter(fig => {
              // 1. Calculate unique point_num sum for components
              const componentPointSum = (equipmentList || [])
              .filter(x => x.parent_key === fig.key) // Filtering to get the subcomponents
              .filter(c => c.lastValUploaded === null || moment(c.lastValUploaded.recorded_at).isBetween(moment(startDate), moment(endDate))) // Additional filter based on lastValUploaded
              .reduce((acc, component) => {
                  return acc + (component.vals && Object.keys(_.groupBy(component.vals.edges, val => val.node && val.node.point_num)).length);
              }, 0);
          
              // 2. Calculate unique point_num vals for fig
              const figPointLength = fig.vals && Object.keys(_.groupBy(fig.vals.edges, val => val.node && val.node.point_num)).length;
          
              // 3. Check if evaluations field is empty
              const hasEvaluations = fig.evaluations && fig.evaluations.length > 0;
          
              // 4. Calculate unique task_uuid in checks
              const uniqueTaskUuidLength = fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length;
          
              // If any of the criteria are met, return true to include in the resulting list
              return componentPointSum > 0 || figPointLength > 0 || hasEvaluations || uniqueTaskUuidLength > 0;
            });
          
            // Now, sort the filtered equipmentList alpha-numerically by name
            equipmentList.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });
            setAllEquipment(equipmentList);
          }
          }else {
            // console.log("NOT ROOT EQUIPMENT ONLY");
            // equipmentList.sort(alphaNumNameCompare);
            // setAllEquipment(equipmentList);
             
            
            /*this logic might not be correct */ 
            
            // Filter equipmentList based on the specified criteria
             equipmentList = equipmentList.filter(fig => {
              // 1. Calculate unique point_num sum for components
              const componentPointSum = (equipmentList || [])
              .filter(x => x.parent_key === fig.key) // Filtering to get the subcomponents
              .filter(c => c.lastValUploaded === null || moment(c.lastValUploaded.recorded_at).isBetween(moment(startDate), moment(endDate))) // Additional filter based on lastValUploaded
              .reduce((acc, component) => {
                  return acc + (component.vals && Object.keys(_.groupBy(component.vals.edges, val => val.node && val.node.point_num)).length);
              }, 0);
          
              // 2. Calculate unique point_num vals for fig
              const figPointLength = fig.vals && Object.keys(_.groupBy(fig.vals.edges, val => val.node && val.node.point_num)).length;
          
              // 3. Check if evaluations field is empty
              const hasEvaluations = fig.evaluations && fig.evaluations.length > 0;
          
              // 4. Calculate unique task_uuid in checks
              const uniqueTaskUuidLength = fig.checks && Object.keys(_.groupBy(fig.checks.edges, check => check.node && check.node.task_uuid)).length;
          
              // If any of the criteria are met, return true to include in the resulting list
              return componentPointSum > 0 || figPointLength > 0 || hasEvaluations || uniqueTaskUuidLength > 0;
            });
          
            // Now, sort the filtered equipmentList alpha-numerically by name
            equipmentList.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            });
            setAllEquipment(equipmentList);

          }
          
          
        }else if (sortByAnalysis){
          
          equipmentList = _.orderBy(equipmentList, [
            fig => Equipment.calcAnalysisComponentWarningValue(fig),
            fig => Equipment.calcAnalysisComponentEvaluationValue(fig),
            
            fig => fig.lastValUploaded && moment(fig.lastValUploaded.recorded_at).unix() || -1,
            fig => lastValUploadedOnComponent(equipmentList, fig) || -1,
            fig => alphaNumberSort(fig.parent_name || fig.name) === -1 ? moment(fig.created_at).unix() : alphaNumberSort(fig.parent_name || fig.name),
          ], ['desc', 'desc', 'desc', 'desc'])
          setAllEquipment(equipmentList);

        } else if (sortByName) {
          equipmentList.sort(alphaNumNameCompare);
          setAllEquipment(equipmentList);

         
        }else if (sortByLastInspection){
          equipmentList = _.orderBy(equipmentList, [
            fig => fig.lastValUploaded && moment(fig.lastValUploaded.recorded_at).unix() || -1,
            fig => alphaNumberSort(fig.name)
          ], ['desc'])
          setAllEquipment(equipmentList);

        }
                
        if (rootEquipmentOnly) {
          equipmentList = equipmentList.filter(x => x.parent_key === null)
        }else{
          equipmentList = equipmentList.map(fig => {
            const parentFigs = equipmentList.filter(f => f.key === f.parent_key)
            return {
              ...fig,
              parent_name: (_.first(parentFigs) || {}).name
            }
          }) 
        }
        setPropertyEquipmentList(equipmentList);
        console.log("equipmentList", equipmentList);
      }
      setLoading(false);
  }

  

  return (
        <div className="d-flex flex-column rounded-lg flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          {loading && <ImSpinner3 className="icon-spin" style={{ fontSize: 25 }} />}
          { !loading && 
            <div className="d-flex flex-row justify-content-start align-items-top flex-wrap">
              {(propertyEquipmentList || []).filter(fig => routine ? routine.routineFigs.filter(rf => rf.fig_uuid === fig.uuid).length > 0 : true).length === 0 &&
                <div className="text-center ml-3">None Found</div>
              }
              {(propertyEquipmentList || [])
                .filter(fig => routine ? routine.routineFigs.filter(rf => rf.fig_uuid === fig.uuid).length > 0 : true)
                .map(e => {
                return (
                  <EquipmentCard 
                    key={e.uuid}
                    e={e}
                    sheetSelectFunction={sheetSelectFunction}
                    hideInspectionCounts={hideInspectionCounts}
                    hideExpandedPointAnalysisColors={hideExpandedPointAnalysisColors}
                    hideAnalysisColors={hideAnalysisColors}
                    subcomponents={(allEquipment || []).filter(x => x.parent_key === e.key)}
                    equipmentSelectFunction={equipmentSelectFunction}
                    routine={routine}
                    pointSelectFunction={pointSelectFunction}
                    subcomponentSelectFunction={subcomponentSelectFunction}
                    hideSheetsSection={hideSheetsSection}
                    allowHidingComponentSection={allowHidingComponentSection}
                    allowHidingPointsSection={allowHidingPointsSection}
                    hideValCheckNumerator={hideValCheckNumerator}
                    hideValInspectionCountNumerator={hideValInspectionCountNumerator}
                    refresh={refresh}
                    setShowComponentsCard={setShowComponents}
                    setShowPointTable={setShowPointTable}
                    isInspectionPage={isInspectionPage}
                    sortByInspections={sortByInspections}
                    isComponent={isComponent}
                    setEditEvaluationModal={setEditEvaluationModal}
                    setShowEvaluationTable={setShowEvaluationTable}
                    setRefreshCounter={setRefreshCounter}
                  ></EquipmentCard>
              )
            })}
        </div>
      }
    </div>
  )
}

export default EquipmentGrid;