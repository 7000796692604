import { NOD_SE_VALUE, POINT_CONDITIONS } from "../constants/Constants";
import Model from "./Model";

export default class Val extends Model {
    static filterBy = async (searchList=[], sortField="recorded_at", sortOrder="desc", first, after, last, before, ignoreCache) => {
        const data = await super.filterBy(ValFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache, {});
        const result = data && data.valFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static filterMediaBy = async (searchList=[], sortField="recorded_at", sortOrder="desc", first, after, last, before) => {
        const data = await super.filterBy(ValMediaFilter, searchList, sortField, sortOrder, first, after, last, before, undefined, {});
        const result = data && data.valFilter;
        console.log('result', result);
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }
  
    static markAsReviewed = async (valUUID, userID) => {
        const vars = {
            input: {
                uuid: valUUID,
                user_id: userID
            }
        };
        const res = await this.runQueryNetworkOnly(MarkValReviewed, vars);
        return res.data && res.data.markValReviewed;
    }

    static isFlagged(reading) {
        return (
            this.isFlaggedExplicitly(reading) // Was manually flagged
            || this.isFlaggedImplicitly(reading) // Was flagged implicitly
        );
    }

    static isFlaggedExplicitly(reading) {
        return reading.cond === POINT_CONDITIONS.FLAGGED // Was manually flagged
    }

    static isFlaggedImplicitly(reading) {
        return (
            (reading.desc && reading.desc.length > 0) // Has a technical comment
            || (reading.rec && reading.rec.length > 0) // Has a reviewer comment
            || reading.sig >= 16
        );
    }

    static async submitReview(valUUID, userID, techNote = ""){
        const vars = {
            input: {
                uuid: valUUID,
                user_id: userID,
                desc: techNote
            }
        };
        const res = await this.runQueryNetworkOnly(SubmitReview, vars);
        return res.data && res.data.submitReview;
    }

    static async submitRecommendation(valUUID, userID, recommendation = ""){
        const vars = {
            input: {
                uuid: valUUID,
                user_id: userID,
                rec: recommendation
            }
        };
        const res = await this.runQueryNetworkOnly(SubmitRecommendation, vars);
        return res.data && res.data.submitRecommendation;
    }

    static async submitEval(valUUID, userID, evaluation){
        const vars = {
            input: {
                uuid: valUUID,
                user_id: userID,
                criticality: evaluation
            }
        };
        const res = await this.runQueryNetworkOnly(SubmitEval, vars);
        return res.data && res.data.submitEval;
    }

    static async updateConditions(valUUID, userID, flagged = false, secondary_noise = false){
        const vars = {
            input: {
                uuid: valUUID,
                user_id: userID,
                flagged, 
                secondary_noise,
            }
        };
        const res = await this.runQueryNetworkOnly(UpdateValConditions, vars);
        return res.data && res.data.updateValConditions;
    }

    static isCheck(reading){
        return reading && reading.v == NOD_SE_VALUE;
    }

    static isSecondaryNoise(reading){
        return reading && reading.secondary_noise;
    }
  
}

const GQL_SHALLOW_INSPECTION_FRAGMENT = `{
    id
    uuid
    v
    plr
    criticality
    
    sig
    aiSig
    warningMode
    recorded_at
    reviewed_at
    desc
    rec
    cond
    secondary_noise
    overb
    reviewed_at
    accelse
    alpha
}`



//criticality_ai

// uuid
// measurement_name
// property_name
// parent_fig_uuid
// fig_criticality

// fig_name
// adam_fig_name
// parent_fig_name
const GQL_MEDIA_INSPECTION_FRAGMENT = `{
    uuid
    recorded_at
    
    wav {
        spectrogram{
            minimum{
                bucket
                key
                region
            }
            default{
                bucket
                key
                region
            }
        }
    }
    point_info {
        uuid
        image{
            bucket
            key
            region
        }
        fig {
            fig_image_ext
            fig_image_key
            image{
                bucket
                key
                region
            }
        }

        valHistory{
            uuid
            v
            plr
            recorded_at
            sig
            rec
            desc
            criticality
            created_at
            reviewed_at
            warningMode
            secondary_noise
            overb   
            accelse
            cond
            aiSig
            point_info {
                uuid
                num
                measurement_name
                measurement_sort_idx
                property_name
                parent_fig_uuid
                fig_criticality
                fig_key
                parent_fig_key
                fig_name
                adam_fig_name
                created_at
                updated_at
                parent_fig_name
                image{
                    bucket
                    key
                    region
                }
                fig {
                    fig_image_ext
                    fig_image_key
                    image{
                        bucket
                        key
                        region
                    }
                }
            }
            
            actions {
                action {
                    name
                }
                pr
            }
            alpha
            signatures {
                signature {
                    name
                }
                pr
                score
            }
            wav{
                mp3Url
                user {
                    uuid
                    id
                    full_name
                }

            }

        }
        measurementStats {
            v_avg
            v_sd
        }
    }
    
    
}`

const GQL_FULL_INSPECTION_FRAGMENT = `{
    id
    uuid
    v
    plr
    criticality
    sig
    aiSig
    warningMode
    recorded_at
    created_at
    updated_at
    reviewed_at
    desc
    rec
    cond
    secondary_noise
    overb
    reviewed_at
    accelse
    alpha
    signatures {
        signature {
            name
        }
        pr
        score
    }

    actions {
        action {
            name
        }
        pr
    }
    point_info {
        uuid
        num
        measurement_name
        measurement_sort_idx
        property_name
        parent_fig_uuid
        fig_criticality
        fig_key
        parent_fig_key
        fig_name
        adam_fig_name
        created_at
        updated_at
        parent_fig_name
        image{
            bucket
            key
            region
        }
        fig {
            fig_image_ext
            fig_image_key
            image{
                bucket
                key
                region
            }
        }
    }
    
    wav{
        mp3Url
    }

}`
// pointBaseline {
//     baseline
// }

// referenceSoundFiles {
//     val_dec
//     notes
//     wav {
//         uuid
//         ver
//         mp3_uuid
//         mp3Upload {
//             rand_key
//             filename
//         }
//     }
// }

const ValFilter = `
query ValFilter($input: FilterConnectionInput){
    valFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_FULL_INSPECTION_FRAGMENT}
      }
    }
  }
`
const ValMediaFilter = `
query ValFilter($input: FilterConnectionInput){
    valFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node 
            ${GQL_MEDIA_INSPECTION_FRAGMENT}
      }
    }
  }
`



const MarkValReviewed = `
mutation MarkValReviewed($input: MarkValReviewedInput!){
    markValReviewed(input: $input)
        ${GQL_SHALLOW_INSPECTION_FRAGMENT}
}`

const SubmitRecommendation = `
mutation SubmitRecommendation($input: SubmitRecommendationInput!){
    submitRecommendation(input: $input)
        ${GQL_SHALLOW_INSPECTION_FRAGMENT}
}`

const SubmitEval = `
mutation SubmitEval($input: SubmitEvalInput!){
    submitEval(input: $input)
        ${GQL_SHALLOW_INSPECTION_FRAGMENT}
}`

const SubmitReview = `
mutation SubmitReview($input: SubmitReviewInput!){
    submitReview(input: $input)
        ${GQL_SHALLOW_INSPECTION_FRAGMENT}
}`

const UpdateValConditions = `
mutation UpdateValConditions($input: ConditionsUpdateInput!){
    updateValConditions(input: $input)
        ${GQL_SHALLOW_INSPECTION_FRAGMENT}
    
}`