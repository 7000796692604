
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form } from 'react-bootstrap';
import { EvaluationMark } from './EvaluationMark';
import { ImSpinner3 } from 'react-icons/im';
import { withTheme } from '@rjsf/core';
import moment from 'moment';
import { FaEdit, FaRegSquare } from 'react-icons/fa';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
import ZoomImage from './ZoomImage';

const JSONForm = withTheme(Bootstrap4Theme);

export const BareEvaluationSlider = ({ newValue, setNewValue }) => {
  return <Form.Control type="range" value={newValue} onChange={e => setNewValue(e.target.value)} min={0} max={5} step={1}
    style={{ zIndex: 1, position: 'relative' }}
  />
}

export const EvaluationSlider = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {/* <EvaluationScale style={{transform: "translateY(20px)", zIndex: -10}}/> */}
      <div className="d-flex flex-row align-items-center">
        <span className="mr-2">New Evaluation Color:</span>
        <EvaluationMark criticality={newValue * (options.value.point_info ? options.value.point_info.fig_criticality : 1)} />
      </div>

      <Form onSubmit={async (e) => {
        setLoading(true);
        await options.onSubmit(e, options.value, newValue)
        setLoading(false);
        options.onHide();
      }}>
        <Form.Group controlId="formBasicRange" className="d-flex flex-column align-items-end justify-content-end">
          <Form.Control type="range" value={newValue} onChange={e => setNewValue(e.target.value)} min={0} max={5} step={1}
            style={{ zIndex: 1, position: 'relative' }}
          />
          <Button variant="primary" className="mt-3" type="submit" >{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
        </Form.Group>
      </Form>
    </>
  )
}


export const EvaluationsSlider = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {/* <EvaluationScale style={{transform: "translateY(20px)", zIndex: -10}}/> */}
      <div className="d-flex flex-row align-items-center">
        <span className="mr-2">New Evaluation Color:</span>
        <EvaluationMark criticality={newValue * (options.value.criticality)} />
      </div>

      <Form onSubmit={async (e) => {
        setLoading(true);
        await options.onSubmit(e, options.value, newValue)
        setLoading(false);
        options.onHide();
      }}>
        <Form.Group controlId="formBasicRange" className="d-flex flex-column align-items-end justify-content-end">
          <Form.Control type="range" value={newValue} onChange={e => setNewValue(e.target.value)} min={0} max={5} step={1}
            style={{ zIndex: 1, position: 'relative' }}
          />
          <Button variant="primary" className="mt-3" type="submit" >{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
        </Form.Group>
      </Form>
    </>
  )
}


export const GenericSlider = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <span className="mr-2">New Value: {newValue || 0}</span>
      </div>
      <Form onSubmit={async (e) => {
        setLoading(true);
        await options.onSubmit(e, options.value, newValue)
        setLoading(false);
        options.onHide();
      }}>
        <Form.Group controlId="formBasicRange" className="d-flex flex-column align-items-end justify-content-end">
          <Form.Control type="range" value={newValue} onChange={e => setNewValue(e.target.value)} min={options.min || 0} max={options.max || 5} step={1}
            style={{ zIndex: 1, position: 'relative' }}
          />
          <Button variant="primary" className="mt-3" type="submit" >{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
        </Form.Group>
      </Form>
    </>
  )
}

export const EditTextField = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);
  
  return (
    <Form onSubmit={async (e) => {
      setLoading(true);
      try {
        await options.onSubmit(e, options.value, newValue)
      } catch (err) {
        console.log("This is the error");
        console.log(err);
      }

      setLoading(false);
      options.onHide();
    }}>
      <Form.Group controlId="formBasicRange" className="d-flex flex-column align-items-end justify-content-end">
        <Form.Control as="textarea" rows={3} value={newValue} onChange={e => setNewValue(e.target.value)} />
        <Button variant="primary" className="mt-3" type="submit" >{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
      </Form.Group>
    </Form>
  )
}

export const EditFaceplateTextField = ({ options, modelNum, serialNum, setModelNum, setSerialNum }) => {
  const [loading, setLoading] = useState(false);


  return (


    <form
      onSubmit={async (e) => {
       setLoading(true);
      console.log('modelNum', modelNum);
      console.log('serialNum', serialNum);

      try{
        await options.onSubmit(e, options.value, modelNum, serialNum);
      } catch(err){
        console.log("This is the error");
        console.log(err);
      }
  
       setLoading(false);
      options.onHide();
    }}>

      

      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 10, marginLeft: 2 }}>
        <label>Model Number:</label>
        <input type="text" style={{flexGrow: 3, marginLeft: '10px' }} value = {modelNum} onChange={e => setModelNum(e.target.value)} ></input>

      </div>


      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 2, marginLeft: 2, marginBottom: 10 }}>
        <label>Serial Number:</label>
        <input type="text" style={{flexGrow: 3, marginLeft: '10px' }} value = {serialNum} onChange={e => setSerialNum(e.target.value)}></input>

      </div>

      <Button type="submit" >{(loading) ? <ImSpinner3 className="icon-spin" style={{fontSize: 30}}/> : "Submit"}</Button>


    </form>
  )
}

export const EditDateField = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form onSubmit={async (e) => {
      setLoading(true);
      await options.onSubmit(e, options.value, newValue)
      setLoading(false);
      options.onHide();
    }}>
      <Form.Group controlId="formBasicRange" className="d-flex flex-column align-items-end justify-content-end">
        <Form.Control type="date" value={newValue} onChange={e => setNewValue(e.target.value)} />
        <Button variant="primary" className="mt-3" type="submit" >{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
      </Form.Group>
    </Form>
  )
}
export const LoadModal = ({ options, modelNum, serialNum, setModelNum, setSerialNum }) => {
  const [loading, setLoading] = useState(false);


  return (


    <form
      onSubmit={async (e) => {
       setLoading(true);
      console.log('modelNum', modelNum);
      console.log('serialNum', serialNum);

      try{
        await options.onSubmit(e, options.value, modelNum, serialNum);
      } catch(err){
        console.log("This is the error");
        console.log(err);
      }
  
       setLoading(false);
      options.onHide();
    }}>

      

      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 10, marginLeft: 2 }}>
        <label>Model Number:</label>
        <input type="text" style={{flexGrow: 3, marginLeft: '10px' }} value = {modelNum} onChange={e => setModelNum(e.target.value)} ></input>

      </div>


      <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: 2, marginLeft: 2, marginBottom: 10 }}>
        <label>Serial Number:</label>
        <input type="text" style={{flexGrow: 3, marginLeft: '10px' }} value = {serialNum} onChange={e => setSerialNum(e.target.value)}></input>

      </div>

      <Button type="submit" >{<ImSpinner3 className="icon-spin" style={{fontSize: 30}}/>}</Button>


    </form>
  )
}

export const EditNumberField = ({ options, newValue, setNewValue }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <JSONForm
        schema={{ type: "number" }}
        formData={newValue}
        onChange={e => {
          setNewValue(e.formData)
        }}
        children={true} //hide submit button
      />
      <Button variant="primary" className="mt-3" type="submit" onClick={async e => {
        e.preventDefault();
        setLoading(true);
        await options.onSubmit(e, options.value, newValue)
        setLoading(false);
        options.onHide();
      }}>{loading ? <ImSpinner3 className="icon-spin" style={{ fontSize: 30 }} /> : "Submit"}</Button>
    </>
  )
}

export const EditModal = (props) => {
  const { options, onImageUpload, onRescan } = props;
  const [newValue, setNewValue] = useState(options.selector && options.selector(options.value));
  const [modelNum, setModelNum] = useState(options.model_num); 
  const [serialNum, setSerialNum] = useState(options.serial_num);


  useEffect(() => {
    setNewValue(options.selector && options.selector(options.value));
    setModelNum(options.model_num);
    setSerialNum(options.serial_num);
  }, [JSON.stringify(options)])

  return (
    <Modal show={(options || {}).title != null} onHide={options.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{options.title || 'Edit'}</Modal.Title>
      
        
        {
          options.type === "fp_text" &&
        (
        <div>
        <div style={{ color: 'blue', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '3em', top: '1.2em' }}>
         
         <a className={`link`} onClick={e => { options.onUpload(e); onImageUpload(e);}}>Retake</a> 
         
        </div>


        <div style={{ color: 'blue', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '7.5em', top: '1.2em' }}>
         
         <a className={`link`} onClick={(e) => { options.onUpload(e); onRescan()}}>Rescan</a> 
         
        </div>




        </div>
        )
        }
      </Modal.Header>
      <Modal.Body>
        {options.fp_image &&
          <ZoomImage src={options.fp_image}/>
        }

        {options.faceplate &&
          (options.value.faceplate_image ? <ZoomImage smallSize={false} src={options.value.faceplate_image.bucket.includes('media3') ? `https://s3.amazonaws.com/${options.value.faceplate_image.bucket}/${options.value.faceplate_image.key}?cachebust=${(new Date().valueOf())}` : `https://${options.value.faceplate_image.bucket}.s3.amazonaws.com/${options.value.faceplate_image.key}?cachebust=${(new Date().valueOf())}`}/> : <ZoomImage src="/icons/image-placeholder3.svg"/>)
        }

         {options.placeholder &&
           <ZoomImage smallSize={true} src="/icons/image-placeholder3.svg"/>
        }

        {options.mn &&

          <EditTextField options={options} newValue={newValue} setNewValue={setNewValue} />

        }

        {
          options.type === "evaluation" &&
          <EvaluationSlider options={options} newValue={newValue} setNewValue={setNewValue} />
        }
         {
          options.type === "evaluations" &&
          <EvaluationsSlider options={options} newValue={newValue} setNewValue={setNewValue} />
        }
        {options.type === "slider" &&
          <GenericSlider options={options} newValue={newValue} setNewValue={setNewValue} />
        }
        {
          options.type === "text" &&
          <EditTextField options={options} newValue={newValue} setNewValue={setNewValue} />
        }

        {
          options.type === "fp_text" &&
          <EditFaceplateTextField options={options} modelNum={modelNum} serialNum={serialNum} setModelNum={setModelNum} setSerialNum={setSerialNum} />
        }

        {options.type === "number" &&
          <EditNumberField options={options} newValue={newValue} setNewValue={setNewValue} />
        }
        {options.type === "date" &&
          <EditDateField options={options} newValue={newValue} setNewValue={setNewValue} />
        }
        {options.fp &&
          <ZoomImage smallSize={false} src={options.fp.bucket.includes('media3') ? `https://s3.amazonaws.com/${options.fp.bucket}/${options.fp.key}?cachebust=${(new Date().valueOf())}` : `https://${options.fp.bucket}.s3.amazonaws.com/${options.fp.key}?cachebust=${(new Date().valueOf())}`}/>
        }
        {options.type === "load" && 
        <LoadModal options={options} modelNum={modelNum} serialNum={serialNum} setModelNum={setModelNum} setSerialNum={setSerialNum}  />


        }

      </Modal.Body>
    </Modal>


  )
}


export default EditModal