
import Resizer from "react-image-file-resizer";
import React, { useEffect, useState } from 'react';

import heic2any from "heic2any";


const resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    1500,
    1500,
    "JPEG",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

export const extractBase64FromDataURL = async (file) => { 
    console.log('in extractBase64FromDataURL');
    let resizedImage = await resizeFile(file);
    let split = resizedImage.split(',');
    return split[split.length - 1]; 

}


export const heicConversion = async (dataURL, name) => {
  console.log("HEIC CONVERSION");
  let converted = {};
  await fetch(dataURL)
  .then((res) => res.blob())
  .then((blob) => heic2any({
    blob,
    toType:"image/jpeg",
    quality: 0.90
  }))
  .then((conversionResult) => {
    var url = URL.createObjectURL(conversionResult);
    var file = new File([conversionResult], name);
    converted = {url: url, file: file};
    console.log('converted in heic is ', converted);
   
  })
  .catch((e) => {
    console.log(e);
  });

  return converted;

}