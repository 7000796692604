import React, { useEffect, useState } from 'react';
import User from "../../models/User";
import Utils from "../../common/Utils";
import Company from "../../models/Company";
import {useAuth} from '../../context/AuthContext';
import DataTable from 'react-data-table-component';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import UserEditScreen from '../user/UserEdit';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcRefresh } from 'react-icons/fc';
import UserHistoryHorizontalScreen from './UserHistoryHorizontalScreen';
import UserCard from './UserCard';
import SectionCard from './SectionCard';
import CheckSectionTable from './CheckSectionTable';
import Check from '../../models/Check';
import moment from 'moment';
import { ImSpinner3 } from 'react-icons/im';

const SectionTaskGrid = (props) => {
  const {sections: providedSections, tasks: providedTasks, startDate, endDate, showAll : providedShowAll, equipment} = props;
  const [sections, setSections] = useState(providedSections || []);
  const [tasks, setTasks] = useState(providedTasks || []);
  const [checks, setChecks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(providedShowAll || false);


  useEffect(() => {
    loadChecks();
  }, [sections, tasks, startDate, endDate])

  const loadChecks = async () => {
    setLoading(true);
    const taskUUIDs = [...(sections.map(section => section.tasks && section.tasks.map(task => task.uuid)) || []), ...(tasks.map(task => task.uuid) || [])].filter(x => x !== undefined);
    const figChecks = await Check.filterBy([
      {field: 'task_uuid', value: JSON.stringify(taskUUIDs), comparison: "IN"},
      {field: "archived", value: 0, comparison: "="},
    ], "created_at", "desc", 250, undefined, undefined, undefined, true );
    let resultChecks = figChecks.data
    if (startDate && endDate){
      const oneDayAfterEndDate = moment(endDate).add(1, 'day');
      resultChecks = resultChecks.filter(x => moment.tz(x.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate));
    }
    resultChecks = resultChecks.filter(x => x.fig_uuid === equipment.uuid)
    setChecks(resultChecks);
    setLoading(false);
  }

  return (
  <>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        <div className="d-flex flex-column bg-white flex-grow-1 pl-3 pr-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 ml-2 mr-2 mb-3 ">
              <h1 className="h2">Sections</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group mr-2">
                  {/* <Button variant="outline-secondary" onClick={() => setShowAll(!showAll)}>Show {showAll ? 'Filtered' : 'All'}</Button> */}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
              {loading && <ImSpinner3 className="icon-spin" style={{ fontSize: 25 }} />}
              {!loading && sections.map(section => {
                const sectionTaskUUIDs = tasks.filter(task => task.section_uuid === section.uuid).map(task => task.uuid);
                const taskChecks = checks.filter(c => sectionTaskUUIDs.includes(c.task_uuid));
                if (taskChecks.length === 0 && !showAll){
                  return <></>
                }else{
                  return <>
                    <div className="ml-2">
                      <h5>{section.name}</h5>
                      <CheckSectionTable checks={taskChecks}/>
                    </div>
                  </>
                }
              })}
            </div>
          </div>
    </div>
    </>
  )
}


export default SectionTaskGrid;