import React, { useEffect } from 'react';
import { handleViewport } from 'react-in-viewport';
import PropertyCard from './PropertyCard'; // Adjust the import path as needed

const PropertyCardViewport = (props) => {
  const { inViewport, forwardedRef, row } = props;

  useEffect(() => {
    if (inViewport) {
      // Trigger any data fetching or operations when the card is in view
      console.log("Property card is in viewport:", row.uuid);
      // Implement your data fetching logic here
    }
  }, [inViewport, row.uuid]);

  return <div ref={forwardedRef}><PropertyCard {...props} /></div>;
};

const ViewportPropertyCard = handleViewport(PropertyCardViewport, {}, { disconnectOnLeave: false });
export default ViewportPropertyCard;
