import React, { useContext, createContext, useState } from 'react';
import md5Hex from 'md5-hex';
import QueryContext from '../context/QueryContext';
import Amplify, { Auth } from 'aws-amplify';
import Login from '../queries/Login';
import DoesUserExist from '../queries/DoesUserExist';
import {USER_ROLES} from '../constants/Constants';
// import ResetPassword from '../queries/ResetPassword';
import SendPasswordReset from '../queries/SendPasswordReset';
import GuestIdentityPoolConfig from '../common/GuestIdentityPoolConfig';
import SuperAdminIdentityPoolConfig from '../common/SuperAdminIdentityPoolConfig';
import Utils from '../common/Utils';
import User from '../models/User';
import ActionLog from '../models/ActionLog';
import { ActionLogType } from '../models/ActionLogType';

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const { runQueryNetworkOnly, runQuery } = useContext(QueryContext);

  const doesUserEmailExist = async (email) => {
    const res = await runQuery(DoesUserExist, { email });
    return res.data && res.data.isEmailValid;
  }

  const parseJwt = (rawToken) => {
    const splitJWT = rawToken.split('.');
    if (splitJWT.length !== 3) {
      console.error(`Login token is malformed!`);
      return;
    }
    return JSON.parse(Buffer.from(splitJWT[1], 'base64').toString('ascii'));
  }

  const runLoginQuery = (vars) => {
    return runQueryNetworkOnly(Login, vars);
  }

  const setUserToGuest = () => {
    console.log("setUserToGuest");
    setUser(null);
    delete localStorage["dst-login-token"];
    Amplify.configure(GuestIdentityPoolConfig);
  }

  const parseTokenAndPersistCredentials = async (rawToken) => {
    console.log("parseTokenAndPersistCredentials");
    console.log("rawToken", rawToken);
    const token = parseJwt(rawToken);
    if (!Utils.isBlank(token)) {
      Amplify.configure(SuperAdminIdentityPoolConfig);
      const { user: tokenUser, cognitoToken } = token;
      console.log("tokenUser", tokenUser)
      const { IdentityId, Token } = cognitoToken;
      const date = new Date();
      const expires_at = 1000 * 1000 + date.getTime();
      let creds = null;
      try{
        creds = await Auth.currentCredentials();
      }catch(e){
        console.log("credential auth error", JSON.stringify(e));
        if (e.name === "NotAuthorizedException"){
          console.log("NotAuthorizedException");
          const session = await Auth.currentSession();
        }
      }
      console.log("creds", creds)
      // if (Utils.isBlank(creds) || creds.expired) {
      if (!Utils.isBlank(creds) && !creds.authenticated) {
        try{
          console.log("federated sign in")
          await Auth.federatedSignIn('developer', { token: Token, identity_id: IdentityId, expires_at }, null);
          creds = await Auth.currentCredentials();
          console.log("post-federated sign in creds", creds)
        }catch(e){
          console.log("error", JSON.stringify(e))
          if (e == "NotAuthorizedException: Invalid login token. Token is expired."){
            console.log("detected not authorized exception")
          //   toast.error(
          //     `Validation Error: Check error list for specific validation warnings and recommendations.`,
          // );
          }
          setUserToGuest();
          return false;
        }
      }
      // AWS.config.credentials = creds;
      // setUser(tokenUser);
      const fullUserContext = await User.findByUUID(tokenUser.uuid);
      setUser({...fullUserContext, ...tokenUser})

      if (localStorage["dst-login-token"] === undefined){
        await ActionLog.createLog({
          user: fullUserContext,
          action_type_id: ActionLogType.webPlatformLogin
        });
      }else{
        await ActionLog.createLog({
          user: fullUserContext,
          action_type_id: ActionLogType.webPlatformAutoLogin
        });
      }
      
      localStorage["dst-login-token"] = rawToken;
      return true;
    }
    setUserToGuest();
    return false;
  }

  const signin = async (email, pass) => {
    console.log("AuthContext.signin")
    const vars = { email, pass: md5Hex(pass) };
    const { data, error } = await runLoginQuery(vars);
    if (!Utils.isBlank(error)) {
      console.log(`%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%: error= ${JSON.stringify(error)}`);
      // signout();
      return false;
    }
    const rawToken = data.login.token;
    if (Utils.isBlank(rawToken)) {
      console.log(`%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%: UserContext.login ERROR: Utils.isBlank(rawToken)`);
      // signout();
      return false;
    }
    await parseTokenAndPersistCredentials(rawToken);
    
    return true;
  };

  const signout = async () => {
    console.log("AuthContext.signout")
    if (user && user.uuid){
      User.findByUUID(user.uuid);
    }
    localStorage["dst-login-token"] = undefined;
    setUserToGuest();
  };

  const isSignedIn = () => {
    return user !== null;
  }

  const isSuperAdmin = () => {
    return user && user.highestClearanceRole && user.highestClearanceRole.clearance_level == 0;
  }

  const isEmailFormatValid = (email) => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line no-useless-escape
    return email.match(emailRegex) !== null;
  }

  const requestPasswordReset = async (email) => {
    if (isEmailFormatValid(email) && await doesUserEmailExist(email)) {
      const passwordRequest = await runQueryNetworkOnly(SendPasswordReset, { email });
      const wasSuccessful = passwordRequest.data.sendPasswordReset;
      return wasSuccessful;
    }
    return null;
  }

  const doesUserHaveRole = (role) => {
    return (isSignedIn() && user.roleNames && user.roleNames.includes(role))
  }

  
  const isAdminOrHigher = () => {
    return doesUserHaveRole(USER_ROLES.admin) || doesUserHaveRole(USER_ROLES.superadmin);
  }

  const isSubdomainAdminOrHigher = () => {
    return isLimitedAdminOrHigher() || doesUserHaveRole(USER_ROLES.subdomainadmin);
  }

  const isLimitedAdminOrHigher = () => {
    return isAdminOrHigher() || doesUserHaveRole(USER_ROLES.limitedadmin);
  }

  const isCustomerTechOrHigher = () => {
    return isLimitedAdminOrHigher() || doesUserHaveRole(USER_ROLES.subdomaincustomer) || doesUserHaveRole(USER_ROLES.subdomaintech) || doesUserHaveRole(USER_ROLES.subdomainadmin);
  }

  // Refresh Token Function
  // Should occur on each page load

  const setSelectedProperty = (selectedProperty) => {
    setUser({
      ...user,
      selectedProperty
    })
  }

  const setSelectedRoutine = (selectedRoutine) => {
    setUser({
      ...user,
      selectedRoutine
    })
  }

  return {
    user,
    isSignedIn,
    signin,
    signout,
    doesUserEmailExist,
    isSubdomainAdminOrHigher,
    requestPasswordReset,
    isEmailFormatValid,
    parseTokenAndPersistCredentials,
    isSuperAdmin,
    doesUserHaveRole,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher,
  };
}

const AuthContext = createContext({});

export default AuthContext;