import React, { useEffect, useState } from 'react';
import { FcDownload } from 'react-icons/fc';

import User from "../../models/User";
import Utils from "../../common/Utils";
import Company from "../../models/Company";
import {useAuth} from '../../context/AuthContext';
import DataTable from 'react-data-table-component';
import './UserTable.css'; // Import the CSS file

import {
  BrowserRouter as Router,
  useLocation, 
  useHistory
} from "react-router-dom";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
import {MdArrowBack} from 'react-icons/md';

const Form = withTheme(Bootstrap4Theme);
import UserEditScreen from '../user/UserEdit';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcRefresh } from 'react-icons/fc';
import UserHistoryHorizontalScreen from '../components/UserHistoryHorizontalScreen';
import { EditModal } from './GenericEditModal';

const UserTable = (props) => {
  const {showMyHistory} = props;
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  const {
    user,
    isAdminOrHigher,
    isSuperAdmin, 
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isSubdomainAdminOrHigher
  } = useAuth();
  const history = useHistory();

  const [editModal, setEditModal] = useState({});

  const [includeArchived, setIncludeArchived] = useState(false);

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [userHistory, setUserHistory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleFilterModalClose = () => setShowModal(false);
  const handleFilterModalShow = () => setShowModal(true);
  const location = useLocation();
  const searchName = (new URLSearchParams(location.search)).get('q');
  const [userQuery, setUserQuery] = useState(searchName);
  const debouncedUserQuery = Utils.useDebounce(userQuery, 250);
  const defaultSearchOptions = {
    filters: [
      {"field": "full_name", "value": userQuery || "", "comparison": "LIKE"}
    ],
    sortOrder: "asc",
    sortField: "full_name",
    company_id: user.company_id || "0"
  };
  const [searchOptions, setSearchOptions] = useState(defaultSearchOptions);
  
  const updateCompanies = async () => {
    const allCompanies = await Company.all();
    setCompanies(allCompanies);
  }

  useEffect(() => {
    updateCompanies();
  }, [])
  
  useEffect(() => {
    searchUsers();
  }, [JSON.stringify(searchOptions)])

  useEffect(() => {
    setSearchOptions({
      ...setSearchOptions,
      filters: debouncedUserQuery ? searchOptions.filters.map(f => f.field === "full_name" ? {...f, value: debouncedUserQuery || ""} : f) : searchOptions.filters
    });
  }, [debouncedUserQuery])

  useEffect(() => {
    searchUsers();
  }, [includeArchived]);
  
  const onSubmit = async data => {
    const updatedSearchOptions = data.formData;
    setSearchOptions(updatedSearchOptions);
    handleFilterModalClose();
  }



  const userHistoryHorizontalScreen = React.useMemo(() => {
    return <UserHistoryHorizontalScreen
    userUUID={user.uuid}
    userID={user.id}
    queryUserInfo
    queryUploads
    queryChecks
    showArrows
    vertical
    noResultsMessage="No User History."
  ></UserHistoryHorizontalScreen>
  }, [user])

  const selectedUserHistoryHorizontalScreen = React.useMemo(() => {
    return <UserHistoryHorizontalScreen
    userUUID={userHistory ? userHistory.uuid : ""}
    userID={userHistory ? userHistory.id : ""}
    queryUserInfo
    queryUploads
    queryChecks
    multiRow
    noResultsMessage="No User History."
    daysAgoCutoff={1000}
  ></UserHistoryHorizontalScreen>
  }, [userHistory])

  // const searchUsers = async () => {
  //   setLoading(true);
  //   const companyID = user && user.company_id;
  //   const searchAllCompanies = searchOptions.company_id === "0";
  //   const results = await User.filterBy([
  //     // {"field": "archived", "value": 0, "comparison": "="},
  //     searchAllCompanies ? {"field": "company_id", "value": `0`, "comparison": "<>"} : {"field": "company_id", "value": `${companyID}`, "comparison": "="},
  //     !Utils.isBlank(searchOptions.company_id) && searchOptions.company_id > 0 ? {"field": "company_id", "value": `${searchOptions.company_id}`, "comparison": "="} : {"field": "company_id", "value": "-1", "comparison":"<>"},
  //     ...searchOptions.filters
  //   ], searchOptions.sortField || "full_name", searchOptions.sortOrder || "asc", 1000000);
  //   results.data = _.orderBy(results.data, [
  //     user => user.archived || user.state === 'passive',
  //     user => user.full_name,
  //   ], ['asc', 'asc']);
  //   setUsers(results.data);
  //   console.log("user results", results)
  //   setLoading(false);
  // }

  const searchUsers = async () => {
    setLoading(true);
    const companyID = user && user.company_id;
    const searchAllCompanies = searchOptions.company_id === "0";
    
    // let filters = [
    //   {"field": "archived", "value": `false`, "comparison": "="},
    //   searchAllCompanies ? {"field": "company_id", "value": `0`, "comparison": "<>"} : {"field": "company_id", "value": `${companyID}`, "comparison": "="},
    //   !Utils.isBlank(searchOptions.company_id) && searchOptions.company_id > 0 ? {"field": "company_id", "value": `${searchOptions.company_id}`, "comparison": "="} : {"field": "company_id", "value": "-1", "comparison":"<>"},
    //   ...searchOptions.filters
    // ];


    let filters = [
      // Only add this filter if includeArchived is false
      ...(!includeArchived ? [{"field": "archived", "value": `false`, "comparison": "="}] : []),
      
      searchAllCompanies ? {"field": "company_id", "value": `0`, "comparison": "<>"} : {"field": "company_id", "value": `${companyID}`, "comparison": "="},
      !Utils.isBlank(searchOptions.company_id) && searchOptions.company_id > 0 ? {"field": "company_id", "value": `${searchOptions.company_id}`, "comparison": "="} : {"field": "company_id", "value": "-1", "comparison":"<>"},
      ...searchOptions.filters
    ];
    if (!isSubdomainAdminOrHigher()) {
      // Add a filter to only search for the current user
      filters.push({"field": "id", "value": `${user.id}`, "comparison": "="});
  }

    const results = await User.filterBy(filters, "last_login" || "full_name", "desc" || "asc", 1000000);

    
    results.data = _.orderBy(results.data, [
      user => user.last_login || Infinity,
    ], ['desc']);
    if (isSubdomainAdminOrHigher() && !user.email.toLowerCase().endsWith('@discoverdst.com')) {
      results.data = results.data.filter(user => !user.email.toLowerCase().includes('@discoverdst.com'));
  }
  
    setUsers(results.data);
    // Filter the results to exclude any user emails containing '@discoverdst.com'
    const usersCount = results.data.filter(user => !user.email.toLowerCase().includes('@discoverdst.com')).length;
    setUsersCount(usersCount);
    setLoading(false);
  }



  const formatDate = (dateString) => {
    if (!dateString) {
      return "Not Recorded";
    }
  
    const date = new Date(dateString);
    const options = { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    return date.toLocaleString(undefined, options);
  };

  const columns = [
  
    {
      name: 'Full Name',
      selector: row => row["full_name"],
      cell: row => (
        <div className="d-flex flex-row text-left">
          <a className={``} onClick={async (e) => {
            setEditModal({
              title: "Update First Name",
              value: row,
              selector: row => row["first_name"] || "",
              type: "text",
              onHide: () => {
                setEditModal({})
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const updatedUser = { ...row, first_name: newValue };
                await User.update(updatedUser);
                searchUsers();
                setEditModal({});
              }
            });
          }}>
            <div className="pl-2">{row["first_name"]}</div>
          </a>
          <a className={``} onClick={async (e) => {
            setEditModal({
              title: "Update Last Name",
              value: row,
              selector: row => row["last_name"] || "",
              type: "text",
              onHide: () => {
                setEditModal({})
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const updatedUser = { ...row, last_name: newValue };
                await User.update(updatedUser);
                searchUsers();
                setEditModal({});
              }
            });
          }}>
            <div className="pl-2">{row["last_name"]}</div>
          </a>
        </div>
      ),
      sortable: true,
      center: true,
      compact: true,
      wrap: true,
      width: '14em'
    },

    {
      name: 'Email',
      selector: row => row["email"],
      cell: row => (
        <div className="d-flex flex-row text-left">
          <a className={``} onClick={async (e) => {
            setEditModal({
              title: "Update Email",
              value: row,
              selector: row => row["email"] || "",
              type: "text",
              onHide: () => {
                setEditModal({})
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const updatedUser = { ...row, email: newValue };
                await User.update(updatedUser);
                searchUsers();
                setEditModal({});
              }
            });
          }}>
            <div className="pl-2">{row["email"]}</div>
          </a>
        </div>
      ),
      sortable: true,
      center: true,
      compact: true,
      wrap: true,
      width: '14em'
    },
    {
      name: 'Phone',
      selector: row => row["phone"],
      cell: row => (
        <div className="d-flex flex-row text-left">
          <a className={``} onClick={async (e) => {
            setEditModal({
              title: "Update Phone Number",
              value: row,
              selector: row => row["phone"] || "N/A",
              type: "text",
              onHide: () => {
                setEditModal({})
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                const updatedUser = { ...row, phone: newValue };
                await User.update(updatedUser);
                searchUsers();
                setEditModal({});
              }
            });
          }}>
            <div className="pl-2">{row["phone"] || "N/A"}</div>
          </a>
        </div>
      ),
      sortable: true,
      center: true,
      compact: true,
      wrap: true,
      width: '14em'
    },
    
    
    
    
    {
      name: 'Roles',
      selector: (row) => row.roles ? row.roles.map(r => r.name).join(', ') : "",
      sortable: true,
      right: true,
    },
    {
      name: 'Active',
      selector: (row) => row.archived,
      cell: (row) => row.archived ? 'No' : 'Yes',
      sortable: true,
      right: true,
    },
    
    {
      name: 'Recent Activity',
      selector: row => row["last_login"],
      cell: row => formatDate(row["last_login"]),
      sortable: true,
      right: true,
      
    },
    {
      name: 'Recent App Version',
      selector: row => row["app_version"],
      cell: row => (row["app_version"]),
      sortable: true,
      right: true,
      
    },
    
    {
      name: 'Actions',
      cell: ((row) => {
        return (
          <div>
            <Button variant="link" onClick={() => {
              setEditModalUserUUID(row.uuid);
            }}>
              Edit
            </Button>
          </div>
        )
      }),
      right: true
    }
  ]
  
  

  const onSearchChange = async (event) => {
    setUserQuery(event.target.value);
    if (event.target.value.length === 0){
      setSearchOptions({
        ...setSearchOptions,
        filters: searchOptions.filters.map(f => f.field === "full_name" ? {...f, value: ""} : f)
      });
    }
  }

  const searchUISchema = {
    "filters": {
      "ui:options": {
        "addable": true,
        "orderable": false,
        "removable": true 
      }
    },
    "company_id": {
      "ui:widget": !isSuperAdmin() ? "hidden" : "select"
    }
  }

  const searchValidationSchema = () => {
    return {
        "type": "object",
        "properties": {
            "filters": {
                "description": "",
                "title": "",
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                      "field": {
                        "type": "string",
                        "title": "Column",
                        "enum": ["email", "full_name", ...(isSuperAdmin() ? ["companies.title", "company_groups.title"] : [])],
                        "enumNames": ["Email", "Full name", ...(isSuperAdmin() ? ["Company", "Company Group (Assigned)"] : [])],
                        "default": "email"
                      },
                      "comparison": {
                        "type": "string",
                        "title": "Comparison",
                        "enum": ["=", ">", "<", "<>", "LIKE"],
                        "enumNames": ["Same As", "Greater Than", "Less Than", "Not Equal To", "Contains"],
                        "default": "LIKE"
                      },
                      "value": {
                        "type": "string",
                        "title": "Value"
                      },
                    }
                }
            },
            "company_id": {
                "title": "User Company",
                "type": "string",
                "enum": companies ? [
                  ...(isSuperAdmin() ? ["0"] : []),
                  ...companies.map(c => `${c.id}`)
                ] : [],
                "enumNames": companies ? [
                  ...(isSuperAdmin() ? ["All Companies"] : []),
                  ...companies.map(c => c.title)] : [],
                "default": `${user.company_id || 0}`
            },
            "sortField": {
              "type": "string",
              "title": "Sort Field",
              "enum": ["email", "full_name"], //, "company_id"],
              "enumNames": ["Email", "Full name"], //, "Company"],
              "default": searchOptions.sortField || "full_name"
            },
            "sortOrder": {
              "type": "string",
              "title": "Sort Order",
              "enum": ["asc", "desc"],
              "enumNames": ["Ascending", "Descending"],
              "default": searchOptions.sortOrder || "asc"
            },
        }
    };
  }

  const [editModalUserUUID, setEditModalUserUUID] = useState(null);
  const handleEditModalClose = (updatedUser) => {
    setEditModalUserUUID(null);
    if (updatedUser) {
      const matchingUserRow = _.first(users.filter(x => x.uuid === updatedUser.uuid));
      if (matchingUserRow) {
        setUsers([...(users.filter(x => x.uuid !== updatedUser.uuid)), {...matchingUserRow, ...updatedUser}])
      }
    }
  }

  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const handleNewModalClose = () => {
    setShowNewUserModal(false);
  }


  const goBack = () => {
    if(showNewUserModal){
      setShowNewUserModal(false);
    }
   else if (editModalUserUUID !== null) {
      setEditModalUserUUID(null);

    } else if(editModalUserUUID == null){
      history.push(`/migration`);
    }

  }

  return (
  <>

   <div className="d-flex flex-row justify-content-start align-items-start">

   <EditModal options={editModal}/>

   
   </div>

  {userHistory === null && <div className="d-flex flex-column" style={{ position: 'sticky', top: '-.25em', zIndex: 99, backgroundColor: 'white' }}>
        <div className={`d-flex flex-row flex-no-wrap justify-start align-items-center`}>
          <Button variant="link" onClick={() => {
          goBack();
          }}>
            <MdArrowBack />
          </Button>
          <div className="font-weight-bold">{'Go Back'}</div>
        </div>
        <div></div>
      </div>
}

           { editModalUserUUID !== null && <div>
                    <UserEditScreen
                     goBackFn={goBack}
                      uuid={editModalUserUUID}
                      //isModal={true}
                      //modalDismiss={handleEditModalClose}
                    ></UserEditScreen>

                    
             </div> }

{showNewUserModal && 
  <div>
<UserEditScreen
uuid={null}
//isModal={true}
//modalDismiss={handleNewModalClose}
goBackFn={goBack}
>
  
</UserEditScreen>
</div>
}


    
{(editModalUserUUID == null) && !showNewUserModal && 
    <div className="d-flex flex-row justify-content-between flex-sm-row">
      
        {userHistory === null &&
        <div className="d-flex flex-column bg-white flex-grow-1 pl-3 pr-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          
          <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 ml-2 mr-2 mb-3 ">
       

              <h1 className="h2">Active Users ({includeArchived ? "All User Count" : "Active User Count"} = {usersCount})</h1>

              {!isSubdomainAdminOrHigher() && <p style={{color: 'red'}}>You Need Higher Privilege To View Other Users</p>}
              <div className="btn-toolbar mb-2 mb-md-0">
              
                <Modal show={showModal} onHide={handleFilterModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form
                      schema={searchValidationSchema()}
                      uiSchema={searchUISchema}
                      formData={searchOptions}
                      onSubmit={onSubmit}
                    />
                  </Modal.Body>
                </Modal>

                <input
                id="search"
                type="text"
                autoComplete="false" 
                placeholder="Search by Name"
                aria-label="Search by Name"
                value={userQuery || ''}
                // onSubmit={e => e.preventDefault()}
                className="mt-2"
                onChange={onSearchChange} />

                <ButtonToolbar>
                  <ButtonGroup className="ml-2 mt-2">
                    <Button className="btn btn-primary" onClick={() => setIncludeArchived(!includeArchived)}>
                    {includeArchived ? 'Hide Archived Users' : 'Show Archived Users'}
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-2 mt-2 mr-2">
                    <Button variant="success" onClick={() => { setShowNewUserModal(true); }}>
                    New User
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-1 mt-2 mr-1">
                    <a className={`btn btn-outline-secondary`} onClick={() => {
                      searchUsers();
                    }}>
                      <div className="d-flex flex-row text-left">
                        <FcRefresh style={{fontSize: 24}}/>
                        <div className="pl-2">Refresh</div>
                      </div>
                    </a>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            <div className="d-flex flex-column">
          
              <DataTable
                title="All Users"
                columns={columns}
                data={users}
                onRowClicked={(row) => {
                  setUserHistory({id: row.id, uuid: row.uuid}) }
                }
                dense
                // onSort={handleSort}
                // sortServer
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
              />
            </div>



            <div className="d-flex flex-row justify-content-end align-items-end">
  <button type="button" className="btn" onClick={() => {
    const columnNames = columns
      .filter(col => col.name !== 'Active' && col.name !== 'Actions')
      .map(col => `'${col.name}'`)
      const dataRowsAsTextOnly = users.map(row => 
        columns
          .filter(col => col.name !== 'Active' && col.name !== 'Actions')
          .map(col => {
            const data = col.selector ? `${col.selector(row)}` : '';
            return data.includes(',') ? `"${data}"` : data;
          })
      )
      
    
    const csvCombined = [
      columnNames.join(','),
      ...(dataRowsAsTextOnly || []).map(row => row.join(','))
    ].join('\n');
      
    let element = document.createElement('a');
    element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
    element.setAttribute('download', `export.csv`);
    document.body.appendChild(element);
    element.click();
  }}>
  <FcDownload/>
  </button>
</div>


          </div>
        }
        {userHistory !== null &&
          <div className="d-flex flex-column bg-white flex-grow-1"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
            <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 pl-2 pr-2 mr-2 mb-3 ">
              <h1 className="h2">Selected User's History</h1>
              <div className="btn-toolbar mb-2 mb-md-0">

                <ButtonToolbar>
                  <ButtonGroup className="ml-2 mt-2">
                    <Button className="btn btn-primary" onClick={() => setUserHistory(null)}>
                    Back
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </div>
            </div>
            {selectedUserHistoryHorizontalScreen}
          </div>
        }

        {showMyHistory && <div className="flex-column align-items-center justify-content-start bg-white col-2 pt-3 d-none d-xl-flex" style={{margin: "10px 10px 10px 10px"}}>
          <h2>My History</h2>
          {userHistoryHorizontalScreen}
        </div>}
    </div>
}
    <div className="d-flex flex-row justify-content-center">
      <div className="flex-column align-items-center justify-content-start bg-white col-6 pt-3  d-flex d-xl-none" style={{margin: "10px 10px 10px 10px"}}>
          <h2>User History</h2>
          {userHistoryHorizontalScreen}
        </div> 
    </div>
    </>
  )
}


export default UserTable;