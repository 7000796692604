import { useEffect, useState, useRef } from 'react';
import Company from "../../models/Company";
import Property from "../../models/Property";
import Equipment from "../../models/Equipment";
import Systems from "../../models/Systems";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import Utils from "../../common/Utils";
import {useAuth} from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import jsonpath from 'JSONPath';
import jsonTransform from 'jsonpath-object-transform';
import Geocode from "react-geocode";
import { GOOGLEMAP_GEOCODING_API_KEY, GOOGLEMAP_API_KEY } from '../../constants/Constants';
import User from '../../models/User';
import {
    useParams,
    useHistory,
    useLocation, 
    Link, 
  } from "react-router-dom";
import { identity } from 'lodash';

  Geocode.setApiKey(GOOGLEMAP_GEOCODING_API_KEY);
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");

  


const PropertyEditScreen = (props) => {
    const { user, isSuperAdmin, signout, isCustomerTechOrHigher} = useAuth();
    const history = useHistory();
    const location = useLocation();
    const [updatedPropertyJSON, setUpdatedPropertyJSON] = useState({});
    const [loading, setLoading] = useState(true);

    const [foundProperty, setFoundProperty] = useState({}); 

    // Users editing themselves should not be able to change their own role (except for super admins)
    const [showRoleSelection, setShowRoleSelection] = useState(true);
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);

    const {uuid} = useParams();
    
    const propertyUUID = uuid || props.uuid || location.state && location.state.propertyUUIDs && (location.state.propertyUUIDs.length > 0 ? location.state.propertyUUIDs[0] : null);
    console.log({uuid, propertyUUID})
    


    useEffect(() => {
        loadPropertyAndFormOptions();
    }, [propertyUUID])

    const loadPropertyAndFormOptions = async () => {
        console.log("I'm in load property and form options");
        setLoading(true);
        await Promise.allSettled([
            loadProperty()
        ]);
        setLoading(false);
    }

    const loadProperty = async () => {
        // const loggedInUser = await User.findByUUID(user.uuid);
        // console.log("loggedInUser: ", loggedInUser.roles_bf)
        // if (loggedInUser.roles_bf > foundUser.roles_bf && foundUser.roles_bf !== 0){
        //     toast.error(
        //         `Logged in user does not have higher privileges than the editing user. Please make these changes via a higher privileged user.`,
        //     );
        //     setHasPermissionToEdit(false);
        // }
        const foundProperty = await Property.searchByPropertyUuid(propertyUUID);
        console.log("This is the foundProperty: ", foundProperty);
        setFoundProperty(foundProperty[0]);
        setUpdatedPropertyJSON(foundProperty[0]);
    }

    const ErrorListTemplate = (props) => {
        const { errors } = props;
        return (
            <div className="alert-danger p-3 m-2">
                <h2>Validation Errors</h2>
                <ul>
                    {errors.map(error => (
                        <li key={error.stack}>
                            {error.readableStack}: 
                            <br/>
                            <ul>
                                <li>{error.message}</li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const transformErrors = (errors) => {
        console.log(errors)
        return errors.map(error => {
            const pathSegments = error.property.split('.');
            const attributeName = pathSegments.slice(pathSegments.length - 1)
            error.readableStack = 
            [
                `${attributeName}`
            ]
            return error;
        })
        .filter(e => e.property === '.state' && e.name === 'pattern' ? false : true)
        .map(e => e.property === ".zip" && e.name === "pattern" ? {...e, message: "should be 5 characters long (27311) or 9 characters for more specific zip codes (28345-1823)."} : e)
        
    }

    const onError = errors => {
        if (errors.length > 0){
            window.scrollTo(0, 0)
            toast.error(
                `Validation Error: Check error list for specific validation warnings and recommendations.`,
            );
        }
    }

    const onChange = data => {
        const updatedProperty = data.formData;
        setUpdatedPropertyJSON(updatedProperty);
    }

    const propertyToAddress = (property) => {
        return `${property.street1 || ''} ${property.street2 || ''} ${property.city || ''} ${property.state || ''} ${property.zip || ''} ${property.country || ''}`;
      }


    const geocodeProperty = async (property) => {
        try {
            const resp = await Geocode.fromAddress(propertyToAddress(property));
            const { lat, lng } = resp.results[0].geometry.location;
            const newProperty = {...property, lat: lat, lng: lng};
            return newProperty;
        } catch (e) {
            console.error("This is the error " + e);
            toast.info("Please Verify That Your Address Is Correct");
            return property;
        }
    }
      
    const isSameAddress = (property1, property2) => {
        return JSON.stringify(property1) === JSON.stringify(property2);
    };

    const updatePropertyArchivedStatus = async (archived = true) => {
        toast.info(
            `${archived ? 'Deleting' : 'Restoring'} ${foundProperty.name}`,
            {autoClose: 2000}
        );
        const newPropertyFields = {uuid: foundProperty.uuid, archived: archived};
        await Property.update(newPropertyFields);
        props.onHide && props.onHide();
        
    }   

    const onSubmit = async (data, event) => {
        //prevent page reload 
        event.preventDefault();
        let updatedProperty = data.formData;
        if (propertyUUID === null || propertyUUID === undefined){
            updatedProperty = await geocodeProperty(updatedProperty);
            let newUUID = Utils.uuid();
            console.log("updatedProperty", updatedProperty);
            const { 
                id, 
                city, 
                country, 
                external_uuid, 
                lat, 
                lng, 
                name, 
                note, 
                state, 
                street1, 
                street2, 
                uuid, 
                zip 
            } = updatedProperty;
            
            await Property.create({
                city: city,
                country: country,
                external_uuid: external_uuid,
                lat: lat,
                lng: lng,
                name: name,
                note: note,
                state: state,
                street1: street1,
                street2: street2,
                uuid: newUUID,
                zip: zip,
                company_id: user.company_id
            });
            

            props.onHide && props.onHide();
    
            toast.info(
                `Creating ${updatedProperty.name}`,
                {autoClose: 5000}
            );
            let propPlusUID = '/properties/' + newUUID; 
            console.log("rerouting to: " + propPlusUID);
            history.push(propPlusUID);
        }else{
            // get property by UUID so you can make the check for street address and lat lng duplicates      
            if (
                !(
                    (foundProperty.lat !== null || foundProperty.lat !== undefined) &&
                    (foundProperty.lng !== null || foundProperty.lng !== undefined) &&
                    isSameAddress(foundProperty, updatedProperty)
                )
            ) {
                console.log('looking up geocode');
                updatedProperty = await geocodeProperty(updatedProperty);
            }
            await Property.update(updatedProperty);
            props.onHide && props.onHide();
            toast.info(
                `Updating ${updatedProperty.name}`,
                {autoClose: 5000}
            );
            let propPlusUID = '/properties/' + propertyUUID; 
            console.log("rerouting to: " + propPlusUID);
            history.push(propPlusUID);
        }
    }

    const uiSchema = {}
    const validationSchema = () => {
        return {
            "type": "object",
            "required": [
              "name"
            ],
            "properties": {
                "name": {
                    "type": "string",
                    "title": "Property Name",
                    "default": "",
                    "maxLength": 500,
                    "minLength": 0
                },
                "street1": {
                    "type": "string",
                    "title": "Street 1",
                    "description": "Street name and number",
                    "default": "",
                    "maxLength": 500
                },
                "street2": {
                    "type": ["string", "null"],
                    "title": "Street 2",
                    "description": "Apartment number, PO box, etc.",
                    "default": "",
                    "maxLength": 500,
                    "minLength": 0
                },
                "city": {
                    "type": "string",
                    "title": "City",
                    "default": "",
                    "maxLength": 500
                },
                "state": {
                    "type": "string",
                    "title": "State Abbreviation",
                    "description": "Two letter State abbreviation - NC, TN, etc.",
                    "default": "",
                    "maxLength": 2,
                    // "pattern": "^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])?$"
                },
                "country": {
                    "type": "string",
                    "title": "Country",
                    "description": "Country Name",
                    "default": "",
                    "maxLength": 500,
                },
                "zip": {
                    "type": "string",
                    "title": "Zip Code",
                    "default": "",
                    "maxLength": 10,
                    // "pattern": "^(\\d{5}(-\\d{4})?)?$"
                },
                "note": {
                    "type": ["string", "null"],
                    "title": "Notes",
                    "default": "",
                    "maxLength": 50000,
                    "minLength": 0
                },
                "external_uuid": {
                    "type": ["string", "null"],
                    "title": "External UUID",
                    "description": "The UUID used to reference this property outside of DST. Used for asset management tracking.",
                    "default": "",
                    "maxLength": 500,
                    "minLength": 0
                },
            }
        };
    }

    return (
        <div className="d-flex justify-content-center align-items-center p-5">
            <div className="flex-column">
                {/* <h3>Property Edit</h3> */}
                    
                <div className="flex-row">
                    <Form
                        disabled={!hasPermissionToEdit || loading}
                        schema={validationSchema()}
                        transformErrors={transformErrors}
                        uiSchema={uiSchema}
                        formData={updatedPropertyJSON}
                        onChange={onChange}
                        showErrorList={true}
                        // extraErrors={extraErrors}
                        // validate={validate}
                        onSubmit={onSubmit}
                        onError={onError}
                        ErrorList={ErrorListTemplate} 
                    />
                </div>

                {
                        isCustomerTechOrHigher() ?  
                        <div className="d-flex flex-row justify-content-end">
                        <a style={{color: 'red', transform: 'translate(0,-1.5em)', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                               const answer = prompt(`Warning! All inspections on this property and equipment/components will be lost if this property is deleted. Please confirm this action by typing confirm.`);
                               if (!Utils.isBlank(answer) && answer.localeCompare('confirm', undefined, { sensitivity: 'base' }) === 0) {
                                   updatePropertyArchivedStatus(true);
                               }
                        }} disabled={loading}>Delete Property</a>
            </div>
            : <div></div>
                    }

            </div>
        </div>
    );
}


export default PropertyEditScreen;


