import Model from "./Model";
import Utils from "../common/Utils";


export default class View extends Model {
   
    static getViewServiceTechAtCompany = async(company_id) => {
        const variables = { company_id: String(company_id) };
        //const variables = { company_id: "12"};
        
        console.log("Variables:", variables);
        const res = await this.runQuery(GetViewServiceTechAtCompany, variables);
        return res.data.ViewServiceTechAtCompany;
    }

    static getViewMetricsAtCompany = async(company_id) => {
        const variables = { company_id: String(company_id) };
        
        console.log("Variables:", variables);
        const res = await this.runQuery(GetViewMetricsAtCompany, variables);
        return res.data.ViewMetricsAtCompany;
    }

    static getViewUserMetricsAtCompany = async(company_id) => {
        const variables = { company_id: String(company_id) };
        console.log("Variables:", variables);

        const res = await this.runQuery(GetViewUserMetricsAtCompany, variables);
        return res.data.ViewUserMetricsAtCompany;
    }
    

    static getViewMSIUsersAggregate = async() => {
        //console.log("runQuery method:", this.runQuery);
        const res2 = await this.runQuery(GetViewMSIUsersAggregate);
        return res2.data.ViewMSIUsersAggregate;
    }

}

const GetViewServiceTechAtCompany = `
query GetViewServiceTechAtCompany($company_id: Int!) {
    ViewServiceTechAtCompany(company_id: $company_id) {
        companyName
        numberOfSubdomainTechs
    }
}`


const GetViewMSIUsersAggregate = `
query GetViewMSIUsersAggregate {
    ViewMSIUsersAggregate {
        day
        uniqueUsersConnectedToMsiDaily
        week
        uniqueUsersConnectedToMsiWeekly
        month
        uniqueUsersConnectedToMsiMonthly
        uniqueUsersConnectedToMsiYearly
    }
}`


const GetViewMetricsAtCompany = `
query GetViewMetricsAtCompany($company_id: Int!) {
    ViewMetricsAtCompany(company_id: $company_id) {
        companyUuid
        inspectionMonthCount
        inspectionQuarterCount
        inspectionYearCount
        deviceMonthCount
        deviceQuarterCount
        deviceYearCount
        checksMonthCount
        checksQuarterCount
        checksYearCount
    }
}`

const GetViewUserMetricsAtCompany = `
query GetViewUserMetricsAtCompany($company_id: Int!) {
    ViewUserMetricsAtCompany(company_id: $company_id) {
        companyUuid
        usersMonthCount
        usersQuarterCount
        usersYearCount
    }
}`
