import React, { useEffect, useState } from 'react';
import View from '../../models/View';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

const ViewMSIUsersAggregatePanel = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedData = await View.getViewMSIUsersAggregate();
            console.log("THIS IS THE DATA FROM FETCHDATA2", fetchedData);
            setData(fetchedData);
        }
        fetchData();
    }, []);

    return (
        <div>
            {data ? (
                <>
                    <h3>Number of unique users who connected with a MSi150 over time</h3>
                    <LineChart width={800} height={400} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="uniqueUsersConnectedToMsiDaily" stroke="#8884d8" name="Daily Users" />
                        <Line type="monotone" dataKey="uniqueUsersConnectedToMsiWeekly" stroke="#82ca9d" name="Weekly Users" />
                        <Line type="monotone" dataKey="uniqueUsersConnectedToMsiMonthly" stroke="#ffc658" name="Monthly Users" />
                        <Line type="monotone" dataKey="uniqueUsersConnectedToMsiYearly" stroke="#ff7300" name="Yearly Users" />
                    </LineChart>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default ViewMSIUsersAggregatePanel;
