import { useEffect, useState, useMemo } from 'react';
import Company from "../../models/Company";
import Property from "../../models/Property";
import Equipment from "../../models/Equipment";
import Systems from "../../models/Systems";
import Makes from "../../models/Makes";

import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import Utils from "../../common/Utils";
import {useAuth} from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import jsonpath from 'JSONPath';
import jsonTransform from 'jsonpath-object-transform';
import User from '../../models/User';
import { useFilterContext } from "../../context/SharedFilterContext";

import ImageUploading from 'react-images-uploading';
import { ImSpinner3 } from 'react-icons/im';
import { EditModal } from '../components/GenericEditModal';
import {FaCamera, FaEdit} from 'react-icons/fa';
import ZoomImage from '../components/ZoomImage';
import {extractBase64FromDataURL, heicConversion} from "../utils/utils";

import heic2any from "heic2any";


// Indicates if uploading a primary image or nameplate image
var uploadFlag = false; 



import {
    useParams,
    useHistory
  } from "react-router-dom";

const EquipmentEditScreen = (props) => {    
    const { user, isSuperAdmin, signout, isCustomerTechOrHigher} = useAuth();
    const history = useHistory();
    const p = useParams();
  
    const {uuid, property, parentEquipment, onHide, selectedEquipment, goBackFn, refreshKey, hideImage} = props;
  
    const [updatedEquipmentJSON, setUpdatedEquipmentJSON] = useState({});
    const [loading, setLoading] = useState(true);
    const {
        propertyEquipmentList,
        setPropertyEquipmentList,
      } = useFilterContext();

    const [foundEquipment, setFoundEquipment] = useState({}); 

    

    

    const [primaryImage, setPrimaryImage] = useState(uuid ? (parentEquipment ? (parentEquipment.image ? `https://${parentEquipment.image.bucket}.s3.amazonaws.com/${parentEquipment.image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg' ) : ((selectedEquipment) ? (selectedEquipment.image ? `https://${selectedEquipment.image.bucket}.s3.amazonaws.com/${selectedEquipment.image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg' ) :  '/icons/image-placeholder3.svg')): '/icons/image-placeholder3.svg'); 
    const [primaryImageFile, setPrimaryImageFile] = useState(''); 

    const [faceplateImage, setFaceplateImage] = useState(uuid ? (parentEquipment ? (parentEquipment.faceplate_image ? `https://${parentEquipment.faceplate_image.bucket}.s3.amazonaws.com/${parentEquipment.faceplate_image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg' ) : ((selectedEquipment) ? (selectedEquipment.faceplate_image ? `https://${selectedEquipment.faceplate_image.bucket}.s3.amazonaws.com/${selectedEquipment.faceplate_image.key}?cachebust=${(new Date().valueOf())}` : '/icons/image-placeholder3.svg' ) :  '/icons/image-placeholder3.svg')) : '/icons/image-placeholder3.svg'); 
    const [faceplateImageFile, setFaceplateImageFile] = useState(''); 



    const [image_URL, setImage_URL] = useState(null);
    const [image_File, setImage_File] = useState(null);




    // Users editing themselves should not be able to change their own role (except for super admins)
    const [hasPermissionToEdit, setHasPermissionToEdit] = useState(true);
    const [systemsLookup, setSystemsLookup] = useState({});
    const [possibleFigs, setPossibleFigs] = useState([]);

    const [makesLookup, setMakesLookup] = useState({});
    const [suggestedConfidence, setSuggestedConfidence] = useState(null);
    const [suggestedSystemType, setSuggestedSystemType] = useState(null);



    const [editModal, setEditModal] = useState({});

    const updateUIWithLambdaResponse = (lambdaResponse) => {
      if(lambdaResponse !== undefined){
        let { confidence, systemType, systemTypeName } = lambdaResponse;
        setSuggestedConfidence(confidence);
        setSuggestedSystemType(systemType);
      }
  };


    const callLambdaFunction = async (updatedEquipment) => {
      const url = 'https://zlhbo25rf5xw4plnaolyicv2vi0sombt.lambda-url.us-west-2.on.aws/'; 
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              apiKey: 'ef7d5cb8-a380-4a71-a71e-133f325da8dd',
              name: updatedEquipment.name || '',
              make: updatedEquipment.make || '',
              model: updatedEquipment.model_num || '', 
              isChildEquipment: false, 
              parentSystemId: parentEquipment?.system_id

          })
      };
  
      try {
          const response = await fetch(url, requestOptions);
          if (!response.ok) {
              throw new Error('Failed to call Lambda function');
          }
          const data = await response.json();
          console.log("JSON RESPONSE: " + JSON.stringify(data));
          // Parse the response and update UI
          updateUIWithLambdaResponse(data);
      } catch (error) {
          console.error('Error calling Lambda function:', error);
      }
  };
  
    useEffect(() => {
        loadEquipmentAndFormOptions();
    }, [uuid, refreshKey])

    const loadEquipmentAndFormOptions = async () => {
        setLoading(true);
        await Promise.allSettled([
            loadProperty(),
            loadEquipment(),
            updateUserSystems(), 
            updateUserMakes()

        ]);
        setLoading(false);
    }

    const loadProperty = async () => {
        const foundProperty = await Property.getPropertyFigsByUUID(property.uuid);
        const validFigs = foundProperty.figs
            .filter(x => x.uuid != uuid)
            .filter(x => x.uuid != parentEquipment)
        setPossibleFigs(validFigs)
    }

    const loadEquipment = async () => {
        const foundEquipment = await Equipment.findByUUID(uuid);
        setFoundEquipment(foundEquipment);
     
          setUpdatedEquipmentJSON({
            ...foundEquipment,
            criticality: foundEquipment.criticality ? foundEquipment.criticality : 5,
            other: {
                external_uuid: foundEquipment.external_uuid,
                building: foundEquipment.building,
                floor: foundEquipment.floor,
                notes: foundEquipment.notes,
                area: foundEquipment.area,
            }
        });
    }

    const updateUserSystems = async () => {
      const newSystemsDictionary = await Systems.getSystemsForCompanyId(user.company_id);  
      // Create a sorted version of the systems dictionary
      const sortedKeys = Object.keys(newSystemsDictionary).sort();
      const sortedSystemsDictionary = {};
      sortedSystemsDictionary[''] = { name: "", id:"", system_id:""};

      sortedKeys.forEach(key => {
          sortedSystemsDictionary[key] = newSystemsDictionary[key];
      });    
      // Set the sorted systems dictionary in the state
      setSystemsLookup(sortedSystemsDictionary);
  };

  const updateUserMakes = async () => {
    try {
        const makesData = await Makes.getAllMakes();
        // Extract the names and sort them
        const sortedMakesNames = makesData.map(make => make.name).sort();
        sortedMakesNames.unshift("");

        // Set the sorted names in the state
        setMakesLookup(sortedMakesNames);
    } catch (error) {
        console.error("Failed to load makes:", error);
        toast.error("Failed to load makes data.");
    }
};




  

    const ErrorListTemplate = (props) => {
        const { errors } = props;
        return (
            <div className="alert-danger p-3 m-2">
                <h2>Validation Errors</h2>
                <ul>
                    {errors.map(error => (
                        <li key={error.stack}>
                            {error.readableStack}: 
                            <br/>
                            <ul>
                                <li>{error.message}</li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    const transformErrors = (errors) => {
        return errors.map(error => {
            const pathSegments = error.property.split('.');
            const attributeName = pathSegments.slice(pathSegments.length - 1)
            error.readableStack = 
            [
                `${attributeName}`
            ]
            return error;
        })
        .map(e => e.property === ".system_id" && e.name === "enum" ? {...e, readableStack: ["System"], message: 'is not a valid choice. Please select one from the dropdown.'} : e)
        .filter(x => {
            return (
                x.message.includes("should be string") ||
                (x.name === "type" && x.property === ".system_id")
                || x.property.includes(".parent_key")
                || x.message.includes("should be array")
            ) ? false : true
        });
    }

    const onError = errors => {
        if (errors.length > 0){
            window.scrollTo(0, 0)
            toast.error(
                `Validation Error: Check error list for specific validation warnings and recommendations.`,
            );
        }
    }

   const onChange_form = async (data) => {
        const updatedEquipment = data.formData;
        setUpdatedEquipmentJSON(updatedEquipment);

        // Calculate lambda response only if name, make, or model_num is present
        if (updatedEquipment.name || updatedEquipment.make || updatedEquipment.model_num) {
            try {
                // Call your lambda function here and get the response
                const lambdaResponse = await callLambdaFunction(updatedEquipment);
                updateUIWithLambdaResponse(lambdaResponse);
            } catch (error) {
                console.error('Error calling Lambda function:', error);
            }
        }
    };



    const onRescan = async (imageDataURL=image_URL, imageFile=image_File) => { 

      if(image_URL === null && faceplateImage !== '/icons/image-placeholder3.svg'){
  
       if(selectedEquipment){
        await fetch(`https://${selectedEquipment.faceplate_image.bucket}.s3.amazonaws.com/${selectedEquipment.faceplate_image.key}?cachebust=${(new Date().valueOf())}`)
      .then(response => response.blob())
      .then(imageBlob => {
      var url = URL.createObjectURL(imageBlob);
      var file = new File([imageBlob], `${faceplateImage}`,{type:"image/jpeg", lastModified:new Date().getTime()})
      
      imageDataURL = url;
      imageFile = file; 
  });

       } else{

        await fetch(`${faceplateImage}`)
        .then(response => response.blob())
      .then(imageBlob => {
      var url = URL.createObjectURL(imageBlob);
      var file = new File([imageBlob], `${faceplateImage}`,{type:"image/jpeg", lastModified:new Date().getTime()})
      
      imageDataURL = url;
      imageFile = file; 
  });

       } 
  
      }

      if(imageFile.type === 'image/heic'){
        setLoading(true);
    
        let converted = await heicConversion(imageDataURL, imageFile.name);
        imageDataURL = converted.url; 
        imageFile = converted.file; 

        setLoading(false);

      }
      
       
      setLoading(true);
  
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'load', 
       
        jsonFlag: true,
  
        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          
       
        }
      });
  
  
      let justBase64 = await extractBase64FromDataURL(imageFile);
  
  
      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json"
       }
        
       let bodyContent = JSON.stringify({
         "base64Image": `${justBase64}`
       
       });
       let response; 
       
       try{
                response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
                method: "POST",
                body: bodyContent,
                headers: headersList
              });
        }catch(err) {
            alert("There was an error trying to get response from OCR Api");
        }
       
            let data = await response.text();
            let json = JSON.parse(data);
            setLoading(false);
  
      setLoading(true);
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        
        type: 'fp_text', 
        model_num: (json.mn === null ? '' : json.mn),
        serial_num: (json.sn === null ? '' : json.sn),
        json: json, 
        jsonFlag: true,
  
        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          e.preventDefault();
      
          setFaceplateImage(imageDataURL);
          setFaceplateImageFile(imageFile);      
          setUpdatedEquipmentJSON({...updatedEquipmentJSON, model_num: modelNum, serial_num: serialNum}); 

          setImage_URL(null);
          setImage_File(null);
       
        }
      });
      setLoading(false);
      
  };
  


const onChange_upload = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0 && !uploadFlag) {
      uploadFlag = false; 
      

if(imageList[0].file.type === 'image/heic'){

  setLoading(true);

  let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);
  setPrimaryImage(converted.url);
  setPrimaryImageFile(converted.file);

setLoading(false);
} else {
  setLoading(true);
  setPrimaryImage(imageList[0].dataURL);
  setPrimaryImageFile(imageList[0].file);  
  setLoading(false);
}

    } else if (imageList.length > 0 && uploadFlag){
      uploadFlag = false; 
      let imageDataURL = imageList[0].dataURL; 
      let imageFile = imageList[0].file;
      if(imageList[0].file.type === 'image/heic'){
        setLoading(true);

        let converted = await heicConversion(imageList[0].dataURL, imageList[0].file.name);
        imageDataURL = converted.url; 
        imageFile = converted.file; 

        setLoading(false);
      }

      setImage_URL(imageDataURL);
      setImage_File(imageFile);
      setLoading(true);

      // SPINNING LOADING MODAL
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'load', 
       
        jsonFlag: true,

        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          
       
        }
      });
      let justBase64 = await extractBase64FromDataURL(imageFile);

      let headersList = {
        "Accept": "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json"
       }
        
       let bodyContent = JSON.stringify({
         "base64Image": `${justBase64}`
       
       });
       let response; 
       
       try{
                response = await fetch("https://5rymsnzzemuqvewgglog5bbkz40hlcjz.lambda-url.us-west-2.on.aws/", {
                method: "POST",
                body: bodyContent,
                headers: headersList
              });
        }catch(err) {
            alert("There was an error trying to get response from OCR Api");
        }
       
            let data = await response.text();
            let json = JSON.parse(data);

      setLoading(true);
      setEditModal({
        title: "Nameplate Photo",
        fp_image: imageDataURL,
        type: 'fp_text', 
        model_num: (json.mn === null ? '' : json.mn),
        serial_num: (json.sn === null ? '' : json.sn),
        json: json, 
        jsonFlag: true,

        onUpload: () => {
          uploadFlag=true;
        },
        onHide: () => {
          setEditModal({})
        },
        onSubmit: async (e, row, modelNum, serialNum) => {
          e.preventDefault();
      
          setFaceplateImage(imageDataURL);
          setFaceplateImageFile(imageFile);      
          setUpdatedEquipmentJSON({...updatedEquipmentJSON, model_num: modelNum, serial_num: serialNum}); 

          setImage_URL(null);
          setImage_File(null);
       
        }
      });
      setLoading(false);
      
  };


}



    const updateEquipmentArchivedStatus = async (archived = true) => {
        toast.info(
            `${archived ? 'Deleting' : 'Restoring'} ${foundEquipment.name}`,
            {autoClose: 2000}
        );
        const newEquipmentFields = {uuid: foundEquipment.uuid, archived: archived};
        const returnedEquipment = await Equipment.update(newEquipmentFields);
        onHide && onHide(returnedEquipment);
        goBackFn();
        goBackFn();

        
    }   

    const onSubmit = async (data, event) => {
        const updatedEquipment = {
            ...data.formData
        };

        if(foundEquipment && foundEquipment.uuid){
          const results = await Equipment.getAllPoints(foundEquipment.uuid);

          const matchingEquipment = results?.data?.length > 0 ? results.data[0] : null;
          if (matchingEquipment && matchingEquipment?.system_id !== updatedEquipment?.system_id && matchingEquipment?.system_id !== null) {
              // Query only returns enabled/non-archived points
              const points = matchingEquipment.points;
              const filterPointsMatchingSystem = points.filter(point => point.system_id === matchingEquipment.system_id);
              if (filterPointsMatchingSystem.length > 0) {
                  alert("All points must be disabled before changing the system_id.");
                  return;
              }
          }
        }
        updatedEquipment.parent_key = updatedEquipment.parent_key === "None" ? null : updatedEquipment.parent_key;
        let parent_key_var = updatedEquipment.parent_key;
        updatedEquipment.year = parseInt(updatedEquipment.year);
        updatedEquipment.system_id = parseInt(updatedEquipment.system_id);
        updatedEquipment.notes = data.formData.notes ? data.formData.notes : '';
        updatedEquipment.building = data.formData.building ? data.formData.building : '';
        updatedEquipment.area = data.formData.area ? data.formData.area : '';
        
        delete updatedEquipment.other;
        delete updatedEquipment["Show additional data fields?"];
        
        if (updatedEquipment.uuid === null || updatedEquipment.uuid === undefined){
            setLoading(true);
            toast.info(
                `Creating ${updatedEquipment.name}`,
                {autoClose: 4000}
            );
            
            
            const newEquipment = {
                ...updatedEquipment,
                uuid: Utils.uuid(),
                property_id: property.id,
                property_uuid: property.uuid,
                // user_uuid: user.uuid
            }
       
          const returnedEquipment = await Equipment.create(newEquipment);

            
          // what if PrimaryImageFile is an empty string? 

            // check and make sure that an image has been uploaded for primary or faceplate
            if(primaryImage !== '/icons/image-placeholder3.svg' && primaryImageFile !== ''){
             await Equipment.updatePhoto(returnedEquipment.uuid, primaryImage, primaryImageFile, false, returnedEquipment);

            }

            if(faceplateImage !== '/icons/image-placeholder3.svg' && faceplateImageFile !== ''){
            await Equipment.updatePhoto(returnedEquipment.uuid, faceplateImage, faceplateImageFile, true, returnedEquipment);

            }

            await Equipment.update({uuid: returnedEquipment.uuid, model_num: data.formData.model_num, serial_num:data.formData.serial_num, parent_key: parent_key_var});


            setPropertyEquipmentList([]);
         
            onHide && onHide(returnedEquipment);
          
            setLoading(false);
            goBackFn && goBackFn();

        }else{
            const returnedEquipment = await Equipment.update(updatedEquipment);
            if(primaryImageFile !== ''){
               await Equipment.updatePhoto(returnedEquipment.uuid, primaryImage, primaryImageFile, false, returnedEquipment);

            }

            if(faceplateImageFile!== ''){
              await Equipment.updatePhoto(returnedEquipment.uuid, faceplateImage, faceplateImageFile, true, returnedEquipment);
            }
            onHide && onHide(returnedEquipment);
            toast.info(
                `Updating ${updatedEquipment.name}`,
                {autoClose: 3000}
            );
            goBackFn && goBackFn();
        }
    }

    const uiSchema = {}
    const validationSchema = useMemo(() => {
        return {
            "type": "object",
            "required": [
              "name",
              //"system_id",
              "criticality"
            ],
            "properties": {
                "name": {
                    "type": "string",
                    "title": "Equipment Name",
                    // "description": "A name for this equipment. DST recommends using all uppercase, distinct names.",
                    "maxLength": 500,
                    "minLength": 1,
                    "required": true,
                    "default": parentEquipment ? parentEquipment.name : null
                },
                "make": {
                  "type": "string",
                  "title": "Make",
                  // "description": "The make of this equipment",
                  "enum": makesLookup, // Directly use the array of sorted names
                  "default": "",
                  "maxLength": 500
              },
              
              "model_num": {
                    "type": "string",
                    "title": "Model Number",
                    // "description": "The model number for this equipment",
                    "default": "",
                    "maxLength": 500,
                },
               "year": {
                    "type": "string",
                    "title": "Year",
                    "default": "",
                    "pattern": "^[0-9]{0,5}$",
                    "maxLength": 500,
                    // "description": "Year of manufacture"
                },
               "serial_num": {
                    "type": "string",
                    "title": "Serial Number",
                    // "description": "The serial number for this equipment",
                    "default": "",
                    "maxLength": 500,
                },
                // "system_id": {
                //     "type": "number",
                //     "title": "System",
                //     // "description": "The system of this equipment",
                //     "enum": [...Utils.isBlank(systemsLookup) ? [] : Object.values(systemsLookup).map(x => x.id)],
                //     "enumNames": [...Utils.isBlank(systemsLookup) ? [] : Object.keys(systemsLookup)],
                //     "required": false
                // },
              // Inside useMemo for validationSchema
                "system_id": {
                  "type": "number",
                  "title": "System",
                  "enum": [...(systemsLookup ? Object.values(systemsLookup).map(x => x.id) : [])],
                  "enumNames": [...(systemsLookup ? Object.keys(systemsLookup).map(key => {
                      const isSuggested = parseInt(suggestedSystemType) === systemsLookup[key].id;
                      return `${systemsLookup[key].name}${isSuggested ? ` (**SUGGESTED** - ${Math.round(suggestedConfidence * 100)}%)` : ''}`;
                  }) : [])],
                  "default": ""
                },

             
              
                "parent_key": {
                    "type": "number",
                    "title": "Parent Equipment",
                    // Default to ParentEquipment if already a child
                    "default": parentEquipment ? parentEquipment.key : null,
                    "enum": [...Utils.uniq([(parentEquipment ? parentEquipment.key : null), ...(possibleFigs.map(x => x.key))])],
                    "enumNames": [...Utils.uniq([(parentEquipment ? parentEquipment.name : "None"), ...(possibleFigs.map(x => x.name))])],
                },
            
                "criticality": {
                    "type": "number",
                    "title": "Criticality",
                    // "description": "How important is this equipment or component?",
                    "enum": [1, 2, 3, 4, 5],
                    "enumNames": ["1 - Low", "2 - Medium", "3 - High", "4 - Very High", "5 - Essential"],
                    "required": true
                },
                 "notes": {
                                    "type": "string",
                                    "title": "Notes",
                                    // "description": "Any notes specific to this equipment",
                                    "default": "",
                                    "maxLength": 50000,
                                },
                                "building": {
                                    "type": "string",
                                    "title": "Building",
                                    "default": "",
                                    "maxLength": 500,
                                    // "description": "Specific building location"
                                },
                                "floor": {
                                    "type": "string",
                                    "title": "Floor",
                                    "default": "",
                                    "maxLength": 500,
                                    // "description": "Specific floor location"
                                },
                                "area": {
                                    "type": "string",
                                    "title": "Area",
                                    "default": "",
                                    "maxLength": 500,
                                    // "description": "Specific area location"
                                },
                                "external_uuid": {
                                    "type": "string",
                                    "title": "Customer Equipment Identifier",
                                    // "description": "A customer defined identifier for this equipment. e.x. if this equipment is already used in a non-DST asset management system",
                                    "default": "",
                                    "maxLength": 500,
                                },
             
            }
        };
    }, [possibleFigs, parentEquipment, systemsLookup, makesLookup, suggestedConfidence, suggestedSystemType]);

    return (
        <div className="d-flex justify-content-center align-items-center pl-5 pr-1">
            <div className="flex-column">                    
                <div style={{display:'flex'}} className="flex-row space-around">
                    <Form
                        disabled={!hasPermissionToEdit || loading}
                        schema={validationSchema}
                        transformErrors={transformErrors}
                        uiSchema={uiSchema}
                        formData={updatedEquipmentJSON}
                        onChange={onChange_form}
                        showErrorList={true}
                        // extraErrors={extraErrors}
                        // validate={validate}
                        onSubmit={onSubmit}
                        onError={onError}
                        ErrorList={ErrorListTemplate} 
                    />
            
               <div style={{display:'flex', flexDirection:'column', alignContent:'center'}}>
                
        
{!hideImage &&
<ImageUploading
            multiple={false}
            onChange={onChange_upload}
            maxNumber={1}
          >
          
      {({ dragProps, isDragging, onImageUpload }) => (
        
        <div {...dragProps} style={{width: '100%'}} >
          <EditModal onRescan={onRescan} onImageUpload={onImageUpload} options={editModal}/>
          {loading && <ImSpinner3 className="icon-spin" style={{fontSize: 30, marginTop: '1em', marginBottom: '1em', minWidth: '300px'}}/>}
          
          {!loading && <>
           

                <div style={{display:'flex', flexDirection:'column', alignContent:'center'}}>   
         <a style={{position:'relative', textAlign:'center', top:'1em'}}>
          Equipment Photo
          </a>

<div style={{position:"relative"}} className="container">
          
          <img onClick={e => {uploadFlag=false; onImageUpload(e);}} className="m-3" style={{ maxWidth: 450, maxHeight: 400, height:'auto', display:'block', marginLeft:'auto', marginRight:'auto'}} src={primaryImage}></img>
          
          
          <div onClick={e => {uploadFlag=false; onImageUpload(e);}} style={{color: 'white', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '2.8em', top: '1.5em'}}>
                  <FaCamera style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }}/>
               
                </div>

</div>
          
         <a style={{position:'relative', textAlign:'center'}}>
          Nameplate Photo
          </a>
<div className="container" style={{position:"relative"}}>
  
<div onClick={e => {
      if(faceplateImage === '/icons/image-placeholder3.svg' ){
        uploadFlag=true; 
        onImageUpload(e);
      }
    }}>     
          <ZoomImage smallSize={false} src={faceplateImage}/>
      
          </div>
        <div onClick={e => { 
                  setEditModal({
                    title: "Nameplate Photo",
                    type: "fp_text",
                    placeholder: (faceplateImage === '/icons/image-placeholder3.svg') ? true : false,
                    jsonFlag: false, 
                    fp_image: (faceplateImage === '/icons/image-placeholder3.svg') ? null: faceplateImage,
                    onUpload: () => {
                      uploadFlag=true;
                    },

                    onHide: () => {
                      setEditModal({})
                    },

                    onSubmit: async (e, row, modelNum, serialNum) => {
                      e.preventDefault();
                      
                    }

                })
                if((faceplateImage === '/icons/image-placeholder3.svg')){
                  uploadFlag=true;
                  onImageUpload(e);
                }
                
                }} style={{color: 'white', position: 'absolute', textAlign: 'right', padding: '0', margin: '0', right: '3em', top: '.4em'}}>
                  <FaEdit style={{ filter: 'drop-shadow(rgb(0,0,0) 0 0 2px)' }}/>
          
                </div>
  
</div>
        
         
        </div>
                
                

          </>}
        
        </div>
      )}
             
            
          </ImageUploading>     }

              
              </div>
              
               </div>
                      {
                        isCustomerTechOrHigher() && uuid ?  
                        <div className="d-flex flex-row justify-content-end">
                        <a style={{color: 'red', transform: 'translate(0,-1.5em)', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                                const answer = prompt(`Warning! All inspection history on this equipment and components will be lost if this equipment is deleted. Please confirm this action by typing confirm.`);
                                if (!Utils.isBlank(answer) && answer.localeCompare('confirm', undefined, { sensitivity: 'base' }) === 0) {
                                    updateEquipmentArchivedStatus(true);
                                }  
                        }} disabled={loading}>Delete Equipment</a>
            </div>
            : <div></div>
                    }  

            </div>
        </div>
    );
}


export default EquipmentEditScreen;


