import React, { useEffect, useState } from 'react';
import Equipment from '../../models/Equipment';
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import TaskTypeIcon from './TaskTypeIcon';
import moment from 'moment';
import Check from '../../models/Check';

const TaskCard = (props) => {
  const { 
    taskSelectFunction, 
    task,
    checkSelectFunction
  } = props;
  const firstCheck = task.checks && task.checks.length > 0 ? _.first(_.orderBy(task.checks, [c => moment(c.created_at).unix()], ['desc'])) : {};

  return (
    <div className="grid-card card btn rounded-lg mr-2 mb-2" key={task.uuid} >
      <div className="d-flex flex-row justify-content-center align-items-center" style={{ minWidth: 250, minHeight: 100, maxHeight: 350, height: 'auto' }} onClick={(event) => {
      event.preventDefault();
      console.log("clicked task", task)
      taskSelectFunction(task)
      checkSelectFunction(firstCheck)
    }}>
        {/* {e.image
          ? <img className="image-thumbnail rounded-lg" style={{ maxWidth: '100%', maxHeight: '100%', backgroundImage: `url("https://web.dstapp.com/icons/image-placeholder3.svg")` }}
            // src={`https://${p.image.bucket}.s3-us-west-2.amazonaws.com/${p.image.key}`} />
            src={e.image.bucket.includes('media3') ? `https://s3.amazonaws.com/${e.image.bucket}/${e.image.key}` : `https://${e.image.bucket}.s3.amazonaws.com/${e.image.key}`} />
          // src={`${e.image.bucket}.s3.amazonaws.com/${e.image.key}`} />
          // : <img className="image-thumbnail rounded-lg" style={{maxWidth: '100%', maxHeight: '100%'}} src={Utils.simpleSvgPlaceholder({width: 250, height: 250, text: "No Image", fontSize: 18})} />
          // : <FcImageFile className="image-responsive"/>
          : <img src="/icons/image-placeholder3.svg" className="image-thumbnail rounded-lg" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        } */}

        {/* {task.type_code === "PHOTO" && <img style={{maxWidth:"100%", maxHeight:"100%"}} src={firstCheck.image && firstCheck.image.bucket && firstCheck.image.key ? `https://${firstCheck.image.bucket}.s3-us-west-2.amazonaws.com/${firstCheck.image.key}` : ``} alt=""></img>} */}
        <TaskTypeIcon task_code={task.type_code} style={{fontSize: '50px'}}/>

      </div>
      <div className="card-body pt-0 pl-0 pr-0 pb-0 mt-1">
        <h5 className="card-title" onClick={(event) => {}}>
          {task.name}
        </h5>

        <div className="d-flex flex-column" >
          <span className="d-flex flex-row justify-content-between">
            <span>Type </span>
            <span>{Check.getTaskCodeProperName(task.type_code)}</span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Created At </span>
            <span>{moment(task.created_at).format("MM-DD-YYYY")}</span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Last Completed At </span>
            <span>{moment(firstCheck.created_at).format("MM-DD-YYYY")}</span>
          </span>
          <span className="d-flex flex-row justify-content-between">
            <span>Latest Value </span>
            <span>{firstCheck.v && Check.formatCheckValue(firstCheck)}</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default TaskCard;