import Model from "./Model";

export default class Report extends Model {
    static send = async(params) => {
        const res = await this.runQueryNetworkOnly(SendItemReport, params);
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Report.send.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }

    static sendQRCodeReport = async params => {
        const {propertyUUID, outputComponentQRCodes, destinationEmail } = params;
        const lambdaParams = {
            input: {
                propertyUUID: propertyUUID,
                outputComponents: outputComponentQRCodes,
                destinationEmail: destinationEmail
            }
        };
        await this.runQueryNetworkOnly(SendQRCodesQuery, lambdaParams);
      }
}

const SendItemReport = `
query sendItemReport($input: SendItemReportInput!){
    generateAndSendReportDev(input: $input)
}
`

const SendQRCodesQuery = `
query sendQRCodes($input: QRCodePDFOptionsInput!){
    generateAndSendQRCodes(input: $input)
}`;