import { useAuth } from '../../context/AuthContext';
import PrivateRoute from '../../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import '../../styles/grid.css';
import moment from 'moment';
import Val from '../../models/Val';
import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT } from '../../constants/Constants';
import {EvaluationMark} from './EvaluationMark';
import _ from 'lodash';
import { FcDownload } from 'react-icons/fc';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Utils from '../../common/Utils';
import { FaStickyNote, FaRegFlag, FaExclamationTriangle, FaRegStickyNote, FaFlag, FaCamera, FaEdit, FaTrash} from 'react-icons/fa';
import {POINT_CONDITIONS} from '../../constants/Constants';
import ExpandedValAnalyticsRow from './ExpandedValAnalyticsRow';
import { toast } from 'react-toastify';
import { EditModal } from './GenericEditModal';
import Systems from "../../models/Systems";
import Property from "../../models/Property";
import { ButtonGroup, OverlayTrigger, Tooltip, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcInspection, FcTimeline, FcEngineering, FcCheckmark, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness, FcExport } from 'react-icons/fc';
import PropertyEditScreen from '../property/PropertyEdit';
import EquipmentEditScreen from '../equipment/EquipmentEdit';
import Toggle from 'react-toggle'
import { MdArrowBack } from 'react-icons/md';
import {useFilterContext} from '../../context/SharedFilterContext';
import {EquipmentEditModal} from './InspectionEquipmentHierarchy';
import ZoomImage from './ZoomImage';

import React, { useEffect, useState } from 'react';
import Equipment from "../../models/Equipment";
import ImageUploading from 'react-images-uploading';
import { ImSpinner3 } from 'react-icons/im';
import {extractBase64FromDataURL, heicConversion} from "../utils/utils";
import heic2any from "heic2any";



const DuplicationBanner = ({ onSaveNext, isQueueEmpty, loading }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    marginBottom: '10px'
  }}>
    <h4 style={{ margin: '0' }}>Duplication Process</h4>
    <button
      className="btn btn-light"
      onClick={onSaveNext}
      disabled={loading}
      style={{
        fontWeight: 'bold',
        color: '#007bff',
        borderColor: '#007bff'
      }}
    >
      { 'Duplicate'}
    </button>
  </div>
);


const PropertyTableDuplicate = (props) => {
  const history = useHistory();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const {setSelectedProperty, setSelectedRoutine} = useFilterContext();
  const {pathname} = useLocation();
  const showBackButton = pathname.includes('/table/');
  const { user } = useAuth();
  const {selectedEquipment, selectedProperty, onEditEquipment, goBackFn, setRefreshCounter} = props;
  const [fullPropertyHierarchy, setFullPropertyHierarchy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [systemIdLookup, setSystemIdLookup] = useState({});
  const [editPropertyModal, setEditPropertyModal] = useState({});
  // const [selectedEquipmentUUIDs, setSelectedEquipmentUUIDs] = useState([]);
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && selectedProperty && selectedProperty.uuid) ? [selectedProperty.uuid] : []
    )
  );
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [editEquipmentModal, setEditEquipmentModal] = useState({});
// Initialize checkedStatus as a Map
const [checkedStatus, setCheckedStatus] = useState(new Map());

const [systemsLoading, setSystemsLoading] = useState(true);



  const [currentEquipment, setCurrentEquipment] = useState(null);
  const [equipmentQueue, setEquipmentQueue] = useState([]);
  const [editedEquipment, setEditedEquipment] = useState([]);
  

  const updateCriticality = async (row) => {
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Criticality",
      value: row,
      selector: row => row.criticality || 0,
      type: "slider",
      min: 1,
      max: 5,
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        updateSingleEquipment({...row, criticality: newValue});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }
  


// const updateSingleEquipment = (updatedEquipment, current) => {
//   // Update currentEquipment if it is the one being edited

//     setCurrentEquipment(updatedEquipment);
  

//   // Also update the equipment in the queue if it exists
//   const queueIndex = equipmentQueue.findIndex(eq => eq.uuid === updatedEquipment.uuid);

//   if (queueIndex !== -1) {
//       const newQueue = [...equipmentQueue];
//       newQueue[queueIndex] = updatedEquipment;
//       setEquipmentQueue(newQueue);
//   }
// };
const updateSingleEquipment = (updatedEquipment) => {
  // Find the index of the equipment to update in the queue
  const index = equipmentQueue.findIndex(eq => eq.uuid === updatedEquipment.uuid);
  console.log("UpdatedEquipment: ", updatedEquipment);
  console.log("This is the equipmentQueue", equipmentQueue);
  console.log("Index", index);
  if (index !== -1) {
    // Create a new copy of the equipment queue
    const newEquipmentQueue = [...equipmentQueue];
    console.log("newEquipmentQueue", newEquipmentQueue);
    // Update the equipment at the found index
    newEquipmentQueue[index] = updatedEquipment;
    console.log("NEWER newEquipmentQueue", newEquipmentQueue);

    // Set the updated equipment queue
    setEquipmentQueue(newEquipmentQueue);
  }
};



  useEffect(() => {
    refreshPropertyInformation();
    
  }, [selectedPropertyUUIDs, (selectedEquipment || {}).uuid])


  useEffect(() => {
    updateUserSystems();
  }, []);


  useEffect(() => {
    console.log("This is the equipmentQueue in useEffect as it changes", equipmentQueue)
  }, [equipmentQueue]);

  
  const updateUserSystems = async () => {
    setSystemsLoading(true);
    try {
      const newSystemsDictionary = await Systems.getSystemsForCompanyId(user.company_id, 'id');
      setSystemIdLookup(newSystemsDictionary);
    } catch (error) {
      console.error('Failed to load systems:', error);
    } finally {
      setSystemsLoading(false);
    }
  };
  


  const refreshPropertyInformation = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0) {
      return;
    }
  
    setLoading(true);
    await updateUserSystems();
    const { data } = await Equipment.filterBy([{ value: selectedEquipment.uuid, field: 'uuid' }], [], 'updated_at', 'desc', 1);
  
    const foundEquipment = data.length > 0 ? data[0] : null;
    const allItems = [foundEquipment, ...(foundEquipment?.allSubcomponents || [])].map(item => {
      if (item) {
          const updatedItem = { ...item };
          if (item.name) {
              updatedItem.name = `${item.name} (1)`;  // Appends " (1)" to the name
          }
          // if (item.make) {
          //     updatedItem.make = "";  // Sets default value if `make` exists
          // }
          if (item.serial_num) {
              updatedItem.serial_num = "";  // Sets default value for `serial_num`
          }
          return updatedItem;
      }
      return item;
    });

      
    // New Code: Update checkedStatus with measurement_id from pointInfos
    const updatedCheckedStatus = new Map(checkedStatus);  // Assuming checkedStatus is already defined
    allItems.forEach(item => {
      if (item && item.pointInfos && item.pointInfos.length > 0) {
        const measurementIds = item.pointInfos.map(info => info.measurement_id.toString()).filter(id => id != null);
        updatedCheckedStatus.set(item.uuid, measurementIds);
      }
    });
  
    // Use the updated map to set the checked status
    setCheckedStatus(updatedCheckedStatus);
  
    setEquipmentQueue(allItems);  // Use slice to exclude the first item from the queue
    setCurrentEquipment(allItems[0]);
    setLoading(false);
  }

  const removeEquipment = (uuidToRemove) => {
    const filteredQueue = equipmentQueue.filter(equipment => equipment.uuid !== uuidToRemove);
    setEquipmentQueue(filteredQueue);
  };
  
  

  const saveChanges = (equipment) => {
    const updatedEditedEquipment = [...editedEquipment, equipment];
    setEditedEquipment(updatedEditedEquipment);

    const nextEquipment = equipmentQueue.shift();
    if (!nextEquipment) {
        // Make sure to pass the updated list explicitly to handle the last equipment.
        handleDuplicate(updatedEditedEquipment);
    } else {
        setCurrentEquipment(nextEquipment);
    }
};

const randInt = () => {
  return parseInt(`${Math.random()}`.split(".")[1].slice(0,9));
}

const handleDuplicate = async (equipmentList) => {
  setLoading(true); // Show loading indicatorß
  let rootKey = null; // Variable to store the root equipment key
// test
  try {
    // Prepare new equipment data
    const newEquipmentPromises = equipmentList.map((equipment, index) => {
      const isRoot = index === 0; // Check if it's the root equipment
      const newKey = randInt(); // Generate a new key for each duplicated equipment using randInt

      if (isRoot) {
        rootKey = newKey; // Set rootKey if it's the root equipment
      }

      const newEquipment = {
        area: equipment.area,
        building: equipment.building,
        criticality: equipment.criticality,
        external_uuid: equipment.external_uuid,
        floor: equipment.floor,
        make: equipment.make,
        model_num: equipment.model_num,
        notes: equipment.notes,
        parent_key: (isRoot && equipment.parent_key) ? equipment.parent_key : (isRoot ? null : rootKey), // Use rootKey as parent_key if not the root
        serial_num: equipment.serial_num,
        system_id: equipment.system_id,
        year: equipment.year,
        uuid: Utils.uuid(), // UUID for system tracking, different from key
        key: newKey, // Key generated from randInt
        property_id: selectedProperty.id,
        property_uuid: selectedProperty.uuid,
        name: equipment.name // Append "(Copy)" to the name to indicate duplication
      };

      return Equipment.create(newEquipment);
    });

    // Wait for all equipment to be created
    const createdEquipments = await Promise.all(newEquipmentPromises);

    // Enable new points and copy sheets concurrently
    const enablePointsAndCopySheetsPromises = createdEquipments.map(async (createdEquipment, index) => {
      const equipment = equipmentList[index];
      const currentMeasurements = checkedStatus.get(equipment.uuid) || [];
      const measurementsAndUUIDs = currentMeasurements.map(measurementId => ({
        uuid: Utils.uuid(), // Generate a new UUID for each new point
        measurement_id: measurementId
      }));

      if (measurementsAndUUIDs.length > 0) {
        await Equipment.enableNewPoints(createdEquipment.uuid, createdEquipment.parentFig ? createdEquipment.parentFig.uuid : createdEquipment.uuid, measurementsAndUUIDs);
      }

      if (equipment.sheets && equipment.sheets.length > 0) {
        const sheet_uuid = equipment.sheets[0].uuid; // Get UUID of the first sheet
        await Equipment.copySheet(createdEquipment.uuid, sheet_uuid, user.id);
      }
    });

    // Wait for all points to be enabled and sheets to be copied
    await Promise.all(enablePointsAndCopySheetsPromises);

    toast.success('All equipment has been duplicated and new points enabled successfully.', {
      autoClose: 3000  
    });
    goBackFn();
    setRefreshCounter(prevCounter => prevCounter + 1);

  } catch (error) {
    console.error('Error during duplication:', error);
    toast.error('Failed to duplicate equipment.');
    goBackFn();

  } finally {
    setLoading(false); // Hide loading indicator
  }
};

// const handleDuplicate = async (equipmentList) => {
//   setLoading(true); // Show loading indicator
//   let rootKey = null; // Variable to store the root equipment key

//   try {
//     // Map over each piece of equipment in the provided list and prepare new equipment data
//     for (const [index, equipment] of equipmentList.entries()) {
//       const isRoot = index === 0; // Check if it's the root equipment
//       const newKey = randInt(); // Generate a new key for each duplicated equipment using randInt

//       if (isRoot) {
//         rootKey = newKey; // Set rootKey if it's the root equipment
//       }

//       const newEquipment = {
//         area: equipment.area,
//         building: equipment.building,
//         criticality: equipment.criticality,
//         external_uuid: equipment.external_uuid,
//         floor: equipment.floor,
//         make: equipment.make,
//         model_num: equipment.model_num,
//         notes: equipment.notes,
//         parent_key: (isRoot && equipment.parent_key) ? equipment.parent_key : (isRoot ? null : rootKey), // Use rootKey as parent_key if not the root
//         serial_num: equipment.serial_num,
//         system_id: equipment.system_id,
//         year: equipment.year,
//         uuid: Utils.uuid(), // UUID for system tracking, different from key
//         key: newKey, // Key generated from randInt
//         property_id: selectedProperty.id,
//         property_uuid: selectedProperty.uuid,
//         name: equipment.name // Append "(Copy)" to the name to indicate duplication
//       };

//       // Create the equipment
//       const createdEquipment = await Equipment.create(newEquipment);
    
//      // Check if the current equipment UUID is in checkedStatus and retrieve the measurements if present
//      const currentMeasurements = checkedStatus.get(equipment.uuid) || [];
//      const measurementsAndUUIDs = currentMeasurements.map(measurementId => ({
//        uuid: Utils.uuid(), // Generate a new UUID for each new point
//        measurement_id: measurementId
//      }));

//      // Enable new points if any measurements were toggled
//      if (measurementsAndUUIDs.length > 0) {
//        await Equipment.enableNewPoints(createdEquipment.uuid, createdEquipment.parentFig ? createdEquipment.parentFig.uuid : createdEquipment.uuid, measurementsAndUUIDs);
//      }
//        // Copy sheet over too if the template equipment has a sheet
//        if (equipment.sheets && equipment.sheets.length > 0) {
//         const sheet_uuid = equipment.sheets[0].uuid; // Get UUID of the first sheet
//         await Equipment.copySheet(createdEquipment.uuid, sheet_uuid, user.id);
//       }
   
//     }

//     toast.success('All equipment has been duplicated and new points enabled successfully.', {
//       autoClose: 3000  
//     });
//         goBackFn();
//         setRefreshCounter(prevCounter => prevCounter + 1);

//   } catch (error) {
//     console.error('Error during duplication:', error);
//     toast.error('Failed to duplicate equipment.');
//     goBackFn();

//   } finally {
//     setLoading(false); // Hide loading indicator
//   }
// };





  const ExpandedRowComponent = (props) => {
    const expandedRow = props.data;
    const { checkedStatus, setCheckedStatus, equipmentUUID } = props;
    const [detailedEquipment, setDetailedEquipment] = useState({});
    const [detailedParentEquipment, setDetailedParentEquipment] = useState({});
    const [loading, setLoading] = useState(true);
    const [image_URL, setImage_URL] = useState(null);
    const [image_File, setImage_File] = useState(null);
  

    const loadEquipmentForEdit = async () => {
      const fig = await Equipment.getEquipmentToEdit(expandedRow.uuid)
      setDetailedEquipment(_.first(fig.data));
      let response = _.first(fig.data);
      if(expandedRow.parent_key){
        const parent_fig = await Equipment.getEquipmentToEditByKey(expandedRow.parent_key)
        setDetailedParentEquipment(_.first(parent_fig.data));
      }
      setImage_URL(null);
    setImage_File(null);
      setLoading(false)
    }


    useEffect(loadEquipmentForEdit, [])


    // Toggle handling inside `ExpandedRowComponent`
    const handleToggle = (equipmentUuid, measurementId) => {
      const updatedStatus = new Map(checkedStatus);
      const currentMeasurements = updatedStatus.get(equipmentUuid) || [];
    
      if (currentMeasurements.includes(measurementId)) {
        updatedStatus.set(equipmentUuid, currentMeasurements.filter(id => id !== measurementId));
      } else {
        updatedStatus.set(equipmentUuid, [...currentMeasurements, measurementId]);
      }
    
      setCheckedStatus(updatedStatus);
    };
    
    


    const generateData = () => {
      return !(detailedEquipment && detailedEquipment.system && detailedEquipment.system.measurements) ? [] : _.orderBy(detailedEquipment.system.measurements.map(x => {
        if (!x.measurement || x.measurement.name.length < 3){
          return null;
        }
        return {
          measurement: x.measurement,
          point: _.first((detailedEquipment.pointInfos || []).filter(y => x.measurement && y.measurement_id == x.measurement.id))
        }
      }).filter(x => x != null), [
        row => !!row.point,
        row => row.measurement && row.measurement.name
      ], ['desc', 'asc'])
    }
   
    return (
      
      <>
      <div className="w-100 d-flex flex-row justify-content-start align-items-top flex-wrap ml-5"> 
           
        
      <div class="d-flex flex-row justify-content-start">
        <div style={{width:"800px"}}>
        <DataTable
          columns={[
           
           
            {
              name: 'Point Name',
              selector: row => row.measurement && row.measurement.name,
              sortable: true,
              center: true,
              compact: true,
              wrap: true, 
              grow: 2, 
            },
        
            {
              name: 'Point Number',
              selector: row => row.point && row.point.num,
              cell: row => {

                return (<>
                   
                  <Toggle
                    checked={checkedStatus.get(equipmentUUID)?.includes(row.measurement.id)}
                    onChange={() => handleToggle(equipmentUUID, row.measurement.id)}
                  />
                </>
                )
              },
              sortable: true,
              center: true,
              compact: true
            },
          ].filter(x => x)}
          data={generateData()}
          noDataComponent = {"Please select a SYSTEM for the Equipment/Component. SYSTEMS will then define what INSPECTION POINTS can be selected."}
          responsive={true}
          dense
          persistTableHead
          noHeader
          highlightOnHover
          defaultSortAsc={false}
          disabled={loading}
              />
              
        </div>

        </div>
      </div>
      </>
    );
  }




  const columns = React.useMemo(() => 
    [
      {
        name: 'Remove',
        button: true,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: row => (
          equipmentQueue.length > 1 && equipmentQueue[0].uuid !== row.uuid ? (
            <button
              onClick={() => removeEquipment(row.uuid)}
              style={{ border: 'none', background: 'none' }}
              aria-label="Delete equipment"
            >
              <FaTrash color="red" />
            </button>
          ) : null
        ),
      },
     
      {
        name: `Name`,
        selector: row => row.name,
        cell: row => <a className={``} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Name",
              value: row,
              selector: row => row.name || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                console.log("This is whats submit", {...row, name: newValue});
                updateSingleEquipment({...row, name: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            <div className="pl-2">{row.name}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
        width: '14em'
      },
      
      {
        name: `Criticality`,
        selector: row => row.criticality,
        cell: row => <a className={`link`} onClick={async (e) => updateCriticality(row, e)}>{row.criticality}</a>,
        sortable: true,
        center: true,
        compact: true,
        width: '8em'
      },
      {
        name: `Make`,
        selector: row => row.make,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Make",
              value: row,
              selector: row => row.make || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                updateSingleEquipment({...row, make: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.make || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Model`,
        selector: row => row.model_num,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Model Number",
              value: row,
              selector: row => row.model_num || "",
              type: "text",
              fp: row.faceplate_image, 
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                updateSingleEquipment({...row, model_num: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            <div >{row.model_num || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Serial`,
        selector: row => row.serial_num,
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Serial Number",
              value: row,
              selector: row => row.serial_num || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                updateSingleEquipment({...row, serial_num: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            <div >{row.serial_num || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
      {
        name: `Year`,
        selector: row => row.year || "",
        cell: row => <a className={`link`} onClick={async (e) => {
          
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Year",
              value: row,
              selector: row => row.year || "",
              type: "number",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                updateSingleEquipment({...row, year: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            <div >{row.year || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
      },
 
      {
        name: `System`,
        selector: row => row.system_id in systemIdLookup ? systemIdLookup[row.system_id].name : "Loading...",
        cell: row => {
          const name = row.system_id in systemIdLookup ? systemIdLookup[row.system_id].name : "Loading...";
          return <span>{name}</span>;
        },
        sortable: true,
        center: true,
        compact: true,
        width: '15em',
        omit: systemsLoading,  // Omit this column if systems are still loading

      },
    
      {
        name: 'Notes',
        selector: row => row.notes,
        cell: row => <a className={`link`} onClick={async (e) => {
            setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
            setEditModal({
              title: "Update Note",
              value: row,
              selector: row => row.notes || "",
              type: "text",
              onHide: () => {
                setEditModal({})
                setLoadingRowUUIDs([]);
              },
              onSubmit: async (e, row, newValue) => {
                e.preventDefault();
                updateSingleEquipment({...row, notes: newValue});
                setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
              }
            });
          }}>
          <div className="d-flex flex-row text-left">
            {/* <FcDocument style={{fontSize: 24}}/> */}
            <div >{row.notes || "__"}</div>
          </div>
        </a>,
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
      },
    
    ].filter(x => x!=null), [selectedPropertyUUIDs, JSON.stringify(fullPropertyHierarchy), systemIdLookup, systemsLoading, equipmentQueue]
  );
  
 
  return (
    <>
      <EquipmentEditModal options={editEquipmentModal}/>
      <div className="d-flex flex-row justify-content-start align-items-start">
          <EditModal options={editModal}/>
          <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
            style={{minWidth: '800px'}}
          >
            {showBackButton && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                    setSelectedRoutine(null)
                    setSelectedProperty(null)
                    history.push("/properties")
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Property</h4>
                </div>
            </div>}
            <DuplicationBanner
          onSaveNext={() => handleDuplicate(equipmentQueue)}
          //isQueueEmpty={equipmentQueue.length === 0}
          loading={loading}
        />

            <div className="d-flex flex-column justify-content-start align-items-top flex-wrap ml-4"> 
              {/* {currentEquipment ? <DataTable
                title="MSi Inspections and Tasks"
                keyField="uuid"
                columns={columns}
                data={[currentEquipment]}
                expandableRows
                expandableRowExpanded={row => showBackButton ? row.defaultExpanded=false : row.defaultExpanded=true}
                noDataComponent = {"Please select a SYSTEM for the Equipment/Component. SYSTEMS will then define what INSPECTION POINTS can be selected."}
                responsive={true}
                dense
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRowsComponent={(props) => <ExpandedRowComponent {...props} checkedStatus={checkedStatus} setCheckedStatus={setCheckedStatus} equipmentUUID = {currentEquipment.uuid} />}
                selectableRows={false}
                disabled={loadingRowUUIDs.length > 0}
              /> : (
                <p>Loading equipment data...</p>
              )} */}
            {equipmentQueue.map((equipment, index) => (
                    <DataTable
                title="MSi Inspections and Tasks"
                keyField="uuid"
                columns={columns}
                data={[equipment]}
                expandableRows
                expandableRowExpanded={false}
                noDataComponent = {"Please select a SYSTEM for the Equipment/Component. SYSTEMS will then define what INSPECTION POINTS can be selected."}
                responsive={true}
                dense
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRowsComponent={(props) => <ExpandedRowComponent {...props} checkedStatus={checkedStatus} setCheckedStatus={setCheckedStatus} equipmentUUID = {equipment.uuid} />}
                selectableRows={false}
                disabled={loadingRowUUIDs.length > 0}
              />
                  ))}


            </div>
          </div>
      </div>
  </>
  )
}

export default PropertyTableDuplicate;