import Model from "./Model";

export default class UserCompanyGroup extends Model{
    static create = async(userCompanyGroupInput) => {
        const res = await this.runMutation(UserCompanyGroupCreate, {input: userCompanyGroupInput});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ UserCompanyGroup.create.runMutation [COMPLETE]: ${JSON.stringify(res)}`);
        return res.data.userCompanyGroupAdd;
    }
}

const UserCompanyGroupCreate = `
    mutation userCompanyGroupAdd($input: UserCompanyGroupUpdateInput){
      updateUserCompanyGroups(input: $input)
    }
`;
