import Model from "./Model";

export default class TaskingSheet extends Model{
    static all = async() => {
        const res = await this.runQueryNetworkOnly(AllSheets, {});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ TaskingSheets.AllSheets.runQuery [COMPLETE]`);
        return res.data.allSheets.sort((a,b) => a.name.localeCompare(b.name));
    }

    static fullSheet = async(uuid) => {
        const res = await this.runQueryNetworkOnly(oneSheet, {uuid});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ TaskingSheets.OneSheet.runQuery [COMPLETE]`);
        return res.data.sheet
    }

    static update = async(fields) => {
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update sheet - no uuid specified")
            return
        }
        const newSheet = {input: {
            uuid: fields.uuid,
            global: fields.global,
            default: fields.default
        }}
        console.log({newSheet})
        const res = await this.runQueryNetworkOnly(UpdateSheet, newSheet);
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.UpdateSheet.runQuery [COMPLETE]`);
        console.log("res", res)
        return res.updateSheet;
    }

}

const UpdateSheet = `
mutation updateSheet($input: UpdateSheetInput){
    updateSheet(input: $input){
        id
        uuid
        created_at
        global
        company_id
        property_uuid
        user_id
        system_id
        system_type_id
        name
        default
        system{
          name
        }
        user{
            full_name
            uuid
        }
    }
  }
`

const oneSheet = `
query oneSheet($uuid: ID!){
    sheet(uuid: $uuid){
      id
      uuid
      created_at
      global
      company_id
      property_uuid
      user_id
      system_id
      system_type_id
      name
      default
      company{
        title
        id
      }
      system{
        name
      }
      user{
        full_name
        uuid
      }
      tasks {
        uuid
        created_at
        name
        sheet_uuid
        task_type_id
        type_code
        section_uuid
        sort_idx
      }
      sections{
        id
        uuid
        created_at
        sheet_uuid
        name
        sort_idx
        tasks {
          uuid
          created_at
          name
          sheet_uuid
          task_type_id
          type_code
          section_uuid
          sort_idx
        }
      }
    }
  }
`;

const AllSheets = `query allSheets {
    allSheets{
      id
      uuid
      created_at
      global
      company_id
      property_uuid
      user_id
      tasks {
        uuid
        created_at
        name
        sheet_uuid
        task_type_id
        type_code
        section_uuid
        sort_idx
      }
      system_id
      system_type_id
      name
      default
      company{
        title
        id
      }
      system{
        name
      }
      user{
          full_name
          uuid
      }
    }
  }
`;