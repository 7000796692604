import {useAuth} from '../context/AuthContext';
import {
    Route,
    Redirect,
  } from "react-router-dom";

function PrivateRoute({ children, path, ...rest }) {
    console.log(children, path, rest)
    let auth = useAuth();
    // const intendedPath = rest.location.pathname || "/";

    // localStorage['originalIntendedPage'] = intendedPath || '/';
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/auth",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default PrivateRoute;