import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { ImSpinner3 } from 'react-icons/im';
import Val from '../../models/Val';
import {EvaluationMark} from './EvaluationMark';
import Utils from '../../common/Utils'; 
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD } from '../../constants/Constants';
import { FaStickyNote, FaRegFlag, FaFileDownload, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ExpandedEvaluationRow = ({ data }) => {
  const [vals, setVals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVals = async () => {
      setLoading(true);
      try {
        const allVals = await Val.filterBy([
          {field: 'archived', value: 0, comparison: "="},
          { field: 'fig_id', value: data.fig_id, comparison: '=' }
        ].filter(x => x!= null), "recorded_at", "desc", 10000, undefined, undefined, undefined, true);
        const filteredVals = allVals.data.filter(val => moment(val.recorded_at).isBefore(moment(data.created_at)));
        setVals(filteredVals);
        // setVals(allVals.data);
      } catch (error) {
        console.error("Error fetching vals:", error);
      }
      setLoading(false);
    };

    fetchVals();
  }, [data.fig_id]);


  const extractSoundSignatures = row => [
    (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
  row.accelse ? "Accelerating SE" : null,
    ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)).map(x => x.signature.name) : [])
  ].filter(x => x != null).join(',')


  const renderSoundSignatures = row => {
    const filteredSignatures = [
       // (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
      row.accelse ? "Accelerating SE" : null,
        ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)).map(x => x.signature.name) : [])
    ].filter(x => x != null);
    
    return filteredSignatures.join(' ');
};

const extractActions = row => {
  const filteredActions = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && x.action.name).filter(x => x != null)
  return filteredActions.join(',')
};

const renderActions = row => {
  const filteredActionNames = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && `${x.action.name}`).filter(x => x != null)
  return (
    <div className="d-flex flex-row justify-content-center flex-wrap">
      {filteredActionNames.map(x => <div className="text-nowrap rounded-sm bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div>)}
    </div>
  )
};

const extractTags = row => [
  !Utils.isBlank(row.reviewed_at) ? "reviewed" : (row.warningMode ? "review-required" : null),
  Val.isFlagged(row)
      ? (
        Val.isFlaggedImplicitly(row) ? "system-flagged-high-evaluation-or-has-notes": "flagged"
      ) : null,
  row.secondary_noise ? "secondary-noise" : null,      
].filter(x => x !== null).join(',')

const renderTags = row => {
  const filteredTags = [
    !Utils.isBlank(row.reviewed_at) ? // Show green exclamation if reviewed
      <FaExclamationTriangle style={{color: "#009900", fontSize: 30}} className="p-1" onClick={() => alert("Already Reviewed")}/>
    : row.warningMode && // Not reviewed and in warning mode, show yellow review required icon
    <img src="/icons/red-stop-sign.svg" style={{width: "30px", height: "30px"}} className="p-1 reviewRequiredIcon" onClick={() => markAsReviewed(row)}/>
    , Val.isFlagged(row)
      ? (
        Val.isFlaggedImplicitly(row) 
        ? <FaFlag role="button" className="p-1" style={{color: 'rgb(217, 72, 47)', fontSize: 28}} onClick={() => 
          toast.info("System flagged due to the high Evaluation or existence of field notes/recommendations. Modify those to resolve this flag.")
        }/>
        : <FaFlag role="button" className="p-1" style={{color: 'black', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
      )
      : <FaRegFlag role="button" className="p-1" style={{color: 'gray', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
    , row.secondary_noise
      ? <RiVolumeVibrateFill role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'black', fontSize: 32}}/>
      : <RiVolumeVibrateLine role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'gray', fontSize: 32}}/>,
    
  ].filter(x => x != false)

  return (
    <div className="d-flex flex-row justify-content-center flex-wrap ml-1 mt-1 mb-1">
      {filteredTags.map(x => _.isString(x) ? <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div> : (x || ''))}
    </div>
  )
}

const extractFieldNotes = row => row.desc || "___";
const renderFieldNotes = row => Utils.isBlank(row.desc)
? <FaRegStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)}/>
: <OverlayTrigger
  placement="bottom"
  overlay={<Tooltip id="button-tooltip">{row.desc}</Tooltip>}>
    <FaStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)} style={{fontSize: 16}}/>
  </OverlayTrigger>;
  
const extractRecommendations = row => row.rec || "___";
const renderRecommendations = row => Utils.isBlank(row.rec)
? <FaRegStickyNote role="button" data-tag="allowRowEvents" />
: <OverlayTrigger
  placement="bottom"
  overlay={<Tooltip id="button-tooltip">{row.rec}</Tooltip>}>
    <FaStickyNote role="button" data-tag="allowRowEvents" />
  </OverlayTrigger>

  


  const columns = [
    {
      name: 'Point Name',
      selector: row => row.point_info.measurement_name,
      sortable: true,
      center: true,
      compact: true,
      width: '250px'    },
    {
      name: 'Sound Energy',
      selector: row => row.v,
      sortable: true,
      center: true,
      compact: true,
      width: '150px'      
    },
    {
      name: 'SE%',
      selector: row => `${Math.round(row.plr)}%`,
      sortable: true,
      center: true,
      compact: true,
      width: '70px'    },
    {
      name: 'Inspection Date',
      selector: row => moment(row.recorded_at).format('M-D-YY HH:mm'),
      sortable: true,
      center: true,
      compact: true,
      width: '150px'    },
    {
      name: 'Upload Date',
      selector: row => moment(row.created_at).format('M-D-YY'),
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'Evaluation',
      selector: row => row.sig,
      cell: row => <EvaluationMark criticality={row.sig} />,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'Sound Evaluation',
      selector: row => row.aiSig,
      cell: row => <EvaluationMark criticality={row.aiSig} />,
      sortable: true,
      center: true,
      compact: true,
      width: '150px'
    },
    {
      name: 'SoundSignatures™',
      selector: extractSoundSignatures,
      cell: row => <div className="d-flex flex-row justify-content-center">{renderSoundSignatures(row)}</div>,
      sortable: true,
      center: true,
      compact: true,
      width: '150px'

    },
    {
      name: 'Tags',
      selector: extractTags,
      cell: renderTags,
      sortable: true,
      compact: true,
      center: true,
      width: '20em'
    },

    {
      name: 'Actions',
      selector: extractActions,
      cell: renderActions,
      sortable: true,
      center: true,
      compact: true,
      width: '200px'

    }, 
    {
      name: 'Field Notes',
      selector: extractFieldNotes,
      cell: renderFieldNotes,
      sortable: true,
      center: true,
      compact: true,
      width: '150px'
    }, 
    {
      name: 'Recommendations',
      selector: extractRecommendations,
      cell: renderRecommendations,
      sortable: true,
      center: true,
      compact: true,
      width: '150px'
    }
  ];

  if (loading) {
    return <ImSpinner3 className="icon-spin" style={{ fontSize: 14 }} />;
  }

  // return (
  //   <DataTable
  //     columns={columns}
  //     data={vals}
  //     noHeader
  //     dense
  //   />
  // );

  return (
    <DataTable
      columns={columns}
      data={vals}
      noHeader
      dense
      customStyles={{
        headCells: {
          style: {
            fontWeight: 'bold',
            fontSize: '12px',
            backgroundColor: '#f8f9fa',
            color: '#343a40',
            borderBottom: '2px solid #dee2e6',
          },
        },
        cells: {
          style: {
            fontSize: '12px',
            padding: '0.5rem',
            borderBottom: '1px solid #dee2e6',
          },
        },
        rows: {
          style: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#f2f2f2',
            },
          },
        },
      }}
    />
  );
};

export default ExpandedEvaluationRow;