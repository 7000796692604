import { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch
} from "react-router-dom";
import { FcInspection, FcTimeline, FcLibrary, FcBusinesswoman, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcDataSheet, FcCheckmark, FcPackage, FcCableRelease, FcSettings } from 'react-icons/fc';
import {GrGroup, GrUser} from 'react-icons/gr';
import {HiOutlineOfficeBuilding} from 'react-icons/hi';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import User from '../../models/User';
import Utils from '../../common/Utils';
import {useFilterContext} from '../../context/SharedFilterContext';
import { BsBuilding } from 'react-icons/bs';

// let selectedCompanyGroup = {};
export const NavSidebar = () => {
    const iconSize = 32;
    const {
      user,
      isSuperAdmin,
      isCustomerTechOrHigher,
      isLimitedAdminOrHigher,
      isAdminOrHigher
    } = useAuth();
    const {
      selectedProperty,
    } = useFilterContext();
    const [selectedCompanyGroup, setSelectedCompanyGroup] = useState({});
    const [selectedCompany, setSelectedCompany] = useState({});
    let { path, url } = useRouteMatch();

    useEffect(() => {
        filterPropertyUUIDs();

        return () => {
            
        }
    }, [selectedCompanyGroup, selectedCompany])
  
    const filterPropertyUUIDs = async () => {
        // let propertiesInCompanyGroup = {data: []};
        // console.log("selectedCompanyGroup", selectedCompanyGroup);
        // if (selectedCompanyGroup && selectedCompanyGroup.uuid){
        //     propertiesInCompanyGroup = await PropertyCompanyGroup.filterBy([
        //         {field: "company_group_uuid", value: selectedCompanyGroup.uuid, comparison: "="}
        //     ], "created_at", "desc", 200);
        // }

        // console.log("propertiesInCompanyGroup", propertiesInCompanyGroup);

        // const propertiesInCompany = await Property.filterBy([
        //     {field: "company_id", value: user.company.id, comparison: "="},
        // ], "created_at", "desc", 200);

        // console.log("propertiesInCompany", propertiesInCompany.data);
        // const merged = [...propertiesInCompanyGroup.data, ...propertiesInCompany.data];
        // console.log({merged});
        // console.log(merged.map(x => x.uuid));
    }

    return (
      <div className="d-flex flex-column rounded-lg bg-white m-2" style={{position: "sticky", top: 0, maxWidth: '15em'}}>
          {user && <div className="d-flex flex-row m-2 mb-0 mt-3 text-left">
              <BsBuilding style={{fontSize: iconSize, minWidth: 16}}/>
              <div className="dropdown d-none d-sm-none d-md-none d-lg-inline">
                <Dropdown>
                  <Dropdown.Toggle variant="outline" id="dropdown-basic">
                    {selectedCompanyGroup.title || "Company Group"}
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu>
                    {User.allCompanyGroups(user).map(cg => {
                      return  <Dropdown.Item key={cg?.title} onClick={(e) => {
                        if (isAdminOrHigher()) {
                          setSelectedCompanyGroup(cg);
                        } else {
                          e.preventDefault();
                        }
                      }}>{cg?.title}</Dropdown.Item>
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
          </div>}
  
          {user && <div className="d-flex flex-row m-2 mb-0 text-left">
              {/* <GrGroup style={{fontSize: iconSize, minWidth: 16}}/>
              <p className="pl-3 d-none d-sm-none d-md-none  d-lg-block">{}</p> */}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">{user.company && user.company.title}</Tooltip>}>
                  <>
                  <GrGroup style={{fontSize: iconSize, minWidth: 16}}/>
                  <p className="pl-3 d-none text-truncate d-sm-none d-md-none d-lg-block">{user.company && user.company.title}</p>
                  </>
              </OverlayTrigger>
          </div>}

          {user && selectedProperty && <div className="d-flex flex-row m-2 mb-0 text-left">
                <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">{selectedProperty && selectedProperty.name}</Tooltip>}>
                  <>
                  <HiOutlineOfficeBuilding style={{fontSize: iconSize, minWidth: 30}}/>
                  <p className="pl-3 d-none text-truncate d-sm-none d-md-none d-lg-block">{selectedProperty && selectedProperty.name}</p>
                  </>
                </OverlayTrigger>
            </div>
          
          }
  
          {user && <div className="d-flex flex-row m-2 text-left">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">{user.full_name}</Tooltip>}>
                  <>
                  <GrUser style={{fontSize: iconSize, minWidth: 16}}/>
                  <p className="pl-3 d-none text-truncate d-sm-none d-md-none d-lg-block">{user.full_name}</p>
                  </>
              </OverlayTrigger>
          </div>}
  
          <div style={{backgroundColor: 'black', height: '1px', width: '80%', marginLeft: '3%', marginBottom: '1em' }}></div>
  
          {isCustomerTechOrHigher() && 
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">View the latest task and DST inspections.</Tooltip>}
            >
              <Link className="btn" to={{pathname: `/inspections/`}} >
              <div className="d-flex flex-row text-left">
                  <FcInspection style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none d-lg-block ${url.includes('/inspections') ? 'text-primary' : ''}`}>Inspections</span>
                </div>
              </Link>
            </OverlayTrigger>}
  
          {/* {isCustomerTechOrHigher() && 
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">View the latest SoundSignature™ results for any inspection.</Tooltip>}
            >
              <Link className="btn" to={`/analytics`}>
              <div className="d-flex flex-row text-left">
                  <FcElectricalSensor style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/analytics') ? 'text-primary' : ''}`}>Analysis</span>
                </div>
              </Link>
            </OverlayTrigger>} */}
  
          {isCustomerTechOrHigher() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">See configuration details and information about any Property, Equipment, or Component.</Tooltip>}
            >
              <Link className="btn" to={{pathname: `/properties/`, state: {destinationPage: `/property/edit/`, showMultiSelect: false}}}>
              <div className="d-flex flex-row text-left">
                  <FcFactoryBreakdown style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/properties') ? 'text-primary' : ''}`}>Property (Edit)</span>
                </div>
              </Link>
            </OverlayTrigger>}
  
          {isCustomerTechOrHigher() && 
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Generate PDF reports detailing historical data about properties, inspections, and configuration information.</Tooltip>}
            >
              <Link className="btn" to={`/reports`}>
              <div className="d-flex flex-row text-left">
                  <FcDocument style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/reports') ? 'text-primary' : ''}`}>Reports</span>
                </div>
              </Link>
            </OverlayTrigger>}


            {isCustomerTechOrHigher() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Admin Page</Tooltip>}
            >
              <Link className="btn" to={`/migration`}>
              <div className="d-flex flex-row text-left">
                  <FcBusinesswoman style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/properties') ? '' : ''}`}>Admin Page</span>
                </div>
              </Link>
            </OverlayTrigger>}

            <div style={{backgroundColor: 'black', height: '1px', width: '80%', marginLeft: '3%', marginBottom: '1em' }}></div>

  
          {/* {isCustomerTechOrHigher() && 
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Show event history for you or other users.</Tooltip>}
            >
              <a className="btn" to={`/history`}>
              <div className="d-flex flex-row text-left">
                  <FcTimeline style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className="pl-3 d-none d-sm-none text-muted d-md-none color-gray d-lg-block"><del>History</del></span>
                </div>
              </a>
            </OverlayTrigger>
          } */}
          
          {/* {isLimitedAdminOrHigher() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Manage user permissions, passwords, personal details, etc.</Tooltip>}
            >
              <Link className="btn" to={`/users`}>
              <div className="d-flex flex-row text-left">
                  <FcBusinesswoman style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/users') ? 'text-primary' : ''}`}>Users</span>
                </div>
              </Link>
            </OverlayTrigger>} */}

          {isSuperAdmin() || isAdminOrHigher() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Manage Tasking Sheets for your company.</Tooltip>}
            >
              <Link className="btn" to={`/tasking-sheets`}>
              <div className="d-flex flex-row text-left">
                  <FcCheckmark style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none d-lg-block ${url.includes('/tasking-sheets') ? 'text-primary' : ''}`}>Tasking Sheets</span>
                </div>
              </Link>
            </OverlayTrigger>}
          {/* {
          
          isAdminOrHigher() && 
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Moving to the DST Platform? Import your properties and equipment in bulk to save you time.</Tooltip>}
            >
              <Link className="btn" to={`/import`}>
              <div className="d-flex flex-row text-left">
                  <FcImport style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className={`pl-3 d-none d-sm-none d-md-none  d-lg-block ${url.includes('/import') ? 'text-primary' : ''}`}>Importer</span>
                </div>
              </Link>
            </OverlayTrigger>} */}
  
          {isAdminOrHigher() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">The DST Machine Learning Dataset interface.</Tooltip>}
            >
              <a className="btn" href={`https://dst:soundenergy@dstmatrix.com/`}>
              <div className="d-flex flex-row text-left">
                  <FcServices style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className="pl-3 d-none d-sm-none d-md-none  d-lg-block">DST Matrix</span>
                </div>
              </a>
            </OverlayTrigger>}
  
          {isSuperAdmin() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Gain business insights by running queries against DST data.</Tooltip>}
            >
              <a className="btn" href={`https://metabase.dstadmin.com/`}>
              <div className="d-flex flex-row text-left">
                  <FcStatistics style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className="pl-3 d-none d-sm-none d-md-none  d-lg-block">Metabase</span>
                </div>
              </a>
            </OverlayTrigger>}

          {isSuperAdmin() &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 1000, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">The prior version of the DST Platform.</Tooltip>}
            >
              <a className="btn" href={`https://${user.company_name || 'dst'}.dsoundtech.com/`}>
              <div className="d-flex flex-row text-left">
                  <FcLibrary style={{fontSize: iconSize, minWidth: 16}}/>
                  <span className="pl-3 d-none d-sm-none d-md-none  d-lg-block">Original Platform</span>
                </div>
              </a>
            </OverlayTrigger>}





        </div>
    )
  }