// Snippet based on https://github.com/hswolff/BetterWeather/blob/master/js/weather/WeatherGraph.js
import React, { useContext, useEffect, useState } from 'react';
import { createTimeSeriesLine } from '../utils/D3Util';
import Utils from '../../common/Utils';
import Val from '../../models/Val'
import * as d3 from "d3";

const isVisibleOnChart = (reading) => {
    return !Val.isCheck(reading) &&
        !Val.isSecondaryNoise(reading);
}

const calcVisibleInspections = (point) => {
    if (point && point.valHistory) {
        return point.valHistory.filter(x => isVisibleOnChart(x))
    }
    return [];
}
const PointGraph = ({ point, accent, instanceNumber = 0, width = 400, height = 200 }) => {
    point = point || {};
    console.log("PointGraph point", point);
    const stackedData = point.measurementStats;
    const data = calcVisibleInspections(point);
    const surfaceDimensions = 400; //Dimensions.get('window').width;
    const chartDOMID = `chart-${point.uuid}-${instanceNumber}`

    console.log({ data, stackedData })
    if (Utils.isBlank(data) || data.length == 0) {
        return null;
    }

    const dInfo = createTimeSeriesLine({
        data,
        accent,
        stackedData,
        width: surfaceDimensions,
        height: (surfaceDimensions / 2),
        pointColorFn: datum => Utils.calcSoundSigColor(datum.sig),
        dateFn: datum => Utils.timeDateStrToDate(datum.recorded_at),
        isTriangleFn: datum => datum.warningMode,
    });

    console.log({ dInfo })

    const { path, symbol, triangle, star, circles, stacked, accents, redArea, yellowArea, triangles, greenLine, yellowLine, redLine } = dInfo;

    useEffect(() => {
        drawChart()
    }, [])

    const drawChart = () => {
        const svg = d3.select(`#${chartDOMID}`)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            // .attr("color", '#33CB24')
            // .style("margin-left", 100);
        svg.append("rect")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("fill", '#33CB24');

        const group = svg.append('g');

        group.append('path')
            .attr('d', yellowArea)
            .attr('stokeWidth', 1)
            .attr('stroke', '#FFD700')
            .attr('fill', '#FFD700')

        group.append('path')
            .attr('d', redArea)
            .attr('stokeWidth', 1)
            .attr('fill', 'red')

        group.append('path')
            .attr('d', greenLine)
            .attr('stokeWidth', 1)
            .attr('stroke', 'black')
            .attr('fill', 'none')

        group.append('path')
            .attr('d', yellowLine)
            .attr('stokeWidth', 1)
            .attr('stroke', 'black')
            .attr('stroke-dasharray', '5,5')
            .attr('fill', 'none')

        group.append('path')
            .attr('d', redLine)
            .attr('stokeWidth', 1)
            .attr('stroke', 'black')
            .attr('stroke-dasharray', '5,5')
            .attr('fill', 'none')

        group.selectAll('circle')
            .data(circles)
            .enter()
            .append('path')
            .attr('d', symbol)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333333')
            .attr('fill', (d, i) => `${d[2]}`)
            .attr("transform", (d, i) => `translate(${d[0]}, ${d[1]})`)

        group.selectAll('triangle')
            .data(triangles)
            .enter()
            .append('path')
            .attr('d', triangle)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333333')
            .attr('fill', '#FFD700')
            .attr("transform", (d, i) => `translate(${d[0]}, ${d[1]})`)

        group.selectAll('star')
            .data(accents)
            .enter()
            .append('path')
            .attr('d', star)
            .attr('opacity', 1)
            .attr('strokeWidth', 1)
            .attr('stroke', '#333')
            .attr('fill', '#333')
            .attr("transform", (d, i) => `translate(${d[0]}, ${d[1]})`)


        group.append('path')
            .attr('d', path)
            .attr('stokeWidth', 1)
            .attr('stroke', '#666666')
            .attr('strokeDash', [3, 2])
            .attr('fill', 'transparent')
        svg.append(() => group.node());
    }

    return (<div id={chartDOMID}></div>)
}

export default PointGraph;