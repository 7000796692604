// StatusBar.js
import React from 'react';

const StatusBar = ({ title, count, color = '#AABBCC' }) => {
  return (
    <div className="status-bar-wrapper" style={{ display: 'inline-block', marginRight: '10px', verticalAlign: 'top' }}>
      <div 
        style={{ 
          height: "30px", 
          width: '120px',  // or adjust as needed
          backgroundColor: color, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderRadius: '5px',
          padding: '0 10px'
        }}
      >
        <span style={{ fontSize: 'medium' }}>{title}</span>
        <span style={{ fontSize: 'medium', color: 'black' }}>{count}</span>
      </div>
    </div>
  );
};

export default StatusBar;
