import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import React, { useState, useEffect, useContext } from 'react';
import {STATUS_COLORS, NOD_SE_VALUE, TASK_TYPES} from '../constants/Constants';

export default class Utils {

    static get ZONE_CODE_CT()   {return 'US/Central'}
	static get ZONE_CODE_UTC()	{return "UTC"}
    static get UTC_TZ()	        {return "UTC"}
    
    static distinct(a){
        return Array.from(new Set(a))
    }

    static truncate = (input, length=30) => input.length > length ? `${input.substring(0, length)}...` : input;

    static uniq(a){
        return this.distinct(a)
    }

    static timeDateStrToDate(timeDateStr){
        return moment.tz(timeDateStr, Utils.UTC_TZ).toDate();
    }

    static buf2hex (arrayBuffer) {
        return Array.prototype.map.call(new Uint8Array(arrayBuffer), x => ('00' + x.toString(16)).slice(-2)).join('');
    }

    static wait(x, ms){
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(x), ms);
        });
    }

    static utcToTargetTz(dt, targetTz='America/Chicago', dateFormat='YYYY-MM-DD HH:mm:ss z'){
        return moment
            .tz(dt, "UTC")
            .clone()
            .tz(targetTz)
            .format(dateFormat);
    }

    static truncate(str, length, ending) {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str && str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    static isEmptyObject(obj) {
        return !Utils.isBlank(obj) && Object.keys(obj).length === 0;
    }

	static isBlank(obj){
		return (obj === null || obj === undefined || obj === '');
	}

    static titleCase(str) {
        return str.toLowerCase().split(' ').map(word => {
            return word.replace(word[0], word[0].toUpperCase())
        }).join(' ')
    }

    static toCamelCase(str){
        return str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, match => {
            return match.charAt(match.length - 1).toUpperCase()
        })
    }

    static capitalize(str){
        return str && str.length > 1 && str.charAt(0).toUpperCase() + str.slice(1);
    }

    static existsAndTruthy(o, attrName){
        return (o.hasOwnProperty(attrName) && o[attrName])
    }

    static existsAndFalsy(o, attrName){
        return (o.hasOwnProperty(attrName) && !o[attrName])
    }

    static falsyOrDNE(o, attrName){
        return (!o.hasOwnProperty(attrName) || !o[attrName])
    }

    static randIntRange = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    static randInt = (max) => {
        return this.randIntRange(0, max);
    }

    static uuid = () => {
        return uuidv4();
    };

    static useDebounce = (value, delay) => {
        // State and setters for debounced value
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
            setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
            clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
        );
        return debouncedValue;
    };

    static calcSoundSigColor = (soundSig, noGreen = false) => {
        if (Utils.isBlank(soundSig) || soundSig <= 0 || soundSig === null || soundSig === undefined) {
            return STATUS_COLORS.GRAY; // N/A
        }
        if (soundSig > 20) {
            return STATUS_COLORS.RED; // red
        }
        if (soundSig > 15) {
            return STATUS_COLORS.AMBER; // amber
        }
        if (soundSig > 10) {
            return STATUS_COLORS.YELLOW; // yellow
        }
        if (soundSig > 5) {
            return STATUS_COLORS.LIME; // lime
        }
        if (soundSig >= 0) {
            return noGreen ? STATUS_COLORS.GRAY : STATUS_COLORS.GREEN; // green
        }

        return STATUS_COLORS.GRAY; // N/A
    };

    static simpleSvgPlaceholder = ({
        width = 300,
        height = 150,
        text = `${width}×${height}`,
        fontFamily = 'sans-serif',
        fontWeight = 'bold',
        fontSize = Math.floor(Math.min(width, height) * 0.2),
        dy = fontSize * 0.35,
        bgColor = '#ddd',
        textColor = 'rgba(0,0,0,0.5)',
        dataUri = true,
        charset = 'UTF-8',
      } = {}) => {
        const str = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
          <rect fill="${bgColor}" width="${width}" height="${height}"/>
          <text fill="${textColor}" font-family="${fontFamily}" font-size="${fontSize}" dy="${dy}" font-weight="${fontWeight}" x="50%" y="50%" text-anchor="middle">${text}</text>
        </svg>`;
      
        const cleaned = str
          .replace(/[\t\n\r]/gim, '') // Strip newlines and tabs
          .replace(/\s\s+/g, ' ') // Condense multiple spaces
          .replace(/'/gim, '\\i'); // Normalize quotes
      
        if (dataUri) {
          const encoded = encodeURIComponent(cleaned)
            .replace(/\(/g, '%28') // Encode brackets
            .replace(/\)/g, '%29');
      
          return `data:image/svg+xml;charset=${charset},${encoded}`;
        }
      
        return cleaned;
      }

}
