const AwsExports =  {
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: "us-west-2:fd5f5a78-20fd-4bac-9c48-20dcdf5c6e12",
        
        // REQUIRED - Amazon Cognito Region
        region: "us-west-2",

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'us-west-2',
        
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
        
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        //authenticationFlowType: 'USER_PASSWORD_AUTH'
        
    },
    Storage: {
        bucket: "dst-tmp",
        region: "us-west-2",
    },
};

export default AwsExports;