import Utils from "../../common/Utils";

export const EvaluationBar = ({ criticalityEquivalentColor, count, total, onClick, noGreen = false }) => {
    if (total === 0) return (<div></div>);
    const backgroundColor = Utils.calcSoundSigColor(criticalityEquivalentColor, noGreen);
    const percent = Math.round((count / total) * 100);
    return (
        <div onClick={onClick} className="rounded-md" style={{ height: "17px", width: `${percent < 10 ? 10 : percent}%`, backgroundColor: backgroundColor }}>
            <span style={{ fontSize: 'small', color: "black", textShadow: "0px 0px 2px white", verticalAlign: 'top', float: 'right', marginRight: '2px', marginTop: '-1px' }}>
                {count}
            </span>
        </div>
    )
}