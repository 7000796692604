import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom";
import '../../styles/grid.css';
import {NavSidebar} from './NavSidebar';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { FcInspection, FcTimeline, FcLibrary, FcBiohazard, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness } from 'react-icons/fc';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form } from 'react-bootstrap';
import Utils from '../../common/Utils';
import {FcDownload} from 'react-icons/fc';
import TaskingSheet from '../../models/TaskingSheet';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import { ImSpinner3 } from 'react-icons/im';
import { toast } from 'react-toastify';
import ExpandedCheckAnalyticsRow from './ExpandedCheckAnalyticsRow';
import Check from '../../models/Check';
import TaskTypeIcon from './TaskTypeIcon';
import {TASK_TYPES} from '../../constants/Constants'

const CheckTable = (props) => {
  const {selectedTask, selectedEquipment} = props;
  const {
    user,
    isSuperAdmin,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher
  } = useAuth();
  let {sheetUUID: providedSheetUUID} = useParams();
  const [checks, setChecks] = useState(selectedTask.checks || []);
  const [loading, setLoading] = useState(false);
  const [selectedSheetUUID, setSelectedSheetUUID] = useState(providedSheetUUID);
  const [selectedSheet, setSelectedSheet] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  
  useEffect(() => {
    loadChecks();
  }, [])

  const loadChecks = async () => {
    if (!selectedEquipment){
      return;
    }

    setLoading(true);
    const figChecks = await Check.filterBy([
      // selectedEquipment ? {field: 'fig_uuid', value: selectedEquipment.uuid, comparison: "="} : null,
      selectedTask ? {field: 'task_uuid', value: selectedTask.uuid, comparison: "="} : null,
      {field: "archived", value: 0, comparison: "="},
    ].filter(x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true );
    console.log({figChecks})
    setChecks(figChecks.data);
    setLoading(false);
  }

  // const updateSingleUpload = (updatedSheet) => {
  //   const sheetsList = sheets.length > 0 ? sheets : [];
  //   const indexOfExistingUpload = sheetsList.findIndex(x => x.uuid === updatedSheet.uuid);
  //   if (indexOfExistingUpload === -1){
  //     console.log("Couldn't find upload row")
  //     return
  //   }
  //   sheetsList[indexOfExistingUpload] = {...sheetsList[indexOfExistingUpload], ...updatedSheet};
  //   setSheets([...sheetsList]);
  // }
  
  // const updateSheet = async (row) => {
  //   setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
  //   const returnedRow = await TaskingSheet.update({
  //     uuid: row.uuid, 
  //     global: row.global,
  //     default: row.default
  //   });
  //   await updateSingleUpload({...row, ...returnedRow});
  //   setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  // }



  const columns = React.useMemo(() => 
    [
      {
        name: 'Task Name',
        selector: row => row.task && row.task.name,
        sortable: true,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: 'Fig Name',
        selector: row => row.fig && row.fig.name,
        sortable: true,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: 'Task Type',
        selector: row => row.type_code,
        cell: row => row.type_code,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: 'App Version',
        selector: row => row.app_version,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: `Value`,
        cell: cell => {
          return <>{Check.getTaskCodeProperName(cell.type_code)}:&nbsp;{Check.formatCheckValue(cell)}</>
        },
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
        width: '14em'
      },
      // {
      //   name: 'Created By',
      //   cell: row =>  row.user && <Link to={`/users/?q=${row.user.full_name}`}>{row.user.full_name}</Link>,
      //   sortable: true,
      //   compact: true,
      //   wrap: true,
      // },
      {
        name: 'Created At',
        selector: row => moment(row.created_at).unix(),
        cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
        sortable: true,
        center: true,
        compact: true
      }
    ].filter(x => x!=null), [JSON.stringify(checks)]
  );

  return (<>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        {/* <NavSidebar/> */}
        <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          {/* <h3>Tasking Sheets and Tasks</h3>
            <div className="d-flex flex-row justify-content-end mb-4">

            <OverlayTrigger
              placement="top"
              delay={{ show: 400, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Refresh the results</Tooltip>}
            >
              <a className={`btn btn-outline-secondary`} onClick={() => {
                  refreshSheetInformation();
                }}>
                <div className="d-flex flex-row text-left">
                  <FcRefresh style={{fontSize: 24}}/>
                  <div className="pl-2">Refresh</div>
                </div>
              </a>
            </OverlayTrigger>

            </div> */}
            <div className="d-flex flex-row justify-content-center align-items-top flex-wrap">  
            <DataTable
                columns={columns}
                data={checks}
                noDataComponent = {"No matching results."}
                responsive={true}
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRows
                onRowExpandToggled={(expanded, row) => {
                  if (expanded){
                    setSelectedSheetUUID(row.uuid)
                  }
                }}
                expandableRowsComponent={(props) => ExpandedCheckAnalyticsRow(props)}
                defaultSortAsc={false}
                defaultSortFieldId={3}
                disabled={loadingRowUUIDs.length > 0}
              />
            </div>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={() => {
        const columnNames = columns.map(col => `'${col.name}'`)
        const dataRowsAsTextOnly = checks.map(row => columns.map(col => col.selector ? `'${col.selector(row)}'` : ``))
        
        const csvCombined = [
          columnNames.join(','),
          ...(dataRowsAsTextOnly || []).map(row => row.join(','))
        ].join('\n');
          
        let element = document.createElement('a');
        element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
        element.setAttribute('download', `export.csv`);
        document.body.appendChild(element);
        element.click();
      }}>
      <FcDownload/>
    </button>
  </div>
</>
  )
}

export default CheckTable;