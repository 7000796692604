import React, { useEffect, useState } from 'react';
import View from '../../models/View';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import { useAuth } from '../../context/AuthContext';


const ViewServiceTechAtCompanyPanel = () => {
    const [data, setData] = useState(null);
    const {
        user,
        isSuperAdmin,
        isCustomerTechOrHigher,
        isLimitedAdminOrHigher,
        isAdminOrHigher,
        isSubdomainAdminOrHigher,
      } = useAuth();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const data = await View.getViewServiceTechAtCompany(user.company_id);
    //         console.log("THIS IS THE DATA FROM FETCHDATA", data); 
    //         setData(data);
    //     }
    //     fetchData();
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await View.getViewServiceTechAtCompany(user.company_id);
                console.log("THIS IS THE DATA FROM FETCHDATA", data); 
                setData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    
        if (user && user.company_id) {  // Ensuring user and user's company_id is available
            fetchData();
        }
    }, [user]);  // Added user as dependency
    

    return (
        <div>
            {/* { user && <h2>Welcome, {user.name}!</h2> } */}
            {data ? (
                <>
                    <h3>Total number of service technicians at company</h3>
                    <BarChart width={600} height={300} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="companyName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="numberOfSubdomainTechs" fill="#8884d8" />
                    </BarChart>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default ViewServiceTechAtCompanyPanel;
