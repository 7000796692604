import React, { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {TbZoomCancel} from 'react-icons/tb';


const ZoomImage = (props) => {
    const { 
        src, 
        smallSize, 
        
      } = props;



      return (
        <>
         <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className='container' style={{position:'relative'}}>
            <TransformComponent>
              
              <img style={{ maxWidth: smallSize ? 300 : 450, maxHeight: 400, height: 'auto', display:'block', marginLeft:'auto', marginRight:'auto'}} src={src}/>
            
            </TransformComponent>
            <div className="tools" style={{position: "relative", top: "-50px", left: "10px", height: 0}}>
            <ButtonGroup>
              <Button variant="light" onClick={() => zoomIn()}>+</Button>
              <Button variant="light" onClick={() => zoomOut()}>-</Button>
              {/* <Button variant="light" onClick={() => resetTransform()}><TbZoomCancel style={{fontSize: 14}} color="black"/></Button> */}
            </ButtonGroup>
            </div>

        </div>
          </React.Fragment>
        )}
      </TransformWrapper>
    
        </>
      );


}

export default ZoomImage; 