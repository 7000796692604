import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams,
  useRouteMatch
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import InspectionEquipmentHierarchy from './components/InspectionEquipmentHierarchy';
import { useFilterContext } from "../context/SharedFilterContext";

const MetabaseScreen = (props) => {
  const params = useParams();
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  const {
    selectedProperty,
  } = useFilterContext();

  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={`${path}`}>
                <PropertyActivityGrid
                  title={"Select a metabase query to use"}
                  noResultsMessage="No properties found within selected date range, consider increasing the date."
                  hideAnalysisColors={true}
                  hideValCheck={true}
                  submitPath={`/metabase_screen`}
                  isInspectionPage={true}
                  hideEndDate={false}
                  hideStructureInfo={true}
                  hideInspectionInfo={true}
                  showMetabase={true}
                  showPanels={false}
                  showAnalyticsCount={false}
                  showSoundDatabase={false} 
                />
              
            </PrivateRoute>
          </Switch>
        </div>
    </div>
  )
}

export default MetabaseScreen;