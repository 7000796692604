import Utils from '../common/Utils';
import moment from 'moment-timezone';
import Reading from './Reading';
import Model from "./Model";

export class Evaluation extends Model{
    static create = async(fields, user) => {
        if (fields.uuid === undefined){
            fields.uuid = Utils.uuid();
        }
        const res = await this.runMutation(CreateEvaluation, {input: {
            // ...equipment,
            uuid: fields.uuid,
            fig_id: fields.fig_id,
            updated_at: moment().toISOString(),
            user_id: fields.user_id || user.id,
            started_at: fields.started_at || moment().toISOString(),
            ended_at: fields.ended_at || moment().toISOString(),
            level: fields.level,
            sound_level: fields.sound_level,
            comments: fields.comments || "",
            rec: fields.rec || "",
            net_samples_uuid: fields.net_samples_uuid || "",
            recorded_yhat_json: fields.recorded_yhat_json || "",
            hash: fields.hash || null
        }});
        console.log("CREATED EVALUATION", {res})
        return res.data.createEvaluation;
    }

    static delete = async(uuid) => {
        const res = await this.runMutation(DeleteEvaluation, {input: {uuid}});
        return res.data.deleteEvaluation;
      }
      

    static soundEvalRollup = async(fig_id, from, to) => {
        const res = await this.runQuery(GetSoundEvalRollUp, {fig_id: fig_id, from, to});
        return res.data.soundEvalRollup;
    }

    static maxSeRollup = async(fig_id, from, to) => {
        const res = await this.runQuery(GetMaxSERollup, {fig_id: fig_id, from, to});
        return res.data.maxSeRollup;
    }

    static signaturesRollup = async(fig_id, from, to, minimumProbability=0.5) => {
        const res = await this.runQuery(GetSignaturesRollUp, {fig_id: fig_id, from, to});
        const {signaturesRollup} = res.data;
        if (signaturesRollup && signaturesRollup.length > 0){
            return signaturesRollup
                .filter(x => x.pr >= minimumProbability)
                .map(x => `${x.signature.name}` )
                .join(', ');
        }
        return null;
    }

    static actionsRollup = async(fig_id, from, to, minimumProbability=0.5) => {
        const res = await this.runQuery(GetActionsRollUp, {fig_id: fig_id, from, to});
        const {actionsRollup} = res.data;
        if (actionsRollup && actionsRollup.length > 0){
            return actionsRollup
                .filter(x => x.pr >= minimumProbability)
                .map(x => `${x.action.name}` )
                .join(', ');
        }
        return null;
    }

    static tagsRollup = async(fig_id, from, to) => {
        const res = await this.runQuery(GetTagsRollUp, {fig_id: fig_id, from, to});
        const {taggedValsRollup} = res.data;
        if (taggedValsRollup && taggedValsRollup.length > 0){
            const allTags = [];
            for (const taggedVal of taggedValsRollup){
                allTags.push(...Reading.getTagNames(taggedVal));
            }
            if (allTags.length === 0){
                return null;
            }
            return Utils.uniq(allTags).join(", ");
        }
        return null;
    }

    static update = async(fields) => {
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update evaluation - no uuid specified")
            return
        }
        const res = await this.runQueryNetworkOnly(UpdateEvaluation, {input: {
            uuid: fields.uuid,
            updated_at: moment().toISOString(),
            user_id: fields.user_id,
            started_at: fields.started_at || moment().toISOString(),
            ended_at: fields.ended_at || moment().toISOString(),
            level: fields.level,
            sound_level: fields.sound_level,
            comments: fields.comments || "",
            rec: fields.rec || ""
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.UpdateEvaluation.runQuery [COMPLETE]`);
        console.log("res", res)
        return res.data.updateEvaluation;
    }
}

const GetActionsRollUp = `
query ActionsRollup($fig_id: Int!, $from: String, $to: String){
    actionsRollup(fig_id: $fig_id, from: $from, to: $to){
        action {
            name
            desc
        }
        pr
        score
    }
}
`

const GetTagsRollUp = `query TagsRollup($fig_id: Int!, $from: String, $to: String){
    taggedValsRollup(fig_id: $fig_id, from: $from, to: $to){
        id
        accelse
        cond
        overb
        secondary_noise
    }
}`

const GetSignaturesRollUp = `query SignaturesRollup($fig_id: Int!, $from: String, $to: String){
    signaturesRollup(fig_id: $fig_id, from: $from, to: $to){
        signature {
            name
            desc
        }
        pr
        score
    }
}`;

const GetSoundEvalRollUp = `query SoundEvalRollup($fig_id: Int!, $from: String, $to: String){
    soundEvalRollup(fig_id: $fig_id, from: $from, to: $to)
}`

const GetMaxSERollup = `query MaxSeRollup($fig_id: Int!, $from: String, $to: String){
    maxSeRollup(fig_id: $fig_id, from: $from, to: $to)
}`

const UpdateEvaluation = `
mutation UpdateEvaluation($input: UpdateEvaluationInput!){
    updateEvaluation(input: $input){
        id
        uuid
        started_at
        ended_at
        level
        sound_level
        comments
        rec
    }
}`

const CreateEvaluation = `
mutation CreateEvaluation($input: EvaluationInput!){
    createEvaluation(input: $input){
        id
        uuid
        started_at
        ended_at
        level
        sound_level
        comments
        user_id
        net_samples_uuid
        recorded_yhat_json
        hash
    }
}`

const DeleteEvaluation = `
  mutation DeleteEvaluation($input: DeleteEvaluationInput!) {
    deleteEvaluation(input: $input) {
      uuid
    }
  }
`


export default Evaluation;