import Form from '@rjsf/bootstrap-4';
import {useAuth} from '../context/AuthContext';
import {useHistory, useLocation} from "react-router-dom";
import { useEffect, useState } from 'react';
import { ImSpinner3 } from 'react-icons/im';
import Amplify, { Auth } from 'aws-amplify';

const schema = {
    title: "Sign in",
    type: "object",
    required: ["email", "pass"],
    properties: {
        email: {
            type: "string",
            title: "Email"
        },
        pass: {
            type: "string",
            title: "Password"
        }
    }
};

const uiSchema = {
    pass: {
        "ui:widget": "password"
    }
};

const noExtraErrors = {
    email: {
        __errors: [],
    },
    pass: {
        __errors: [],
    },
};

const SigninScreen = () => {
    const history = useHistory();
    const {
        signin,
        doesUserEmailExist,
        isEmailFormatValid,
        parseTokenAndPersistCredentials
    } = useAuth();
    const [extraErrors, setExtraErrors] = useState(noExtraErrors);
    const [loggingIn, setLoggingIn] = useState(false);
    let location = useLocation();

    useEffect(() => {
        sessionRestoreFromLocalStorage();
    }, [])

    const sessionRestoreFromLocalStorage = async () => {
        // Refresh Storage/Amplify session
        try {
            await Auth.currentSession()
        }catch(e){
            console.log(e)
        }

        // Check if user is already logged in via local storage
        const existingCookie = localStorage["dst-login-token"];
        const autoLogin = existingCookie !== undefined;
        setLoggingIn(autoLogin);
        if (autoLogin){
            await parseTokenAndPersistCredentials(existingCookie)
            redirectToIntendedPage();
        }
    }

    const redirectToIntendedPage = () => {
        // console.log("history.location.state", history.location.state)
        // let originalIntendedPage = localStorage['originalIntendedPage'] || '/';
        // if (history.location.state && history.location.state.from && history.location.state.from.pathname && history.location.state.from.pathname.localeCompare('/') !== 0){
        //     originalIntendedPage = history.location.state.from.pathname;
        // }
        // console.log("originalIntendedPage", originalIntendedPage)
        // history.push(originalIntendedPage)

        console.log("location.state", location)
        let { from } = location.state || { from: { pathname: "/" } };
        console.log("from", from)
        history.replace(from);
    }

    const onSubmit = async data => {
        setLoggingIn(true);
        const {email, pass} = data.formData;

        let emailExists = false;
        try{
            emailExists = await doesUserEmailExist(email);
        } catch{
            console.log("email exists failed")
            setLoggingIn(false);
            return;
        }

        if (!emailExists){
            setExtraErrors({email: { __errors: ["Account does not exist"]}})
            setLoggingIn(false);
            return;
        }

        const isSignedIn = await signin(email, pass);
        if (isSignedIn){
            setExtraErrors(noExtraErrors);
            setLoggingIn(false);
            redirectToIntendedPage();
        }else{
            setExtraErrors({email: { __errors: ["Failed to sign in"]}})
            setLoggingIn(false);
        }
    }

    const onError = errors => {
        console.log("Errors", errors)
    }

    function validate(formData, errors) {
        if (!isEmailFormatValid(formData.email)){
            errors.email.addError("Invalid Format");
        }
        return errors;
    }

    return (
            <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-center">
                    <div className="p-3 border border-light rounded-lg bg-white" style={{boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.15)", marginTop: '17.5vh'}}>
                        {loggingIn ?
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h4>Logging in...</h4>
                            <ImSpinner3 className="icon-spin" style={{fontSize: 30}}/>
                        </div>
                        :
                        <Form
                            schema={schema}
                            uiSchema={uiSchema}
                            extraErrors={extraErrors}
                            validate={validate}
                            onSubmit={onSubmit}
                            onError={onError}
                        />
                        }
                    </div>
                </div>
            </div>
    );
}

export default SigninScreen;