import { useEffect, useState, useMemo } from 'react';
import Company from "../../models/Company";
import Property from "../../models/Property";
import Equipment from "../../models/Equipment";
import Systems from "../../models/Systems";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import Utils from "../../common/Utils";
import {useAuth} from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import jsonpath from 'JSONPath';
import jsonTransform from 'jsonpath-object-transform';
import User from '../../models/User';
import {
    useParams,
    useHistory
  } from "react-router-dom";
import {BareEvaluationSlider, EditModal} from  '../components/GenericEditModal';
import Evaluation from '../../models/Evaluation';
import {EvaluationMark} from '../components/EvaluationMark';
import { ImSpinner3 } from 'react-icons/im';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useFilterContext } from '../../context/SharedFilterContext';
import moment from 'moment';

const LoadingSpinner = () => {
  return <ImSpinner3 className="icon-spin" style={{fontSize: 14}}/>
}

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'space-between'};
  align-items: center;
  margin-bottom: 0.5em;
`

const EvaluationCreate = (props) => {
    console.log({props})
    const {selectedEquipment, onHide, refreshEquipment, onEvaluationCreated} = props;
    const fig_id = selectedEquipment?.id;
    const { user, isSuperAdmin, signout } = useAuth();
    const history = useHistory();
    const p = useParams();
    console.log({p})
    console.log({props})
    const {uuid, property, parentEquipment} = props;
    console.log("property", property, parentEquipment)
    const [editModal, setEditModal] = useState({});
    
    const oneDayAgo = moment().subtract(1, 'day').endOf("day").format("YYYY-MM-DD");
    const [startedAt, setStartedAt] = useState(oneDayAgo);

    const endOfToday = moment().endOf("day").format("YYYY-MM-DD");
    const [endedAt, setEndedAt] = useState(endOfToday);
    const [evaluation, setEvaluation] = useState("");
    const [note, setNote] = useState(null);
    const [recommendedNote, setRecommendedNote] = useState(null);
    const [rolledUpStats, setRolledUpStats] = useState({});
    const [loadingRolledUpColumns, setLoadingRolledUpColumns] = useState(false);
    
    const updateRollUps = async () => {
        setLoadingRolledUpColumns(true);
        const rolledUpPromises = Promise.all([
            Evaluation.signaturesRollup(fig_id, startedAt, endedAt),
            Evaluation.maxSeRollup(fig_id, startedAt, endedAt),
            Evaluation.soundEvalRollup(fig_id, startedAt, endedAt),
            Evaluation.actionsRollup(fig_id, startedAt, endedAt, 0.5),
            Evaluation.tagsRollup(fig_id, startedAt, endedAt)
        ])
        const rolledUpData = await rolledUpPromises;
        const [
            rolledUpSignatures, 
            rolledUpSE, 
            rolledUpSoundEval, 
            rolledUpActions, 
            rolledTags
          ] = rolledUpData;
        const newEval ={
            rolledUpSE,
            rolledUpSoundEval,
            rolledUpSignatures,
            rolledUpActions,
            rolledTags
        };
      setRolledUpStats(newEval);
      setLoadingRolledUpColumns(false);
      setEvaluation(Math.round(rolledUpSoundEval/selectedEquipment?.criticality));
    }

    useEffect(() => {
        updateRollUps();
    }, [uuid, startedAt, endedAt])

    useEffect(() => {
console.log("Evaluation in useEffect", evaluation)  
}, [evaluation])


    const renderSoundEvaluation = row => loadingRolledUpColumns ? <LoadingSpinner /> : 
    <EvaluationMark criticality={rolledUpStats.rolledUpSoundEval} onClick={async (e) => {
    e.preventDefault();
  }} />

  const renderSignature = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : rolledUpStats.rolledUpSignatures || "N/A";
  const renderSE = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : `${rolledUpStats.rolledUpSE ?? 0} SE`;
  const renderNotes = _ => note;
  const renderTags = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : rolledUpStats.rolledTags || "N/A";
  const renderActions = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : rolledUpStats.rolledUpActions || "N/A";

  
  const renderLevel = row => 
  <EvaluationMark criticality={evaluation * selectedEquipment?.criticality}/>;
    
    const handleSubmit = async (e) => {
      const data = e.target.value;
      console.log({data})
      const newEvaluation = await Evaluation.create({
        fig_id,
        started_at: startedAt,
        ended_at: endedAt,
        level: evaluation || 0,
        sound_level: evaluation || 0,
        comments: note,
        rec: recommendedNote,
      }, user)
      onEvaluationCreated();

      console.log('newEvaluation', newEvaluation)
      refreshEquipment(); 
      onHide();
    }

    // const onSubmit = async data => {
    //     const updatedEquipment = {
    //         ...data.formData,
    //         ...data.formData.other // collapse any 'other' fields into the main object
    //     };
    //     updatedEquipment.parent_key = updatedEquipment.parent_key === "None" ? null : updatedEquipment.parent_key;
    //     updatedEquipment.year = parseInt(updatedEquipment.year);
    //     updatedEquipment.system_id = parseInt(updatedEquipment.system_id);
    //     delete updatedEquipment.other;
    //     delete updatedEquipment["Show additional data fields?"];
        
    //     console.log("updatedEquipment", updatedEquipment)
    //     if (updatedEquipment.uuid === null || updatedEquipment.uuid === undefined){
    //         console.log("New Equipment")
    //         const newEquipment = {
    //             ...updatedEquipment,
    //             uuid: Utils.uuid(),
    //             property_id: property.id,
    //             property_uuid: property.uuid,
    //             // user_uuid: user.uuid
    //         }
    //         console.log("newEquipment", newEquipment)
    //         await Equipment.create(newEquipment);
    //         props.onHide && props.onHide();
    //         toast.info(
    //             `Creating ${updatedEquipment.name}`,
    //             {autoClose: 5000}
    //         );
    //     }else{
    //         await Equipment.update(updatedEquipment);
    //         props.onHide && props.onHide();
    //         toast.info(
    //             `Updating ${updatedEquipment.name}`,
    //             {autoClose: 5000}
    //         );
    //     }
    // }


    const handleEndedAtChange = (event) => {
        const date = event.target.value;
        setEndedAt(date);
    }

    const handleStartedAtChange = (event) => {
        const date = event.target.value;
        setStartedAt(date);
    }

    const handleNoteChange = (event) => {
        const note = event.target.value;
        setNote(note);
    }

    const handleRecommendationChange = (event) => {
        const recommendation = event.target.value;
        setRecommendedNote(recommendation);
    }


    const handleEvaluationChange = (newEvaluation) => {
        setEvaluation(newEvaluation);
    }

    const options = {
        title: "Update Evaluation",
        value: evaluation,
        selector: _ => evaluation,
        type: "evaluation",
        onHide: () => {
            setEditModal({})
        },
        onSubmit: async (e, row, newValue) => {
          e.preventDefault();
          setEvaluation(newValue);
        }
      }

    return (
        <div className="d-flex justify-content-center align-items-center w-100">
            <EditModal options={editModal}/>
            <div className="flex-column w-75">                    
                <div className="flex-row">
                    {/* <h1>{fig_id}</h1> */}
                    
                    

<div className="d-flex flex-column">
  <Row>
  <div className="d-flex flex-column form-label">
    Started At
  </div>
  <div className="d-flex flex-column flex-2">
    <input type="date" name="startedAt" class="form-control" onChange={handleStartedAtChange} value={startedAt}/>
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column form-label">
    Ended At
  </div>
  <div className="d-flex flex-column flex-2">
    <input type="date" name="endedAt" class="form-control" onChange={handleEndedAtChange} value={endedAt}/>
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column">
    SoundEvaluator™
  </div>
  <div className="d-flex flex-column flex-2">
    {renderSoundEvaluation()}
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column form-label">
    SoundEnergy™
  </div>
  <div className="d-flex flex-column flex-2">
    {renderSE()}
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column form-label">
    Tags
  </div>
  <div className="d-flex flex-column flex-2">
    
    {renderTags()}
  </div>
  </Row>
  
  <Row>
  <div className="d-flex flex-column form-label" style={{textAlign: 'start'}}>
    SoundSignature™
  </div>
  <div className="d-flex flex-column flex-2 " style={{textAlign: 'end'}}>
    {renderSignature()}
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column form-label" style={{textAlign: 'start'}}>
    Recommended Action
  </div>
  <div className="d-flex flex-column flex-2 " style={{textAlign: 'end'}}>
    {renderActions()}
  </div>
  </Row>

  <Row>
  <div className="d-flex flex-column form-label">
    Evaluation
  </div>
  <div className="d-flex flex-column flex-2">
    {renderLevel()}
  </div>
  </Row>
  <div className="d-flex flex-row mb-2">
    <BareEvaluationSlider newValue={evaluation} setNewValue={setEvaluation}/> 
  </div>

  <Row>
  <div className="d-flex flex-column form-label">
    Field Technician Notes
  </div>
  <div className="d-flex flex-column flex-2">
    <input type="text" name="notes" class="form-control" style={{height: '100px'}} onChange={handleNoteChange} value={note}/>
  </div>
  </Row>


  <Row>
  <div className="d-flex flex-column form-label">
    Recommendations / Repairs
  </div>
  <div className="d-flex flex-column flex-2">
    <input type="text" name="recommendations" class="form-control" style={{height: '100px'}} onChange={handleRecommendationChange} value={recommendedNote}/>
  </div>
  </Row>

</div>

<Row justifyContent="end">
<Button variant="primary" type="submit" onClick={e => {
  e.preventDefault();
  handleSubmit(e);
}}>Submit</Button>
</Row>

                </div>
            </div>
        </div>
    );
}


export default EvaluationCreate;


