import React, { useContext, useState, useEffect } from 'react';
import PointGraph from './PointGraph';
import Val from '../../models/Val';
import { ImSpinner3 } from 'react-icons/im';
import DataTable from 'react-data-table-component';
import {EvaluationMark} from './EvaluationMark';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from 'moment-timezone';
import Utils from '../../common/Utils';
import { Link } from "react-router-dom";
import {renderTags} from '../AnalyticsScreen';
import { FaStickyNote, FaRegFlag, FaPlayCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, TASK_TYPES } from '../../constants/Constants';
import { Player, BigPlayButton, LoadingSpinner, ControlBar, PlaybackRateMenuButton, PlayToggle, VolumeMenuButton } from 'video-react';
import Check from '../../models/Check';
import "../../../node_modules/video-react/dist/video-react.css";

const ExpandedCheckAnalyticsRow = (props) => {
    console.log('ExpandedCheckAnalyticsRow props', props);
    const {isCheck, type_code, image, task} = props.data || props || {};
    const {bucket, key} = image || {};
    const mediaURL = image && bucket && key ? `https://${bucket}.s3-us-west-2.amazonaws.com/${key}?cachebust=${(new Date().valueOf())}` : ``;
    // const videoFileExtensions = [".mov", ".mp4", ".flv", ".avi", ".wmv", ".webm"]
    // const isVideo = videoFileExtensions.filter(ext => key.toLowerCase().indexOf(ext) >= 0).length >= 1
    console.log({type_code, image, bucket, key, mediaURL})

    return (
      <div className="d-flex flex-column justify-content-around p-4 w-100">
        <div style={{maxWidth: '50%', maxHeight: '50%'}}>
        {type_code === TASK_TYPES.VIDEO.code &&
            <Player
                playsInline
                fluid={false}
                width={'auto'}
                height={650}
                src={mediaURL}
                preload={'none'}
            >
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar autoHide={false} disableDefaultControls={false} >
                    <PlayToggle />
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    <VolumeMenuButton vertical />
                </ControlBar>
            </Player>
        }

        {type_code === TASK_TYPES.PHOTO.code &&
            <img style={{maxWidth:"100%", maxHeight:"650px"}} src={mediaURL} alt=""></img>
        }
        </div>

        {type_code != TASK_TYPES.VIDEO.code && type_code != TASK_TYPES.PHOTO.code &&
          <><h3>{Check.getTaskCodeProperName(type_code)}:  </h3> {Check.formatCheckValue(props.data)}
             <h5>{task.name}</h5>
          </>
        }
      </div>
    )
  }

  export default ExpandedCheckAnalyticsRow;