import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';

const PointInspectionTableCard = (props) => {
    const {setShowPointInspectionTable, property} = props; 

    // Determine the URL for the property image
    const imageUrl = !property?.image || !property?.image?.bucket || !property?.image?.key
    ? "/icons/image-placeholder3.svg"
    : property?.image?.bucket.includes('media3')
        ? `https://s3.amazonaws.com/${property?.image?.bucket}/${property?.image?.key}?cachebust=${(new Date().valueOf())}`
        : `https://${property?.image?.bucket}.s3.amazonaws.com/${property?.image?.key}?cachebust=${(new Date().valueOf())}`;

    return (
        <Card className="user-panel-card" onClick={() => setShowPointInspectionTable(true)}>
            <Card.Img
                className="image-thumbnail rounded-lg"
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    backgroundImage: `url("https://web.dstapp.com/icons/image-placeholder3.svg")`
                }}
                src={imageUrl}
            />
            <Card.Body>
                <Card.Title className="user-panel-title">MSi Inspections Table</Card.Title>
                <Card.Text className="user-panel-description">
                    Point Information
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default PointInspectionTableCard;
