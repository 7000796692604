import React, { useEffect, useState } from 'react';
import ActionLog from '../../models/ActionLog';
import Wav from '../../models/Wav';
import Check from '../../models/Check';
import { ActionLogType } from '../../models/ActionLogType';
import moment from 'moment';
import { ImSpinner3 } from 'react-icons/im';
import {Card, Button, Modal} from 'react-bootstrap';
import { FcInspection, FcAudioFile, FcBrokenLink, FcQuestions, FcLink, FcDocument, FcClock } from 'react-icons/fc';
import {AiOutlineClockCircle} from 'react-icons/ai';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE } from '../../constants/Constants';

const UserHistoryHorizontalScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [userActions, setUserActions] = useState(props.userActions || []);
  const [selectedHistoryRow, setSelectedHistoryRow] = useState({});
  const {
    userUUID,
    userID,
    vertical,
    queryUploads,
    queryChecks,
    queryUserInfo,
    noResultsMessage,
    setOutputData,
    setUserHistory,
    multiRow,
    showArrows,
    daysAgoCutoff} = props;
  const [checksHistory, setChecks] = useState(props.checkHistory || []);
  const ref = React.createRef();
  const [uploadsHistory, setUploads] = useState(props.uploadsHistory || []);

  const queryCutoffDate = moment.utc().local().subtract(daysAgoCutoff || 90,'d').endOf("day").format("YYYY-MM-DD hh:mm:ss");

  const queryAdditionalUserInfo = async () => {
    const results = await ActionLog.filterBy([
      {"field": "user_uuid", "value": userUUID, "comparison": "="},
      {"field": "action_type_id", "value": JSON.stringify([ActionLogType.sentReport, ActionLogType.webPlatformAutoLogin, ActionLogType.webPlatformLogin, ActionLogType.msiConnected, ActionLogType.msiDisconnected, ActionLogType.appUserLoggedIn, ActionLogType.appAutoLogin]), "comparison": "IN"},
      {"field": "created_at", value:  queryCutoffDate, comparison: ">"}
    ], "created_at", "desc", 500);
    setUserActions(results.data);
    console.log("result.data!!!!", results.data);
  }

  const queryUserUploads = async () => {
    const results = await Wav.filterBy([
      {"field": "user_id", "value": userID, "comparison": "="},
      {"field": "created_at", value:  queryCutoffDate, comparison: ">"},
      {"field": "duplicate", "value": 0, "comparison": "="}
    ], "recorded_at", "desc", 500);
    console.log({uploads: results.data});
    setUploads(results.data);
  }

  const queryUserChecks = async () => {
    const results = await Check.filterBy([
      {"field": "user_id", "value": userID, "comparison": "="},
      {"field": "created_at", value:  queryCutoffDate, comparison: ">"}
    ], "recorded_at", "desc", 500);
    setChecks(results.data);
  }

  useEffect(() => {
    if (userUUID && userID){
      loadUserHistory();
    }else{
      setUserActions([]);
      setUploads([]);
      setChecks([]);
    }
  },[userUUID, userID])

  const loadUserHistory = async () => {
    setLoading(true);
    await Promise.all([
      queryUserInfo !== undefined && queryAdditionalUserInfo(),
      queryUploads !== undefined && queryUserUploads(),
      queryChecks !== undefined && queryUserChecks()
    ]);
    setLoading(false);
  }

  const formatRawAppVersion = (appVersion) =>{
    const defaultResponse = {
      fullVersion: "Unknown",
      major: 0,
      minor: 0,
      patch: 0,
      publishingVersion: 0,
      commit: "000000"
    };
    if (!appVersion){
      return defaultResponse;
    }
    const matches = appVersion.match(/(([0-9]+)\.([0-9]+)\.([0-9]+))-?([0-9]*)\ ?#?(.*)/);

    return matches && matches.length > 0 ? 
    {
      fullVersion: matches[1],
      major: matches[2],
      minor: matches[3],
      patch: matches[4],
      publishingVersion: matches[5],
      commit: matches[6]
    } : defaultResponse;
  }

  const mergeUserHistory = React.useMemo(() => {
    const merge = [...(uploadsHistory || []), ...(checksHistory || []), ...(userActions || [])]
      .filter(row => row != null)
      .sort((a,b) => moment(b.recorded_at || b.created_at) - moment(a.recorded_at || a.created_at))
      .map(row => {
        const iconSize = 32;
        let icon = <FcQuestions style={{fontSize: iconSize}}/>
        let title = row.action_type_id;
        let description = '';
        let links = [];
        let createdDateTime = row.created_at ? moment.utc(row.created_at) : moment();
        let isCurrentDate = createdDateTime && createdDateTime.isSame(new Date(), "day");
        let formattedFullDateTime = createdDateTime.format("M-D-YY h:mm:s a");
        let formattedRelativeDate = isCurrentDate ? 'Today' : createdDateTime.format("M-D-YY"); // Use relative time if today
        let modalColumnsNames = null;
        let modalColumns = null;

        if (row.action_type_id === ActionLogType.appUserLoggedIn || row.action_type_id === ActionLogType.appAutoLogin){
          title = "App Login";
          icon = <img src="/icons/blue-unlocked-padlock.svg" style={{width: iconSize, height: iconSize}}/>
          description = `${(row.device_uuid || '').includes('-') ? "iOS" : "Android"}: ${formatRawAppVersion(row.app_version).fullVersion}`;
          modalColumnsNames = ["Title", "Date/Time", "App Version"]
          
        }else if (row.action_type_id === ActionLogType.webPlatformAutoLogin){
          title = "Web Login";
          icon = <img src="/icons/green-unlocked-padlock.svg" style={{width: iconSize, height: iconSize}}/>
          description = "Auto-logged in"
        }else if (row.action_type_id === ActionLogType.webPlatformLogin){
          title = "Web Login";
          icon = <img src="/icons/green-unlocked-padlock.svg" style={{width: iconSize, height: iconSize}}/>
        }else if (row.action_type_id === ActionLogType.msiConnected){
          title = `Connected to MSi #${row.msi_serial}`;
          icon = <FcLink style={{fontSize: iconSize}}/>
        }else if (row.action_type_id === ActionLogType.msiDisconnected){
          title = `Disconnected from MSi #${row.msi_serial}`;
          icon = <FcBrokenLink style={{fontSize: iconSize}}/>
        }else if (row.action_type_id === ActionLogType.sentReport){
          title = `Sent Report`;
          icon = <FcDocument style={{fontSize: iconSize}}/>;
          try{
            const parsedRow = JSON.parse(decodeURI(row.context));
            modalColumnsNames = ["Title", "Date/Time", "Link"]
            modalColumns = [title, formattedFullDateTime, <a href={parsedRow.reportS3Link.replace('email-reports', 'r')} target="_blank" rel="noopener noreferrer">Download</a>]
          }catch{}
        }else if (row.recorded_at){ // Any upload
          const recordedDateTime = row.recorded_at ? moment.utc(row.recorded_at) : moment();
          isCurrentDate = recordedDateTime && recordedDateTime.isSame(new Date(), "day");
          formattedRelativeDate = isCurrentDate ? 'Today' : recordedDateTime.format("M-D-YY"); // Use relative time if today
          if (row.type_code != null){
            icon = <FcInspection style={{fontSize: iconSize}}/>
            title = `Task`
          }else{
            icon = <FcAudioFile style={{fontSize: iconSize}}/>
            if (row.duplicate){
              title = `Duplicate Upload`;
            }else if (row.val !== null){
              if (row.val.v === NOD_SE_VALUE){
                title = `NOD Upload`;
              }else{
                title = `Upload`
              }
            }   
            modalColumnsNames = ["Uploaded", "Recorded", "Location", "Property"]
            modalColumns = [
              createdDateTime.local().format("M-D-YY h:mm:ss a"),
              recordedDateTime.local().format("M-D-YY h:mm:ss a"),
              row.val && row.val.point_info ? Wav.getFigNameHierarchy(row) + " ⮕ " + row.val.point_info.measurement_name || '' : '',
              row.val && row.val.point_info ? row.val.point_info.property_name || '' : '',
            ]
          }
        }


        return {
          title,
          subtitle: `Date: ${formattedRelativeDate}`,
          sortDateTime: createdDateTime,
          icon,
          description,
          // links,
          row,
          modalColumnsNames: modalColumnsNames || ["Title", "Date/Time", "Description"],
          modalColumns: modalColumns || [title, formattedFullDateTime, description],
          useLargeModal: modalColumnsNames ? modalColumnsNames.length > 3 : false,
        }
    })

    setOutputData && setOutputData(merge);


    // Collapse consecutive repeats
    const reduction = merge.reduce((acc, cur) => {
      const isEqual = acc.prev && acc.prev.title === cur.title
        && acc.prev.sortDateTime.day() === cur.sortDateTime.day()
        && acc.prev.sortDateTime.month() === cur.sortDateTime.month()
        && acc.prev.sortDateTime.year() === cur.sortDateTime.year();
      if (acc.prev === null) {
        acc.prev = {...cur, count: 1, list: []};
      }else if (!isEqual){
        acc.result.push(acc.prev);
        acc.prev = {...cur, count: 1, list: []};
      }else if (isEqual){
        acc.prev.count++;
        acc.prev.list = [...acc.prev.list, cur];
      }
      return acc;
    }, {
      prev: null,
      result: []
    });

      return reduction.result;
  });

 

  const handleHistoryDetailClose = () => {
    setSelectedHistoryRow({});
  }

  return (
    <div className="d-flex flex-row">
      {!loading && showArrows && !vertical && mergeUserHistory.length > 0 &&
        <a className="d-flex flex-column rounded-lg mt-3 mb-3 align-items-center justify-content-center" style={{zIndex: 2, marginRight: "-1em", backgroundColor: "rgba(200,200,200,0.8)"}}  onClick={() => {
          if (ref && ref.current){
            ref.current.scrollLeft -= 150;
          }
        }}>
          <FaChevronLeft className="text-dark" style={{fontSize: 24}}/>
        </a>
      }

      <div style={{overflowY: "hidden", overflowX: (loading || mergeUserHistory.length === 0) ? "hidden" : "scroll"}} ref={ref} className="d-flex flex-row pt-3 pl-3">
        {loading
          ? <ImSpinner3 className="icon-spin" style={{fontSize: 25}}/>
          : (userID && userUUID ?
            <div>
              <div className={`d-flex ${vertical ? "flex-column" : "flex-row"} ${multiRow ? 'flex-wrap': 'flex-no-wrap'}  justify-content-center`}>
                {mergeUserHistory.length === 0 && <div>{noResultsMessage}</div>}
                {mergeUserHistory
                  .map((row, index) => {
                    return (
                      <Card key={index} style={{ marginLeft: vertical ? 0 : '1rem', marginBottom: '1em', width: vertical ? '100%' : '12em'}} onClick={(e) => {
                          if (!e.target.nodeName.includes("A")){ // Don't open popup if clicking directly on a link
                            setSelectedHistoryRow(row)
                          }
                        }}>
                        <Card.Body style={{padding:'1em'}}>
                          <div className={`d-flex ${vertical ? "flex-row" : "flex-column"} justify-content-start align-items-center`}>
                            <div className={`d-flex pb-2 ${vertical ? 'pr-3' : ''} `}>{row.icon}</div>
                            <div className={`d-flex flex-column justify-content-center ${vertical ? '' : 'align-items-center'}`}>
                            <Card.Title className="pb-1">{`${row.title}${row.count > 1 ? ' (' + row.count + ')' : ''}`}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{row.subtitle}</Card.Subtitle>
                            <Card.Subtitle className="mb-2 text-muted">{row.description}</Card.Subtitle>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                  )
                })}
                <Modal show={selectedHistoryRow.title && selectedHistoryRow.title.length > 0} onHide={handleHistoryDetailClose} dialogClassName={`${selectedHistoryRow.useLargeModal ? 'modal-90w' : ''}`}>
                  <Modal.Header closeButton>
                    <Modal.Title>{selectedHistoryRow.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedHistoryRow.modalColumnsNames &&
                    <DataTable
                      title={selectedHistoryRow.title}
                      columns={(selectedHistoryRow.modalColumnsNames || []).map((col, index, array) => {
                          return {
                            name: col,
                            selector: row => row[index],
                            grow: (array.length > 3 && index < 2) ? 1 : 3,
                            compact: true,
                            wrap: true
                          }
                        }
                      )}
                      data={[selectedHistoryRow.modalColumns, ...(selectedHistoryRow.list || []).map(x => x.modalColumns)]}
                      dense
                      noHeader
                    />
                      }
                  </Modal.Body>
                </Modal>
              </div>
              </div>
              : null )
            }
        </div>

        {!loading && showArrows && !vertical && mergeUserHistory.length > 0 &&
          <a className="d-flex flex-column rounded-lg mt-3 mb-3 align-items-center justify-content-center" style={{zIndex: 2, marginLeft: "-1em", backgroundColor: "rgba(200,200,200,0.8)" }}  onClick={() => {
            if (ref && ref.current){
              ref.current.scrollLeft += 150;
            }
          }}>
            <FaChevronRight className="text-dark" style={{fontSize: 24}}/>
          </a>
        }
      </div>
  )
}


export default UserHistoryHorizontalScreen;

