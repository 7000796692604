import React, { useContext, useState, useEffect } from 'react';
import PointGraph from './PointGraph';
import Val from '../../models/Val';
import { ImSpinner3 } from 'react-icons/im';
import DataTable from 'react-data-table-component';
import {EvaluationMark} from './EvaluationMark';
import {NOD_SE_VALUE, POINT_CONDITIONS, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD} from '../../constants/Constants';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import moment from 'moment-timezone';
import Utils from '../../common/Utils';
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditModal } from './GenericEditModal';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';


import {renderTags} from '../AnalyticsScreen';
import { FaStickyNote, FaRegFlag, FaPlayCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag, FaFileDownload } from 'react-icons/fa';
 
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Check from '../../models/Check';

const ExpandedValAnalyticsRow = (props) => {
    const [valMedia, setValMedia] = useState({});
    const [loading, setLoading] = useState(false);

    const [editModal, setEditModal] = useState({});
    const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
    const { user } = useAuth();

    let currentMusic = new Audio();
    
    const queryValMedia = async (row) => {
        setLoading(true);
        let media = await Val.filterMediaBy([{field: 'uuid', value: row.uuid, comparison: '='}])
        media = (media.data && media.data.length === 1) ? media.data[0] : {};
        setValMedia(media);
        setLoading(false);
    }

    useEffect(() => {
      queryValMedia(props.data);
    }, [])

    const updateSingleUpload = async (row, returnedRow) => {
      const index = valMedia.point_info.valHistory.findIndex(item => item.uuid === row.uuid);
      if (index === -1) {
        console.error("Item to update not found");
        return;
      }
    
      const updatedItem = { ...valMedia.point_info.valHistory[index], ...returnedRow };
    
      const updatedValHistory = [
        ...valMedia.point_info.valHistory.slice(0, index),
        updatedItem,
        ...valMedia.point_info.valHistory.slice(index + 1),
      ];
    

      setValMedia({
        ...valMedia,
        point_info: {
          ...valMedia.point_info,
          valHistory: updatedValHistory,
        },
      });
    };
    

  

    const spectrogram = valMedia && valMedia.wav && valMedia.wav.spectrogram.minimum;
    let spectrogramURL = spectrogram && `https://s3-${spectrogram.region}.amazonaws.com/${spectrogram.bucket}/${spectrogram.key}`;
    const pointImage = valMedia && valMedia.point_info && valMedia.point_info.image;
    const figImage = valMedia.point_info && valMedia.point_info.fig.image; 
    //const user = valMedia && valMedia.wav && valMedia.wav.user;

    const editFieldNotes = async (row) => {
      //if (isDisabled()) return;
      setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
      setEditModal({
        title: "Update Field Note",
        value: row,
        selector: row => row.desc || "",
        type: "text",
        onHide: () => {
          setEditModal({})
          setLoadingRowUUIDs([]);
        },
        onSubmit: async (e, row, newValue) => {
          e.preventDefault();
          const returnedRow = await Val.submitReview(row.uuid, user.id, newValue);
          await updateSingleUpload(row, returnedRow);
          setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
        }
      });
    }

    const editRecommendations = async (row) => {
      //if (isDisabled()) return;
      setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
      // console.log("edit rec uploads", uploads);
      // const newRecommendations = prompt("Update Recommendations or Repair", row.rec || "");
      // updateSingleUpload(uploads, {...row, rec: newRecommendations});
      // await Val.submitRecommendation(row.uuid, user.id, newRecommendations);
      // console.log("a", {...row, rec: newRecommendations});
      setEditModal({
        title: "Update Recommendations",
        value: row,
        selector: row => row.rec || "",
        type: "text",
        onHide: () => {
          setEditModal({})
          setLoadingRowUUIDs([]);
        },
        onSubmit: async (e, row, newValue) => {
          e.preventDefault();
          const returnedRow = await Val.submitRecommendation(row.uuid, user.id, newValue);
          await updateSingleUpload(row, returnedRow);
          setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
        }
      });
      
    };

    const markAsReviewed = async (row) => {
     // if (isDisabled()) return;
      setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
      console.log("Mark as Reviewed");
      console.log(row.uuid, user.id);
      const returnedRow = await Val.markAsReviewed(row.uuid, user.id);
      await updateSingleUpload(row, returnedRow);
      setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
    }

    const toggleFlagged = async (row) => {
     // if (isDisabled()) return;
      setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
      const returnedRow = await Val.updateConditions(
        row.uuid,
        user.id,
        (row.cond === POINT_CONDITIONS.FLAGGED) ? POINT_CONDITIONS.NORMAL : POINT_CONDITIONS.FLAGGED,
        row.secondary_noise
      );
      await updateSingleUpload(row, returnedRow);
      setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
    }


  const toggleSecondaryNoise = async (row) => {
    //if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await Val.updateConditions(
      row.uuid,
      user.id,
      row.cond,
      !row.secondary_noise
    );
    await updateSingleUpload(row, returnedRow);
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const updateEvaluation = async (row) => {
    //if (isDisabled()) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Evaluation",
      value: row,
      selector: row => row.criticality || 0,
      type: "evaluation",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        console.log(newValue)
        const returnedRow = await Val.submitEval(row.uuid, user.id, newValue);
        await updateSingleUpload(row, returnedRow);
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }

  const extractEvaluation = row => row.sig;
  const renderEvaluationmark = row => <EvaluationMark criticality={row.sig} onClick={async (e) => updateEvaluation(row, e)}/>;

    const extractTags = row => [
      !Utils.isBlank(row.reviewed_at) ? "reviewed" : (row.warningMode ? "review-required" : null),
      Val.isFlagged(row)
          ? (
            Val.isFlaggedImplicitly(row) ? "system-flagged-high-evaluation-or-has-notes": "flagged"
          ) : null,
      row.secondary_noise ? "secondary-noise" : null,      
    ].filter(x => x !== null).join(' ')
  
    const renderTags = row => {
      const filteredTags = [
        !Utils.isBlank(row.reviewed_at) ? // Show green exclamation if reviewed
          <FaExclamationTriangle style={{color: "#009900", fontSize: 30}} className="p-1" onClick={() => alert("Already Reviewed")}/>
        : row.warningMode && // Not reviewed and in warning mode, show yellow review required icon
        <img src="/icons/red-stop-sign.svg" style={{width: "30px", height: "30px"}} className="p-1 reviewRequiredIcon" onClick={() => markAsReviewed(row)}/>
        , Val.isFlagged(row)
          ? (
            Val.isFlaggedImplicitly(row) 
            ? <FaFlag role="button" className="p-1" style={{color: 'rgb(217, 72, 47)', fontSize: 28}} onClick={() => 
              toast.info("System flagged due to the high Evaluation or existence of field notes/recommendations. Modify those to resolve this flag.")
            }/>
            : <FaFlag role="button" className="p-1" style={{color: 'black', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
          )
          : <FaRegFlag role="button" className="p-1" style={{color: 'gray', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
        , row.secondary_noise
          ? <RiVolumeVibrateFill role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'black', fontSize: 32}}/>
          : <RiVolumeVibrateLine role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'gray', fontSize: 32}}/>,
        
      ].filter(x => x != false)
  
      return (
        <div className="d-flex flex-row justify-content-center flex-wrap ml-1 mt-1 mb-1">
          {filteredTags.map(x => _.isString(x) ? <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div> : (x || ''))}
        </div>
      )
    }


    const extractFieldNotes = row => row.desc || "___";
    const renderFieldNotes = row => Utils.isBlank(row.desc)
    ? <FaRegStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)}/>
    : <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip">{row.desc}</Tooltip>}>
        <FaStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)} style={{fontSize: 16}}/>
      </OverlayTrigger>;
 
    const extractSoundSignatures = row => row.signatures && (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT).length);
    const renderSoundSignatures = row => {
        const filteredSignatures = row.signatures && (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT));
        return (
        <div className="d-flex flex-row justify-content-center flex-wrap">
            {filteredSignatures.map(x => <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x.signature.name || ''}</div>)}
        </div>
        )
    };

    const extractRecommendations = row => row.rec || "___";
    const renderRecommendations = row => Utils.isBlank(row.rec)
    ? <FaRegStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)}/>
    : <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip">{row.rec}</Tooltip>}>
        <FaStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)} style={{fontSize: 16}}/>
      </OverlayTrigger>
      
      
    const extractSE = row => row.v === NOD_SE_VALUE ? -1 : row.v;
    const renderSEAndPlayButton = row => {
      const convertMp3Url = (mp3Url) => {
          return mp3Url.replace('dst-mp3s.s3.amazonaws.com/', 'mp3.dstapp.com/');
      };
  
      return row.v === NOD_SE_VALUE ? "VISUAL" : (
          <>
              <div role="button" onClick={() => {
                  const newPlayerUrl = row.wav && row.wav.mp3Url;
                  if (currentMusic.src === newPlayerUrl) {
                      if (currentMusic.paused) {
                          currentMusic.play();
                      } else {
                          currentMusic.pause();
                      }
                      return;
                  }
                  currentMusic.pause();
                  currentMusic.src = newPlayerUrl;
                  let userConsentsToPlayingLoudInspection = true;
                  if (row.v > 65) {
                      userConsentsToPlayingLoudInspection = confirm("The selected inspection may be very loud. Please turn down your volume or take off headphones/earpieces before continuing.");
                  }
                  if (userConsentsToPlayingLoudInspection) {
                      currentMusic.play();
                  }
              }}>
                  {row.v}
                  {row.wav && <img src="/icons/playpause.svg" className="ml-2 mr-2" width="17px" alt="Play/Pause"></img>}      
              </div>
              {row.wav && row.wav.mp3Url && (
                  <a className="ml-1" href={convertMp3Url(row.wav.mp3Url)} download>
                      <FaFileDownload />
                  </a>
              )}
          </>
      );
  };
  

    const renderReadOnlyTags = row => {
        const filteredTags = [
          !Utils.isBlank(row.reviewed_at) ? // Show green exclamation if reviewed
            <FaExclamationTriangle style={{color: "#009900", fontSize: 30}} className="p-1" />
          : row.warningMode && // Not reviewed and in warning mode, show yellow review required icon
            <img src="/icons/red-stop-sign.svg" style={{width: "25px", height: "30px"}} className="p-1 reviewRequiredIcon" />
          , Val.isFlagged(row)
            ? (
              Val.isFlaggedImplicitly(row) 
              ? <FaFlag className="p-1" style={{color: 'rgb(217, 72, 47)', fontSize: 28}} />
              : <FaFlag className="p-1" style={{color: 'black', fontSize: 28}} />
            )
            : <FaRegFlag className="p-1" style={{color: 'gray', fontSize: 28}} />
          , row.secondary_noise
            ? <RiVolumeVibrateFill className="p-1" style={{color: 'black', fontSize: 32}}/>
            : <RiVolumeVibrateLine className="p-1" style={{color: 'gray', fontSize: 32}}/>,
          (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : "",
          row.accelse && "Accelerating SE",
        ].filter(x => x != false)
    
        return (
          <div className="d-flex flex-row justify-content-center flex-wrap ml-1 mt-1 mb-1">
            {filteredTags.map(x => _.isString(x) ? <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div> : (x || ''))}
          </div>
        )
      }

    const onImageLoadError = (event) => {
      event.target.onerror=null;
      event.target.src='/icons/empty-image.png';
      event.target.style="width: 0; height: 0;";
    }

    const extractCreatedAtFormattedYMD = row => moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
    const renderCreatedAtFormatted = row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>
    const extractActions = row => {
      const filteredActions = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && x.action.name).filter(x => x != null)
      return filteredActions.join(' ')
    };
  
    const renderActions = row => {
      const filteredActionNames = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && `${x.action.name}`).filter(x => x != null)
      return (
        <div className="d-flex flex-row justify-content-center flex-wrap">
          {filteredActionNames.map(x => <div className="text-nowrap rounded-sm bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div>)}
        </div>
      )
    };

    return (
    <div className="d-flex flex-column justify-content-around p-4 w-100">
        <EditModal options={editModal}/>

        {loading && <ImSpinner3 className="icon-spin" style={{fontSize: 25}}/>}
        {!loading && <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start', padding: '4px' }}>
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', padding: '4px', width: '450px' }}>
    <div style={{ marginBottom: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start' }}>
      <PointGraph point={valMedia && valMedia.point_info} accent={{uuid: (props.data || {}).uuid}} instanceNumber={valMedia.uuid} width={400} height={200}></PointGraph>
    </div>
    <div style={{ marginBottom: '2px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start' }}>
    {spectrogramURL && <img className="mb-2" style={{height: 'auto', maxWidth: '400px', maxHeight: '800px'}} src={spectrogramURL} onError={onImageLoadError} />}
    </div>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 2 }}>
    {pointImage && <img style={{ width: '100%', height: 'auto', maxWidth: '450px', maxHeight: '400px' }} src={`https://s3-us-west-2.amazonaws.com/${pointImage.bucket}/${pointImage.key}?cachebust=${(new Date().valueOf())}`} onError={onImageLoadError} />}
    {!pointImage && figImage && <img style={{ height: 'auto', maxWidth: '450px', maxHeight: '400px' }} src={`https://s3-us-west-2.amazonaws.com/${figImage.bucket}/${figImage.key}?cachebust=${(new Date().valueOf())}`} onError={onImageLoadError} />}
  </div>
</div>

            <div className="d-sm-flex ml-5 flex-row justify-content-start align-items-start">
                <DataTable
                    title="MSi Inspections"
                    columns={[
                        {
                            name: `Inspection Date`,
                            selector: row => moment(row.recorded_at).unix(),
                            cell: row => <span>{moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
                            sortable: true,
                            compact: true,
                            wrap: true,
                            left: true,
                            width: "8em"
                        },
                        {
                          name: 'Upload Date',
                          selector: extractCreatedAtFormattedYMD,
                          cell: renderCreatedAtFormatted,
                          sortable: true,
                          center: true,
                          compact: true,
                          width: '8em'
                        },
                        {
                          name: 'SoundEnergy™',
                          selector: extractSE,
                          cell: renderSEAndPlayButton,
                          sortable: true,
                          center: true,
                          compact: true,
                          width: '8em'
                        },
                        {
                          name: 'SE(%)',
                          selector: row => row.plr, // Use the numeric value for sorting
                          format: row => `${Math.round(row.plr)}%`, // Format the displayed value
                          sortable: true,
                          center: true,
                          compact: true,
                          width: '125px'
                        },
                          {
                            name: `Evaluation`,
                            selector: extractEvaluation,
                            cell: renderEvaluationmark,
                            sortable: true,
                            compact: true,
                            width: "5em"
                        },
                        {
                          name: 'SoundEvaluator™',
                          selector: row => row.aiSig,
                          cell: (row) => <EvaluationMark criticality={row.aiSig}/>,
                          sortable: true,
                          center: true,
                          compact: true,
                          width: '110px'
                        }, 
                        {
                          name: 'SoundSignatures™',
                          selector: extractSoundSignatures,
                          cell: renderSoundSignatures,
                          sortable: true,
                          compact: true,
                          wrap: true,
                          center: true,
                          width: '150px'
                        },
                        {
                            name: `User`,
                            selector: row => row.user && row.user.full_name,
                            cell: row => <Link to={`/users/?q=${row.user && row.user.full_name}`}>{row.user && row.user.full_name}</Link>,
                            sortable: true,
                            compact: true,
                            wrap: true,
                            center: true,
                        },{
                            name: 'Tags',
                            selector: extractTags,
                            cell: renderTags,
                            sortable: true,
                            compact: true,
                            center: true
                        },
                        {
                          name: 'Actions',
                          selector: extractActions,
                          cell: renderActions,
                          sortable: true,
                          center: true,
                          compact: true,
                          // width: '80px'
                          width: '15%' // Percentages instead of fixed pixel values
                  
                        },
                        
                        {
                            name: `Field Notes`,
                            selector: extractFieldNotes,
                            cell: renderFieldNotes,
                            compact: true,
                            wrap: true,
                            center: true,
                        },{
                            name: `Recommendations`,
                            selector: extractRecommendations,
                            cell: renderRecommendations,
                            compact: true,
                            wrap: true,
                            center: true,
                        }
                    ]}
                    data={valMedia && valMedia.point_info && valMedia.point_info.valHistory.map(row => {
                        return {
                            recorded_at: row.recorded_at,
                            v: row.v,
                            sig: row.sig,
                            user: row.wav && row.wav.user,
                            rec: row.rec,
                            desc: row.desc,
                            reviewed_at: row.reviewed_at,
                            warningMode: row.warningMode,
                            secondary_noise: row.secondary_noise,
                            overb: row.overb,
                            accelse: row.accelse,
                            cond: row.cond,
                            signatures: row.signatures,
                            wav: row.wav, 
                            created_at: row.created_at,
                            plr: row.plr,
                            aiSig: row.aiSig,
                            actions: row.actions,
                            criticality: row.criticality, 
                            point_info: row.point_info,
                            uuid: row.uuid
                        }
                    })}
                    responsive={false}
                    persistTableHead
                    noHeader
                    highlightOnHover
                    dense
                />
            </div>
        </>}
    </div>
    )
  }

  export default ExpandedValAnalyticsRow;