import React, { useEffect, useState } from 'react';
import { useFilterContext } from '../../context/SharedFilterContext';
import { useAuth } from '../../context/AuthContext';
import { ImSpinner3 } from 'react-icons/im';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { EvaluationMark } from './EvaluationMark';
import Utils from '../../common/Utils';
import Evaluation from '../../models/Evaluation';
import Equipment from '../../models/Equipment';
import MultipointEvaluation from '../../models/MultipointEvaluation';
import User from '../../models/User';
import ExpandedEvaluationRow from './ExpandedEvaluationRow'; 
import { FcDownload } from 'react-icons/fc';
import { EditModal } from './GenericEditModal';
import  EvaluationSeverityModal from './EvaluationSeverityModal';
import _ from 'lodash';


const EvaluationTableComponentLayout = (props) => {
  const [evaluations, setEvaluations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRolledUpColumns, setLoadingRolledUpColumns] = useState(false);
  const { user, isSuperAdmin } = useAuth();

  const { propertyEquipmentList, selectedProperty } = useFilterContext();
  const [showManualEvaluations, setShowManualEvaluations] = useState(false); 
  const [editModal, setEditModal] = useState({});



  useEffect(() => {
    const gatherEvaluations = async (equipment) => {
      let allEvaluations = [];
      if (equipment.evaluations) {
        let equipmentName = equipment.name;

        if (equipment.parent_key) {
          const parentEquipmentData = await Equipment.getEquipmentToEditByKey(equipment.parent_key);
          const parentEquipment = _.first(parentEquipmentData.data);
          equipmentName = `${parentEquipment.name} / ${equipment.name}`;
        }
        const evaluationsWithEquipmentName = equipment.evaluations.map(evaluation => ({
          ...evaluation,
          equipmentName: equipmentName,
          criticality: equipment.criticality,
          fig_key: equipment.key,
        }));
        allEvaluations = allEvaluations.concat(evaluationsWithEquipmentName);
      }

      if (equipment.allSubcomponents) {
        for (const subcomponent of equipment.allSubcomponents) {
          allEvaluations = allEvaluations.concat(await gatherEvaluations(subcomponent));
        }
      }

      return allEvaluations;
    };

  

const BATCH_SIZE = 6;

const processBatch = async (batch) => {
  return await Promise.all(batch.map(async (evaluation) => {
    evaluation.started_at = moment(evaluation.started_at).startOf('day').toISOString();
    evaluation.ended_at = moment(evaluation.ended_at).endOf('day').toISOString();
    evaluation.created_at = moment(evaluation.created_at).toISOString();
    const rolledUpPromises = Promise.all([
      Evaluation.signaturesRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
      Evaluation.maxSeRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
      Evaluation.soundEvalRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
      Evaluation.actionsRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at, 0.5),
      Evaluation.tagsRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at)
    ]);
    const rolledUpData = await rolledUpPromises;
    const [
      rolledUpSignatures, 
      rolledUpSE, 
      rolledUpSoundEval, 
      rolledUpActions, 
      rolledTags
    ] = rolledUpData;
    const updatedEvaluation = {
      ...evaluation,
      rolledUpSE,
      rolledUpSoundEval,
      rolledUpSignatures,
      rolledUpActions,
      rolledTags
    };
    const user = await User.findById(evaluation.user_id);
    updatedEvaluation.userFullName = user.full_name;
    const equipmentTest = await Equipment.findByID(evaluation.fig_id);
    updatedEvaluation.fig_image = equipmentTest.image;
    return await updateSingleEvaluation(updatedEvaluation);
  }));
};

const loadEvaluations = async () => {
  setLoading(true);
  let allEvaluations = [];
  for (const equipment of propertyEquipmentList) {
    allEvaluations = allEvaluations.concat( await gatherEvaluations(equipment));
  }

  let processedEvaluations = [];
  for (let i = 0; i < allEvaluations.length; i += BATCH_SIZE) {
    const batch = allEvaluations.slice(i, i + BATCH_SIZE);
    const processedBatch = await processBatch(batch);
    processedEvaluations = processedEvaluations.concat(processedBatch);
  }

  const sortedEvaluations = processedEvaluations.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  console.log("Evaluation Table Layout - SortedEvaluations", sortedEvaluations);
  setEvaluations(sortedEvaluations);
  setLoading(false);
};


    loadEvaluations();
  }, [propertyEquipmentList]);

  const toggleEvaluationType = () => {
    setShowManualEvaluations(!showManualEvaluations);
  };

  const filteredEvaluations = evaluations.filter(evaluation => 
    showManualEvaluations ? !evaluation.recorded_yhat_json : evaluation.recorded_yhat_json
  );
  if (loading) {
    return <ImSpinner3 className="icon-spin" style={{ fontSize: 14 }} />;
  }

  const badgeStyle = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '90%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem',
    color: '#fff',
    backgroundColor: '#007bff',
    margin: '0.1em',
    maxWidth: '25ch'
  };


  const deleteEvaluation = async (uuid) => {
    if (!isSuperAdmin()) {
      alert("You must be a super admin to delete an evaluation");
      return;
    }
  
    if (confirm(`Warning! Please confirm this action by clicking OK.`)) {
     const updatedEvaluations = evaluations.filter(evaluation => evaluation.uuid !== uuid);
     setEvaluations(updatedEvaluations);
      await Evaluation.delete(uuid);
    }
  };


  const editLevel = async (row) => {
    setEditModal({
      title: "Set Evaluation Severity",
      value: row,
      type: "evaluationSeverity",
      onHide: () => {
        setEditModal({})
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        setLoading(true);
        const returnedRow = await Evaluation.update({...row, level: newValue});
           // Update the evaluations state with the updated row
      const updatedEvaluations = evaluations.map(evaluation =>
        evaluation.uuid === row.uuid ? { ...evaluation, ...returnedRow } : evaluation
      );
      setEvaluations(updatedEvaluations);
        setLoading(false);
      }
    });
  };

  const fetchNamesByUUIDs = async (uuids, fetchFunction) => {
    const names = await Promise.all(uuids.map(async (uuid) => {
      const result = await fetchFunction(uuid);
      return result.length > 0 ? result[0].name : uuid;
    }));
    return names;
  };



  const updateSingleEvaluation = async (evaluation) => {
    if (evaluation.recorded_yhat_json) {
      try {
        const parsedYhatJson = JSON.parse(evaluation.recorded_yhat_json);
        let parsedNode;
        if (parsedYhatJson.nodes) {
          parsedNode = parsedYhatJson.nodes[0];
        } else {
          parsedNode = parsedYhatJson;
        }
        const mpeTagsNames = await fetchNamesByUUIDs(parsedNode.tagUuids, MultipointEvaluation.findTagsByUUID);
        const mpeActionsNames = await fetchNamesByUUIDs(parsedNode.actionUuids, MultipointEvaluation.findActionsByUUID);
        evaluation.mpeTags = mpeTagsNames;
        evaluation.mpeActions = mpeActionsNames;
      } catch (error) {
        console.error("Error parsing recorded_yhat_json:", error);
      }
    }
    return evaluation;
  };
  

  const renderCreatedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>;

  const extractCreatedAtFormattedYMD = row => moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm');

  const renderStartedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{moment.tz(row.started_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>;

  const extractStartedAtFormattedYMD = row => moment.tz(row.started_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm');

  const renderEndedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{moment.tz(row.ended_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>;

  const extractEndedAtFormattedYMD = row => moment.tz(row.ended_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm');

  const extractLevel = row => row.level * row.criticality;
  const renderLevel = row => <EvaluationMark criticality={row.level * row.criticality} onClick={async (e) => {
    e.preventDefault();
    editLevel(row)
    }} />;
  


  const extractSoundEvaluation = row => row.sound_level * row.criticality;
  const renderSoundEvaluation = row => <EvaluationMark criticality={row.sound_level * row.criticality} />;

  const extractTags = row => row.rolledTags;
  const renderTags = row => row.rolledTags || "N/A";
  const extractSignature = row => row.rolledUpSignatures;
  const renderSignature = row => row.rolledUpSignatures || "N/A";
  const extractActions = row => row.rolledUpActions;
  const renderActions = row => row.rolledUpActions || "N/A";
  

  const extractSE = row => row.rolledUpSE;
  const renderSE = row => `${row.rolledUpSE ?? 0} SE`;
  
  const extractNotes = row => row.comments;
  const renderNotes = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{row.comments || "N/A"}</a>;
  
  const extractRecommendations = row => row.rec;
  const renderRecommendations = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{row.rec || "N/A"}</a>;


  const extractEquipmentImageUrl = row => {
    if (row.fig_image) {
      const { bucket, key, region } = row.fig_image;
      if (bucket && key) {
        return bucket.includes('media3')
          ? `https://s3.amazonaws.com/${bucket}/${key}?cachebust=${(new Date().valueOf())}`
          : `https://${bucket}.s3.${region}.amazonaws.com/${key}?cachebust=${(new Date().valueOf())}`;
      }
    }
    return null;
  };
  
  const renderEquipmentImage = row => {
    const imageUrl = extractEquipmentImageUrl(row);
    if (imageUrl) {
      return <img src={imageUrl} alt="Equipment" style={{ width: '50px', height: '50px' }} />;
    }
    return null; 
  };


  const exportToCSV = () => {
    let csvTitle = `Evaluations Export`;
    const columnNames = columns.map(col => `${col.name}`);
    const dataRowsAsTextOnly = evaluations.map(row => columns.map(col => col.selector ? `${col.selector(row)}` : ``));
    const csvCombined = [
      csvTitle,
      columnNames.join(';'),
      ...(dataRowsAsTextOnly || []).map(row => row.join(';'))
    ].join('\n');

    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
    element.setAttribute('download', `evaluations_export.csv`);
    document.body.appendChild(element);
    element.click();
  };
  
  
  const columns = [
    {
      name: 'Equipment Photo',
      selector: extractEquipmentImageUrl,
      cell: renderEquipmentImage,
      sortable: false,
      center: true,
      compact: true,
      width: '60px'
    },
    {
      name: 'Root/Comp',
      selector: row => row.equipmentName,
      sortable: true,
      center: true,
      wrap: true,
      compact: true,
      width: '250px', 
      style: {
        backgroundColor: '#f0f0f0', 
        color: '#000000', 
      },
    },
    {
      name: 'Created At',
      selector: extractCreatedAtFormattedYMD,
      cell: renderCreatedAtFormatted,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'SoundEvaluator™',
      selector: extractSoundEvaluation,
      cell: renderSoundEvaluation,
      sortable: true,
      center: true,
      compact: true,
      width: '130px'
    },
    {
      name: 'Evaluation',
      selector: extractLevel,
      cell: renderLevel,
      sortable: true,
      center: true,
      compact: true,
      width: '90px'
    },
    {
      name: 'MPE Tags',
      selector: row => row.mpeTags || [],
      cell: row => (
        <div>
         {row.mpeTags?.map((tag, index) => {
  const displayTag = tag.includes('^') ? tag.split('^')[1] : tag;
  return <span key={index} style={badgeStyle}>{displayTag}</span>;
})}
        </div>
      ),
    },
    {
      name: 'MPE Actions',
      selector: row => row.mpeActions || [],
      cell: row => (
        <div>
         {row.mpeActions?.map((action, index) => {
  const displayAction = action.includes('^') ? action.split('^')[1] : action;
  return <span key={index} style={badgeStyle}>{displayAction}</span>;
})}
        </div>
      ),
    },
  
    {
      name: 'Started At',
      selector: extractStartedAtFormattedYMD,
      cell: renderStartedAtFormatted,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'Ended At',
      selector: extractEndedAtFormattedYMD,
      cell: renderEndedAtFormatted,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'SoundEnergy™',
      selector: extractSE,
      cell: renderSE,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'Tags',
      selector: extractTags,
      cell: renderTags,
      sortable: true,
      center: true,
      compact: true,
      width: '120px'
    },
    {
      name: 'SoundSignature™',
      selector: extractSignature,
      cell: renderSignature,
      sortable: true,
      center: true,
      compact: true,
      width: '120px'
    },
    {
      name: 'Actions',
      selector: extractActions,
      cell: renderActions,
      sortable: true,
      center: true,
      compact: true,
      width: '120px'
    },
    {
      name: 'Comments',
      selector: extractNotes,
      cell: renderNotes,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    },
    {
      name: 'Recommendations',
      selector: extractRecommendations,
      cell: renderRecommendations,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    }, 
    {
      name: 'Full Name',
      selector: row => row.userFullName,
      sortable: true,
      center: true,
      compact: true,
      width: '100px'
    }, 
    ...(isSuperAdmin() ? [{
      name: 'Delete',
      cell: (row) => <button onClick={() => deleteEvaluation(row.uuid)}>Delete</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }] : [])
  ];
  
  return (
    <div className="d-flex flex-column rounded-lg bg-white mt-2">
{editModal.type === "evaluationSeverity" && (
        <EvaluationSeverityModal
          show={!!editModal.title}
          onHide={editModal.onHide}
          onSubmit={editModal.onSubmit}
          row={editModal.value}  
        />
      )}
        <div className="d-flex flex-column">
          <h4>Evaluations - {selectedProperty.name}</h4>
          <button 
          onClick={toggleEvaluationType} 
          style={{
            padding: '0.5em 1em',
            fontSize: '1em',
            fontWeight: 'bold',
            color: '#fff',
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '0.25rem',
            cursor: 'pointer',
            alignSelf: 'flex-end',
            marginBottom: '1em'
          }}
        >
          {showManualEvaluations ? 'Show MPE Evaluations' : 'Show Manual Evaluations'}
        </button>
        </div>

        <div>
      <DataTable
        columns={columns}
        keyField="uuid"
        data={filteredEvaluations}
        expandableRows={true}
        expandableRowsComponent={ExpandedEvaluationRow}
      />
      <div className="d-flex flex-row justify-content-end align-items-end">
        <button type="button" className="btn" onClick={exportToCSV}>
          <FcDownload />
        </button>
      </div>
    </div>

    </div>
  
  );
};

export default EvaluationTableComponentLayout;

