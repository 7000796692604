import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

const QueryPanel = ({ title, description, ChartComponent }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Card className="user-panel-card" onClick={handleOpenModal}>
        <Card.Img className="user-panel-image" variant="top" src="/icons/image-placeholder.svg"/>
        <Card.Body>
          <Card.Title className="user-panel-title">{title}</Card.Title>
          <Card.Text className="user-panel-description">
            {description}
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChartComponent />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QueryPanel;
