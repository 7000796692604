// Snippet based on https://github.com/hswolff/BetterWeather/blob/master/js/weather/graph-utils.js
import * as scale from 'd3-scale';
import * as shape from 'd3-shape';

const d3 = { scale, shape };

function createScaleX(start, end, xRangeMin, xRangeMax) {
    const marginX = 8;
    const xRangeMinWithMargin = xRangeMin + marginX;
    const xRangeMaxWithMargin = xRangeMax - marginX;
    return d3.scale.scaleTime()
      .domain([start, end])
      .range([xRangeMinWithMargin, xRangeMaxWithMargin]);
}

function createScaleY(minY, maxY, height) {
    const margin = 8;
    return d3.scale.scaleLinear()
      .domain([minY, maxY]).nice()
      // We invert our range so it outputs using the axis that React uses.
      .range([height-margin, 10+margin]);
}
export function createTimeSeriesLine({data, accent, stackedData, width, height, pointColorFn, dateFn, isTriangleFn}) {
    const dataSorted = data
        .map(x => ({...x, date: dateFn(x)}))
        .sort((a,b) => a.date - b.date);
    const firstDatum = dataSorted[0];
    const lastDatum = dataSorted[dataSorted.length - 1];

    const accentItemUuid = ((!accent || accent === null || accent === undefined) ? null : accent.uuid);

    const xRangeMin = 0;
    const xRangeMax = width;
    const stackedYellowY0 = stackedData.v_avg;
    const stackedYellowY1 = stackedData.v_avg + stackedData.v_sd*2;

    const allYValues = dataSorted.map(datum => datum.v);
    //const extentY = d3Array.extent(allYValues);
    const scaleX = createScaleX(dateFn(firstDatum), dateFn(lastDatum), xRangeMin, xRangeMax);
    const scaleY = createScaleY(0, 150, height);
    const getLineShape = () => {
        return d3.shape
            .line()
            .x(datum => scaleX(dateFn(datum)))
            .y(datum => scaleY(datum.v));
    }
    const path = getLineShape()(dataSorted);
    const symbolGenerator = d3.shape
        .symbol()
        .type(d3.shape.symbolCircle)
        .size(40);
    const triangleGenerator = d3.shape
        .symbol()
        .type(d3.shape.symbolTriangle)
        .size(40);
    const starGenerator = d3.shape
        .symbol()
        .type(d3.shape.symbolStar)
        .size(20);

    const areaScaleX = (negOrPos) => {
        return negOrPos < 0 ? xRangeMin : xRangeMax; 
    }    

    const redAreaGenerator = d3.shape
        .area()
        .x(datum => areaScaleX(datum))
        .y1(datum => 0)
        .y0(scaleY(stackedYellowY1));
    
    const yellowAreaGenerator = d3.shape
        .area()
        .x(datum => areaScaleX(datum))
        .y1(datum => scaleY(stackedYellowY1))
        .y0(scaleY(stackedYellowY0));

    const greenLineGenerator = d3.shape
        .line()
        .x(datum => areaScaleX(datum))
        .y(scaleY(stackedData.v_avg));

    const yellowLineGenerator = d3.shape
        .line()
        .x(datum => areaScaleX(datum))
        .y(scaleY(stackedYellowY0));

    const redLineGenerator = d3.shape
        .line()
        .x(datum => areaScaleX(datum))
        .y(scaleY(stackedYellowY1));

    const circles = dataSorted.filter(datum => !isTriangleFn(datum)).map((datum,i) => {
        return [scaleX(dateFn(datum)), scaleY(datum.v), pointColorFn(datum)];
    });
    const triangles = dataSorted.filter(datum => isTriangleFn(datum)).map((datum,i) => {
        return [scaleX(dateFn(datum)), scaleY(datum.v), "blue"];
    });
    const accents = dataSorted.filter(datum => datum.uuid == accentItemUuid).map((datum,i) => {
        return [scaleX(dateFn(datum)), scaleY(datum.v+15), "white"];
    });

    const greenLine = greenLineGenerator([-1, 1]);
    const yellowLine = yellowLineGenerator([-1, 1]);
    const redLine = redLineGenerator([-1, 1]);

    return {
        path,
        symbol: symbolGenerator(dataSorted),
        triangle: triangleGenerator(dataSorted),
        star: starGenerator(dataSorted),
        redArea: redAreaGenerator([-1,1]),
        yellowArea: yellowAreaGenerator([-5,5]),
        circles,
        triangles,
        accents,
        greenLine,
        yellowLine,
        redLine
    };

}