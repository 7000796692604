import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams,
  useRouteMatch
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import InspectionEquipmentHierarchy from './components/InspectionEquipmentHierarchy';
import { useFilterContext } from "../context/SharedFilterContext";
import EvaluationTableComponent from './components/EvaluationTableComponent';

const AnalyticsScreen = (props) => {
  const params = useParams();
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  const {
    selectedProperty,
  } = useFilterContext();

  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={`${path}`}>
              {selectedProperty ?
                <Redirect to={`${path}/${selectedProperty.uuid}`} />
              :
                <PropertyActivityGrid
                  title={"Analysis"}
                  noResultsMessage="No properties found within selected date range, consider increasing the date."
                  hideAnalysisColors={false}
                  hideValCheck={true}
                  submitPath={`/analytics`}
                  isAnalyticsPage={true}
                  hideEndDate={false}
                  hideStructureInfo={false}
                  hideInspectionInfo={false}
                />
              }
            </PrivateRoute>
            <PrivateRoute path={`${path}/table/:propertyUUID`}>
            <div className="ml-1 mt-1">
                <PointAnalyticsTable />
              </div>
            </PrivateRoute>
            <PrivateRoute path={`${path}/:propertyUUID`}>
              <InspectionEquipmentHierarchy
                backPath={`/analytics`}
                showSendReport={true}
                sortByAnalysis={true}
                hideSheetsSection={true}
                sortByInspections={false}
                hideAnalysisColors={false}
                hideDateSelector={false}
                hideStartDate={false}
                hideEndDate={false}
                hideLayoutButton={false}
                hideValCheck={true}
                allowHidingComponentSection={true}
                allowHidingPointsSection={true}
                PointTableComponent={PointAnalyticsTable}
                EvaluationTableComponent={EvaluationTableComponent}
                // EquipmentEditComponent={PropertyTable}
              />
            </PrivateRoute>
          </Switch>
        </div>
    </div>
  )
}

export default AnalyticsScreen;