import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PointInspectionTable from './components/PointInspectionTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import InspectionEquipmentHierarchy from './components/InspectionEquipmentHierarchy';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import ReportTable from './components/ReportTable';
import { useFilterContext } from "../context/SharedFilterContext";
import UserTable from './components/UserTable';
import UserGrid from './components/UserGrid';

const ReportsScreen = (props) => {
  const params = useParams();
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  const {
    selectedProperty,
  } = useFilterContext();

  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={`${path}`}>
                {/* <Redirect to="/users/table" /> */}
                <UserTable showMyHistory={false}/>
            </PrivateRoute>
            <PrivateRoute path={`${path}/grid`}>
                <UserGrid />
            </PrivateRoute>
            <PrivateRoute path={`${path}/table`}>
              <UserTable showMyHistory={false}/>
            </PrivateRoute>
            {/* <PrivateRoute path={`${path}/:userUUID`}>
                
            
            </PrivateRoute> */}
          </Switch>
        </div>
    </div>
  )
}

export default ReportsScreen;