import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PointInspectionTable from './components/PointInspectionTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import InspectionEquipmentHierarchy from './components/InspectionEquipmentHierarchy';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import ReportTable from './components/ReportTable';
import { useFilterContext } from "../context/SharedFilterContext";

const ReportsScreen = (props) => {
  const params = useParams();
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  const {
    selectedProperty,
  } = useFilterContext();



  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={`${path}`}>
              {selectedProperty ?
                <Redirect to={`${path}/${selectedProperty.uuid}`} />
              :
                <PropertyActivityGrid
                  title={"Select a property to see related reports"}
                  noResultsMessage="No properties found."
                  hideAnalysisColors={true}
                  hideValCheck={true}
                  submitPath={`/reports`}
                  isInspectionPage={false}
                  hideEndDate={true}
                  hideStartDate={true}
                  hideStructureInfo={false}
                  hideInspectionInfo={true}
                  hideReportInfo={false}
                  sortByReports={true}
                  filterByReports={true}
                />
            }
            </PrivateRoute>
            {/* <PrivateRoute path={`${path}/table/:propertyUUID`}>
              <ReportTable/>
            </PrivateRoute> */}
            <PrivateRoute path={`${path}/:propertyUUID`}>
              <ReportTable title={`${(selectedProperty || {}).name || ""}`}/>
            </PrivateRoute>
          </Switch>
        </div>
    </div>
  )
}

export default ReportsScreen;