import { useEffect } from 'react';
import { useState } from 'react';
import Company from '../models/Company';

function AboutScreen () {
    const [result, setResult] = useState(null);

    useEffect(() => {
        load();
    }, [])

    const load = async () => {
        const a = await Company.all();
        console.log(a)
        setResult(a);
    }

    return (
        <div className="d-flex flex-row justify-content-center align-items-center">
            <div>
            <h2>About Us</h2>
            <p>We specialize in ultrasound diagnostic equipment...</p>
            </div>
        </div>
    );
}


export default AboutScreen;