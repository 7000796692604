import Model from "./Model";

export const ActionLogType = {
    msiConnected: 1,
    login: 2,
    sentReport: 3,
    reportRequested: 4,
    companyDataExported: 5,
    userloggedIn: 6,
    appUserLoggedIn: 7,
    msiDisconnected: 8,
    appAutoLogin: 9,
    webPlatformAutoLogin: 10,
    webPlatformLogin: 11
};