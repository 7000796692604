import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
    useHistory,
  useParams
} from "react-router-dom";
import '../../styles/grid.css';
import {NavSidebar} from '../components/NavSidebar';
import ActionLog from '../../models/ActionLog';
import {ActionLogType} from '../../models/ActionLogType';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { FcDocument, FcRefresh } from 'react-icons/fc';
import Utils from '../../common/Utils';
import SendReportModal from '../components/SendReportModal';
import Property from '../../models/Property';
import {useFilterContext} from '../../context/SharedFilterContext';
import { OverlayTrigger, Tooltip, Modal, Dropdown, Button, Form } from 'react-bootstrap';
import {MdArrowBack} from 'react-icons/md';

export const processContext = (context) => {
  try {
    const json = JSON.parse(context);
    return json;
  }catch(e){}
  return {}
}

const ReportTable = (props) => {
  const {user} = useAuth();
  const history = useHistory();
  const {selectedProperty, setSelectedProperty, setSelectedRoutine} = useFilterContext();
  const {title} = props;
  let {propertyUUID: providedPropertyUUID} = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allFlagged, setAllFlagged] = useState(false);
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && selectedProperty && selectedProperty.uuid) ? [selectedProperty.uuid] : []
    )
  );
  const [sendReportModal, setSendReportModal] = useState({});

  const sendReport = (context) => {
    console.log("sendReport", {context})
    setSendReportModal({
      context: {
        itemReportInput: {
          propertyUUID: _.first(selectedPropertyUUIDs),
        }
      },
      show: true,
      onHide: () => setSendReportModal({})
    })

  }

  const columns = React.useMemo(() => 
    [
      {
        name: 'Initiated By',
        selector: row => {
          const context = processContext(row.context)
          const email = context.emailInput && context.emailInput.to;
          return <a href={`mailto:${email}`}>{email}</a>
        },
        sortable: true,
        wrap: true,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: 'Request Date',
        selector: row => moment(row.created_at).unix(),
        cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
        sortable: true,
        center: true,
        compact: true
      },
      {
        name: 'Report Type',
        // selector: row => row.action_type_id,
        cell: row => processContext(row.context).itemReportInput && processContext(row.context).itemReportInput.reportTypeName,
        sortable: true,
        center: true,
        compact: true
      },
      {
        name: 'Report Link',
        // selector: row => row.action_type_id,
        cell: row => row.action_type_id === ActionLogType.sentReport ? <a href={processContext(row.context).reportS3Link.replace('email-reports', 'r')} target="_blank" rel="noopener noreferrer">Download</a> : "N/A",
        sortable: true,
        center: true,
        compact: true
      },
      {
        name: 'Date Range',
        // selector: row => row.action_type_id,
        cell: row => {
          const context = processContext(row.context).itemReportInput || {};
          return `
            ${moment.tz(context.startTimestamp, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).utc().format('M-D-YY')}
            to
            ${moment.tz(context.endTimestamp, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).utc().format('M-D-YY')}
          `
        },
        sortable: true,
        wrap: true,
        center: true,
        compact: true
      },
      {
        name: 'Actions',
        // selector: row => row.action_type_id,
        cell: row => <button type="button" className="btn btn-link" onClick={() => {
          setSendReportModal({
            context: processContext(row.context),
            onHide: () => setSendReportModal({})
          })
        }}>Re-run</button>,
        sortable: true,
        center: true,
        compact: true
      }
    ].filter(x => x!=null), [selectedPropertyUUIDs, JSON.stringify(reports)]
  );

  useEffect(() => {
    refreshReportResults();
  }, [allFlagged, selectedPropertyUUIDs])


  const refreshReportResults = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      return
    }

    setLoading(true);
    
    const results = await ActionLog.filterBy([
      // {"field": "user_uuid", "value": userUUID, "comparison": "="},
      {"field": "action_type_id", "value": JSON.stringify(
        [ActionLogType.sentReport]
        // [ActionLogType.sentReport, ActionLogType.reportRequested]
      ), "comparison": "IN"},
      {"field": "property_uuid", value: JSON.stringify(selectedPropertyUUIDs), comparison: "IN"}

      
    ], "created_at", "desc", 200);

    setReports(results.data);
    console.log("reports", results.data)
    setLoading(false);
  }

  return (
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        <SendReportModal options={sendReportModal}/>
        <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
            <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                <div className="d-flex flex-row align-items-baseline ">
                    <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                        setSelectedRoutine(null)
                        setSelectedProperty(null)
                        // history.goBack()
                        history.push("/reports")
                    }}>
                        <MdArrowBack />
                    </Button>

                    <div className="d-flex flex-column">
                        <h4>Reports</h4>
                        {/* <div class="font-weight-bold">{title}</div> */}
                    </div>
                </div>

                <div className="">
                    {(selectedPropertyUUIDs || []).length > 0 && <OverlayTrigger
                        placement="top"
                        delay={{ show: 400, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip">Request a new report</Tooltip>}
                    >
                    <a className={`btn btn-outline-secondary mr-2`} onClick={() => {
                        sendReport();
                        }}>
                        <div className="d-flex flex-row text-left">
                        <FcDocument style={{fontSize: 24}}/>
                        <div className="pl-2">Send Report</div>
                        </div>
                    </a>
                    </OverlayTrigger>}

                    <OverlayTrigger
                    placement="top"
                    delay={{ show: 400, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Refresh the results</Tooltip>}
                    >
                    <a className={`btn btn-outline-secondary`} onClick={() => {
                        refreshReportResults();
                        }}>
                        <div className="d-flex flex-row text-left">
                        <FcRefresh style={{fontSize: 24}}/>
                        <div className="pl-2">Refresh</div>
                        </div>
                    </a>
                    </OverlayTrigger>
                </div>

            </div>
            <div className="d-flex flex-row justify-content-center align-items-top flex-wrap">  
              <DataTable
                title="Reports"
                columns={columns}
                data={reports}
                noDataComponent = {"No matching results. Please select a different property or adjust filters."}
                responsive={true}
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                defaultSortField="Request Time"
                defaultSortAsc={false}
              />
            </div>


        
        </div>
    </div>
  )
}

export default ReportTable;