import Model from "./Model";
import XLSX from 'xlsx';
import Systems from "./Systems";
import { Storage } from 'aws-amplify';
import moment from "moment-timezone";

export default class Property extends Model {
    static searchByCompanyIds = async(query, companyIds) => {
        return this.searchBy("company_id", companyIds, query);
    }

    static searchByPropertyUuid = async(propertyUuid) => {
        return this.searchBy("uuid", propertyUuid);
    }

    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, ignoreCache=false) => {
        const data = await super.filterBy(PropertyFilter, searchList, sortField, sortOrder, first, after, last, before, ignoreCache);
        const result = data && data.propertyFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": `${uuid}`, "comparison": "="}
        ], "uuid", "asc", 1, undefined, undefined, undefined, true);
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }

    static getCardData = async (uuids, userId, startDate, endDate) => {
        const vars = {
            propertiesFilterInput: {
                searchList: [
                    {field: "archived", value: 0, comparison: "="},
                    {field: "uuid", value: JSON.stringify(uuids), comparison: "IN"}
                ],
                sortField: "last_upload_at",
                sortOrder: "asc",
                first: 100 
            },
            propertiesValFilterInput: {
                searchList: [
                    ...(startDate ? [{field: "recorded_at", value: startDate, comparison: ">="}] : []),
                    ...(endDate ? [{field: "recorded_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison: "<="}] : []),
                    {field: "archived", value: 0, comparison: "="},
                ],
                sortField: "recorded_at",
                sortOrder: "desc",
                first: 10000
            },
            propertiesChecksFilterInput: {
                searchList: [
                    // {field:"created_at", value: startDate, comparison:">"},
                    ...(startDate !== null ? [{field:"created_at", value: startDate, comparison:">="}] : []),
                    ...(endDate !== null ? [{field:"created_at", value: moment(endDate).add(1, 'day').format("YYYY-MM-DD"), comparison:"<="}] : []),
                    {field:"archived", value: 0, comparison:"="},
                ],
                sortField: "created_at",
                sortOrder: "desc",
                first: 1
            },
            propertiesPointInfosFilterInput: {
                searchList: [],
                sortField: "uuid",
                sortOrder: "desc",
                limit: 10000,
                first: 10000
            },
            userId: userId
        };
    
        const results = await this.runQueryNetworkOnly(PropertyCardQuery, vars);
        return results.data.propertyFilter?.edges?.map(edge => edge.node);
    };
    
    static searchBy = async (key="uuid", value, query="") => {
        const data = await super.searchBy(PropertySearch, key, value, query);
        return data && data.propertySearch.sort((a,b) => a.name.localeCompare(b.name));
    }

    static getFullPropertyByUUID = async (uuid, user_id, routine_uuid, startDate = "2000-01-01") => {
        const res = await super.filterBy(PropertyAllData, [
            {field: 'uuid', value: uuid, comparison: '='},
            // {field:"archived", value: 0, comparison:"="},
        ], undefined, undefined, 1, undefined, undefined, undefined, true, {
            user_id,
            routine_uuid,
            routineCountValFilterInput: {
                searchList: [
                    {"field": "recorded_at", "value": startDate, "comparison": ">"},

                ],
            },
        });
        return _.first(res && res.propertyFilter.edges && res.propertyFilter.edges).node;
    }

    static getPropertyFigsByUUID = async (uuid) => {
        const vars = {
            input: {
                query: "",
                fieldName: "uuid",
                filters: [{ 
                        id: uuid,
                        fieldName: "uuid"
                    }]
            }
        }
        const res = await this.runQueryNetworkOnly(PropertyFigInfo, vars);
        return _.first(res && res.data && res.data.propertySearch);
    }

    

    static getFilterablePropertyByUUID = async (uuid, user_id, startDate = "2000-01-01") => {
        const res = await super.filterBy(PropertyFilterData, [
            {field: 'uuid', value: uuid, comparison: '='},
            {field:"archived", value: 0, comparison:"="},
        ], undefined, undefined, 1, undefined, undefined, undefined, true, {
            routineCountValFilterInput: {
                searchList: startDate ? [{"field": "recorded_at", "value": startDate, "comparison": ">"}] : [],
            }
        });
        
        return _.first(res && res.propertyFilter.edges && res.propertyFilter.edges)?.node;
    }

    static getStructuralPropertyJSONByUUID = async (userCompanyName, uuid) => {
        const data = await super.filterBy(PropertyExport, [
            {field: 'uuid', value: uuid, comparison: '='}
        ], undefined, undefined, 1);

        const importableJSON = {
            "companies": [
                {
                    "companyDetails": {
                        "name": userCompanyName
                    },
                    "properties": data.propertyFilter.edges.map(p => {
                        const property = p.node;
                        return {
                            ...property,
                            figs: undefined,
                            equipments: property.rootFigs
                        }
                    })
                }
            ]
        }

        return importableJSON;
    }

    static getStructuralPropertyXLSXByUUID = async (userCompany, uuid) => {
        const importableJSON = await Property.getStructuralPropertyJSONByUUID(userCompany.name, uuid);
        let propertyName = "";
        const jsonPropertySheet = importableJSON.companies[0].properties.map(p => {
            propertyName = p.name;
            return {
                "COMPANY UUID": '',
                "PROPERTY UUID": '', //p.uuid,
                "PROPERTY NAME": p.name,
                "STREET1": p.street1,
                "STREET2": p.steet2,
                "CITY": p.city,
                "STATE": p.state,
                "ZIP": p.zip,
                "COUNTRY": p.country,
                "NOTES": p.note
            }
        });
        const propertySheet = XLSX.utils.json_to_sheet(jsonPropertySheet);
        propertySheet['!cols'] = [
            {wch: 15},{wch: 15},{wch: 30},{wch: 30},{wch: 15},{wch: 20},{wch: 10},{wch: 10},{wch: 30}
        ];

        const newSystemsDictionary = await Systems.getSystemsForCompanyId(userCompany.id, 'id')
        const jsonEquipmentSheet = importableJSON.companies[0].properties.map(p => {
            const allEquipmentAndComponents = []
            p.equipments.map(e => {
                allEquipmentAndComponents.push(e);
                e.subcomponents.map(s => {
                    allEquipmentAndComponents.push({...s, parent_name: e.name});
                })
            })
            return allEquipmentAndComponents.map(e => {
                return {
                    "PROPERTY UUID": p.name,
                    "EQUIPMENT UUID": '',
                    "EXTERNAL UUID": e.external_uuid,
                    "PARENT EQUIPMENT NAME": e.parent_name || '',
                    "EQUIPMENT NAME": e.name,
                    "MAKE": e.make,
                    "MODEL NUMBER": e.model_num,
                    "SERIAL NUMBER": e.serial_num,
                    "YEAR": e.year,
                    "NOTES": e.notes,
                    "DST SYSTEM": e.system_id in newSystemsDictionary ? newSystemsDictionary[e.system_id].name : '',
                    "CRITICALITY": e.criticality,
                    "BUILDING NAME": e.building,
                    "FLOOR": e.floor,
                    "AREA": e.area,
                }
            })
        });
        const equipmentSheet = XLSX.utils.json_to_sheet(_.flatten(jsonEquipmentSheet));
        equipmentSheet['!cols'] = [
            {wch: 30},{wch: 10},{wch: 10},{wch: 30},{wch: 30},{wch: 30},{wch: 20},{wch: 30},{wch: 5},{wch: 30},{wch: 30},{wch: 5},{wch: 30},{wch: 30},{wch: 30},
        ];

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, propertySheet, "WEB PROP TEMPLATE")
        XLSX.utils.book_append_sheet(workbook, equipmentSheet, "WEB EQPT TEMPLATE")
        if(!workbook.Props){
            workbook.Props = {};
        }
        workbook.Props.Title = `${propertyName} EXPORT`

        return workbook;
    }

    static downloadImportableXLSX = async (userCompany, propertyUUID) => {
        //Attempt Browser download
        const workbook = await Property.getStructuralPropertyXLSXByUUID(userCompany, propertyUUID);
        const wopts = { bookType:'xlsx', bookSST:false, type:'array'};
        XLSX.writeFile(workbook, `${workbook.Props.Title}.xlsx`, wopts)
    }

    static downloadImportableJSON = async (userCompany, propertyUUID) => {
        const importableJSON = await Property.getStructuralPropertyXLSXByUUID(userCompany.title || "", propertyUUID)
        const propertyName = _.first(_.first(importableJSON.companies).properties).name
        
        let element = document.createElement('a');
        element.setAttribute('href','data:text/json;charset=utf-8, ' + encodeURIComponent(JSON.stringify(importableJSON)));
        element.setAttribute('download', `${propertyName}_export.json`);
        document.body.appendChild(element);
        element.click();
    }

    static create = async(property) => {
        // Validate json? https://www.npmjs.com/package/ajv
        const res = await this.runMutation(PropertyCreate, {input: property});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Property.PropertyCreate.runMutation [COMPLETE]: ${JSON.stringify(res)}`);
        return res.data.createProperty;
    }

    static update = async(fields) => {
        if (fields.uuid === undefined || fields.uuid === null){
            console.log("Can't update property - no uuid specified")
            return
        }
        const res = await this.runQueryNetworkOnly(UpdateProperty, {input: {
            uuid: fields.uuid,
            name: fields.name,
            archived: fields.archived, 
            tz: fields.tz,
            street1: fields.street1,
            street2: fields.steet2,
            city: fields.city,
            state: fields.state,
            zip: fields.zip,
            country: fields.country, 
            lat: fields.lat, 
            lng: fields.lng,
            routine_uuid: fields.routine_uuid,
            note: fields.note,
            external_uuid: fields.external_uuid
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.updateProperty.runQuery [COMPLETE]`);
        return res;
    }

    static updatePhotoFromAlreadyUploadedFile = async (propertyUUID, fileName, contentType) => {
        const res = await this.runQueryNetworkOnly(UpdatePropertyPhoto, {input: {
            uuid: propertyUUID,
            image_input: {
                mimeType: contentType,
                key: `mobile-photos/${fileName}`, 
                bucket: "dst-tmp", 
                region: "us-west-2",
            }
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.updateProperty.runQuery [COMPLETE]`);
        return res;
    }

    static updatePhoto = async (propertyUUID, photoDataURI, photoFileMetadata) => {
        const extension = photoFileMetadata.name.split('.').pop() || 'jpg';
        const fileName = `${propertyUUID}` //`${Utils.uuid()}`; //don't include .${extension}
        const split = photoDataURI.split(',');
        const justBase64 = split[split.length - 1]
        const uInt8Array = Buffer.from(justBase64, 'base64')
        const buffer = Buffer(uInt8Array, 'binary')
        const customPrefix = {
            public: 'mobile-photos/',
            protected: 'mobile-photos/',
            private: 'mobile-photos/'
        };
        const contentType  = photoFileMetadata.type || `image/${extension}`;
        const result = await Storage.put(fileName, buffer, {customPrefix, contentType, level: "public", acl: "public-read"});
        const uploadedFilename = result.key;
        return this.updatePhotoFromAlreadyUploadedFile(propertyUUID, uploadedFilename, contentType);
    }

}

const UpdateProperty = `
mutation updateProperty($input: UpdatePropertyInput){
    updateProperty(input: $input){
      id
    }
  }
`

const UpdatePropertyPhoto = `
mutation updateProperty($input: UpdatePropertyInput){
    updateProperty(input: $input){
      id
    }
  }
`

const PropertySearch = `
    query PropertySearch($input:SearchInput){
        propertySearch(input: $input){
            id
            uuid
            name
            street1
            street2
            city
            state
            zip
            country
            lat
            lng
            note
            external_uuid
        
        }
    }
`;

const PropertyCreate = `
mutation CreateProperty($input:PropertyInput){
	createProperty(input: $input){
        uuid
    }
}
`;

const PropertyFilter = `
query PropertyFilter($input: FilterConnectionInput){
    propertyFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
          id
          archived
          updated_at
            created_at
            uuid
            name
            street1
            street2
            city
            state
            zip
            country
            lat
            lng
            note
            routine_uuid
            last_upload_at
            last_evaluation_at
            last_evaluation_user_id
            evalNum
            
            rootFigCount
            warningMode{
                id
                recorded_at
                overb
                reviewed_at
            }
            image {
                bucket
                key
                region
            }
        }
      }
    }
  }
`

const PropertyAllData = `
    query PropertySearch($input: FilterConnectionInput,$user_id: Int!, $routineUuid: ID, $routineCountValFilterInput: FilterConnectionInput){
        propertyFilter(input: $input){
        edges{
            node{
            updated_at
            created_at
            id
            external_uuid
            uuid
            name
            street1
            street2
            city
            country
            state
            zip
            note
            routine_uuid
            sheets(user_id: $user_id){
               id
               uuid
               global
               company_id
               property_uuid
               system_type_id
               system_id
               name
               tasks {
                   id
                   uuid
                   name
                   sheet_uuid
                   type_code
                   section_uuid
               }
               sections {
                   id
                   uuid
                   name
                   sheet_uuid
               }
            }
            figs {
                id
                uuid
                property_id
                external_uuid
                created_at
                archived
                key
                parent_key
                name
                updated_at
                system_id
                make
                notes
                year
                model_num
                serial_num
                criticality
                fig_image_key
                fig_image_ext
                image{
                    bucket
                    key
                    region
                }
                faceplate_image {
                    bucket
                    key
                }
                evaluations{
                    id
                    uuid
                    fig_id
                    started_at
                    ended_at
                    level
                    sound_level
                    comments
                    rec
                    net_samples_uuid
                    recorded_yhat_json
                    hash
                    user_id
                }
              
                pointInfos {
                    uuid
                    evalNum
                    parent_fig_name
                    measurement_id
                    num
                    created_at
                    measurement_name
                    measurement_sort_idx
                    measurement_non_sound
                    image {
                        bucket
                        key
                    }
                }
                evalNumLocal(routineUuid: $routineUuid)
                evalNumChildren(routineUuid: $routineUuid)
                warningModeLocal(routineUuid: $routineUuid){
                    id
                }
                warningModeChildren(routineUuid: $routineUuid){
                    id
                }
            }
    		routines {
                uuid
                name
                routinePoints {
                  routine_uuid
                  point_uuid
                }
                routineFigs {
                    fig_uuid
                    sort_idx
                    sheet_option
                    sheet_uuid
                }
                valsFilter(input: $routineCountValFilterInput){
                    edges{
                        node{
                          recorded_at
                          uuid
                          point_uuid
                          point_num
                          
                        }
                      }
                      totalCount
                }
            }
            image {
                bucket
                key
            }
        }
        }
    }
}
`;


const PropertyFigInfo = `
    query PropertySearch($input:SearchInput, $inspectionFilter: FilterConnectionInput){
        propertySearch(input: $input){
            updated_at
            created_at
            id
            external_uuid
            uuid
            name
            street1
            street2
            city
            country
            state
            zip
            note
            routine_uuid
            figs {
                id
                uuid
                created_at
                key
                parent_key
                name
                updated_at
                system_id
                make
                notes
                year
                model_num
                serial_num
                criticality
                fig_image_key
                fig_image_ext
                sheets {
                    id
                    uuid
                    global
                    company_id
                    property_uuid
                    system_type_id
                    system_id
                    name
                    tasks {
                        id
                        created_at
                        uuid
                        name
                        sheet_uuid
                        type_code
                        section_uuid
                    }
                    sections {
                        id
                        uuid
                        name
                        sheet_uuid
                    }
                }
                checks(input: $inspectionFilter){
                edges{
                    node{
                        created_at
                        uuid
                        task_uuid
                        type_code
                        fig_uuid
                        name
                    }
                }
                totalCount
            }
                
                     evaluations{
                    id
                    created_at
                    uuid
                    fig_id
                    started_at
                    ended_at
                    level
                    sound_level
                    comments
                    rec
                    net_samples_uuid
                    recorded_yhat_json
                    hash
                    user_id
                }
                image{
                    bucket
                    key
                    region
                }
            }
        }
    }
`;



const PropertyFilterData = `
    query PropertySearch($input: FilterConnectionInput, $routineCountValFilterInput: FilterConnectionInput){
        propertyFilter(input: $input){
        edges{
            node{

            updated_at
            created_at
            id
            external_uuid
            uuid
            name
            street1
            street2
            city
            country
            state
            zip
            note
    		routines {
                uuid
                name
                routinePoints {
                  routine_uuid
                  point_uuid
                }
                routineFigs {
                    fig_uuid
                    sort_idx
                    sheet_option
                    sheet_uuid
                }
                valsFilter(input: $routineCountValFilterInput){
                    edges{
                        node{
                          recorded_at
                          uuid
                          point_uuid
                          point_num
                        }
                      }
                      totalCount
                }
            }
            image {
                bucket
                key
            }
        }
    }
}
    }
`;


const PropertyExport = `
query PropertySearch($input: FilterConnectionInput){
    propertyFilter(input: $input){
    edges{
        node{
            id
            uuid
            external_uuid
            name
            street1
            street2
            city
            state
            zip
            country
            note
            rootFigs {
                id
                uuid
                external_uuid
                name
                model_num
                serial_num
                notes
                make
                system_id
                criticality
                year
                building
                floor
                area
                subcomponents{
                    id
                    uuid
                    external_uuid
                    name
                    model_num
                    serial_num
                    notes
                    make
                    system_id
                    criticality
                    year
                    building
                    floor
                    area
                }
            }
        }
        }
    }
}
`;



const PropertyCardQuery = `
    query PropertySearch(
        $propertiesFilterInput: FilterConnectionInput,
        $userId: Int!,
        $propertiesValFilterInput: FilterConnectionInput,
        $propertiesChecksFilterInput: FilterConnectionInput,
        $propertiesPointInfosFilterInput: FilterConnectionInput){
        propertyFilter(input: $propertiesFilterInput){
        edges{
            node {
                uuid
                    id
                    name
                    updated_at
                    street1
                    street2
                    lat
                    lng
                    city
                    state
                    zip


                    figs {
                        id
                        uuid
                        created_at
                        key
                        is_valid_mpe
                        parent_key
                        name
                        updated_at
                        system_id
                        make
                        notes
                        year
                        model_num
                        serial_num
                        criticality
                        fig_image_key
                        fig_image_ext
                        evaluations{
                            id
                            uuid
                            fig_id
                            started_at
                            ended_at
                            level
                            sound_level
                            comments
                            rec
                            net_samples_uuid
                            recorded_yhat_json
                            hash
                            user_id
                        }
                        sheets {
                            id
                            uuid
                            global
                            company_id
                            property_uuid
                            system_type_id
                            system_id
                            name
                            tasks {
                                id
                                created_at
                                uuid
                                name
                                sheet_uuid
                                type_code
                                section_uuid
                            }
                            sections {
                                id
                                uuid
                                name
                                sheet_uuid
                            }
                        }
                       
                    }

                    
                    
                last_upload_at
                last_evaluation_at
                last_evaluation_user_id
                warningMode{
                    id
                    recorded_at
                    overb
                    reviewed_at
                    
                }
                vals(input: $propertiesValFilterInput){
                    edges{
                      node{
                        uuid
                        point_num
                        recorded_at
                        wav{
                            unit_dec
                            user {
                                full_name
                                uuid
                                id
                            }
                        }
                      }
                    }
                    totalCount
                }
                pointInfos(input: $propertiesPointInfosFilterInput){
                    edges{
                      node{
                        uuid
                        evalNum
                        parent_fig_name
                        measurement_id
                        num
                        fig_id
                        created_at
                        updated_at
                        measurement_name
                        measurement_sort_idx
                        measurement_non_sound


                        fig {
                            id
                            uuid
                            created_at
                            key
                            parent_key
                            name
                            updated_at
                            system_id
                            make
                            notes
                            year
                            model_num
                            serial_num
                            criticality
                            fig_image_key
                            fig_image_ext
                            evaluations{
                                id
                                uuid
                                fig_id
                                started_at
                                ended_at
                                level
                                sound_level
                                comments
                                rec
                                net_samples_uuid
                                recorded_yhat_json
                                hash
                                user_id
                            }
                            sheets {
                                id
                                uuid
                                global
                                company_id
                                property_uuid
                                system_type_id
                                system_id
                                name
                                tasks {
                                    id
                                    created_at
                                    uuid
                                    name
                                    sheet_uuid
                                    type_code
                                    section_uuid
                                }
                                sections {
                                    id
                                    uuid
                                    name
                                    sheet_uuid
                                }
                            }
                           
                        }


                      }
                    }
                    totalCount
                }
                checks(input: $propertiesChecksFilterInput){
                    edges{
                      node{
                        created_at
                        uuid
                        user {
                            uuid
                            id
                            full_name
                        }
                      }
                    }
                    totalCount
                }
                lastValUploaded{
                    recorded_at
                    uuid
                    user {
                            full_name
                            uuid
                            id
                        }
                    wav{
                    user_id
                        user {
                            full_name
                            uuid
                            id
                        }
                    }
                }
                lastReportRun {
                    created_at
                    context
                }
                sheets(user_id: $userId) {
                    id
                    uuid
                    global
                    company_id
                    property_uuid
                    system_type_id
                    system_id
                    name
                    tasks {
                        id
                        created_at
                        uuid
                        name
                        sheet_uuid
                        type_code
                        section_uuid
                    }
                    sections {
                        id
                        uuid
                        name
                        sheet_uuid
                    }
                }
                
                image {
                    bucket
                    key
                    region
                }
            }
        }
        
    }
}
`;