import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import MultipointEvaluation from '../../models/MultipointEvaluation';
import Equipment from '../../models/Equipment';
import moment from 'moment';
import { EditModal } from './GenericEditModal';
import Evaluation from '../../models/Evaluation';
import { FaRegStickyNote, FaStickyNote } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const MPETable = ({ equipmentUUID, data: initialData, equipment }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [evaluationWithNetSamplesUUID, setEvaluationWithNetSamplesUUID] = useState(null);


  const calcAnalysisEvalIcon = (evalLevel) => {
    if (evalLevel && equipment) {
      const evalNum = evalLevel * equipment.criticality;
      const color = Equipment.calcSoundSigColor(evalNum);
      return (<div role="button" className="rounded-lg" style={{height: "17px", width: "17px", padding: "5px", backgroundColor: color}}></div>);
    }
    return null;
  };

  const renderEvalColorIconCell = (row) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {calcAnalysisEvalIcon(row.evalLevel)}
      {formatEvalCreatedAt(row.evalCreatedAt)}
    </div>
  );

  const formatEvalCreatedAt = (date) => {
    const d = new Date(date);
    const fullDate = d.toLocaleString();
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} title={fullDate}>
        <span>{`${d.getMonth() + 1}/${d.getDate()}`}</span>
        <span>{`${d.getFullYear()}`}</span>
      </div>
    );
  };

  const parseStringData = (data) => {
    const nodes = [];
    const nodePattern = /\{nodes=\[([^]+)\]\}/;
    const nodeMatches = data.match(nodePattern);

    if (nodeMatches) {
      const nodesString = nodeMatches[1];
      const itemPattern = /\{([^]+?)\}/g;
      let itemMatch;

      while ((itemMatch = itemPattern.exec(nodesString)) !== null) {
        const nodeString = itemMatch[1];
        const tagPattern = /tagUuids=\[([^\]]+)\]/;
        const tagMatch = nodeString.match(tagPattern);
        const tagUuids = tagMatch ? tagMatch[1].split(', ') : [];
        const evalPattern = /evalLevel=(\d+)/;
        const evalMatch = nodeString.match(evalPattern);
        const evalLevel = evalMatch ? parseInt(evalMatch[1], 10) : null;
        const actionPattern = /actionUuids=\[([^\]]+)\]/;
        const actionMatch = nodeString.match(actionPattern);
        const actionUuids = actionMatch ? actionMatch[1].split(', ') : [];
        nodes.push({ tagUuids, evalLevel, actionUuids });
      }
    }

    return nodes;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const fetchNamesByUUIDs = async (uuids, fetchFunction) => {
  //       const names = await Promise.all(uuids.map(async (uuid) => {
  //         const result = await fetchFunction(uuid);
  //         return result.length > 0 ? result[0].name : uuid;
  //       }));
  //       return names;
  //     };
  
  //     if (initialData) {
  //       console.log("Initial Data");
  //       const mpeTagsArray = JSON.parse(initialData.mpeTags);
  //       const mpeActionsArray = JSON.parse(initialData.mpeActions);
  
  //       const mpeTagsNames = await fetchNamesByUUIDs(mpeTagsArray, MultipointEvaluation.findTagsByUUID);
  //       const mpeActionsNames = await fetchNamesByUUIDs(mpeActionsArray, MultipointEvaluation.findActionsByUUID);
  
  //       const formattedInitialData = {
  //         id: initialData.id,
  //         evalLevel: initialData.evalColorIcon,
  //         evalCreatedAt: initialData.evalCreatedAt,
  //         mpeTags: mpeTagsNames,
  //         mpeActions: mpeActionsNames,
  //         comments: initialData?.comments || "N/A", // Assuming comments field is available in initialData
  //       };
  
  //       setData([formattedInitialData]);
  //       return;
  //     }
  
  //     try {
  //       setLoading(true);
  //       if (equipment && Array.isArray(equipment.evaluations)) {
  //         const evaluation = equipment.evaluations.find(evaluation => evaluation.net_samples_uuid);
  //         if (evaluation) {
  //           if (evaluation.recorded_yhat_json) {
  //             try {
  //               const parsedYhatJson = JSON.parse(evaluation.recorded_yhat_json);
  //               console.log("evaluation", evaluation);
  //               console.log("parsedYhatJson", parsedYhatJson);
  //               const parsedNode = parsedYhatJson;
  //               const evalCreatedAt = evaluation.created_at;
  //               console.log("parsedNodes", parsedNode); 
  
  //               const mpeTagsNames = await fetchNamesByUUIDs(parsedNode.tagUuids, MultipointEvaluation.findTagsByUUID);
  //               const mpeActionsNames = await fetchNamesByUUIDs(parsedNode.actionUuids, MultipointEvaluation.findActionsByUUID);
        
  //               const formattedData = {
  //                 id: evaluation.id,
  //                 evalLevel: parsedNode.evalLevel,
  //                 evalCreatedAt: evalCreatedAt, // Use timestamp from parsedYhatJson
  //                 mpeTags: mpeTagsNames,
  //                 mpeActions: mpeActionsNames,
  //                 comments: evaluation?.comments || "", // Assuming comments field is available in the evaluation
  //               };
        
  //               setData([formattedData]);
  //               setEvaluationWithNetSamplesUUID(evaluation); // Save evaluation to state
  //             } catch (error) {
  //               console.error("Error parsing recorded_yhat_json:", error);
  //             }
  //           }
  //         }
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching multipoint evaluation data:", error);
  //     }
  //   };
  
  //   fetchData();
  // }, [equipmentUUID, initialData, equipment]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchNamesByUUIDs = async (uuids, fetchFunction) => {
        const names = await Promise.all(uuids.map(async (uuid) => {
          const result = await fetchFunction(uuid);
          return result.length > 0 ? result[0].name : uuid;
        }));
        return names;
      };
  
      if (initialData) {
        console.log("Initial Data");
        const mpeTagsArray = JSON.parse(initialData.mpeTags);
        const mpeActionsArray = JSON.parse(initialData.mpeActions);
  
        const mpeTagsNames = await fetchNamesByUUIDs(mpeTagsArray, MultipointEvaluation.findTagsByUUID);
        const mpeActionsNames = await fetchNamesByUUIDs(mpeActionsArray, MultipointEvaluation.findActionsByUUID);
  
        const formattedInitialData = {
          id: initialData.id,
          evalLevel: initialData.evalColorIcon,
          evalCreatedAt: initialData.evalCreatedAt,
          mpeTags: mpeTagsNames,
          mpeActions: mpeActionsNames,
          comments: initialData?.comments || "N/A", // Assuming comments field is available in initialData
        };
  
        setData([formattedInitialData]);
        return;
      }
  
      try {
        setLoading(true);
        if (equipment && Array.isArray(equipment.evaluations)) {
          const evaluation = equipment.evaluations.find(evaluation => evaluation.net_samples_uuid);
          if (evaluation) {
            if (evaluation.recorded_yhat_json) {
              try {
                const parsedYhatJson = JSON.parse(evaluation.recorded_yhat_json);
                console.log("evaluation", evaluation);
                console.log("parsedYhatJson", parsedYhatJson);
                
                let parsedNode;
                if (parsedYhatJson.nodes) {
                  parsedNode = parsedYhatJson.nodes[0];
                } else {
                  parsedNode = parsedYhatJson;
                }
                
                const evalCreatedAt = evaluation.created_at;
                console.log("parsedNodes", parsedNode); 
  
                const mpeTagsNames = await fetchNamesByUUIDs(parsedNode.tagUuids, MultipointEvaluation.findTagsByUUID);
                const mpeActionsNames = await fetchNamesByUUIDs(parsedNode.actionUuids, MultipointEvaluation.findActionsByUUID);
        
                const formattedData = {
                  id: evaluation.id,
                  evalLevel: parsedNode.evalLevel,
                  evalCreatedAt: evalCreatedAt, // Use timestamp from parsedYhatJson
                  mpeTags: mpeTagsNames,
                  mpeActions: mpeActionsNames,
                  comments: evaluation?.comments || "", // Assuming comments field is available in the evaluation
                };
        
                setData([formattedData]);
                setEvaluationWithNetSamplesUUID(evaluation); // Save evaluation to state
              } catch (error) {
                console.error("Error parsing recorded_yhat_json:", error);
              }
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching multipoint evaluation data:", error);
      }
    };
  
    fetchData();
  }, [equipmentUUID, initialData, equipment]);
  const badgeStyle = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '90%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem',
    color: '#fff',
    backgroundColor: '#007bff',
    margin: '0.1em',
    maxWidth: '25ch'
  };

  const editNotes = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.id])
    setEditModal({
      title: "Update Comments",
      value: row,
      selector: row => row.comments || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const updatedEvaluation = { ...evaluationWithNetSamplesUUID, comments: newValue };
        const returnedRow = await Evaluation.update(updatedEvaluation);
        updateSingleEvaluation({ ...row, comments: newValue });
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.id));
      }
    });
  };

  const updateSingleEvaluation = (updatedEvaluation) => {
    setData(data.map(x => x.id === updatedEvaluation.id ? updatedEvaluation : x));
  }
  
  const renderComments = (row) => {
    const iconStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center',paddingLeft:'15px', height: '100%' };
  
    return row.comments === "N/A" || !row.comments
      ? <div style={iconStyle}>
          <FaRegStickyNote role="button" onClick={(e) => editNotes(row, e)} />
        </div>
      : <div style={iconStyle}>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip">{row.comments}</Tooltip>}>
            <FaStickyNote role="button" onClick={(e) => editNotes(row, e)} style={{ fontSize: 16 }} />
          </OverlayTrigger>
        </div>;
  };
  const columns = [
    {
      name: 'Evaluation',
      selector: row => row.evalLevel,
      cell: renderEvalColorIconCell,
    },
    {
      name: 'MPE Tags',
      selector: row => row.mpeTags,
      cell: row => (
        <div>
          {row.mpeTags.map((tag, index) => {
  const displayTag = tag.includes('^') ? tag.split('^')[1] : tag;
  return <span key={index} style={badgeStyle}>{displayTag}</span>;
})}
        </div>
      ),
    },
    {
      name: 'MPE Actions',
      selector: row => row.mpeActions,
      cell: row => (
        <div>
          {row.mpeActions.map((action, index) => {
  const displayAction = action.includes('^') ? action.split('^')[1] : action;
  return <span key={index} style={badgeStyle}>{displayAction}</span>;
})}
        </div>
      ),
    },
    {
      name: 'Comments',
      selector: row => row.comments,
      cell: renderComments,
      width: '100px', // Adjust the width to make the column less wide
    },
  ];

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <EditModal options={editModal} />
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
      />
    </div>
  );
};

export default MPETable;
