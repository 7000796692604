// Authorization Levels
export const SUPERADMIN = "superadmin"
export const ADMIN = "admin"
export const GUEST = "guest"
export const DST_SUPPORT_PHONE_NUMBER = "1-800-957-1424"
export const UNSAVED_WARNING_MESSAGE = 'You have unsaved edits on this page. Please save or cancel before navigating off this screen.';
export const TAG_THRESHOLD_PERCENT = 50;
export const ACTION_THRESHOLD = 0.50;

export const REPORT_TYPE = Object.freeze({
    INSPECTION_ALL: 0,
    INSPECTION_FLAGGED: 1,
    PROPERTY_ALL: 2,
    PROPERTY_FLAGGED: 3,
    ANALYSIS_ALL: 4,
    ANALYSIS_FLAGGED: 5,
    EQUIPMENT_OVERVIEW: 6,
    EQUIPMENT_DETAIL: 7,
    NON_DST_INSPECTION_ALL: 8,
    QR_CODE_PDF: 9,
    BLUEFIN_EXPORT: 10,
});

export const REPORT_TYPE_DESC = Object.freeze([
    'All Inspection Points',
    'Flagged Inspection Points',
    'All Property Points',
    'Flagged Property Points',
    'All Analysis Points',
    'Flagged Analysis Points',
    'Property Equipment Overview',
    'Property Equipment Details',
    'Technical Inspection Report',
    'QR Code Report'
    // 'Bluefin Export',
]);

export const EQUIPMENT_REPORT_TYPES = Object.freeze([
    REPORT_TYPE.EQUIPMENT_OVERVIEW,
    REPORT_TYPE.EQUIPMENT_DETAIL
]);

export const READING_REPORT_TYPES = Object.freeze([
    REPORT_TYPE.INSPECTION_ALL,
    REPORT_TYPE.INSPECTION_FLAGGED,
    REPORT_TYPE.PROPERTY_ALL,
    REPORT_TYPE.PROPERTY_FLAGGED,
    REPORT_TYPE.ANALYSIS_ALL,
    REPORT_TYPE.ANALYSIS_FLAGGED,
]);

export const EXPORT_REPORT_TYPES = Object.freeze([
    REPORT_TYPE.BLUEFIN_EXPORT
])

export const NON_DST_REPORT_TYPES = Object.freeze([
    REPORT_TYPE.NON_DST_INSPECTION_ALL
])

export const EXPORT_REPORT_CONDITIONS = {}
EXPORT_REPORT_CONDITIONS[REPORT_TYPE.BLUEFIN_EXPORT] = { companyGroupUUID: "1bd557fe-e53f-4ff8-810f-75f8811d501f" };

export const ANALYSIS_REPORT_TYPES = Object.freeze([
    REPORT_TYPE.ANALYSIS_ALL,
    REPORT_TYPE.ANALYSIS_FLAGGED
]);

export const SOUND_SIGNATURES = [
    {
        id: 1,
        name: '1',
        title: '1 (LEAST CRITICAL)'
    },
    {
        id: 2,
        name: '2',
        title: '2',
    },
    {
        id: 3,
        name: '3',
        title: '3',
    },
    {
        id: 4,
        name: '4',
        title: '4',
    },
    {
        id: 5,
        name: '5',
        title: '5 (MOST CRITICAL)',
    },
];


export const DST_ZONES = [
    {
        id: 1,
        name: 'Eastern',
        title: 'ET',
        code: 'US/Eastern'
    },
    {
        id: 2,
        name: 'Central',
        title: 'CT',
        code: 'US/Central'
    },
    {
        id: 3,
        name: 'Mountain',
        title: 'MT',
        code: 'US/Mountain'
    },
    {
        id: 4,
        name: 'Pacific',
        title: 'PT',
        code: 'US/Pacific'
    },
];

export const STATES = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
];

export const HEADERS = {
    analysis: 'You are online and analyzing the most recent data for this property.',
    inspection: 'You are viewing the Current Inspection status.',
    routine: (x)=>`You are editing the "${x ? x.name : ''}" routine.`,
};

export const USER_ROLES = {
    superadmin: "superadmin",
    limitedadmin: "limitedadmin",
    admin: "admin",
    intern: "intern",
    contractor: "contractor",
    contractorhardware: "contractorhardware",
    contractordataentry: "contractordataentry",
    subdomainadmin: "subdomainadmin",
    subdomaintech: "subdomaintech",
    subdomaincustomer: "subdomaincustomer",
    guest: "guest",
}

export const POINT_CONDITIONS = {
    NORMAL: 0,
    FLAGGED: 2,
    OVERBASELINE: 4
}

export const USER_RESTRICTED_ERROR = "Your account does not have the permissions required to continue.";

const alertBoxStyle = {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'mediumblue',
    justifyContent: 'center',
    alignItems: 'center',
    paddingStart: 10,
    paddingEnd: 10,
    marginBottom:10,
}

export const STYLES = {
    routineBox: {
        ...alertBoxStyle,
        backgroundColor: 'orange',
    },
    inspectionBox: {
        ...alertBoxStyle,
        backgroundColor: 'mediumblue',
    },
    analysisBox: {
        ...alertBoxStyle,
        backgroundColor: '#333333',
    },
    partAlertBox: {
        ...alertBoxStyle,
        backgroundColor: '#1d631d',
    },
    advancedAnalysisAlertBox: {
        ...alertBoxStyle,
        backgroundColor: '#1d631d',
    },

};

export const GOOGLEMAP_API_KEY = "AIzaSyDS5-HoJnPaYo-Mw4G3qI5UPUYqXX5c_2E";
export const GOOGLEMAP_GEOCODING_API_KEY = "AIzaSyBKni2RF9QNbDtvkqB5Sx6MbgP1_xdM08c";

export const STATUS_COLORS = Object.freeze({
    RED: '#FF1100',
    AMBER: '#FFBF00',
    YELLOW: '#FFFF00',
    LIME: '#BFFF00',
    GREEN: '#33CB24',
    GRAY: '#CCCCCC',
    LINE: '#666666',
})

export const NOD_SE_VALUE = 22616;

export const TASK_TYPE_DATE     = "DATE";
export const TASK_TYPE_VARCHAR  = "VARCHAR";
export const TASK_TYPE_TEMP     = "TEMP";
export const TASK_TYPE_PERC     = "PERC";
export const TASK_TYPE_BOOL     = "BOOL";
export const TASK_TYPE_AMP      = "AMP";
export const TASK_TYPE_INT      = "INT";
export const TASK_TYPE_TEXT     = "TEXT";
export const TASK_TYPE_PHOTO    = "PHOTO";
export const TASK_TYPE_VIDEO    = "VIDEO";
export const TASK_TYPE_VOLT     = "VOLT";
export const TASK_TYPE_VOLTSET  = "VOLTSET";
export const TASK_TYPE_PRESSURE = "PRESSURE";

export const TASK_TYPES = {
    [TASK_TYPE_DATE]:       {code:TASK_TYPE_DATE, name:"Date"},
    [TASK_TYPE_VARCHAR]:    {code:TASK_TYPE_VARCHAR, name:"Text"},
    [TASK_TYPE_TEMP]:       {code:TASK_TYPE_TEMP, name:"Temperature"},
    [TASK_TYPE_PERC]:       {code:TASK_TYPE_PERC, name:"Percentage"},
    [TASK_TYPE_BOOL]:       {code:TASK_TYPE_BOOL, name:"Check"},
    [TASK_TYPE_AMP]:        {code:TASK_TYPE_AMP, name:"Amperage"},
    [TASK_TYPE_INT]:        {code:TASK_TYPE_INT, name:"Integer"},
    [TASK_TYPE_TEXT]:       {code:TASK_TYPE_TEXT, name:"Long Text"},
    [TASK_TYPE_PHOTO]:      {code:TASK_TYPE_PHOTO, name:"Photo"},
    [TASK_TYPE_VIDEO]:      {code:TASK_TYPE_VIDEO, name:"Video"},
    [TASK_TYPE_VOLT]:       {code:TASK_TYPE_VOLT, name:"Voltage"},
    [TASK_TYPE_VOLTSET]:    {code:TASK_TYPE_VOLTSET, name:"Voltage Set"},
    [TASK_TYPE_PRESSURE]:   {code:TASK_TYPE_PRESSURE, name:"Pressure"},
};