import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { FcInspection, FcTimeline, FcLibrary, FcBiohazard, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness } from 'react-icons/fc';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form } from 'react-bootstrap';
import Utils from '../common/Utils';
import TaskingSheet from '../models/TaskingSheet';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import { ImSpinner3 } from 'react-icons/im';
import { toast } from 'react-toastify';

const TaskingSheetScreen = () => {
  const {
    user,
    isSuperAdmin,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher
  } = useAuth();
  const [sheets, setSheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSheetUUID, setSelectedSheetUUID] = useState(null);
  const [selectedSheet, setSelectedSheet] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  
  const updateSingleUpload = (updatedSheet) => {
    const sheetsList = sheets.length > 0 ? sheets : [];
    const indexOfExistingUpload = sheetsList.findIndex(x => x.uuid === updatedSheet.uuid);
    if (indexOfExistingUpload === -1){
      console.log("Couldn't find upload row")
      return
    }
    sheetsList[indexOfExistingUpload] = {...sheetsList[indexOfExistingUpload], ...updatedSheet};
    setSheets([...sheetsList]);
  }
  
  const updateSheet = async (row) => {
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await TaskingSheet.update({
      uuid: row.uuid, 
      global: row.global,
      default: row.default
    });
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const columns = React.useMemo(() => 
    [
      {
        name: 'Global',
        selector: row => row.global,
        cell: row => row.global
        ? <FaCheckCircle role="button" data-tag="allowRowEvents" onClick={async (e) => {
          if(isSuperAdmin()){
            updateSheet({...row, global: false})
          }else{
            toast.warning(`You do not have permissions to update this tasking sheet.`); 
          }
        }} style={{fontSize: 16}}/>
        : <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip">{row.global ? "Yes": "Make Global"}</Tooltip>}>
            <FaRegTimesCircle role="button" data-tag="allowRowEvents" onClick={async (e) => {
              if(isSuperAdmin()){
                updateSheet({...row, global: true})
              }else{
                toast.warning(`You do not have permissions to update this tasking sheet.`); 
              }
            }} style={{fontSize: 16}}/>
          </OverlayTrigger>,
        sortable: true,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: 'Default',
        selector: row => row.default,
        cell: row => row.default
        ? <FaCheckCircle role="button" data-tag="allowRowEvents" onClick={async (e) => {
          if(isSuperAdmin() || (isAdminOrHigher() && row.company && row.company.id === `${user.company_id}` )){
            updateSheet({...row, default: false})
          }else{
            toast.warning(`You do not have permissions to update this tasking sheet.`); 
          }
        }} style={{fontSize: 16}}/>
        : <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip">{row.default ? "Yes": "Make Default"}</Tooltip>}>
            <FaRegTimesCircle role="button" data-tag="allowRowEvents" onClick={async (e) => {
            if(isSuperAdmin() || (isAdminOrHigher() && row.company && row.company.id === `${user.company_id}` )){
              updateSheet({...row, default: true})
            }else{
              toast.warning(`You do not have permissions to update this tasking sheet.`); 
            }
        }} style={{fontSize: 16}}/>
          </OverlayTrigger>,
        sortable: true,
        center: true,
        compact: true,
        width: '14em'
      },
      {
        name: `Company`,
        selector: row => row.company && row.company.title || "",
        sortable: true,
        center: true,
        compact: true,
        wrap: true
      },
      {
        name: `Name`,
        selector: row => row.name,
        sortable: true,
        center: true,
        compact: true,
        wrap: true
      },
      {
        name: `System`,
        selector: row => row.system && row.system.name,
        sortable: true,
        center: true,
        compact: true,
      },
      // {
      //   name: `Property`,
      //   selector: row => row.property_uuid,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      //   wrap: true,
      //   width: '14em'
      // },
      {
        name: 'Created By',
        cell: row =>  row.user && <Link to={`/users/?q=${row.user.full_name}`}>{row.user.full_name}</Link>,
        sortable: true,
        compact: true,
        wrap: true,
      },
      {
        name: 'Created At',
        selector: row => moment(row.created_at).unix(),
        cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
        sortable: true,
        center: true,
        compact: true
      },
      // {
      //   name: 'Actions',
      //   // selector: row => row.action_type_id,
      //   cell: row => <button type="button" className="btn btn-link" onClick={() => {

      //   }}>TBD</button>,
      //   sortable: true,
      //   center: true,
      //   compact: true
      // },
    ].filter(x => x!=null), [JSON.stringify(sheets)]
  );

  useEffect(() => {
    refreshSheetInformation();
  }, [])

  useEffect(() => {
    refreshSingleSheetDetails(selectedSheetUUID);
  }, [selectedSheetUUID])

  const refreshSheetInformation = async () => {
    setLoading(true);
    let results = await TaskingSheet.all();
    results = results.filter(sheet => sheet.company_id === user.company_id || sheet.global)
    setSheets(results)
    setLoading(false);
  }

  const refreshSingleSheetDetails = async (uuid) => {
    if (!uuid) return;
    const fullSheetDetails = await TaskingSheet.fullSheet(uuid)
    setSelectedSheet(fullSheetDetails);
  }

  const ExpandedRowComponent = (props) => {
    const expandedRow = props.data;
    return (
      <>
        {expandedRow.uuid !== selectedSheet.uuid ? <ImSpinner3 className="icon-spin" style={{fontSize: 25}}/> : 
        <div className="w-100 d-flex flex-row justify-content-start align-items-top flex-wrap ml-5 mt-5">  
          <ul className="list-group">
            <li className="list-group-item">Sheet: {selectedSheet.name}</li>
            {(selectedSheet.tasks || []).map(task => 
                <ul className="list-group pl-5">
                  <li className="list-group-item">Sheet Task: {task.name} <span className="badge float-right ml-5">{task.type_code}</span></li>
                </ul>
            )}
            <div className="mt-5"/>
            {(selectedSheet.sections || []).map(section =>
                <ul className="list-group pl-5">
                  <li className="list-group-item">Section: {section.name}</li>
                  {(section.tasks || []).map(task => 
                      <ul className="list-group pl-5">
                        <li className="list-group-item">Task: {task.name} <span className="badge float-right ml-5">{task.type_code}</span></li>
                      </ul>
                  )}
                </ul>
            )}
          </ul>
        </div>
      }
      </>
    );
  }

  return (
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          <h3>Tasking Sheets and Tasks</h3>
            <div className="d-flex flex-row justify-content-end mb-4">

            <OverlayTrigger
              placement="top"
              delay={{ show: 400, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Refresh the results</Tooltip>}
            >
              <a className={`btn btn-outline-secondary`} onClick={() => {
                  refreshSheetInformation();
                }}>
                <div className="d-flex flex-row text-left">
                  <FcRefresh style={{fontSize: 24}}/>
                  <div className="pl-2">Refresh</div>
                </div>
              </a>
            </OverlayTrigger>

            </div>
            <div className="d-flex flex-row justify-content-center align-items-top flex-wrap">  
            <DataTable
                columns={columns}
                data={sheets}
                noDataComponent = {"No matching results."}
                responsive={true}
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRows
                onRowExpandToggled={(expanded, row) => {
                  if (expanded){
                    setSelectedSheetUUID(row.uuid)
                  }
                }}
                expandableRowsComponent={(props) => ExpandedRowComponent(props)}
                defaultSortAsc={false}
                defaultSortFieldId={3}
                disabled={loadingRowUUIDs.length > 0}
              />
            </div>


        
        </div>
    </div>
  )
}

export default TaskingSheetScreen;