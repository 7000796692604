import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import { Card, Button, Modal, Popover } from 'react-bootstrap';
import {EvaluationMark} from './EvaluationMark';
import { FaExclamationTriangle, FaMapMarkerAlt, FaEdit } from 'react-icons/fa';
import { FcBusinesswoman } from 'react-icons/fc';
import moment from 'moment';
import {useFilterContext} from '../../context/SharedFilterContext';
import { GOOGLEMAP_API_KEY } from '../../constants/Constants';
import {processContext} from './ReportTable';
import ImageUploading from 'react-images-uploading';
import Property from '../../models/Property';
import { ImSpinner3 } from 'react-icons/im';


const SoundDatabasePanel = (props) => {
  const { 
    title, 
    description,
    dest
  } = props;

  const {startDate} = useFilterContext();
  const [property, setProperty] = useState({});
  const [loading, setLoading] = useState(false);
  const cardOpacity = 0.5;


  useEffect(() => {
    
  });

  return (

    <a className="btn">
   
      <Card bg={'secondary'} key={'secondary'} style={{ width: '18rem', opacity: cardOpacity }}>
        <Card.Img variant="top" src="/icons/image-placeholder3.svg"/>
     
        <Card.Body>
          {}
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            {description}
          </Card.Text>
        </Card.Body>
      </Card>
    

      </a>
  
  )
}

export default SoundDatabasePanel;