import Model from "./Model";

export default class CompanyGroup extends Model{
  // static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before) => {
  //   const data = await super.filterBy(CompanyGroupFilter, searchList, sortField, sortOrder, first, after, last, before);
  //   const result = data && data.companyGroupFilter;
  //   return {
  //       data: result && (result.edges || []).map(e => e.node),
  //       pageInfo: result && result.pageInfo
  //   }
  // }

    static all = async () => {
        const res = await this.runQueryNetworkOnly(CompanyGroupSearch, {input: {
            query: ""
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ CompanyGroup.companyGroupSearch.runQuery [COMPLETE]`, res);
        return res.data.companyGroupSearch;
    }

    static create = async(companyGroup) => {
        const res = await this.runMutation(CompanyGroupCreate, {input: companyGroup});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ CompanyGroup.companyGroup.runMutation [COMPLETE]: ${JSON.stringify(res)}`);
        return res.data.createCompanyGroup;
    }
}

const CompanyGroupSearch = `
query CompanyGroupSearch($input:SearchInput) {
    companyGroupSearch(input: $input){
      id
      uuid
      title
  }
}
`;

const CompanyGroupCreate = `
    mutation CreateCompanyGroup($input:CompanyGroupInput){
        createCompanyGroup(input: $input){
            id
            uuid
            title
        }
    }
`;

const CompanyGroupFilter = `
query companyGroupFilter($input: FilterConnectionInput){
    companyGroupFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            id
            uuid
            created_at
            updated_at
            name
        }
      }
    }
  }
`