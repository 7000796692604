import { createClient, createRequest } from 'urql';
import { pipe, subscribe } from 'wonka';

export default class Model {
    static urqlClient = createClient({
        url: 'https://ewh7pcy2zjh6zcc2b3zsdpsuze.appsync-api.us-west-2.amazonaws.com/graphql',
        // url: 'https://7ymw6tbkmbds5buzwbmj6odley.appsync-api.us-west-2.amazonaws.com/graphql',

        fetchOptions: {
            headers: { 
                "Content-Type":"application/graphql", 
                "x-api-key":"da2-tovef3yejze3rpa5kzneqbokui",
                // "x-api-key":"da2-pe4pm4hlbnghdkwdyfhegfgwvm",
                'Access-Control-Allow-Origin': '*'
            }
        },
    });;

    static runQueryCachedFirst = (query, vars) => {
        return new Promise((resolve, reject) => {
            const request = createRequest(query, vars);
            pipe(this.urqlClient.executeQuery(request, {requestPolicy: 'cache-first'}), subscribe(res => {
                if (this.isResponseAnError(res)){
                    reject(res);
                }
                resolve(res);
            }));
        });
    }

    static runMutation = async(query, vars) => {
        return new Promise((resolve, reject) => {
            const request = createRequest(query, vars);
            pipe(this.urqlClient.executeMutation(request), subscribe(res => {
                if (this.isResponseAnError(res)){
                    reject(res);
                }
                resolve(res);
            }));
        });
    }

    static runQueryNetworkOnly = (query, vars) => {
        return new Promise((resolve, reject) => {
            const request = createRequest(query, vars);
            pipe(this.urqlClient.executeQuery(request, {requestPolicy: 'network-only'}), subscribe(res => {
                if (this.isResponseAnError(res)){
                    reject(res);
                }
                resolve(res);
            }));
        });
    }

    static runQuery = (query, vars) => {
        return this.runQueryNetworkOnly(query, vars);
    }

    static runQueryCacheAndNetwork = (query, vars) => {
        return new Promise((resolve, reject) => {
            const request = createRequest(query, vars);
            pipe(this.urqlClient.executeQuery(request, {requestPolicy: 'cache-and-network'}), subscribe(res => {
                if (this.isResponseAnError(res)){
                    reject(res);
                }
                resolve(res);
            }));
        });
    }

    static searchBy = async (graphQLQuery, key="uuid", value, query="", limit=15) => {
        const vars = {
            input: {
                query, 
                limit,
                fieldName: key,
                filters: value === null ? [] :
                    [{ 
                        id: value,
                        fieldName: key
                    }]
            }
        }
        const res = await this.runQueryNetworkOnly(graphQLQuery, vars);
        // console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ Model.searchBy.runQuery [COMPLETE]: ${JSON.stringify(res)}`);
        return res.data;
    }

    static filterBy = async (graphQLQuery, searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before, ignoreCache, additionalVars={}) => {
        const vars = {
            input: {
                searchList,
                sortOrder,
                sortField,
                first,
                last,
                after,
                before
            },
            ...(additionalVars || {})
        }
        //console.log("vars", vars)
        if (ignoreCache){
            const res = await this.runQueryNetworkOnly(graphQLQuery, vars);
            //console.log('res1', res)
            return res.data;
        }else{
            const res = await this.runQueryCacheAndNetwork(graphQLQuery, vars);
            //console.log('res2', res)
            return res.data;
        }
    }

    

    static isResponseAnError = (res) => {
        return res && !res.data && res.error !== undefined;
    }
}