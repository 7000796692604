import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  BrowserRouter as Router,
  Link,
  useParams
} from "react-router-dom";
import '../../styles/grid.css';
import {NavSidebar} from './NavSidebar';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import _ from 'lodash';
import { FcInspection, FcTimeline, FcLibrary, FcBiohazard, FcImport, FcServices, FcDocument, FcFactoryBreakdown, FcElectricalSensor, FcStatistics, FcRating, FcBadDecision, FcRefresh, FcBusiness } from 'react-icons/fc';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form } from 'react-bootstrap';
import Utils from '../../common/Utils';
import {FcDownload} from 'react-icons/fc';
import TaskingSheet from '../../models/TaskingSheet';
import { FaCheckCircle, FaTimesCircle, FaRegTimesCircle, FaStopCircle, FaExclamationTriangle, FaRegStickyNote, FaFlag } from 'react-icons/fa';
import { ImSpinner3 } from 'react-icons/im';
import { toast } from 'react-toastify';
import ExpandedCheckAnalyticsRow from './ExpandedCheckAnalyticsRow';
import Check from '../../models/Check';
import TaskTypeIcon from './TaskTypeIcon';
import {TASK_TYPES} from '../../constants/Constants'

const CheckSectionTable = (props) => {
  const {taskUUIDs, startDate, endDate, checks : providedChecks, isReadOnly} = props;
  const {
    user,
    isSuperAdmin,
    isCustomerTechOrHigher,
    isLimitedAdminOrHigher,
    isAdminOrHigher
  } = useAuth();
  let {sheetUUID: providedSheetUUID} = useParams();
  const [checks, setChecks] = useState(providedChecks || []);
  const [loading, setLoading] = useState(false);
  const [selectedSheetUUID, setSelectedSheetUUID] = useState(providedSheetUUID);
  const [selectedSheet, setSelectedSheet] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  
  useEffect(() => {
    setChecks(providedChecks);
  }, [providedChecks]);

  const columns = React.useMemo(() => 
    [
      {
        name: 'Task Name',
        selector: row => row.task && row.task.name,
        sortable: true,
        center: true,
        compact: true,
        width: '16em'
      },
      {
        name: `Value`,
        selector: cell => Check.formatCheckValueTextOnly(cell),
        cell: cell => {
          return <span>{Check.formatCheckValue(cell)}</span>
        },
        sortable: true,
        center: true,
        compact: true,
        wrap: true,
        width: '20em'
      },
      {
        name: 'Created At',
        selector: row => moment(row.created_at).unix(),
        cell: row => <span>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>,
        sortable: true,
        center: true,
        compact: true,
        
      }
    ].filter(x => x!=null), [JSON.stringify(checks)]
  );

  return (<>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        <div className="d-flex flex-column rounded-lg bg-white flex-grow-1 p-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
            <div className="d-flex flex-row justify-content-center align-items-top flex-wrap">  
            <DataTable
                columns={columns}
                data={checks}
                noDataComponent = {"No matching results."}
                responsive={true}
                progressPending={loading}
                persistTableHead
                noHeader
                highlightOnHover
                expandableRows={!isReadOnly}
                onRowExpandToggled={(expanded, row) => {
                  if (expanded){
                    setSelectedSheetUUID(row.uuid)
                  }
                }}
                expandableRowsComponent={!isReadOnly && ((props) => ExpandedCheckAnalyticsRow(props))} // Only assign component if not readOnly
                defaultSortAsc={false}
                defaultSortFieldId="created_at"
                disabled={loadingRowUUIDs.length > 0}
              />
            </div>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={() => {
        const columnNames = columns.map(col => `'${col.name}'`)
        const dataRowsAsTextOnly = checks.map(row => columns.map(col => col.selector ? `'${col.selector(row)}'` : ``))
        
        const csvCombined = [
          columnNames.join(','),
          ...(dataRowsAsTextOnly || []).map(row => row.join(','))
        ].join('\n');
          
        let element = document.createElement('a');
        element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
        element.setAttribute('download', `export.csv`);
        document.body.appendChild(element);
        element.click();
      }}>
      <FcDownload/>
    </button>
  </div>
</>
  )
}

export default CheckSectionTable;