import Model from "./Model";
import Utils from "../common/Utils";
import { ActionLogType } from "./ActionLogType";
import User from "./User";
export default class ActionLog extends Model {
    static filterBy = async (searchList=[], sortField="created_at", sortOrder="desc", first, after, last, before) => {
        const data = await super.filterBy(ActionLogFilter, searchList, sortField, sortOrder, first, after, last, before);
        const result = data && data.actionLogFilter;
        return {
            data: result && (result.edges || []).map(e => e.node),
            pageInfo: result && result.pageInfo
        }
    }

    static findByUUID = async (uuid) => {
        const results = await this.filterBy([
            {"field": "uuid", "value": `${uuid}`, "comparison": "="}
        ], "uuid", "asc", 1);
        return (results.data && results.data.length > 0) ? results.data[0] : [];
    }

    static createLog = async (fields) => {
        const res = await this.runQueryNetworkOnly(CreateActionLog, {input: {
            user_uuid: fields.user.uuid,
            company_uuid: fields.user.company && fields.user.company.uuid,
            action_type_id: fields.action_type_id,
            context: fields.context
        }});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ ActionLog.createLog.runQuery [COMPLETE]`);
        console.log("res", res)
        return res;
    }



}

const ActionLogFilter = `
query ActionLogFilter($input: FilterConnectionInput){
    actionLogFilter(input: $input){
        pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPrevPage
            pageCount
            totalCount
            startIndex
            endIndex
          }
      edges {
        node {
            uuid
            created_at
            user_id
            device_uuid
            user_uuid
            property_uuid
            company_uuid
            fig_uuid
            action_type_id
            msi_serial
            app_version
            msi_version
            context
        }
      }
    }
  }
`

const CreateActionLog = `
mutation LogAction($input: ActionLogInput!) {
    logAction(input:$input){
        uuid
    }
}
`