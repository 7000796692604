import Model from "./Model";
import Utils from "../common/Utils";

export default class Systems extends Model{
    static getSystemTypesForCompanyId = async(companyId) => {
        const res = await this.runQueryNetworkOnly(SystemTypesForCompanyId, {companyId});
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.systems.runQuery [COMPLETE]`);
        return res.data.systemTypesForCompany
    }

    static getAllSystemTypes = async() => {
        const res = await this.runQueryNetworkOnly(SystemTypes);
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.SystemTypes.runQuery [COMPLETE]`);
        return res.data.systemTypes
        
    }

    static getAllSystems = async() => {
        const res = await this.runQueryNetworkOnly(AllSystems);
        console.log(`@@@@@@@@@@@@@@@@@@@@@@@@@ User.Systems.runQuery [COMPLETE]`);
        return res.data.systems
        
    }

    static getSystemsForCompanyId = async(companyId, key = 'name') => {
        const systems = {}
        const res = await this.runQueryNetworkOnly(SystemTypesForCompanyId, {companyId});
        res.data.systemTypesForCompany.map(row => {
            row.systemType.systems.map(system => {
                systems[system[key]] = {
                    id: system.id,
                    system_id: system.id,
                    name: system.name,
                    system_type_id: row.systemType.id,
                    system_type_name: row.systemType.name,
                    notes: system.criticality_notes,
                    disabled: row.disabled
                }
            })
        })
        return systems;
    }
}

const SystemTypesForCompanyId = `
query SystemTypesForCompanyId($companyId : Int) {
    systemTypesForCompany(companyId: $companyId){
        company_id
        systemType{
            name
            id
            systems{
                id
                name
            }
        }
        primary
        secondary
        disabled
    }
}
`;

const SystemTypes = `
query SystemTypes {
    systemTypes{
      name
      id
      systems{
        id
        name
      }
    }
}
`;


const AllSystems = `
query Systems {
    systems{
      name
      id
    }
}
`;
