import React, { useEffect, useState } from 'react';
import Equipment from '../../models/Equipment';
import _ from 'lodash';
import { OverlayTrigger } from 'react-bootstrap';
import {FcImageFile, FcVideoFile, FcElectricity, FcDocument, FcCalendar, FcFlashOn, FcCalculator, FcCheckmark, FcPieChart, FcCollect, } from 'react-icons/fc';
import {FaTemperatureHigh} from 'react-icons/fa';
import {TASK_TYPES} from '../../constants/Constants';

const TaskTypeIcon = (props) => {
  const { 
    task_code,
    style
  } = props;

  return (
      <div>
          {task_code === TASK_TYPES.PHOTO.code && <FcImageFile style={style}/>}
          {task_code === TASK_TYPES.VIDEO.code && <FcVideoFile style={style}/>}
          {task_code === TASK_TYPES.AMP.code && <FcElectricity style={style}/>}
          {/* FcElectroDevices */}
          {task_code === TASK_TYPES.TEXT.code && <FcDocument style={style}/>}
          {task_code === TASK_TYPES.VOLT.code && <FcFlashOn style={style}/>}
          {task_code === TASK_TYPES.VOLTSET.code && <FcFlashOn style={style}/>}
          {task_code === TASK_TYPES.INT.code && <FcCalculator style={style}/>}
          {task_code === TASK_TYPES.BOOL.code && <FcCheckmark style={style}/>}
          {/* FcTodoList */}
          {task_code === TASK_TYPES.PERC.code && <FcPieChart style={style}/>}
          {/* FcDoughnutChart */}
          {task_code === TASK_TYPES.PRESSURE.code && <FcCollect style={style}/>}
          {/* FcMultipleInputs */}
          {task_code === TASK_TYPES.VARCHAR.code && <FcDocument style={style}/>}
          {task_code === TASK_TYPES.DATE.code && <FcCalendar style={style}/>}
          {task_code === TASK_TYPES.TEMP.code && <FaTemperatureHigh style={style}/>}
      </div>
  )
}

export default TaskTypeIcon;