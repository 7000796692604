import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';
import _ from 'lodash'; 
import Check from '../../models/Check';
import Utils from "../../common/Utils";
import moment from 'moment';
import SectionTaskGrid from './SectionTaskGrid';
import { MdPlaylistAddCheck, MdEdit, MdCheck, MdClear } from 'react-icons/md';




const TaskingSheetCard = (props) => {
  const { 
    selectSheet, 
    lastSelectedEquipment, 
    hideValCheckNumerator, 
    hideValInspectionCountNumerator, 
    hideInspectionCounts, 
    startDate, 
    endDate
  } = props;

  const [taskNumber, setTaskNumber] = useState(0);
  const [taskNumberDenom, setTaskNumberDenom] = useState(0);



  const sheets = lastSelectedEquipment && lastSelectedEquipment.sheets;
  const idSet = new Set();
const uniqueSheets = sheets && sheets.filter(sheet => {
  if (sheet.system_id && !idSet.has(sheet.system_id)) {
    idSet.add(sheet.system_id);
    return true;
  }
  return false;
});
  
  useEffect(() => {
    loadChecks(); 
  }, lastSelectedEquipment)

  const loadChecks = async () => {
    let sections = lastSelectedEquipment.sheets.length !== 0 ? lastSelectedEquipment.sheets[0].sections : [];
    let tasks = lastSelectedEquipment.sheets.length !== 0 ? lastSelectedEquipment.sheets[0].tasks : [];
    const taskUUIDs = [...(sections.map(section => section.tasks && section.tasks.map(task => task.uuid)) || []), ...(tasks.map(task => task.uuid) || [])].filter(x => x !== undefined);
    const figChecks = await Check.filterBy([
      {field: 'task_uuid', value: JSON.stringify(taskUUIDs), comparison: "IN"},
      {field: "archived", value: 0, comparison: "="},
    ], "created_at", "desc", 250, undefined, undefined, undefined, true );
    let resultChecks = figChecks.data
    setTaskNumberDenom(resultChecks.length);
    if (startDate && endDate){
      const oneDayAfterEndDate = moment(endDate).add(1, 'day');
      resultChecks = resultChecks.filter(x => moment.tz(x.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).isBetween(moment(startDate), oneDayAfterEndDate));
    }
    resultChecks = resultChecks.filter(x => x.fig_uuid === lastSelectedEquipment.uuid)
    setTaskNumber(resultChecks.length);
    // setChecks(resultChecks);
  }

  return (
    <Card className="user-panel-card" style={{ width: '650px' }}>
      {/* Adding back the old image */}
      <Card.Img  className="image-thumbnail rounded-lg"
 
      variant="top" 
      src="/icons/test_4.svg"
      style={{
        maxWidth: '100%',
        maxHeight: '450px',
        backgroundImage: `url("https://web.dstapp.com/icons/image-placeholder3.svg")`
      }}
      />
      
      <Card.Body>
        <Card.Title className="user-panel-title">Tasking Sheet</Card.Title>
        {uniqueSheets && uniqueSheets.length > 0 ? (
          uniqueSheets.map((sheet, index) => (
            <div key={index}>
              <Card.Text className="user-panel-description hoverable-text" onClick={() => selectSheet(sheet)}>
                {sheet.name}
                {/* {!hideInspectionCounts && lastSelectedEquipment.checks && (
                  <span className="badge badge-dark float-right">
                    {!hideValInspectionCountNumerator && (
                      <>
                        {!hideValCheckNumerator && (
                          <>
                            {taskNumber}
                            &nbsp;/&nbsp;
                          </>
                        )}
                      </>
                    )}
                    {
                      taskNumberDenom
                    }
                  </span>
                )} */}
                {!hideInspectionCounts && <span className="badge badge-dark float-right">
                    {taskNumber === 0 ? <MdClear /> : <MdCheck />}
                </span>}
              </Card.Text>
              <div 
                style={{ 
                  maxHeight: '200px', 
                  maxWidth: '100%', 
                  overflowY: 'auto', 
                  overflowX: 'auto', 
                  border: '1px solid #ccc', 
                  borderRadius: '3px', 
                  marginTop: '10px' 
                }}
              >
                <SectionTaskGrid
                  sections={sheet.sections}
                  tasks={sheet.tasks}
                  startDate={startDate}
                  endDate={endDate}
                  equipment={lastSelectedEquipment}
                  isReadOnly={true}
                />
              </div>
            </div>
          ))
        ) : (
          <Card.Text className="user-panel-description">No Tasking Sheets Currently Exist</Card.Text>
        )}
      </Card.Body>
    </Card>
);

  
  
};

export default TaskingSheetCard;
