import React, { useEffect, useState, } from 'react';
import { useAuth } from '../../context/AuthContext';
import PrivateRoute from '../../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import '../../styles/grid.css';
import moment from 'moment';
import Val from '../../models/Val';
import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD } from '../../constants/Constants';
import {EvaluationMark} from './EvaluationMark';
import _ from 'lodash';
import { FcDownload } from 'react-icons/fc';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Utils from '../../common/Utils';
import { FaStickyNote, FaRegFlag, FaExclamationTriangle, FaRegStickyNote, FaFlag, FaFileDownload } from 'react-icons/fa';
import {POINT_CONDITIONS} from '../../constants/Constants';
import ExpandedValAnalyticsRow from './ExpandedValAnalyticsRow';
import { toast } from 'react-toastify';
import { EditModal } from './GenericEditModal';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import {useFilterContext} from '../../context/SharedFilterContext';


const PointAnalyticsTable = (props) => {
  const { user } = useAuth();
  const history = useHistory();
  let {propertyUUID: providedPropertyUUID} = useParams();
  const {setSelectedProperty, setSelectedRoutine} = useFilterContext();
  const {pathname} = useLocation();
  const showBackButton = pathname.includes('/table/');
  const {selectedPointNumbers, selectedEquipment, selectedProperty, refreshCounter} = props;
  let rawUploads = [];
  const [uploads, setUploads] = useState([]);
  const [uploadPage, setUploadPage] = useState([]);
  const [loadingInspections, setLoadingInspections] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewRequired, setReviewRequired] = useState(false);
  const [allFlagged, setAllFlagged] = useState(false);
  const [reviewRequiredWarning, setEvaluationStatusWarning] = useState(false);
  const [selectedPropertyUUIDs, setSelectedPropertyUUIDs] = useState(
    providedPropertyUUID ? [providedPropertyUUID] : (
      (user && user.selectedProperty && user.selectedProperty.uuid) ? [user.selectedProperty.uuid] : []
    )
  );
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedRootEquipment, setSelectedRootEquipment] = useState(null);
  const [selectedSubcomponentList, setSelectedSubcomponentList] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [sendReportModal, setSendReportModal] = useState({});
  const [refreshPropertySelector, setRefreshPropertySelector] = useState(false);
  const [showDatatable, setShowDatatable] = useState(false);
  let currentMusic = new Audio();

  const updateSingleUpload = (updatedUpload) => {
    const uploadList = uploads.length > 0 ? uploads : rawUploads;
    const indexOfExistingUpload = uploadList.findIndex(x => x.uuid === updatedUpload.uuid);
    if (indexOfExistingUpload === -1){
      console.log("Couldn't find upload row")
      return
    }
    uploadList[indexOfExistingUpload] = {...uploadList[indexOfExistingUpload], ...updatedUpload};
    setUploads([...uploadList]);
  }

  const triggerPropertySelectorRefresh = () => {
    setRefreshPropertySelector(!refreshPropertySelector);
  }
  
  const markAsReviewed = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    console.log("Mark as Reviewed");
    console.log(row.uuid, user.id);
    const returnedRow = await Val.markAsReviewed(row.uuid, user.id);
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const editRecommendations = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    // console.log("edit rec uploads", uploads);
    // const newRecommendations = prompt("Update Recommendations or Repair", row.rec || "");
    // updateSingleUpload(uploads, {...row, rec: newRecommendations});
    // await Val.submitRecommendation(row.uuid, user.id, newRecommendations);
    // console.log("a", {...row, rec: newRecommendations});
    setEditModal({
      title: "Update Recommendations",
      value: row,
      selector: row => row.rec || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Val.submitRecommendation(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
    
  };

  const editFieldNotes = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Field Note",
      value: row,
      selector: row => row.desc || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Val.submitReview(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }

  const toggleFlagged = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await Val.updateConditions(
      row.uuid,
      user.id,
      (row.cond === POINT_CONDITIONS.FLAGGED) ? POINT_CONDITIONS.NORMAL : POINT_CONDITIONS.FLAGGED,
      row.secondary_noise
    );
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const toggleSecondaryNoise = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    const returnedRow = await Val.updateConditions(
      row.uuid,
      user.id,
      row.cond,
      !row.secondary_noise
    );
    await updateSingleUpload({...row, ...returnedRow});
    setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
  }

  const updateEvaluation = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Evaluation",
      value: row,
      selector: row => row.criticality || 0,
      type: "evaluation",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        console.log(newValue)
        // console.log(updatedFieldValue)
        const returnedRow = await Val.submitEval(row.uuid, user.id, newValue);
        await updateSingleUpload({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  }

  const getFigNameHierarchy = (row) => {
    return [
      row.point_info && row.point_info.adam_fig_name,
      row.point_info && row.point_info.adam_fig_name ? row.point_info && row.point_info.grandparent_fig_name : false,
      row.point_info && row.point_info.grandparent_fig_name ? row.point_info && row.point_info.parent_fig_name : false,
      row.point_info && row.point_info.parent_fig_name ? row.point_info && row.point_info.fig_name : false,
    ].filter(x => !!x).join(" ⮕ ")
  }

  const convertMp3Url = (mp3Url) => {
    //https://dst-mp3s.s3.amazonaws.com/c7820a9a-3d3f-4a2b-8e42-63f88f0d642c.mp3
    //mp3.dstapp.com/c7820a9a-3d3f-4a2b-8e42-63f88f0d642c.mp3
    return mp3Url.replace('dst-mp3s.s3.amazonaws.com/', 'mp3.dstapp.com/');
  }

  const extractFigImageUrl = row => row.point_info && row.point_info.fig && row.point_info.fig.image;
  const extractPropertyName = row => row.point_info && row.point_info.property_name;
  const extractEquipmentHierarchy = row => <span>{getFigNameHierarchy(row)}</span>;
  const extractPointName = row => row.point_info ? row.point_info.measurement_name : "";
  const renderRecordedAtFormatted = row => <span>{moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY HH:mm')}</span>
  const extractRecordedAtFormattedYMD = row => moment.tz(row.recorded_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
  const extractSE = row => row.v === NOD_SE_VALUE ? -1 : row.v;
  const renderSEAndPlayButton = row => 
    row.v === NOD_SE_VALUE ? "VISUAL" : 
    <><div role="button" onClick={() => {
      const newPlayerUrl = row.wav && row.wav.mp3Url;
      if (currentMusic.src === newPlayerUrl) {
        if (currentMusic.paused) {
          currentMusic.play();
        }else{
          currentMusic.pause();
        }
        return; 
      }
      currentMusic.pause();
      currentMusic.src = newPlayerUrl;
      let userConsentsToPlayingLoudInspection = true;
      if (row.v > 65){
        userConsentsToPlayingLoudInspection = confirm("The selected inspection may be very loud. Please turn down your volume or take off headphones/earpieces before continuing.")
      }
      console.log({userConsentsToPlayingLoudInspection})
      if (userConsentsToPlayingLoudInspection){
        currentMusic.play();
      }
    }}>
      {row.v}
      {row.wav && <img src="/icons/playpause.svg" className="ml-2 mr-2" width="17px" alt="Play/Pause"></img>}
    </div>
    {row.wav && row.wav.mp3Url && 
      <a class="ml-1" href={`${convertMp3Url(row.wav.mp3Url)}`} download>
        <FaFileDownload />
      </a>
    }
  </>
  const extractEvaluation = row => row.sig;
  const renderEvaluationmark = row => <EvaluationMark criticality={row.sig} onClick={async (e) => updateEvaluation(row, e)}/>;
  const extractAiEvaluation = row => row.aiSig;
  const renderAiEvaluationMark = row => <EvaluationMark criticality={row.aiSig}/>;
  const renderPLR = row => row.v === NOD_SE_VALUE ? ' ' : `${Math.round(row.plr)}%`;
  const extractTags = row => [
    !Utils.isBlank(row.reviewed_at) ? "reviewed" : (row.warningMode ? "review-required" : null),
    Val.isFlagged(row)
        ? (
          Val.isFlaggedImplicitly(row) ? "system-flagged-high-evaluation-or-has-notes": "flagged"
        ) : null,
    row.secondary_noise ? "secondary-noise" : null,      
  ].filter(x => x !== null).join(' ')

  const renderTags = row => {
    const filteredTags = [
      !Utils.isBlank(row.reviewed_at) ? // Show green exclamation if reviewed
        <FaExclamationTriangle style={{color: "#009900", fontSize: 30}} className="p-1" onClick={() => alert("Already Reviewed")}/>
      : row.warningMode && // Not reviewed and in warning mode, show yellow review required icon
      <img src="/icons/red-stop-sign.svg" style={{width: "30px", height: "30px"}} className="p-1 reviewRequiredIcon" onClick={() => markAsReviewed(row)}/>
      , Val.isFlagged(row)
        ? (
          Val.isFlaggedImplicitly(row) 
          ? <FaFlag role="button" className="p-1" style={{color: 'rgb(217, 72, 47)', fontSize: 28}} onClick={() => 
            toast.info("System flagged due to the high Evaluation or existence of field notes/recommendations. Modify those to resolve this flag.")
          }/>
          : <FaFlag role="button" className="p-1" style={{color: 'black', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
        )
        : <FaRegFlag role="button" className="p-1" style={{color: 'gray', fontSize: 28}} onClick={async (e) => toggleFlagged(row, e)}/>
      , row.secondary_noise
        ? <RiVolumeVibrateFill role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'black', fontSize: 32}}/>
        : <RiVolumeVibrateLine role="button" className="p-1" onClick={async (e) => toggleSecondaryNoise(row, e)} style={{color: 'gray', fontSize: 32}}/>,
      
    ].filter(x => x != false)

    return (
      <div className="d-flex flex-row justify-content-center flex-wrap ml-1 mt-1 mb-1">
        {filteredTags.map(x => _.isString(x) ? <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div> : (x || ''))}
      </div>
    )
  }
  const extractSoundSignatures = row => [
      (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
    row.accelse ? "Accelerating SE" : null,
      ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)).map(x => x.signature.name) : [])
    ].filter(x => x != null).join(' ')
  const renderSoundSignatures = row => {
    const filteredSignatures = [
      (row.overb || row.cond === POINT_CONDITIONS.OVERBASELINE) ? "Overbaseline" : null,
      row.accelse ? "Accelerating SE" : null,
      ...(row.signatures ? (row.signatures.filter(sig => (sig.pr * 100) >= TAG_THRESHOLD_PERCENT)) : [])
    ].filter(x => x != null)
    return (
      <div className="d-flex flex-row justify-content-center flex-wrap">
        {filteredSignatures.map(x => <div className="text-nowrap rounded-lg bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x.signature ? x.signature.name : x}</div>)}
      </div>
    )
  };

  const extractActions = row => {
    const filteredActions = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && x.action.name).filter(x => x != null)
    return filteredActions.join(' ')
  };

  const renderActions = row => {
    const filteredActionNames = (row.actions || []).filter(action => action.pr > ACTION_THRESHOLD).map(x => x.action && `${x.action.name}`).filter(x => x != null)
    return (
      <div className="d-flex flex-row justify-content-center flex-wrap">
        {filteredActionNames.map(x => <div className="text-nowrap rounded-sm bg-secondary text-white pl-1 pr-1 mr-2 mb-1">{x}</div>)}
      </div>
    )
  };
  const extractFieldNotes = row => row.desc || "___";
  const renderFieldNotes = row => Utils.isBlank(row.desc)
  ? <FaRegStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)}/>
  : <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip">{row.desc}</Tooltip>}>
      <FaStickyNote role="button" onClick={async (e) => editFieldNotes(row, e)} style={{fontSize: 16}}/>
    </OverlayTrigger>;
  const extractRecommendations = row => row.rec || "___";
  const renderRecommendations = row => Utils.isBlank(row.rec)
  ? <FaRegStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)}/>
  : <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip id="button-tooltip">{row.rec}</Tooltip>}>
      <FaStickyNote role="button" data-tag="allowRowEvents" onClick={async (e) => editRecommendations(row, e)} style={{fontSize: 16}}/>
    </OverlayTrigger>

  const columns =
    [
      (!selectedEquipment && !selectedPointNumbers) ? {
        name: `Equipment`,
        selector: getFigNameHierarchy,
        cell: extractEquipmentHierarchy,
        sortable: true,
        grow: 4, 
        width: '8%',
        center: true,
        compact: true,
      } : null,
      (selectedEquipment && !selectedPointNumbers || (pathname.includes('/table/'))) ? {
        name: 'Point Name',
        selector: extractPointName,
        sortable: true,
        compact: true,
        grow: 2, 
        wrap: true,
        width: '12em'
      } : null,
      {
        name: 'Inspection Date',
        selector: extractRecordedAtFormattedYMD,
        cell: renderRecordedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      },
      {
        name: 'SoundEnergy™',
        selector: extractSE,
        cell: renderSEAndPlayButton,
        sortable: true,
        center: true,
        compact: true,
        width: '90px'
      },
      {
        name: 'Evaluation',
        selector: extractEvaluation,
        cell: renderEvaluationmark,
        sortable: true,
        center: true,
        compact: true,
        width: '80px'
      },
      {
        name: 'SoundEvaluator™',
        selector: extractAiEvaluation,
        cell: renderAiEvaluationMark,
        sortable: true,
        center: true,
        compact: true,
        width: '110px'
      },
      {
        name: 'SE(%)',
        selector: renderPLR,
        sortable: true,
        center: true,
        compact: true,
        width: '125px'
      },
      {
        name: 'Tags',
        selector: extractTags,
        cell: renderTags,
        sortable: true,
        compact: true,
        center: true,
        width: '10em'
      },
      {
        name: 'SoundSignatures™',
        selector: extractSoundSignatures,
        cell: renderSoundSignatures,
        sortable: true,
        compact: true,
        wrap: true,
        center: true,
        // width: '150px'
        width: '25%' // Percentages instead of fixed pixel values

      },
      {
        name: 'Actions',
        selector: extractActions,
        cell: renderActions,
        sortable: true,
        center: true,
        compact: true,
        // width: '80px'
        width: '15%' // Percentages instead of fixed pixel values

      },
      {
        name: 'Field Notes',
        selector: extractFieldNotes,
        cell: renderFieldNotes,
        sortable: true,
        center: true,
        compact: true,
        width: '80px'
      },
      {
        name: 'Recommendations',
        selector: extractRecommendations,
        cell: renderRecommendations,
        sortable: true,
        center: true,
        compact: true,
        width: '120px'
      }
    ].filter(x => x != null)

  useEffect(() => {
    refreshUploads();
  }, [selectedPointNumbers, selectedEquipment, user.company_id, refreshCounter])

  const refreshUploads = async () => {
    if (Utils.isBlank(selectedPropertyUUIDs) || selectedPropertyUUIDs.length === 0){
      console.log("inside the if statement for refresh uploads"); 
      return
    }

    setLoadingInspections(true);
    const newUploads = await Val.filterBy([
      {field: 'archived', value: 0, comparison: "="},
      selectedPropertyUUIDs ? {field: "properties.uuid", value: _.first(selectedPropertyUUIDs), comparison: "="} : null,
      selectedEquipment ? {field: 'fig_id', value: selectedEquipment.id, comparison: "="} : null,
      selectedPointNumbers ? {field: "point_num", value: `${_.first(_.first(selectedPointNumbers).split('-'))}`, comparison: "="} : null,
    ].filter(x => x != null), "recorded_at", "desc", 250, undefined, undefined, undefined, true );


    let uploadList = newUploads.data || []

    uploadList = uploadList.map(upload => {
      return {
        ...upload,
        sortDay: moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(),
        parent_fig_key: upload.point_info && upload.point_info.parent_fig_key,
      }
    })
    .filter(x => !!x.point_info)

    if (allFlagged){
      uploadList = uploadList.filter(upload => Val.isFlagged(upload))
    }

    uploadList = _.orderBy(uploadList, [
      upload => moment(moment(upload.recorded_at).format("YYYY-MM-DD")).unix(), // Reversed Chronological(new to old)
      // upload => upload.point_info.parent_fig_key === null ? upload.point_info.fig_key + 1 : upload.point_info.parent_fig_key,
    ], ['desc', 'desc', 'desc'])
    
    if (reviewRequiredWarning){
        uploadList = _.orderBy(uploadList, [
          upload => upload.warningMode,
          upload => !Utils.isBlank(upload.reviewed_at),
          upload => upload.cond
        ], ['desc', 'desc', 'desc'])
    }

    rawUploads = uploadList;

    if(selectedEquipment !== undefined){
      uploadList = _.flatten(
        Object.values(
          _.groupBy(uploadList, row => extractPointName(row)
        )
      )
      .map(x => _.first(x)))
      .filter(x => {
        console.log("point analytics table row ", x);
        return !!x.point_info;
      })
      // .sort((a, b) => a.point_info && b.point_info && a.point_info.measurement_sort_idx - b.point_info.measurement_sort_idx)

      uploadList = _.orderBy(uploadList, [
        u => extractSE(u),
        u => u.warningMode,
      ], ['desc', 'desc'])
    }

    setUploads(uploadList);
    console.log("This is the uploads list");
    setUploadPage(newUploads.pageInfo);
    setLoadingInspections(false);
  }

 
  

  return (
    <>
    <div className="d-flex flex-row justify-content-start align-items-start">
        <EditModal options={editModal}/>
        <div className="d-flex flex-column rounded-lg bg-white mt-2 "  
          style={{minWidth: '800px'}}
        >
          {showBackButton && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                    setSelectedRoutine(null)
                    setSelectedProperty(null)
                    history.push("/analytics")
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Analysis</h4>
                </div>
            </div>}

          <div className="d-flex flex-column justify-content-start align-items-top flex-wrap mt-3"> 
            <DataTable
              title="MSi Inspections and Tasks"
              keyField="uuid"
              columns={columns}
              data={uploads}
              expandableRows
              noDataComponent = {"No matching results. Please select a different property or adjust filters."}
              // onRowClicked={(row) => {
              //   console.log("Row", {row});
              // }}
              responsive={false}
              dense
              // onSort={(column, sortDirection, event) => {
              //   console.log("handleSort", column, sortDirection, event);
              // }}
              // sortFunction={(rows, field, direction) => {
              //   console.log(rows, field, direction);
              //   return uploads;
              // }}
              // sortServer
              progressPending={loadingInspections}
              persistTableHead
              noHeader
              highlightOnHover
              expandableRowsComponent={ExpandedValAnalyticsRow}
              // defaultSortField="Inspection Date"
              // defaultSortAsc={false}
              selectableRows={false}
              disabled={loadingRowUUIDs.length > 0}
            />
          </div>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={() => {
        const columnNames = columns.map(col => `'${col.name}'`)
        const dataRowsAsTextOnly = uploads.map(row => columns.map(col => col.selector ? `'${col.selector(row)}'` : ``))
        
        const csvCombined = [
          columnNames.join(','),
          ...(dataRowsAsTextOnly || []).map(row => row.join(','))
        ].join('\n');
          
        let element = document.createElement('a');
        element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
        element.setAttribute('download', `export.csv`);
        document.body.appendChild(element);
        element.click();
      }}>
      <FcDownload/>
    </button>
  </div>
  </>
  )
}

export default PointAnalyticsTable;