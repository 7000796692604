import React, { useContext, createContext, useEffect, useState } from 'react';
import moment from 'moment';

export function ProvideFilterContext({ children }) {
  const auth = useProvideFilterContext();
  return (
    <SharedFilterContext.Provider value={auth}>
      {children}
    </SharedFilterContext.Provider>
  );
}

export function useFilterContext() {
  return useContext(SharedFilterContext);
}


export function useProvideFilterContext() {
  const defaultStartDate = moment().subtract(1, 'month').endOf("day").format("YYYY-MM-DD");
  const defaultEndDate = moment().endOf("day").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [selectedEquipmentList, setSelectedEquipmentList] = useState([]);
  const [propertyEquipmentList, setPropertyEquipmentList] = useState([]);

  // const lastSelectedEquipment = React.useMemo(
  //   () => _.last(selectedEquipmentList]
  // ), [selectedEquipmentList]);

  const setSelectedRootEquipment = (equipment) => {
    setSelectedEquipmentList([equipment]);
  }

  const updateEquipmentFromProperty = (updatedEquipment) => {
    const otherEquipment = propertyEquipmentList.filter(x => x.id !== updatedEquipment.id);
    setPropertyEquipmentList([...otherEquipment, updatedEquipment]);
  }


  useEffect(() => {
    () => {
      console.log("sharedFilterChanged: ", {startDate, endDate, selectedProperty, selectedRoutine, selectedEquipmentList});
    }
  }, [startDate, endDate, selectedProperty, selectedRoutine, selectedEquipmentList]);

  return {
    startDate,
    endDate,
    selectedProperty,
    selectedRoutine,
    setSelectedProperty: (property) => {
      console.log("SET A PROPERTY INTO SHARED FILTER CONTEXT")
      setSelectedProperty(property);
    },
    setSelectedRoutine,
    defaultStartDate,
    defaultEndDate,
    setStartDate,
    setEndDate,
    selectedEquipmentList,
    setSelectedEquipmentList,
    setSelectedRootEquipment,
    propertyEquipmentList,
    setPropertyEquipmentList,
    updateEquipmentFromProperty
  };
}

const SharedFilterContext = createContext({});
export default SharedFilterContext;
