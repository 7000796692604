import Model from "./Model";
import Utils from "../common/Utils";

export default class Makes extends Model{
    static getAllMakes = async() => {
        const res = await this.runQueryNetworkOnly(AllMakes);
        return res.data.makes.filter(make => make.validated === true);
    }
}

const AllMakes = `
query Makes {
    makes{
      name
      id
      validated
      title
    }
}
`;
