import React from 'react';
import { Card } from 'react-bootstrap';
import '../../styles/Panel.css';

const EvaluationTableCardLayout = (props) => {
  const { setShowEvaluationTable, property } = props;

//   const evaluationsCount = property.equipment.reduce((count, equipment) => count + (equipment.evaluations?.length || 0), 0);
const evaluationsCount = 0;

  return (
    <Card className="user-panel-card" onClick={() => setShowEvaluationTable(true)}>
      <Card.Img className="user-panel-image" variant="top" src="/icons/test_7.svg"/>
      <Card.Body>
        <Card.Title className="user-panel-title">Evaluations</Card.Title>
        {evaluationsCount !== 0 && (
          <Card.Text className="user-panel-description">
            Evaluations 
            <span className="badge badge-dark float-right">
              {evaluationsCount}
            </span>
          </Card.Text>
        )}
        {evaluationsCount === 0 && (
          <Card.Text className="user-panel-description">
            Evaluation Table
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default EvaluationTableCardLayout;