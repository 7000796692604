import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import PrivateRoute from '../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  useParams,
  useRouteMatch, 
  useHistory
} from "react-router-dom";
import '../styles/grid.css';
import {NavSidebar} from './components/NavSidebar';
import _ from 'lodash';
import PropertyTable from './components/PropertyTable';
import PointAnalyticsTable from './components/PointAnalyticsTable';
import PointInspectionTable from './components/PointInspectionTable';
import PropertyActivityGrid from './components/PropertyActivityGrid';
import InspectionDateSelector from './components/InspectionDateSelector';
import InspectionEquipmentHierarchy from './components/InspectionEquipmentHierarchy';
import PointInspectionTableCard from './components/PointInspectionTableCard';
import EvaluationTableCardLayout from './components/EvaluationTableCardLayout';
import TaskingSheetTableCard from './components/TaskingSheetTableCard';
import EvaluationTableComponent from './components/EvaluationTableComponent';
import EvaluationTableComponentLayout from './components/EvaluationTableComponentLayout';

import DatePicker from 'react-date-picker';
import moment from 'moment';



import { MdArrowBack } from 'react-icons/md';
import { OverlayTrigger, Tooltip, Modal, Col, Button, Form, Dropdown } from 'react-bootstrap';

import TaskingSheetTable from './components/TaskingSheetTable';


import { useFilterContext } from "../context/SharedFilterContext";

const InspectionsScreen = (props) => {
  const params = useParams();
  let { path, url } = useRouteMatch();
  const { user } = useAuth();
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedProperty,
    selectedRoutine,
    setSelectedProperty,
    setSelectedRoutine,
    propertyEquipmentList,  
  } = useFilterContext();

  const [showPointInspectionTable, setShowPointInspectionTable] = useState(false); 
  const [showTaskingSheetTable, setShowTaskingSheetTable] = useState(false); 
  const [showEvaluationTable, setShowEvaluationTable] = useState(false);

  const history = useHistory();

  return (
    <div className="d-flex flex-row justify-content-start align-items-start">
        
        <NavSidebar/>
        <div className="d-flex flex-column rounded-lg bg-white p-3 mt-2 "  
          style={{width:'87vw', minWidth: '800px'}}
        >
          <Switch>
            <PrivateRoute exact path={path}>
              {/* <InspectionDateSelector /> */}
              {selectedProperty ?
                <Redirect to={`${path}/${selectedProperty.uuid}`} />
              :
                <PropertyActivityGrid
                  title={"Inspections"}
                  noResultsMessage="No properties found within selected date range, consider increasing the date."
                  hideAnalysisColors={false}
                  submitPath={`/inspections`}
                  isInspectionPage={true}
                  hideStartDate={false}
                  hideEndDate={false}
                  hideStructureInfo={true}
                  hideInspectionInfo={false}
                />
            }
            </PrivateRoute>
            
            <PrivateRoute path={`${path}/table/:propertyUUID`}>
            {!showTaskingSheetTable && !showPointInspectionTable && !showEvaluationTable && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                  setShowTaskingSheetTable(false);
                  setShowPointInspectionTable(false);
                    history.push(`${path}/${selectedProperty.uuid}`)
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Layout View Cards</h4>
                </div>
              
            </div>}

{
            !showPointInspectionTable && !showTaskingSheetTable && !showEvaluationTable && <div>
                 {/* TIME AND Routine */}
                 <div className="d-flex flex-row flex-no-wrap justify-content-start align-items-center">
              {<>
                <span className="font-weight-bold align-self-center">Date Range:</span>

                { <>
                  <DatePicker onChange={(value) => {
                    const startDate = moment(value)
                    const newStartDate = startDate.endOf("day").format("YYYY-MM-DD");
                    setStartDate(newStartDate)
                  }} value={moment(startDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null}/>
                </>}

                {<>
                  <span>&nbsp;to&nbsp;&nbsp;</span>
                </>}

                {<>
                  <DatePicker onChange={(value) => {
                    const endDate = moment(value)
                    const newDate = endDate.endOf("day").format("YYYY-MM-DD");
                    setEndDate(newDate)
                  }} value={moment(endDate).toDate()} className="ml-1 mr-2" calendarIcon={null} clearIcon={null}/>
                </>}
              </>}


              <span className="font-weight-bold">Routine: </span>
              <Dropdown>
                <Dropdown.Toggle variant="outline" id="dropdown-basic">{selectedRoutine && selectedRoutine.name || "None Selected"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {
                    setSelectedRoutine(null);
                  }}>None Selected</Dropdown.Item>
                  {selectedProperty?.routines && selectedProperty?.routines.map(routine => {
                    return <Dropdown.Item key={routine.uuid} onClick={(e) => {
                      setSelectedRoutine(routine)
                    }}>
                      {routine && routine.name}
                    </Dropdown.Item>
                  })}
                </Dropdown.Menu>
              </Dropdown>

              <div>
                {selectedRoutine && <span className="">
                  <span className="font-weight-bold ml-3 mr-2">Completion: </span>
                  {selectedRoutine.valsFilter && selectedRoutine.valsFilter.edges && Object.values(_.groupBy(selectedRoutine.valsFilter.edges.filter(x => moment(x.node.recorded_at).isAfter(moment(startDate))), x => x.node.point_num)).map(x => _.first(x)).length || 0}
                  &nbsp;/&nbsp;
                  {selectedRoutine.routinePoints.length}
                </span>}
              </div>
              </div>
            </div>
}

            <div className="ml-1 mt-1 d-flex">
              {showPointInspectionTable && 
              <div>
              <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={() => {setShowPointInspectionTable(false);}} >Back To Cards</a>

              <PointInspectionTable locked={false}  setShowPointInspectionTable={setShowPointInspectionTable}/>
              </div>
              }
              {!showTaskingSheetTable && !showPointInspectionTable && !showEvaluationTable && <PointInspectionTableCard property={selectedProperty} setShowPointInspectionTable={setShowPointInspectionTable} />
              
              }

              {showTaskingSheetTable && 
              <div>
              <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={() => {setShowTaskingSheetTable(false);}} >Back To Cards</a>

              <TaskingSheetTable  setShowTaskingSheetTable={setShowTaskingSheetTable}/>
              </div>
              }
              {!showPointInspectionTable && !showTaskingSheetTable && !showEvaluationTable && <TaskingSheetTableCard setShowTaskingSheetTable={setShowTaskingSheetTable} />}


              {showEvaluationTable && 
              <div>
              <a type="button" className="btn btn-outline-secondary btn-sm mr-1 " onClick={() => {setShowEvaluationTable(false);}} >Back To Cards</a>

              <EvaluationTableComponentLayout property={selectedProperty} startDate={startDate} endDate={endDate} />
              </div>
              }
              {!showPointInspectionTable && !showTaskingSheetTable && !showEvaluationTable && <EvaluationTableCardLayout setShowEvaluationTable={setShowEvaluationTable} />}


           </div>
</PrivateRoute>

            <PrivateRoute path={`${path}/properties`}>
              {selectedProperty ?
                <Redirect to={`${path}/${selectedProperty.uuid}`} />
              :
                <PropertyActivityGrid
                  title={"Inspections"}
                  noResultsMessage="No properties found within selected date range, consider increasing the date."
                  hideAnalysisColors={false}
                  submitPath={`/inspections`}
                  isInspectionPage={true}
                  hideStartDate={false}
                  hideEndDate={false}
                  hideStructureInfo={true}
                  hideInspectionInfo={false}
                />
            }
            </PrivateRoute>
            <PrivateRoute path={`${path}/:propertyUUID`}>
              <InspectionEquipmentHierarchy
                sortByInspections={true}
                showSendReport={true}
                hideAnalysisColors={false}
                hideExpandedPointAnalysisColors={true}
                backPath={`/inspections`}
                hideDateSelector={false}
                hideStartDate={false}
                hideEndDate={false}
                hideLayoutButton={false}
                hideValCheck={false}
                allowHidingComponentSection={true}
                allowHidingPointsSection={true}
                PointTableComponent={PointInspectionTable}
                isInspectionPage={true}
                EvaluationTableComponent={EvaluationTableComponent}

                // EquipmentEditComponent={PropertyTable}
              />
            </PrivateRoute>
          </Switch>
        </div>
    </div>
  )
}

export default InspectionsScreen;