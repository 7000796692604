import React, { useEffect, useState } from 'react';
import User from "../../models/User";
import Utils from "../../common/Utils";
import Company from "../../models/Company";
import {useAuth} from '../../context/AuthContext';
import DataTable from 'react-data-table-component';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { withTheme } from '@rjsf/core';
import { Theme as Bootstrap4Theme } from '../../theme/bootstrap-4';
const Form = withTheme(Bootstrap4Theme);
import UserEditScreen from '../user/UserEdit';
import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { FcRefresh } from 'react-icons/fc';
import UserHistoryHorizontalScreen from './UserHistoryHorizontalScreen';
import TaskCard from './TaskCard';
import Check from '../../models/Check';

const TaskGrid = (props) => {
  const {tasks: providedTasks, taskSelectFunction, checkSelectFunction} = props;
  const [tasks, setTasks] = useState(providedTasks || []);
  const [checks, setChecks] = useState([]);
  const {
    user,
    isAdminOrHigher,
    isSuperAdmin
  } = useAuth();
  // const defaultSearchOptions = {
  //   filters: [
  //     {"field": "full_name", "value": userQuery || "", "comparison": "LIKE"}
  //   ],
  //   sortOrder: "asc",
  //   sortField: "full_name",
  //   company_id: user.company_id || "0"
  // };
  // const [searchOptions, setSearchOptions] = useState(defaultSearchOptions);
  
  // const updateCompanies = async () => {
  //   const allCompanies = await Company.all();
  //   setCompanies(allCompanies);
  // }

  useEffect(() => {
    queryChecks();
  }, [JSON.stringify(tasks)])
  
  // useEffect(() => {
  //   searchUsers();
  // }, [JSON.stringify(searchOptions)])

  // useEffect(() => {
  //   setSearchOptions({
  //     ...setSearchOptions,
  //     filters: debouncedUserQuery ? searchOptions.filters.map(f => f.field === "full_name" ? {...f, value: debouncedUserQuery || ""} : f) : searchOptions.filters
  //   });
  // }, [debouncedUserQuery])
  
  // const onSubmit = async data => {
  //   const updatedSearchOptions = data.formData;
  //   setSearchOptions(updatedSearchOptions);
  //   handleFilterModalClose();
  // }

  // const userHistoryHorizontalScreen = React.useMemo(() => {
  //   return <UserHistoryHorizontalScreen
  //   userUUID={user.uuid}
  //   userID={user.id}
  //   queryUserInfo
  //   queryUploads
  //   queryChecks
  //   showArrows
  //   vertical
  //   noResultsMessage="No User History."
  // ></UserHistoryHorizontalScreen>
  // }, [user])

  // const selectedUserHistoryHorizontalScreen = React.useMemo(() => {
  //   return <UserHistoryHorizontalScreen
  //   userUUID={userHistory ? userHistory.uuid : ""}
  //   userID={userHistory ? userHistory.id : ""}
  //   queryUserInfo
  //   queryUploads
  //   queryChecks
  //   multiRow
  //   noResultsMessage="No User History."
  //   daysAgoCutoff={1000}
  // ></UserHistoryHorizontalScreen>
  // }, [userHistory])

  const queryChecks = async () => {
    const results = await Check.filterBy([
      {field: "task_uuid", value: JSON.stringify(tasks.map(t => t.uuid)), comparison: "IN"}
    ])
    console.log("checks", {results})
    const checks = results.data;
    setTasks(tasks.map(t => {
      return {...t, "checks": checks.filter(c => c.task_uuid === t.uuid)}
    }));

  }

  // const searchUsers = async () => {
  //   setLoading(true);
  //   const companyID = user && user.company_id;
  //   const searchAllCompanies = searchOptions.company_id === "0";
  //   const results = await User.filterBy([
  //     // {"field": "archived", "value": 0, "comparison": "="},
  //     searchAllCompanies ? {"field": "company_id", "value": `0`, "comparison": "<>"} : {"field": "company_id", "value": `${companyID}`, "comparison": "="},
  //     !Utils.isBlank(searchOptions.company_id) && searchOptions.company_id > 0 ? {"field": "company_id", "value": `${searchOptions.company_id}`, "comparison": "="} : {"field": "company_id", "value": "-1", "comparison":"<>"},
  //     ...searchOptions.filters
  //   ], searchOptions.sortField || "full_name", searchOptions.sortOrder || "asc", 1000000);
  //   results.data = _.orderBy(results.data, [
  //     user => user.archived || user.state === 'passive',
  //     user => user.full_name,
  //   ], ['asc', 'asc']);
  //   setTasks(results.data);
  //   console.log("user results", results)
  //   setLoading(false);
  // }

  return (
  <>
    <div className="d-flex flex-row justify-content-between flex-sm-row">
        <div className="d-flex flex-column bg-white flex-grow-1 pl-3 pr-3"  style={{overflow: 'hidden', margin: "10px 10px 10px 10px"}}>
          <div className="d-flex flex-row justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 ml-2 mr-2 mb-3 ">
              <h1 className="h2">Tasks</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                {/* <input
                id="search"
                type="text"
                autoComplete="false" 
                placeholder="Quick Search by Name"
                aria-label="Search by Name"
                value={userQuery || ''}
                // onSubmit={e => e.preventDefault()}
                className="mt-2"
                onChange={onSearchChange} />

                <ButtonToolbar>
                  <ButtonGroup className="ml-2 mt-2">
                    <Button className="btn btn-primary" onClick={handleFilterModalShow}>
                    Advanced Filters
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-2 mt-2 mr-2">
                    <Button variant="success" onClick={() => { setShowNewUserModal(true); }}>
                    New User
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup className="ml-1 mt-2 mr-1">
                    <a className={`btn btn-outline-secondary`} onClick={() => {
                      searchUsers();
                    }}>
                      <div className="d-flex flex-row text-left">
                        <FcRefresh style={{fontSize: 24}}/>
                        <div className="pl-2">Refresh</div>
                      </div>
                    </a>
                  </ButtonGroup>
                </ButtonToolbar> */}
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap">
              {tasks.map(task => {
                return <TaskCard key={task.uuid}
                  taskSelectFunction={taskSelectFunction}
                  checkSelectFunction={checkSelectFunction}
                  task={task}
                ></TaskCard>
              })}
            </div>
          </div>
    </div>
    </>
  )
}


export default TaskGrid;