import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import PrivateRoute from '../../routeUtilities/PrivateRoute';
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory,
  useLocation
} from "react-router-dom";
import '../../styles/grid.css';
import moment from 'moment';
import Val from '../../models/Val';
import DataTable from 'react-data-table-component';
import { NOD_SE_VALUE, TAG_THRESHOLD_PERCENT, ACTION_THRESHOLD } from '../../constants/Constants';
import {EvaluationMark} from './EvaluationMark';
import _ from 'lodash';
import { FcDownload } from 'react-icons/fc';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {RiVolumeVibrateLine, RiVolumeVibrateFill} from 'react-icons/ri';
import Utils from '../../common/Utils';
import { FaStickyNote, FaRegFlag, FaExclamationTriangle, FaRegStickyNote, FaFlag, FaFileDownload } from 'react-icons/fa';
import {POINT_CONDITIONS} from '../../constants/Constants';
import ExpandedValAnalyticsRow from './ExpandedValAnalyticsRow';
import { toast } from 'react-toastify';
import { EditModal } from './GenericEditModal';
import  EvaluationSeverityModal from './EvaluationSeverityModal';

import { ButtonGroup, Button, Modal, ButtonToolbar, Row, Col, Container } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import {useFilterContext} from '../../context/SharedFilterContext';
import Evaluation from '../../models/Evaluation';
import Equipment from '../../models/Equipment';
import MultipointEvaluation from '../../models/MultipointEvaluation';



import { ImSpinner3 } from 'react-icons/im';

const LoadingSpinner = () => {
  return <ImSpinner3 className="icon-spin" style={{fontSize: 14}}/>
}

const EvaluationTableComponent = (props) => {
  const history = useHistory();
  const { user, isSuperAdmin } = useAuth();

  const {selectedProperty, setSelectedProperty, setSelectedRoutine, startDate, endDate} = useFilterContext();


  const {pathname} = useLocation();
  const showBackButton = pathname.includes('/table/');
  const {selectedEquipment, refreshCounter, key} = props;
  const [editModal, setEditModal] = useState({});
  const [loadingRowUUIDs, setLoadingRowUUIDs] = useState([]);
  const [evaluations, setEvaluations] = useState(selectedEquipment?.evaluations || []);
  const [loadingRolledUpColumns, setLoadingRolledUpColumns] = useState(false);
  const [loading, setLoading] = useState(false);

console.log("EVALUATIONS", evaluations);
  useEffect(() => {
    updateRollUps();
  }, [selectedEquipment.uuid, selectedEquipment.updated_at, selectedEquipment, refreshCounter, key]);
  const updateRollUps = async () => {
    setLoading(true);

    const {data} = await Equipment.filterBy([{ 
      value: selectedEquipment.uuid,
      field: 'uuid'
    }], [], 'updated_at', 'desc', 1, undefined, undefined, undefined, true);
    const foundEquipment = data.length > 0 ? data[0] : null;

    const rawEvaluations = [
      ...(foundEquipment?.evaluations.map(evaluation => ({
        ...evaluation,
        equipmentName: foundEquipment.name, // Add foundEquipment name to its evaluations
        criticality: foundEquipment.criticality
      })) || []),
      ...(foundEquipment?.allSubcomponents?.flatMap(sub => sub.evaluations.map(evaluation => ({
        ...evaluation,
        equipmentName: sub.name, 
        criticality: sub.criticality
      }))) || [])
    ];
  
    setLoadingRolledUpColumns(true);
    const allPromises = rawEvaluations.map(async(evaluation) => {
      evaluation.started_at = moment(evaluation.started_at).startOf('day').toISOString();
      evaluation.ended_at = moment(evaluation.ended_at).endOf('day').toISOString();
      const rolledUpPromises = Promise.all([
        Evaluation.signaturesRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
        Evaluation.maxSeRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
        Evaluation.soundEvalRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at),
        Evaluation.actionsRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at, 0.5),
        Evaluation.tagsRollup(evaluation.fig_id, evaluation.started_at, evaluation.ended_at)
      ])
      const rolledUpData = await rolledUpPromises;
      const [
        rolledUpSignatures, 
        rolledUpSE, 
        rolledUpSoundEval, 
        rolledUpActions, 
        rolledTags
      ] = rolledUpData;
      const newEval ={
        ...evaluation,
        rolledUpSE,
        rolledUpSoundEval,
        rolledUpSignatures,
        rolledUpActions,
        rolledTags
      };


      updateSingleEvaluation( newEval);
      return newEval;
    });
    await Promise.all(allPromises);
    setLoadingRolledUpColumns(false);
    setLoading(false);
  }

  const fetchNamesByUUIDs = async (uuids, fetchFunction) => {
    const names = await Promise.all(uuids.map(async (uuid) => {
      const result = await fetchFunction(uuid);
      return result.length > 0 ? result[0].name : uuid;
    }));
    return names;
  };

  const updateSingleEvaluation = async (updatedEvaluation) => {

    if (updatedEvaluation.recorded_yhat_json) {
      try {
        const parsedYhatJson = JSON.parse(updatedEvaluation.recorded_yhat_json);
       
        let parsedNode;
        if (parsedYhatJson.nodes) {
          parsedNode = parsedYhatJson.nodes[0];
        } else {
          parsedNode = parsedYhatJson;
        }
        const mpeTagsNames = await fetchNamesByUUIDs(parsedNode.tagUuids, MultipointEvaluation.findTagsByUUID);
        const mpeActionsNames = await fetchNamesByUUIDs(parsedNode.actionUuids, MultipointEvaluation.findActionsByUUID);
        updatedEvaluation.mpeTags = mpeTagsNames;
        updatedEvaluation.mpeActions = mpeActionsNames;
      } catch (error) {
        console.error("Error parsing recorded_yhat_json:", error);
      }
    }
    selectedEquipment.evaluations = [
      ...selectedEquipment.evaluations.filter(x => x.uuid != updatedEvaluation.uuid),
      updatedEvaluation
    ];
    setEvaluations(selectedEquipment.evaluations);
  }

  const deleteEvaluation = async (uuid) => {
    if (!isSuperAdmin()) {
      alert("You must be a super admin to delete an evaluation");
      return;
    }
  
    if (confirm(`Warning! Please confirm this action by clicking OK.`)) {
      const updatedEvaluations = evaluations.filter(evaluation => evaluation.uuid !== uuid);
      setEvaluations(updatedEvaluations);
      const returnedValue = await Evaluation.delete(uuid);
    }
  };
  

  const editNotes = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Evaluation Notes",
      value: row,
      selector: row => row.comments || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Evaluation.update({...row, comments: newValue});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  };

  const editRecommendations = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Recommendations / Repairs",
      value: row,
      selector: row => row.rec || "",
      type: "text",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Evaluation.update({...row, rec: newValue});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  };

  const editSoundLevel = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Sound Level",
      value: row,
      selector: row => row.sound_level || 0,
      type: "slider",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Evaluation.update({...row, sound_level: newValue});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  };



  const editLevel = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Set Evaluation Severity",
      value: row,
      type: "evaluationSeverity",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        setLoading(true);
        const returnedRow = await Evaluation.update({...row, level: newValue});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
        setLoading(false);
      }
    });
  };

  const editStartAtDate = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update Start Date",
      value: row,
      selector: row => moment.tz(row.started_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-MM-DD') || "",
      type: "date",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Evaluation.update({...row, started_at: moment(newValue).utcOffset(0).format()});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  };

  const editEndedAtDate = async (row) => {
    if (loadingRowUUIDs.length > 0) return;
    setLoadingRowUUIDs([...loadingRowUUIDs, row.uuid])
    setEditModal({
      title: "Update End Date",
      value: row,
      selector: row => moment.tz(row.ended_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-MM-DD') || "",
      type: "date",
      onHide: () => {
        setEditModal({})
        setLoadingRowUUIDs([]);
      },
      onSubmit: async (e, row, newValue) => {
        e.preventDefault();
        const returnedRow = await Evaluation.update({...row, ended_at: moment(newValue).utcOffset(0).format()});
        await updateSingleEvaluation({...row, ...returnedRow});
        setLoadingRowUUIDs(loadingRowUUIDs.filter(uuid => uuid != row.uuid))
      }
    });
  };

  
  const renderCreatedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
  }}>{moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>
  const extractCreatedAtFormattedYMD = row => moment.tz(row.created_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')



  const renderStartedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
    editStartAtDate(row)
  }}>{moment.tz(row.started_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>
  const extractStartedAtFormattedYMD = row => moment.tz(row.started_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')
  const renderEndedAtFormatted = row => <a onClick={(e) => {
    e.preventDefault();
    editEndedAtDate(row)
  }}>{moment.tz(row.ended_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('M-D-YY')}</a>
  const extractEndedAtFormattedYMD = row => moment.tz(row.ended_at, 'YYYY-MM-DD HH:mm:ss', Utils.UTC_TZ).local().format('YYYY-M-D HH:mm')

  const extractLevel = row => row.level * row.criticality;

  const extractSoundEvaluation = row => row.sound_level * row.criticality;
  const renderSoundEvaluation = row => <EvaluationMark criticality={row.sound_level * row.criticality} />;


  const extractTags = row => row.rolledTags;
  const renderTags = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : row.rolledTags || "N/A";
  const extractSignature = row => row.rolledUpSignatures;
  const renderSignature = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : row.rolledUpSignatures || "N/A";
  const extractActions = row => row.rolledUpActions;
  const renderActions = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : row.rolledUpActions || "N/A";
  
  const renderLevel = row => 
  <EvaluationMark criticality={row.level * row.criticality} onClick={async (e) => {
    e.preventDefault();
    editLevel(row)
  }} />;

  const extractSE = row => row.rolledUpSE;
  const renderSE = row =>  loadingRolledUpColumns ? <LoadingSpinner /> : `${row.rolledUpSE ?? 0} SE`;

  const extractNotes = row => row.comments;
  const renderNotes = row => <a onClick={(e) => {
    e.preventDefault();
    editNotes(row)
  }}>{row.comments || "N/A"}</a>;
  const extractRecommendations = row => row.rec;
  const renderRecommendations = row => <a onClick={(e) => {
    e.preventDefault();
    editRecommendations(row)
  }}>{row.rec || "N/A"}</a>;


  const extractMPEUUID = row => row.net_samples_uuid;
  const renderMPEUUID = row => <a>{row.net_samples_uuid || "N/A"}</a>;
  const badgeStyle = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '90%',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem',
    color: '#fff',
    backgroundColor: '#007bff',
    margin: '0.1em',
    maxWidth: '25ch'
  };


  const columns =
    [
      {
        name: 'Equipment Name',
        selector: row => row.equipmentName,
        sortable: true,
        center: true,
        compact: true,
        width: '150px'
      },
      {
        name: 'Created At',
        selector: extractCreatedAtFormattedYMD,
        cell: renderCreatedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      },
      {
        name: 'SoundEvaluator™',
        selector: extractSoundEvaluation,
        cell: renderSoundEvaluation,
        sortable: true,
        center: true,
        compact: true,
        width: '130px'
      },
      {
        name: 'Evaluation',
        selector: extractLevel,
        cell: renderLevel,
        sortable: true,
        center: true,
        compact: true,
        width: '90px'
      },
      {
        name: 'MPE Tags',
        selector: row => row.mpeTags || [],
        cell: row => (
          <div>
            {row.mpeTags?.map((tag, index) => {
              const displayTag = tag.includes('^') ? tag.split('^')[1] : tag;
              return <span key={index} style={badgeStyle}>{displayTag}</span>;
            })}
          </div>
        ),
      },
      {
        name: 'MPE Actions',
        selector: row => row.mpeActions || [],
        cell: row => (
          <div>
            {row.mpeActions?.map((action, index) => {
              const displayAction = action.includes('^') ? action.split('^')[1] : action;
              return <span key={index} style={badgeStyle}>{displayAction}</span>;
            })}
          </div>
        )
      },
    
      {
        name: 'Started At',
        selector: extractStartedAtFormattedYMD,
        cell: renderStartedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      },
      {
        name: 'Ended At',
        selector: extractEndedAtFormattedYMD,
        cell: renderEndedAtFormatted,
        sortable: true,
        center: true,
        compact: true,
        width: '100px'
      },

      {
        name: 'SoundEnergy™',
        selector: extractSE,
        cell: renderSE,
        sortable: true,
        center: true,
        compact: true,
        width: '120px'
      },
     
        {
          name: 'Tags',
          selector: extractTags,
          cell: renderTags,
          sortable: true,
          center: true,
          compact: true,
          width: '120px'
        },
        {
          name: 'SoundSignature™',
          selector: extractSignature,
          cell: renderSignature,
          sortable: true,
          center: true,
          compact: true,
          width: '120px'
        },
        {
          name: 'Recommended Action',
          selector: extractActions,
          cell: renderActions,
          sortable: true,
          center: true,
          compact: true,
          width: '140px'
        },
        {
          name: 'Comments',
          selector: extractNotes,
          cell: renderNotes,
          sortable: true,
          center: true,
          compact: true,
          width: '200px' 
        },
      
      {
        name: 'Recommendations / Repairs',
        selector: extractRecommendations,
        cell: renderRecommendations,
        sortable: true,
        center: true,
        compact: true,
      },
      // {
      //   name: 'MPE UUID',
      //   selector: extractMPEUUID,
      //   cell: renderMPEUUID,
      //   sortable: true,
      //   center: true,
      //   compact: true,
      // },
      {
        name: 'Delete',
        cell: (row) => <button onClick={() => deleteEvaluation(row.uuid)}>Delete</button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
      
    ].filter(x => x != null)

  const evals = React.useMemo(() => selectedEquipment.evaluations, [selectedEquipment]);

  return (
    <>
    <div className="d-flex flex-row justify-content-start align-items-start">
    {editModal.type === "evaluationSeverity" && (
        <EvaluationSeverityModal
          show={!!editModal.title}
          onHide={editModal.onHide}
          onSubmit={editModal.onSubmit}
          row={editModal.value}  
        />
      )}
      {     
        editModal.type !== "evaluationSeverity" &&   (<EditModal options={editModal}/>)
      }        
    
    <div className="d-flex flex-column rounded-lg bg-white mt-2 "  
          style={{minWidth: '800px'}}
        >
          {showBackButton && <div className="d-flex flex-row align-items-baseline ">
                <Button variant="link"  className="d-flex flex-row align-items-baseline " onClick={() => {
                    setSelectedRoutine(null)
                    setSelectedProperty(null)
                    history.push("/analytics")
                }}>
                    <MdArrowBack />
                </Button>

                <div className="d-flex flex-column">
                    <h4>Analysis</h4>
                </div>
            </div>}

          <div className="d-flex flex-column justify-content-start align-items-top flex-wrap mt-3"> 
            <DataTable
              title="MSi Inspections and Tasks"
              keyField="uuid"
              columns={columns}

            data={evaluations
              .filter(evaluation => moment(evaluation.created_at, 'YYYY-MM-DD').isSameOrAfter(moment(startDate).startOf('day')) && moment(evaluation.created_at, 'YYYY-MM-DD').isSameOrBefore(moment(endDate).startOf('day')))
              .sort((a, b) => moment(b.ended_at).startOf('day') - moment(a.ended_at).startOf('day'))
            }
              noDataComponent = {"No evaluations."}
              dense
              noHeader
              highlightOnHover
              disabled={loadingRowUUIDs.length > 0}
              progressPending={loading}
            />
          </div>
        </div>
    </div>
    <div className="d-flex flex-row justify-content-end align-items-end">
      <button type="button" className="btn" onClick={() => {
        const columnNames = columns.map(col => `'${col.name}'`)
        const dataRowsAsTextOnly = evaluations.map(row => columns.map(col => col.selector ? `'${col.selector(row)}'` : ``))
        
        const csvCombined = [
          columnNames.join(','),
          ...(dataRowsAsTextOnly || []).map(row => row.join(','))
        ].join('\n');
          
        let element = document.createElement('a');
        element.setAttribute('href','data:text/csv;charset=utf-8, ' + encodeURIComponent(csvCombined));
        element.setAttribute('download', `export.csv`);
        document.body.appendChild(element);
        element.click();
      }}>
      <FcDownload/>
    </button>
  </div>
  </>
  )
}

export default EvaluationTableComponent;