import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/react-toggle/style.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import Fonts from 'react-web-vector-icons/fonts'

Sentry.init({
  dsn: "https://ae30e34b3d9641229d75f1d9ed59a190@o452240.ingest.sentry.io/6134636",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// reportWebVitals(console.log);
